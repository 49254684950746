import GroundFloorFloorplate from '../assets/floorplates/floorplate-ground.png';

const GroundFloor = ({ handleVrClick }) => {
    return (
        <div className="suites__floorplate">
            <img src={GroundFloorFloorplate} alt="" />
            <div className="suites__floorplate--units">
            <svg xmlns="http://www.w3.org/2000/svg" width="3840" height="2160" viewBox="-9 -8 3840 2160">
                <g id="Units">
                  <rect width="3840" height="2160" fill="none"/>
                  <g className="suites__unit" id="GC1">
                    <polygon id="C1_UNIT_MERRY" data-name="C1 UNIT MERRY" points="1409.38 1481.8 1383.75 1458.71 1338.03 1509.45 1342.33 1513.33 1332.35 1524.4 1405.65 1590.45 1465.97 1523.51 1414 1476.68 1409.38 1481.8" fill="#dadada"/>
                    <g>
                      <g>
                        <path d="M1397.2,1511.88a2.72,2.72,0,0,0-2.77-2c-1.73,0-3.19,1.21-3.19,3.81s1.46,3.81,3.19,3.81a2.73,2.73,0,0,0,2.77-2l1.87.48a4.39,4.39,0,0,1-4.64,3.4c-2.81,0-5.3-2-5.3-5.73s2.49-5.73,5.3-5.73a4.39,4.39,0,0,1,4.64,3.39Z" fill="#59585b"/>
                        <path d="M1404.81,1508.14v11.2h-2V1511l-2.14,1.28v-2.17l3.28-1.92Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1363.88,1525.14,1362,1535h4.93l-.27,1.34h-6.36l2.19-11.2Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1375.1,1525.14l1.7,11.2h-1.42l-.41-3h-4.17l-1.59,3h-1.42l6-11.2Zm-3.6,6.86h3.28l-.72-5Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1387.12,1525.14l-6,11.2h-1.42l-1.55-11.2h1.48l1.16,9.2,4.8-9.2Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1395.37,1525.14l-.27,1.34h-4.91l-.63,3.22h4l-.25,1.34h-4l-.77,4h5.07l-.27,1.34h-6.5l2.19-11.2Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1405.64,1525.14l-2.19,11.2h-1.59l-3.28-8.72-1.7,8.72h-1.42l2.19-11.2h1.59l3.26,8.75,1.72-8.75Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1411.28,1525.14c2.6,0,4.42,1.47,4.42,4.46,0,3.75-2.56,6.74-6.38,6.74h-3.11l2.19-11.2Zm-1.72,1.34-1.65,8.52h1.42c3,0,4.85-2.33,4.85-5.25,0-2.2-1.15-3.27-3.2-3.27Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1425.05,1525.14l-.27,1.34h-4.91l-.63,3.22h4L1423,1531h-4l-.77,4h5.07l-.27,1.34h-6.5l2.19-11.2Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1429.25,1531.06h-1.64l-1,5.28h-1.42l2.19-11.2h3.56c1.77,0,2.85,1,2.85,2.51a3.15,3.15,0,0,1-3,3.31l2.29,5.38h-1.58Zm-.76-4.58-.63,3.23h2.23c1.34,0,2.17-.59,2.17-1.81,0-1-.56-1.42-1.6-1.42Z" fill="#59585b"/>
                      </g>
                    </g>
                  </g>
                  <g className="suites__unit" id="GC2">
                    <polygon id="C2_UNIT_STELLAR" data-name="C2 UNIT STELLAR" points="1263.08 1601.28 1278.35 1615.04 1288.5 1603.74 1346.53 1656.05 1402.97 1593.42 1329.68 1527.37 1263.08 1601.28" fill="#dadada"/>
                    <g>
                      <g>
                        <path d="M1337.47,1576.31a2.72,2.72,0,0,0-2.77-2c-1.73,0-3.19,1.21-3.19,3.81s1.46,3.81,3.19,3.81a2.73,2.73,0,0,0,2.77-2l1.87.47a4.39,4.39,0,0,1-4.64,3.4c-2.8,0-5.3-2-5.3-5.73s2.5-5.73,5.3-5.73a4.39,4.39,0,0,1,4.64,3.39Z" fill="#59585b"/>
                        <path d="M1340.85,1583l4-5.17a3.06,3.06,0,0,0,.86-2,1.29,1.29,0,0,0-1.38-1.48c-.8,0-1.32.53-1.45,1.48l-1.86-.36a3.21,3.21,0,0,1,3.36-3,3.09,3.09,0,0,1,3.35,3.25,4.29,4.29,0,0,1-1,2.65l-2.74,3.57H1348v1.86h-7.13Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1322.21,1589.57l-1.92,9.86h4.93l-.27,1.34h-6.36l2.19-11.2Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1330.55,1589.57l-2.2,11.2h-1.42l2.19-11.2Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1334.61,1589.57l-1.92,9.86h4.93l-.27,1.34H1331l2.19-11.2Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1345.82,1589.57l1.7,11.2h-1.43l-.4-3h-4.17l-1.59,3h-1.42l6-11.2Zm-3.6,6.86h3.28l-.72-5Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1358.21,1597.5c-1,2.21-2.57,3.4-4.83,3.4s-4.07-1.55-4.07-4.48c0-3.56,2.29-7,6.21-7a3.55,3.55,0,0,1,3.64,3.39l-1.36.37c-.27-1.68-1.11-2.42-2.53-2.42-3,0-4.48,2.8-4.48,5.48,0,2.11.94,3.29,2.77,3.29,1.47,0,2.48-.7,3.41-2.44Z" fill="#59585b"/>
                      </g>
                    </g>
                  </g>
                  <g className="suites__unit" id="GC3">
                    <polygon id="C3_UNIT_INSPIRE" data-name="C3 UNIT INSPIRE" points="1281.32 1617.72 1281.32 1617.72 1260.81 1640.48 1267.99 1646.95 1245.82 1671.55 1262.22 1686.33 1255.2 1694.12 1289.95 1725.43 1291.71 1723.48 1288.43 1720.53 1343.85 1659.02 1288.8 1609.39 1281.32 1617.72" fill="#dadada"/>
                    <g>
                      <g>
                        <path d="M1290.46,1652.39a2.72,2.72,0,0,0-2.77-2c-1.73,0-3.19,1.21-3.19,3.81s1.46,3.81,3.19,3.81a2.73,2.73,0,0,0,2.77-2l1.87.48a4.39,4.39,0,0,1-4.64,3.4c-2.8,0-5.3-2-5.3-5.73s2.5-5.73,5.3-5.73a4.39,4.39,0,0,1,4.64,3.39Z" fill="#59585b"/>
                        <path d="M1295.65,1656.49a1.81,1.81,0,0,0,1.81,1.63,1.93,1.93,0,0,0,0-3.84,2,2,0,0,0-1,.24l-.8-1.12,2.11-2.9h-3.56v-1.85h6.41v.7l-2.64,3.57a3,3,0,0,1,.45,0c1.73,0,2.79,1.36,2.79,3.33a3.79,3.79,0,0,1-7.54.52Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1272.59,1665.65l-1.92,9.85h4.93l-.27,1.35H1269l2.19-11.2Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1287.19,1670.05a7.09,7.09,0,0,1-2.42,5.57A5.36,5.36,0,0,1,1281,1677c-2.31,0-4-1.65-4-4.53a7.07,7.07,0,0,1,2.42-5.57,5.34,5.34,0,0,1,3.76-1.36C1285.54,1665.52,1287.19,1667.17,1287.19,1670.05Zm-7.09-1.88a6,6,0,0,0-1.57,4.15c0,2.14,1,3.31,2.72,3.31a3.94,3.94,0,0,0,2.9-1.31,6.05,6.05,0,0,0,1.56-4.15c0-2.14-1-3.31-2.72-3.31A3.94,3.94,0,0,0,1280.1,1668.17Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1289.2,1665.65h7.73l-.27,1.34h-3.16l-1.92,9.86h-1.42l1.92-9.86h-3.15Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1299.37,1665.65h1.43l-1.46,7.49a4.64,4.64,0,0,0-.07.64c0,1.18.6,1.85,2,1.85a2.84,2.84,0,0,0,3-2.49l1.45-7.49h1.43l-1.43,7.39c-.54,2.85-2.26,3.94-4.61,3.94a2.88,2.88,0,0,1-3.2-2.87,5.84,5.84,0,0,1,.1-1.07Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1313.66,1668.17c-.35-1-.87-1.31-1.54-1.31a1.42,1.42,0,0,0-1.55,1.55c0,1,.7,1.44,1.78,2,1.28.67,2.13,1.24,2.13,2.86a3.28,3.28,0,0,1-1.06,2.66,3.89,3.89,0,0,1-3,1,3.11,3.11,0,0,1-3-3.08l1.28-.68c.1,1.32.72,2.41,2,2.41a2,2,0,0,0,2.24-2.09c0-1.11-.64-1.52-1.71-2a3.25,3.25,0,0,1-2.19-2.87,3,3,0,0,1,3.28-3.1,2.57,2.57,0,0,1,2.5,2Z" fill="#59585b"/>
                      </g>
                    </g>
                  </g>
                  <g className="suites__unit" id="GC4">
                    <polygon id="C4_UNIT_PRIMROSE" data-name="C4 UNIT PRIMROSE" points="1256.57 1686.62 1240.17 1671.84 1262.34 1647.24 1256.65 1642.11 1241.53 1628.49 1206.06 1667.85 1194.37 1657.32 1149.02 1707.65 1231.13 1781.64 1250.89 1759.71 1255.4 1763.77 1287.27 1728.4 1249.55 1694.41 1256.57 1686.62" fill="#dadada"/>
                    <g>
                      <g>
                        <path d="M1217,1701.89a2.72,2.72,0,0,0-2.77-2c-1.73,0-3.19,1.21-3.19,3.81s1.46,3.81,3.19,3.81a2.73,2.73,0,0,0,2.77-2l1.87.48a4.39,4.39,0,0,1-4.64,3.4c-2.8,0-5.3-2-5.3-5.73s2.5-5.73,5.3-5.73a4.39,4.39,0,0,1,4.64,3.39Z" fill="#59585b"/>
                        <path d="M1225.07,1707.27h-4.82v-1l4.73-8.09h2.11v7.26h1.25v1.86h-1.25v2.08h-2Zm0-1.86v-4.27l-2.37,4.27Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1197.26,1715.15l-1.92,9.85h4.93l-.27,1.35h-6.36l2.19-11.2Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1203.8,1715.15h1.43l-1.46,7.49a4.62,4.62,0,0,0-.06.64c0,1.18.59,1.85,2,1.85a2.84,2.84,0,0,0,3-2.49l1.45-7.49h1.43l-1.43,7.39c-.54,2.85-2.26,3.94-4.61,3.94a2.88,2.88,0,0,1-3.2-2.87,5.84,5.84,0,0,1,.1-1.07Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1214.15,1721.55l-.94,4.8h-1.43l2.19-11.2h3.59c1.81,0,3,1,3,2.65,0,2.5-1.66,3.75-4.13,3.75Zm4.91-3.49a1.42,1.42,0,0,0-1.6-1.57h-2.33l-.72,3.71h2.44A2,2,0,0,0,1219.06,1718.06Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1224.49,1715.15l-2.2,11.2h-1.42l2.19-11.2Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1235.11,1715.15l-2.19,11.2h-1.59l-3.28-8.72-1.7,8.72h-1.42l2.19-11.2h1.59l3.26,8.75,1.72-8.75Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1244.22,1715.15l-.27,1.34H1239l-.63,3.22h4l-.25,1.34h-4l-.77,4h5.07l-.27,1.35h-6.5l2.19-11.2Z" fill="#59585b"/>
                      </g>
                    </g>
                  </g>
                  <g className="suites__unit" id="GC5">
                    <polygon id="C5_UNIT_REVIVE" data-name="C5 UNIT REVIVE" points="1214.73 1603.21 1198.93 1588.98 1198.93 1588.98 1194.52 1585.01 1170.14 1612.07 1152.3 1596 1149.53 1599.07 1143.99 1594.09 1140.67 1597.78 1110.53 1570.62 1079.4 1605.16 1084.73 1609.97 1064.96 1631.9 1133.51 1693.67 1214.73 1603.21" fill="#dadada"/>
                    <g>
                      <g>
                        <path d="M1128.12,1617a2.72,2.72,0,0,0-2.77-2c-1.73,0-3.19,1.21-3.19,3.81s1.46,3.81,3.19,3.81a2.73,2.73,0,0,0,2.77-2l1.87.47a4.39,4.39,0,0,1-4.64,3.4c-2.81,0-5.3-2-5.3-5.73s2.49-5.73,5.3-5.73a4.39,4.39,0,0,1,4.64,3.39Z" fill="#59585b"/>
                        <path d="M1133.54,1621.21c.24,1.23.94,1.55,1.68,1.55,1.09,0,1.66-.72,1.66-2s-.62-1.94-1.76-1.94a2.75,2.75,0,0,0-2,1l-1.27-.26.63-6.3h5.76v1.85h-4.11l-.26,2.34a3.07,3.07,0,0,1,1.58-.42c2.34,0,3.43,1.52,3.43,3.72a3.57,3.57,0,0,1-3.68,3.84,3.41,3.41,0,0,1-3.59-3.06Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1095.19,1630.29l1.47,7.89,4.47-7.89h1.72l-2.19,11.2h-1.42l1.6-8.16-4.07,7.14h-1l-1.36-7.14-1.61,8.16h-1.42l2.19-11.2Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1109.58,1630.29l1.7,11.2h-1.43l-.4-3h-4.17l-1.59,3h-1.42l6-11.2Zm-3.6,6.86h3.28l-.72-5Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1118.84,1635.84h3.77l-1.1,5.65h-1.22l.15-1.54a3.88,3.88,0,0,1-3.41,1.67c-2,0-4-1.44-4-4.56,0-3.59,2.26-6.9,6.2-6.9a3.47,3.47,0,0,1,3.68,3.38l-1.41.36a2.38,2.38,0,0,0-2.53-2.4c-2.72,0-4.46,2.56-4.46,5.35,0,2.22,1,3.42,2.75,3.42a3.76,3.76,0,0,0,3.59-3.09h-2.31Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1134.44,1630.29l-2.19,11.2h-1.59l-3.28-8.72-1.69,8.72h-1.43l2.19-11.2H1128l3.26,8.75,1.72-8.75Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1145.48,1634.69a7.09,7.09,0,0,1-2.42,5.57,5.36,5.36,0,0,1-3.76,1.36c-2.31,0-4-1.65-4-4.53a7.07,7.07,0,0,1,2.42-5.57,5.34,5.34,0,0,1,3.76-1.36C1143.83,1630.16,1145.48,1631.81,1145.48,1634.69Zm-7.09-1.88a6,6,0,0,0-1.57,4.15c0,2.15,1,3.31,2.72,3.31a3.94,3.94,0,0,0,2.9-1.31,6,6,0,0,0,1.56-4.14c0-2.15-1-3.32-2.72-3.32A3.94,3.94,0,0,0,1138.39,1632.81Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1150.36,1630.29l-1.92,9.86h4.93l-.27,1.34h-6.36l2.19-11.2Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1158.69,1630.29l-2.19,11.2h-1.43l2.19-11.2Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1165.25,1630.29l1.7,11.2h-1.43l-.4-3H1161l-1.59,3h-1.42l6-11.2Zm-3.6,6.86h3.28l-.72-5Z" fill="#59585b"/>
                      </g>
                    </g>
                  </g>
                  <g className="suites__unit" id="GC6">
                    <polygon id="C6_UNIT_ORCHID" data-name="C6 UNIT ORCHID" points="1143.7 1588.44 1149.24 1593.43 1152.01 1590.35 1169.84 1606.42 1194.23 1579.36 1201.61 1586.01 1224.15 1561 1224.56 1561.37 1228.35 1557.17 1173.92 1508.11 1119.78 1568.18 1115.89 1564.67 1113.21 1567.65 1140.37 1592.13 1143.7 1588.44" fill="#dadada"/>
                    <g>
                      <g>
                        <path d="M1170.62,1545.71a2.72,2.72,0,0,0-2.77-2c-1.73,0-3.19,1.21-3.19,3.81s1.46,3.81,3.19,3.81a2.73,2.73,0,0,0,2.77-2l1.87.48a4.39,4.39,0,0,1-4.64,3.4c-2.81,0-5.3-2-5.3-5.73s2.49-5.73,5.3-5.73a4.39,4.39,0,0,1,4.64,3.39Z" fill="#59585b"/>
                        <path d="M1178,1546a3.51,3.51,0,0,1,3.62,3.65,3.75,3.75,0,0,1-7.49,0,4.3,4.3,0,0,1,.54-2l3.27-5.84h2.24l-2.43,4.16Zm-1.85,3.65a1.73,1.73,0,1,0,3.46,0,1.73,1.73,0,1,0-3.46,0Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1143.92,1559l1.47,7.89,4.47-7.89h1.72l-2.19,11.2H1148l1.6-8.16-4.07,7.14h-1l-1.36-7.14-1.61,8.16h-1.42l2.19-11.2Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1154.3,1559h1.43l-1.46,7.49a4.62,4.62,0,0,0-.06.64c0,1.18.59,1.85,2,1.85a2.84,2.84,0,0,0,3-2.49l1.45-7.49H1162l-1.43,7.39c-.54,2.85-2.26,3.94-4.61,3.94a2.88,2.88,0,0,1-3.2-2.87,5.84,5.84,0,0,1,.1-1.07Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1168.59,1561.49c-.35-1-.87-1.31-1.54-1.31a1.42,1.42,0,0,0-1.55,1.55c0,1,.7,1.44,1.78,2,1.28.67,2.13,1.24,2.13,2.86a3.28,3.28,0,0,1-1.06,2.66,3.89,3.89,0,0,1-3,1,3.11,3.11,0,0,1-3-3.08l1.28-.68c.1,1.33.72,2.41,2,2.41a2,2,0,0,0,2.24-2.09c0-1.11-.64-1.52-1.71-2a3.25,3.25,0,0,1-2.19-2.87,3,3,0,0,1,3.28-3.1,2.55,2.55,0,0,1,2.49,2Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1179.8,1566.9c-1,2.21-2.57,3.4-4.83,3.4s-4.07-1.55-4.07-4.48c0-3.56,2.29-7,6.22-7a3.55,3.55,0,0,1,3.63,3.39l-1.36.37c-.27-1.68-1.11-2.42-2.53-2.42-2.95,0-4.48,2.8-4.48,5.48,0,2.11.94,3.29,2.77,3.29,1.47,0,2.48-.7,3.41-2.45Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1187.85,1559l1.7,11.2h-1.43l-.4-3h-4.17l-1.59,3h-1.42l6-11.2Zm-3.6,6.86h3.28l-.72-5Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1195.42,1564.89h-1.64l-1,5.28h-1.42l2.19-11.2h3.56c1.77,0,2.85,1,2.85,2.51a3.15,3.15,0,0,1-3,3.31l2.29,5.38h-1.58Zm-.76-4.58-.63,3.23h2.23c1.34,0,2.17-.59,2.17-1.81,0-1-.56-1.42-1.6-1.42Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1204.42,1559l-2.2,11.2h-1.42L1203,1559Z" fill="#59585b"/>
                      </g>
                    </g>
                  </g>
                  <g className="suites__unit" id="GB11">
                    <rect id="B11_UNIT_FLORA" data-name="B11 UNIT FLORA" x="1327.38" y="549.55" width="87.1" height="98.66" fill="#dadada"/>
                    <g>
                      <g>
                        <path d="M1366.38,584.89c1.9,0,3.2,1,3.2,3a2.26,2.26,0,0,1-1.1,2.07,2.68,2.68,0,0,1,1.68,2.73c0,2.18-1.41,3.38-3.7,3.38h-4.18v-11.2Zm-2,1.92v2.43h1.91c.83,0,1.31-.31,1.31-1.22s-.48-1.21-1.36-1.21Zm0,4.35v3h1.91c1.29,0,1.8-.55,1.8-1.51s-.51-1.5-1.8-1.5Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1376.07,584.89v11.2h-2V587.7l-2.14,1.28v-2.17l3.28-1.92Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1382.33,584.89v11.2h-2V587.7l-2.14,1.28v-2.17l3.28-1.92Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1356.64,601.89l-2.19,11.2h-1.59l-3.28-8.72-1.7,8.72h-1.42l2.19-11.2h1.59l3.26,8.75,1.72-8.75Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1365.75,601.89l-.27,1.34h-4.91l-.63,3.22h4l-.25,1.34h-4l-.77,4H1364l-.27,1.34h-6.5l2.19-11.2Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1370,607.81h-1.64l-1,5.28h-1.42l2.19-11.2h3.56c1.77,0,2.85,1,2.85,2.51a3.15,3.15,0,0,1-3,3.31l2.29,5.38h-1.58Zm-.76-4.58-.63,3.23h2.23c1.34,0,2.17-.59,2.17-1.81,0-1-.56-1.42-1.6-1.42Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1379,601.89l-2.2,11.2h-1.42l2.19-11.2Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1389.57,601.89l-2.19,11.2h-1.59l-3.28-8.72-1.7,8.72h-1.42l2.19-11.2h1.59l3.26,8.75,1.72-8.75Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1398.67,601.89l-.27,1.34h-4.91l-.63,3.22h4l-.25,1.34h-4l-.77,4h5.07l-.27,1.34h-6.5l2.19-11.2Z" fill="#59585b"/>
                      </g>
                    </g>
                  </g>
                  <g className="suites__unit" id="GB12">
                    <polygon id="B12_UNIT_GLOW" data-name="B12 UNIT GLOW" points="1225.25 549.66 1225.27 570.77 1239.34 570.76 1239.39 648.31 1323.42 648.26 1323.36 549.59 1225.25 549.66" fill="#dadada"/>
                    <g>
                      <g>
                        <path d="M1274.92,584.89c1.9,0,3.2,1,3.2,3A2.26,2.26,0,0,1,1277,590a2.68,2.68,0,0,1,1.68,2.73c0,2.18-1.41,3.38-3.7,3.38h-4.18v-11.2Zm-2,1.92v2.43h1.91c.83,0,1.31-.31,1.31-1.22s-.48-1.21-1.36-1.21Zm0,4.35v3h1.91c1.29,0,1.8-.55,1.8-1.51s-.51-1.5-1.8-1.5Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1284.61,584.89v11.2h-2V587.7l-2.14,1.28v-2.17l3.28-1.92Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1286.8,595.29l4-5.17a3.06,3.06,0,0,0,.86-2,1.29,1.29,0,0,0-1.38-1.48c-.8,0-1.33.53-1.45,1.48l-1.86-.36a3.21,3.21,0,0,1,3.36-3,3.09,3.09,0,0,1,3.35,3.25,4.29,4.29,0,0,1-1,2.65l-2.74,3.57h3.94v1.86h-7.13Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1252.84,601.89c1.79,0,2.77.91,2.77,2.49a2.76,2.76,0,0,1-1.78,2.61,2.37,2.37,0,0,1,1.42,2.27c0,2.53-1.8,3.83-4.21,3.83h-4l2.19-11.2Zm-4,9.86h2.32c1.53,0,2.67-.71,2.67-2.21,0-1.14-.69-1.75-1.9-1.75h-2.32Zm1.65-8.52-.63,3.22h2.24a1.89,1.89,0,0,0,2.13-1.81c0-1-.58-1.41-1.54-1.41Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1260.36,601.89l-1.92,9.86h4.93l-.27,1.34h-6.36l2.19-11.2Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1266.9,601.89h1.43l-1.46,7.49a4.64,4.64,0,0,0-.07.64c0,1.18.6,1.85,2,1.85a2.84,2.84,0,0,0,3-2.49l1.45-7.49h1.43l-1.43,7.39c-.54,2.85-2.26,3.94-4.61,3.94a2.88,2.88,0,0,1-3.2-2.87,5.84,5.84,0,0,1,.1-1.07Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1283.41,601.89l-.27,1.34h-4.91l-.63,3.22h4l-.25,1.34h-4l-.77,4h5.07l-.27,1.34h-6.5l2.19-11.2Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1289.26,601.89c1.79,0,2.77.91,2.77,2.49a2.76,2.76,0,0,1-1.78,2.61,2.37,2.37,0,0,1,1.42,2.27c0,2.53-1.8,3.83-4.21,3.83h-4l2.19-11.2Zm-4,9.86h2.32c1.53,0,2.67-.71,2.67-2.21,0-1.14-.69-1.75-1.9-1.75H1286Zm1.65-8.52-.63,3.22h2.24a1.89,1.89,0,0,0,2.13-1.81c0-1-.58-1.41-1.54-1.41Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1301.69,601.89l-.27,1.34h-4.91l-.63,3.22h4l-.25,1.34h-4l-.77,4h5.07l-.27,1.34h-6.5l2.19-11.2Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1305.4,601.89l-1.92,9.86h4.93l-.28,1.34h-6.35l2.19-11.2Z" fill="#59585b"/>
                        <path d="M1313.59,601.89l-1.92,9.86h4.93l-.27,1.34H1310l2.2-11.2Z" fill="#59585b"/>
                      </g>
                    </g>
                  </g>
                  <g className="suites__unit" id="GB17">
                    <polygon id="B17_UNIT_JOY" data-name="B17 UNIT JOY" points="1226.89 521.92 1323.62 521.88 1323.62 423.2 1238.19 423.25 1238.19 499.17 1226.88 499.17 1226.89 521.92" fill="#dadada"/>
                    <g>
                      <g>
                        <path d="M1275.08,457.72c1.9,0,3.2,1,3.2,3a2.26,2.26,0,0,1-1.1,2.07,2.68,2.68,0,0,1,1.68,2.73c0,2.18-1.41,3.38-3.7,3.38H1271v-11.2Zm-2,1.92v2.43H1275c.83,0,1.31-.31,1.31-1.22s-.48-1.21-1.36-1.21Zm0,4.35v3H1275c1.29,0,1.8-.54,1.8-1.51s-.51-1.5-1.8-1.5Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1284.77,457.72v11.2h-2v-8.39l-2.14,1.28v-2.17l3.28-1.92Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1293.57,458.55l-4.22,10.37h-2l3.83-9.35h-4.41v-1.85h6.79Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1251.78,474.72l-.27,1.34h-5l-.64,3.35h4l-.28,1.34h-4l-1,5.17h-1.42l2.19-11.2Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1262,479.12a7.09,7.09,0,0,1-2.42,5.57,5.36,5.36,0,0,1-3.76,1.36c-2.31,0-4-1.65-4-4.53a7.07,7.07,0,0,1,2.42-5.57,5.34,5.34,0,0,1,3.76-1.36C1260.31,474.59,1262,476.24,1262,479.12Zm-7.09-1.88a6,6,0,0,0-1.57,4.15c0,2.15,1,3.31,2.72,3.31a3.94,3.94,0,0,0,2.9-1.31,6,6,0,0,0,1.56-4.14c0-2.15-1-3.32-2.72-3.32A3.94,3.94,0,0,0,1254.87,477.24Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1266.05,474.72l1.38,3.74,2.83-3.74h1.57l-3.89,5.13,2.27,6.07h-1.47l-1.79-4.75-3.56,4.75h-1.61l4.65-6.15-1.89-5Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1278.24,480.27H1282l-1.1,5.65h-1.22l.15-1.54a3.88,3.88,0,0,1-3.41,1.67c-2,0-4-1.44-4-4.56,0-3.59,2.26-6.9,6.2-6.9a3.47,3.47,0,0,1,3.68,3.37l-1.41.37a2.38,2.38,0,0,0-2.53-2.4c-2.72,0-4.46,2.56-4.46,5.35,0,2.22,1,3.42,2.75,3.42a3.76,3.76,0,0,0,3.59-3.09H1278Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1287.28,474.72l-1.92,9.86h4.93l-.27,1.34h-6.36l2.19-11.2Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1301.88,479.12a7.09,7.09,0,0,1-2.42,5.57,5.38,5.38,0,0,1-3.76,1.36c-2.31,0-4-1.65-4-4.53a7.07,7.07,0,0,1,2.42-5.57,5.34,5.34,0,0,1,3.76-1.36C1300.23,474.59,1301.88,476.24,1301.88,479.12Zm-7.09-1.88a6,6,0,0,0-1.57,4.15c0,2.15,1,3.31,2.72,3.31a3.94,3.94,0,0,0,2.9-1.31,6,6,0,0,0,1.56-4.14c0-2.15-1-3.32-2.72-3.32A3.94,3.94,0,0,0,1294.79,477.24Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1312.64,474.72l-6,11.2h-1.42l-1.55-11.2h1.49l1.15,9.2,4.8-9.2Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1320.89,474.72l-.27,1.34h-4.91l-.63,3.22h4l-.25,1.34h-4l-.77,4h5.07l-.27,1.34h-6.5l2.19-11.2Z" fill="#59585b"/>
                      </g>
                    </g>
                  </g>
                  <g className="suites__unit" id="GB18">
                    <polygon id="B19_UNIT_LOVE" data-name="B19 UNIT LOVE" points="1403.07 423.15 1327.62 423.2 1327.62 521.88 1403.04 521.85 1403.07 423.15" fill="#dadada"/>
                    <g>
                      <g>
                        <path d="M1359.62,457.72c1.9,0,3.2,1,3.2,3a2.26,2.26,0,0,1-1.1,2.07,2.68,2.68,0,0,1,1.68,2.73c0,2.18-1.41,3.38-3.7,3.38h-4.18v-11.2Zm-2,1.92v2.43h1.91c.83,0,1.31-.31,1.31-1.22s-.48-1.21-1.36-1.21Zm0,4.35v3h1.91c1.29,0,1.8-.54,1.8-1.51s-.51-1.5-1.8-1.5Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1369.31,457.72v11.2h-2v-8.39l-2.14,1.28v-2.17l3.28-1.92Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1378.56,460.64a2.78,2.78,0,0,1-1.06,2.25,2.9,2.9,0,0,1,1.49,2.68,3.65,3.65,0,0,1-7.3,0,2.89,2.89,0,0,1,1.48-2.68,2.77,2.77,0,0,1-1-2.25,3.22,3.22,0,0,1,6.43,0Zm-4.85,4.93a1.64,1.64,0,1,0,1.63-1.61A1.53,1.53,0,0,0,1373.71,465.57Zm.29-4.89a1.35,1.35,0,1,0,1.34-1.4A1.3,1.3,0,0,0,1374,460.68Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1345,474.72l-.27,1.34h-5l-.64,3.35h4l-.28,1.34h-4l-1,5.17h-1.42l2.19-11.2Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1347.08,474.72h1.43l-1.46,7.49a4.64,4.64,0,0,0-.07.64c0,1.18.6,1.85,2,1.85a2.84,2.84,0,0,0,3-2.49l1.45-7.49h1.43l-1.43,7.39c-.54,2.85-2.26,3.94-4.61,3.94a2.88,2.88,0,0,1-3.2-2.87,5.84,5.84,0,0,1,.1-1.07Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1361.37,477.24c-.35-1-.87-1.31-1.54-1.31a1.42,1.42,0,0,0-1.55,1.55c0,1,.7,1.44,1.78,2,1.28.67,2.13,1.24,2.13,2.86a3.28,3.28,0,0,1-1.06,2.66,3.89,3.89,0,0,1-3,1,3.11,3.11,0,0,1-3-3.08l1.28-.68c.1,1.33.72,2.41,2,2.41a2,2,0,0,0,2.24-2.09c0-1.11-.64-1.52-1.71-2.05a3.25,3.25,0,0,1-2.19-2.87,3,3,0,0,1,3.28-3.1,2.55,2.55,0,0,1,2.49,2Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1372.58,482.65c-1,2.21-2.57,3.4-4.83,3.4s-4.07-1.55-4.07-4.48c0-3.56,2.29-7,6.22-7a3.55,3.55,0,0,1,3.63,3.39l-1.36.37c-.27-1.68-1.11-2.42-2.53-2.42-3,0-4.48,2.8-4.48,5.48,0,2.11.94,3.29,2.77,3.29,1.47,0,2.48-.7,3.41-2.45Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1384.5,474.72l-2.19,11.2h-1.42l1-5.28h-5l-1,5.28h-1.42l2.19-11.2h1.43l-.9,4.57h5l.9-4.57Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1388.72,474.72l-2.2,11.2h-1.42l2.19-11.2Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1395.27,474.72l1.7,11.2h-1.43l-.4-3H1391l-1.59,3H1388l6-11.2Zm-3.6,6.86H1395l-.72-5Z" fill="#59585b"/>
                      </g>
                    </g>
                  </g>
                  <g className="suites__unit" id="GB19">
                    <polygon id="B18_UNIT_KEEN" data-name="B18 UNIT KEEN" points="1499.9 414.81 1498.93 414.81 1498.94 423.09 1407.07 423.15 1407.04 521.85 1499.9 521.81 1499.9 414.81" fill="#dadada"/>
                    <g>
                      <g>
                        <path d="M1445.85,457.72c1.9,0,3.2,1,3.2,3a2.26,2.26,0,0,1-1.1,2.07,2.68,2.68,0,0,1,1.68,2.73c0,2.18-1.41,3.38-3.7,3.38h-4.18v-11.2Zm-2,1.92v2.43h1.91c.83,0,1.31-.31,1.31-1.22s-.48-1.21-1.36-1.21Zm0,4.35v3h1.91c1.29,0,1.8-.54,1.8-1.51s-.51-1.5-1.8-1.5Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1455.54,457.72v11.2h-2v-8.39l-2.14,1.28v-2.17l3.28-1.92Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1461.39,464.89a3.51,3.51,0,0,1-3.61-3.65,3.75,3.75,0,0,1,7.49,0,4.19,4.19,0,0,1-.55,2l-3.26,5.84h-2.24l2.43-4.16Zm1.86-3.65a1.73,1.73,0,1,0-3.46,0,1.73,1.73,0,1,0,3.46,0Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1422.6,480.27h3.77l-1.1,5.65h-1.22l.15-1.54a3.88,3.88,0,0,1-3.41,1.67c-2,0-4-1.44-4-4.56,0-3.59,2.26-6.9,6.2-6.9a3.47,3.47,0,0,1,3.68,3.37l-1.41.37a2.38,2.38,0,0,0-2.53-2.4c-2.72,0-4.46,2.56-4.46,5.35,0,2.22,1,3.42,2.75,3.42a3.76,3.76,0,0,0,3.59-3.09h-2.31Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1434.36,474.72l1.7,11.2h-1.43l-.4-3h-4.17l-1.59,3h-1.42l6-11.2Zm-3.6,6.86H1434l-.72-5Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1441.92,480.64h-1.64l-1,5.28h-1.42l2.19-11.2h3.56c1.77,0,2.85,1,2.85,2.51a3.15,3.15,0,0,1-3,3.31l2.29,5.38h-1.58Zm-.76-4.58-.63,3.23h2.23c1.34,0,2.17-.59,2.17-1.81,0-1-.56-1.42-1.6-1.42Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1452.37,474.72c2.6,0,4.42,1.47,4.42,4.46,0,3.75-2.56,6.74-6.38,6.74h-3.11l2.19-11.2Zm-1.72,1.34-1.65,8.52h1.42c3,0,4.85-2.33,4.85-5.25,0-2.2-1.15-3.27-3.2-3.27Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1466.14,474.72l-.27,1.34H1461l-.63,3.22h4l-.25,1.34h-4l-.77,4h5.07l-.27,1.34h-6.5l2.19-11.2Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1476.41,474.72l-2.19,11.2h-1.59l-3.28-8.72-1.7,8.72h-1.42l2.19-11.2H1470l3.26,8.75,1.72-8.75Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1480.6,474.72l-2.2,11.2H1477l2.19-11.2Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1487.16,474.72l1.7,11.2h-1.43l-.4-3h-4.17l-1.59,3h-1.42l6-11.2Zm-3.6,6.86h3.28l-.72-5Z" fill="#59585b"/>
                      </g>
                    </g>
                  </g>
                  <g className="suites__unit" id="GB13">
                    <polygon id="B13_UNIT_GRAND" data-name="B13 UNIT GRAND" points="1225.27 574.77 1225.27 577.25 1193.26 577.27 1193.26 584.45 1160.42 584.47 1160.43 607.65 1150.22 607.66 1150.22 654.16 1153.43 654.16 1153.42 648.36 1235.39 648.31 1235.34 574.76 1225.27 574.77" fill="#dadada"/>
                    <g>
                      <g>
                        <path d="M1189,600.07c1.9,0,3.2,1,3.2,3a2.26,2.26,0,0,1-1.1,2.07,2.68,2.68,0,0,1,1.68,2.73c0,2.18-1.41,3.38-3.7,3.38h-4.18v-11.2Zm-2,1.92v2.43h1.91c.83,0,1.31-.31,1.31-1.22s-.48-1.21-1.36-1.21Zm0,4.35v3h1.91c1.29,0,1.8-.54,1.8-1.51s-.51-1.5-1.8-1.5Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1198.69,600.07v11.2h-2v-8.39l-2.14,1.28V602l3.28-1.92Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1202.69,607.91a1.81,1.81,0,0,0,1.81,1.63,1.93,1.93,0,0,0,0-3.84,2,2,0,0,0-1,.24l-.8-1.12,2.11-2.9h-3.57v-1.85h6.42v.7l-2.64,3.57a3,3,0,0,1,.45,0c1.73,0,2.79,1.36,2.79,3.33a3.79,3.79,0,0,1-7.54.52Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1175.34,625c-1,2.21-2.57,3.4-4.83,3.4s-4.07-1.55-4.07-4.48c0-3.56,2.29-7,6.21-7a3.55,3.55,0,0,1,3.64,3.39l-1.36.37c-.27-1.68-1.11-2.42-2.53-2.42-3,0-4.48,2.8-4.48,5.48,0,2.11.94,3.29,2.77,3.29,1.47,0,2.48-.7,3.41-2.45Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1185.79,617.07l-.27,1.34h-4.91l-.63,3.22h4l-.25,1.34h-4l-.77,4H1184l-.27,1.34h-6.5l2.19-11.2Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1189.5,617.07l-1.92,9.86h4.93l-.28,1.34h-6.35l2.19-11.2Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1204.1,621.47a7.09,7.09,0,0,1-2.42,5.57,5.38,5.38,0,0,1-3.76,1.36c-2.31,0-4-1.65-4-4.53a7.07,7.07,0,0,1,2.42-5.57,5.34,5.34,0,0,1,3.76-1.36C1202.45,616.94,1204.1,618.59,1204.1,621.47Zm-7.09-1.88a6,6,0,0,0-1.57,4.15c0,2.15,1,3.31,2.72,3.31a3.94,3.94,0,0,0,2.9-1.31,6,6,0,0,0,1.56-4.14c0-2.15-1-3.32-2.72-3.32A3.94,3.94,0,0,0,1197,619.59Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1211.6,619.59c-.35-1-.87-1.31-1.54-1.31a1.42,1.42,0,0,0-1.55,1.55c0,1,.7,1.44,1.78,2,1.28.67,2.13,1.24,2.13,2.86a3.28,3.28,0,0,1-1.06,2.66,3.89,3.89,0,0,1-3,1,3.11,3.11,0,0,1-3-3.08l1.28-.68c.1,1.33.72,2.41,2,2.41a2,2,0,0,0,2.24-2.09c0-1.11-.64-1.52-1.71-2.05A3.25,3.25,0,0,1,1207,620a3,3,0,0,1,3.28-3.1,2.55,2.55,0,0,1,2.49,2Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1217.3,617.07l-2.2,11.2h-1.42l2.19-11.2Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1223.85,617.07l1.7,11.2h-1.43l-.4-3h-4.17l-1.59,3h-1.42l6-11.2Zm-3.6,6.86h3.28l-.72-5Z" fill="#59585b"/>
                      </g>
                    </g>
                  </g>
                  <g className="suites__unit" id="GB14">
                    <polygon id="B14_UNIT_GRACE" data-name="B14 UNIT GRACE" points="1156.42 580.47 1189.26 580.45 1189.24 555.2 1135.7 555.23 1135.69 537.29 1069.04 537.36 1069.11 648.14 1098.09 648.12 1098.09 654.19 1146.22 654.16 1146.22 603.66 1156.43 603.65 1156.42 580.47" fill="#dadada"/>
                    <g>
                      <g>
                        <path d="M1106.74,581.31c1.9,0,3.2,1,3.2,3a2.25,2.25,0,0,1-1.11,2.07,2.67,2.67,0,0,1,1.69,2.73c0,2.18-1.41,3.38-3.7,3.38h-4.18v-11.2Zm-2,1.92v2.43h1.91c.83,0,1.31-.31,1.31-1.22s-.48-1.21-1.36-1.21Zm0,4.35v3h1.91c1.29,0,1.8-.55,1.8-1.51s-.51-1.5-1.8-1.5Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1116.43,581.31v11.2h-2v-8.39l-2.14,1.28v-2.17l3.28-1.92Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1123.22,590.43h-4.82v-1l4.73-8.09h2.11v7.26h1.25v1.86h-1.25v2.08h-2Zm0-1.86V584.3l-2.37,4.27Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1094.54,606.24c-1,2.21-2.57,3.4-4.83,3.4s-4.07-1.56-4.07-4.48c0-3.56,2.29-7,6.21-7a3.55,3.55,0,0,1,3.64,3.39l-1.36.37c-.27-1.68-1.11-2.42-2.53-2.42-2.95,0-4.48,2.8-4.48,5.48,0,2.11.94,3.29,2.77,3.29,1.47,0,2.48-.7,3.41-2.45Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1100.08,598.31l-1.92,9.86h4.93l-.28,1.34h-6.35l2.19-11.2Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1114.67,602.71a7.09,7.09,0,0,1-2.42,5.57,5.38,5.38,0,0,1-3.76,1.36c-2.31,0-4-1.65-4-4.53a7.07,7.07,0,0,1,2.42-5.57,5.34,5.34,0,0,1,3.76-1.36C1113,598.18,1114.67,599.83,1114.67,602.71Zm-7.09-1.88A6,6,0,0,0,1106,605c0,2.14,1,3.31,2.72,3.31a3.9,3.9,0,0,0,2.89-1.31,6,6,0,0,0,1.57-4.15c0-2.14-1-3.31-2.72-3.31A3.94,3.94,0,0,0,1107.58,600.83Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1125.44,598.31l-6,11.2H1118l-1.55-11.2H1118l1.16,9.2,4.8-9.2Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1133.69,598.31l-.27,1.34h-4.91l-.63,3.22h4l-.25,1.34h-4l-.77,4h5.07l-.27,1.34h-6.5l2.19-11.2Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1137.89,604.23h-1.64l-1,5.28h-1.43l2.19-11.2h3.56c1.77,0,2.85,1,2.85,2.51a3.15,3.15,0,0,1-3,3.31l2.29,5.38h-1.58Zm-.76-4.58-.63,3.23h2.23c1.34,0,2.17-.59,2.17-1.81,0-1-.56-1.42-1.6-1.42Z" fill="#59585b"/>
                      </g>
                    </g>
                  </g>
                  <g className="suites__unit" id="GB15">
                    <polygon id="B15_UNIT_HOPE" data-name="B15 UNIT HOPE" points="1135.68 516.73 1191.98 516.7 1191.96 492 1156.91 492.02 1156.9 469.39 1151.1 469.39 1151.1 458.63 1145.85 458.63 1145.85 417.51 1099.33 417.54 1099.33 422.51 1068.97 422.53 1069.04 533.36 1135.69 533.29 1135.68 516.73" fill="#dadada"/>
                    <g>
                      <g>
                        <path d="M1106.92,455.44c1.9,0,3.2,1,3.2,3a2.26,2.26,0,0,1-1.1,2.07,2.68,2.68,0,0,1,1.68,2.73c0,2.18-1.41,3.38-3.7,3.38h-4.18v-11.2Zm-2,1.92v2.43h1.91c.83,0,1.31-.31,1.31-1.22s-.48-1.21-1.36-1.21Zm0,4.35v3h1.91c1.29,0,1.8-.54,1.8-1.51s-.51-1.5-1.8-1.5Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1116.61,455.44v11.2h-2v-8.39l-2.14,1.28v-2.17l3.28-1.92Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1120.86,463.36c.24,1.23.94,1.55,1.68,1.55,1.09,0,1.66-.72,1.66-2s-.62-1.94-1.76-1.94a2.75,2.75,0,0,0-2,1l-1.27-.26.63-6.3h5.76v1.85h-4.11l-.26,2.34a3.07,3.07,0,0,1,1.58-.42c2.34,0,3.43,1.52,3.43,3.72a3.57,3.57,0,0,1-3.68,3.84,3.4,3.4,0,0,1-3.59-3.06Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1081.91,478h3.77l-1.1,5.65h-1.22l.15-1.54a3.88,3.88,0,0,1-3.41,1.67c-2,0-4-1.44-4-4.56,0-3.59,2.26-6.9,6.2-6.9a3.47,3.47,0,0,1,3.68,3.38l-1.41.36a2.38,2.38,0,0,0-2.53-2.4c-2.72,0-4.46,2.56-4.46,5.35,0,2.22,1,3.42,2.75,3.42a3.76,3.76,0,0,0,3.59-3.09h-2.31Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1095.86,472.44l-.27,1.34h-4.91l-.63,3.22h4l-.25,1.34h-4l-.77,4h5.07l-.27,1.34h-6.5l2.19-11.2Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1100.07,478.36h-1.64l-1,5.28H1096l2.19-11.2h3.56c1.77,0,2.85,1,2.85,2.51a3.15,3.15,0,0,1-3,3.31l2.29,5.38h-1.58Zm-.76-4.58-.63,3.23h2.23c1.34,0,2.17-.59,2.17-1.81,0-1-.56-1.42-1.6-1.42Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1112.26,472.44l1.7,11.2h-1.43l-.4-3H1108l-1.59,3H1105l6-11.2Zm-3.6,6.86h3.28l-.72-5Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1125.89,472.44l-2.19,11.2h-1.59l-3.28-8.72-1.7,8.72h-1.42l2.19-11.2h1.59l3.26,8.75,1.72-8.75Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1130.09,472.44l-2.19,11.2h-1.43l2.19-11.2Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1132.74,472.44h1.43l-1.46,7.49a4.62,4.62,0,0,0-.06.64c0,1.18.59,1.85,1.95,1.85a2.84,2.84,0,0,0,3-2.49l1.45-7.49h1.43l-1.43,7.39c-.54,2.85-2.26,3.94-4.61,3.94a2.88,2.88,0,0,1-3.2-2.87,5.84,5.84,0,0,1,.1-1.07Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1144.85,472.44l1.47,7.89,4.47-7.89h1.73l-2.2,11.2h-1.42l1.6-8.16-4.07,7.14h-1l-1.36-7.14-1.6,8.16H1141l2.19-11.2Z" fill="#59585b"/>
                      </g>
                    </g>
                  </g>
                  <g className="suites__unit" id="GB16">
                    <polygon id="B16_UNIT_IMAGINE" data-name="B16 UNIT IMAGINE" points="1155.1 465.39 1160.9 465.39 1160.91 488.02 1195.96 488 1195.96 495.17 1234.19 495.17 1234.19 423.25 1154.66 423.3 1154.66 417.51 1149.85 417.51 1149.85 454.63 1155.1 454.63 1155.1 465.39" fill="#dadada"/>
                    <g>
                      <g>
                        <path d="M1187.38,432.5c1.9,0,3.2,1,3.2,3a2.26,2.26,0,0,1-1.1,2.07,2.68,2.68,0,0,1,1.68,2.73c0,2.18-1.41,3.38-3.7,3.38h-4.18V432.5Zm-2,1.92v2.43h1.91c.83,0,1.31-.31,1.31-1.22s-.48-1.21-1.36-1.21Zm0,4.35v3h1.91c1.29,0,1.8-.54,1.8-1.51s-.51-1.5-1.8-1.5Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1197.07,432.5v11.2h-2v-8.39l-2.14,1.28v-2.17l3.28-1.92Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1203.23,436.53a3.51,3.51,0,0,1,3.62,3.65,3.75,3.75,0,0,1-7.49,0,4.18,4.18,0,0,1,.54-2l3.27-5.84h2.24l-2.43,4.16Zm-1.85,3.65a1.73,1.73,0,1,0,3.45,0,1.73,1.73,0,1,0-3.45,0Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1167,449.5l-2.19,11.2h-1.59L1160,452l-1.7,8.72h-1.42l2.19-11.2h1.59l3.26,8.75,1.72-8.75Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1173.21,449.5l1.7,11.2h-1.43l-.4-3h-4.17l-1.59,3h-1.42l5.95-11.2Zm-3.6,6.86h3.28l-.72-5Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1180.38,455.42h-1.64l-1,5.28h-1.42l2.19-11.2H1182c1.77,0,2.85,1,2.85,2.51a3.15,3.15,0,0,1-3,3.31l2.29,5.38h-1.58Zm-.76-4.58-.63,3.23h2.23c1.34,0,2.17-.59,2.17-1.81,0-1-.56-1.42-1.6-1.42Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1194.29,457.43c-1,2.21-2.57,3.4-4.83,3.4s-4.07-1.55-4.07-4.48c0-3.56,2.29-7,6.21-7a3.55,3.55,0,0,1,3.64,3.39l-1.36.37c-.27-1.68-1.11-2.42-2.53-2.42-2.95,0-4.48,2.8-4.48,5.48,0,2.11.94,3.29,2.77,3.29,1.47,0,2.48-.7,3.41-2.44Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1199.43,449.5l-2.2,11.2h-1.42l2.19-11.2Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1205.73,452c-.35-1-.87-1.31-1.54-1.31a1.42,1.42,0,0,0-1.55,1.55c0,1,.7,1.45,1.78,2,1.28.67,2.12,1.24,2.12,2.86a3.28,3.28,0,0,1-1,2.66,3.89,3.89,0,0,1-3,1,3.11,3.11,0,0,1-3-3.08l1.28-.68c.1,1.33.72,2.41,2,2.41a2,2,0,0,0,2.24-2.09c0-1.11-.64-1.52-1.71-2.05a3.25,3.25,0,0,1-2.19-2.87,3,3,0,0,1,3.28-3.1,2.55,2.55,0,0,1,2.49,2Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1213.7,452c-.35-1-.87-1.31-1.54-1.31a1.42,1.42,0,0,0-1.55,1.55c0,1,.7,1.45,1.78,2,1.28.67,2.13,1.24,2.13,2.86a3.28,3.28,0,0,1-1.06,2.66,3.89,3.89,0,0,1-3,1,3.11,3.11,0,0,1-3-3.08l1.28-.68c.1,1.33.72,2.41,2,2.41a2,2,0,0,0,2.24-2.09c0-1.11-.64-1.52-1.71-2.05a3.25,3.25,0,0,1-2.19-2.87,3,3,0,0,1,3.28-3.1,2.57,2.57,0,0,1,2.5,2Z" fill="#59585b"/>
                        <path d="M1217.41,449.5h1.42l-1.45,7.49a4.64,4.64,0,0,0-.07.64c0,1.18.6,1.85,2,1.85a2.82,2.82,0,0,0,3-2.49l1.46-7.49h1.42l-1.42,7.39c-.54,2.85-2.26,3.94-4.61,3.94a2.88,2.88,0,0,1-3.2-2.87,6.81,6.81,0,0,1,.09-1.07Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1231.51,452c-.35-1-.87-1.31-1.54-1.31a1.42,1.42,0,0,0-1.55,1.55c0,1,.7,1.45,1.78,2,1.28.67,2.13,1.24,2.13,2.86a3.28,3.28,0,0,1-1.06,2.66,3.89,3.89,0,0,1-3,1,3.11,3.11,0,0,1-3-3.08l1.28-.68c.1,1.33.72,2.41,2,2.41a2,2,0,0,0,2.24-2.09c0-1.11-.64-1.52-1.71-2.05a3.25,3.25,0,0,1-2.19-2.87,3,3,0,0,1,3.28-3.1,2.55,2.55,0,0,1,2.49,2Z" fill="#59585b"/>
                      </g>
                    </g>
                  </g>
                  <g className="suites__unit" id="GB20">
                    <polygon id="B20_UNIT_LUCKY" data-name="B20 UNIT LUCKY" points="1565.64 507.08 1634.39 507.04 1634.31 444.53 1589.33 444.56 1589.31 414.75 1503.9 414.81 1503.9 521.81 1565.65 521.79 1565.64 507.08" fill="#dadada"/>
                    <g>
                      <g>
                        <path d="M1558.28,452c1.9,0,3.2,1,3.2,3a2.26,2.26,0,0,1-1.1,2.07,2.68,2.68,0,0,1,1.68,2.73c0,2.18-1.41,3.38-3.7,3.38h-4.18V452Zm-2,1.92v2.43h1.91c.83,0,1.31-.31,1.31-1.22s-.48-1.21-1.36-1.21Zm0,4.35v3h1.91c1.29,0,1.8-.54,1.8-1.51s-.51-1.5-1.8-1.5Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1563.74,462.44l4-5.17a3.06,3.06,0,0,0,.86-2,1.29,1.29,0,0,0-1.38-1.48c-.8,0-1.32.53-1.45,1.48l-1.86-.36a3.21,3.21,0,0,1,3.36-3,3.09,3.09,0,0,1,3.35,3.25,4.29,4.29,0,0,1-1,2.65l-2.74,3.57h3.94v1.86h-7.13Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1581.14,457.64c0,3.58-1.45,5.73-4.13,5.73s-4.13-2.15-4.13-5.73,1.46-5.73,4.13-5.73S1581.14,454.05,1581.14,457.64Zm-6.27,0c0,2.49.67,3.87,2.14,3.87s2.15-1.38,2.15-3.87-.68-3.88-2.15-3.88S1574.87,455.14,1574.87,457.64Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1548.41,474.59h3.77l-1.1,5.65h-1.22l.15-1.54a3.88,3.88,0,0,1-3.41,1.67c-2,0-4-1.44-4-4.56,0-3.59,2.26-6.9,6.2-6.9a3.47,3.47,0,0,1,3.68,3.38l-1.41.36a2.38,2.38,0,0,0-2.53-2.4c-2.72,0-4.46,2.56-4.46,5.35,0,2.22,1,3.42,2.75,3.42a3.76,3.76,0,0,0,3.59-3.09h-2.31Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1560.16,469l1.7,11.2h-1.43l-.4-3h-4.17l-1.59,3h-1.42l6-11.2Zm-3.6,6.86h3.28l-.72-5Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1565.49,469h1.43l-1.46,7.49a4.62,4.62,0,0,0-.06.64c0,1.18.59,1.85,1.95,1.85a2.84,2.84,0,0,0,3-2.49l1.45-7.49h1.43l-1.43,7.39c-.54,2.85-2.26,3.94-4.61,3.94a2.88,2.88,0,0,1-3.2-2.87,5.84,5.84,0,0,1,.1-1.07Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1577.59,475H1576l-1,5.28h-1.42l2.19-11.2h3.56c1.77,0,2.85,1,2.85,2.51a3.15,3.15,0,0,1-3,3.31l2.29,5.38h-1.58Zm-.76-4.58-.63,3.23h2.23c1.34,0,2.17-.59,2.17-1.81,0-1-.56-1.42-1.6-1.42Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1589.78,469l1.7,11.2h-1.43l-.4-3h-4.17l-1.59,3h-1.42l6-11.2Zm-3.6,6.86h3.28l-.72-5Z" fill="#59585b"/>
                      </g>
                    </g>
                  </g>
                </g>
                <g id="VR">
                  <g className="suites__vr" data-vr="https://astoria-tours.web.app/Amenities_Ground_Floor/#dining_room_lounge" id="vrLounge" >
                    <circle cx="1706.68" cy="739.98" r="26.03" fill="#baa6cc"/>
                    <g id="Group_125-6" data-name="Group 125-6">
                      <g id="Light-6">
                        <g>
                          <g>
                            <path d="M1693.45,740.16l1.35-.18a3,3,0,0,0,.79,1.66,2,2,0,0,0,1.37.51,2.27,2.27,0,0,0,2.27-2.31,2,2,0,0,0-.61-1.53,2.09,2.09,0,0,0-1.55-.61,3.89,3.89,0,0,0-1,.15l.15-1.19.21,0a2.79,2.79,0,0,0,1.56-.45,1.55,1.55,0,0,0,.69-1.39,1.64,1.64,0,0,0-.5-1.24,1.82,1.82,0,0,0-1.3-.49,1.85,1.85,0,0,0-1.32.5,2.5,2.5,0,0,0-.67,1.49l-1.36-.24a3.48,3.48,0,0,1,1.13-2.11,3.27,3.27,0,0,1,2.19-.75,3.59,3.59,0,0,1,1.66.39,2.82,2.82,0,0,1,1.16,1.05,2.71,2.71,0,0,1,.4,1.43,2.3,2.3,0,0,1-.38,1.3,2.58,2.58,0,0,1-1.13.93,2.51,2.51,0,0,1,1.51.94,2.77,2.77,0,0,1,.55,1.77,3.26,3.26,0,0,1-1.06,2.45,3.7,3.7,0,0,1-2.66,1,3.45,3.45,0,0,1-2.41-.87A3.33,3.33,0,0,1,1693.45,740.16Z" fill="#fff"/>
                            <path d="M1709,734.75l-1.35.1a2.56,2.56,0,0,0-.51-1.16,1.9,1.9,0,0,0-2.49-.21,3.06,3.06,0,0,0-1,1.36,7.2,7.2,0,0,0-.38,2.55,3.09,3.09,0,0,1,1.2-1.11,3.2,3.2,0,0,1,1.48-.36,3,3,0,0,1,2.3,1,3.54,3.54,0,0,1,1,2.57,4.22,4.22,0,0,1-.45,1.93,3.17,3.17,0,0,1-1.22,1.36,3.37,3.37,0,0,1-1.78.48A3.47,3.47,0,0,1,1703,742a6.28,6.28,0,0,1-1.07-4.1,7.41,7.41,0,0,1,1.18-4.65,3.42,3.42,0,0,1,2.78-1.26,3.08,3.08,0,0,1,2.13.73A3.13,3.13,0,0,1,1709,734.75Zm-5.53,4.75a3.15,3.15,0,0,0,.3,1.34,2.2,2.2,0,0,0,.83,1,2.1,2.1,0,0,0,1.12.34,1.92,1.92,0,0,0,1.47-.7,2.71,2.71,0,0,0,.62-1.88,2.53,2.53,0,0,0-.61-1.8,2,2,0,0,0-1.53-.66,2.09,2.09,0,0,0-1.56.66A2.37,2.37,0,0,0,1703.5,739.5Z" fill="#fff"/>
                            <path d="M1710.57,737.63a10,10,0,0,1,.4-3.14,3.9,3.9,0,0,1,1.2-1.84,3.05,3.05,0,0,1,2-.65,3.27,3.27,0,0,1,1.56.36,3,3,0,0,1,1.11,1,5.62,5.62,0,0,1,.68,1.64,11.11,11.11,0,0,1,.25,2.6,10.34,10.34,0,0,1-.4,3.14,4,4,0,0,1-1.19,1.84,3.09,3.09,0,0,1-2,.65,3,3,0,0,1-2.5-1.15A7.22,7.22,0,0,1,1710.57,737.63Zm1.39,0a6.87,6.87,0,0,0,.64,3.62,1.81,1.81,0,0,0,3.13,0,10.5,10.5,0,0,0,0-7.22,1.88,1.88,0,0,0-1.58-.9,1.73,1.73,0,0,0-1.49.79A6.94,6.94,0,0,0,1712,737.63Z" fill="#fff"/>
                          </g>
                        </g>
                      </g>
                      <path d="M1722.55,727a2.83,2.83,0,1,0,2.45,2.8A2.64,2.64,0,0,0,1722.55,727Zm0,4.9a2.12,2.12,0,1,1,1.84-2.1A2,2,0,0,1,1722.55,731.87Z" fill="#fff"/>
                      <path id="Path_76-6" data-name="Path 76-6" d="M1691.4,738s-8.6,3.6-2.6,9,15.6,4.9,15.6,4.9" fill="none" stroke="#fdfeff" stroke-linecap="round" stroke-width="2"/>
                      <path id="Path_77-6" data-name="Path 77-6" d="M1701,748.68l.34.34.81.81,1,1,.85.85c.14.14.27.28.41.41h0v-.53l-.34.34-.81.81-1,1-.84.84-.41.41h0a.39.39,0,0,0,0,.55.39.39,0,0,0,.54,0l.34-.34.81-.8,1-1,.85-.85.41-.41h0a.38.38,0,0,0,0-.54l-.34-.34-.81-.81-1-1-.84-.84c-.14-.14-.27-.28-.41-.41h0a.38.38,0,0,0-.54,0,.39.39,0,0,0,0,.54h0Z" fill="#fff" stroke="#fdfeff"/>
                      <path id="Path_78-6" data-name="Path 78-6" d="M1710.39,751.88s12-1.42,14.52-6.27-3.19-7.26-3.19-7.26" fill="none" stroke="#fdfeff" stroke-linecap="round" stroke-width="2"/>
                    </g>
                  </g>
                  <g className="suites__vr" data-vr="https://astoria-tours.web.app/Amenities_Ground_Floor/#lobby" id="vrLobby" >
                    <g>
                      <circle cx="1365.86" cy="1359.84" r="26.03" fill="#baa6cc"/>
                      <g id="Group_125-4" data-name="Group 125-4">
                        <g id="Light-4">
                          <g>
                            <g>
                              <path d="M1352.63,1360l1.35-.18a3,3,0,0,0,.79,1.66,2,2,0,0,0,1.37.5,2.25,2.25,0,0,0,2.27-2.3,2,2,0,0,0-.61-1.53,2.09,2.09,0,0,0-1.55-.61,3.89,3.89,0,0,0-1,.15l.15-1.19.21,0a2.79,2.79,0,0,0,1.56-.45,1.55,1.55,0,0,0,.69-1.39,1.64,1.64,0,0,0-.5-1.24,1.82,1.82,0,0,0-1.3-.49,1.85,1.85,0,0,0-1.32.5,2.5,2.5,0,0,0-.67,1.49l-1.36-.24a3.48,3.48,0,0,1,1.13-2.11,3.27,3.27,0,0,1,2.19-.75,3.59,3.59,0,0,1,1.66.39,2.82,2.82,0,0,1,1.16,1,2.71,2.71,0,0,1,.4,1.43,2.3,2.3,0,0,1-.38,1.3,2.58,2.58,0,0,1-1.13.93,2.51,2.51,0,0,1,1.51.94,2.77,2.77,0,0,1,.55,1.77,3.26,3.26,0,0,1-1.06,2.45,3.69,3.69,0,0,1-2.66,1,3.44,3.44,0,0,1-2.41-.86A3.33,3.33,0,0,1,1352.63,1360Z" fill="#fff"/>
                              <path d="M1368.21,1354.61l-1.35.1a2.56,2.56,0,0,0-.51-1.16,1.82,1.82,0,0,0-1.35-.58,1.89,1.89,0,0,0-1.14.36,3.23,3.23,0,0,0-1,1.37,7.2,7.2,0,0,0-.38,2.55,3.09,3.09,0,0,1,1.2-1.11,3.2,3.2,0,0,1,1.48-.36,3,3,0,0,1,2.3,1,3.54,3.54,0,0,1,1,2.57,4.22,4.22,0,0,1-.45,1.93,3.17,3.17,0,0,1-1.22,1.36,3.37,3.37,0,0,1-1.78.48,3.47,3.47,0,0,1-2.76-1.25,6.28,6.28,0,0,1-1.07-4.1,7.41,7.41,0,0,1,1.18-4.65,3.42,3.42,0,0,1,2.78-1.26,3,3,0,0,1,3.13,2.75Zm-5.53,4.75a3.15,3.15,0,0,0,.3,1.34,2.2,2.2,0,0,0,.83,1,2.09,2.09,0,0,0,1.12.33,1.9,1.9,0,0,0,1.47-.69,2.71,2.71,0,0,0,.62-1.88,2.53,2.53,0,0,0-.61-1.8,2,2,0,0,0-1.53-.66,2.09,2.09,0,0,0-1.56.66A2.37,2.37,0,0,0,1362.68,1359.36Z" fill="#fff"/>
                              <path d="M1369.75,1357.49a10,10,0,0,1,.4-3.14,3.9,3.9,0,0,1,1.2-1.84,3.05,3.05,0,0,1,2-.65,3.24,3.24,0,0,1,1.56.36,3,3,0,0,1,1.11,1,5.62,5.62,0,0,1,.68,1.64,11.11,11.11,0,0,1,.25,2.6,9.92,9.92,0,0,1-.4,3.13,4,4,0,0,1-1.19,1.85,3.09,3.09,0,0,1-2,.65,3,3,0,0,1-2.5-1.15A7.22,7.22,0,0,1,1369.75,1357.49Zm1.39,0a6.87,6.87,0,0,0,.64,3.62,1.82,1.82,0,0,0,3.13,0,10.5,10.5,0,0,0,0-7.22,1.88,1.88,0,0,0-1.58-.9,1.73,1.73,0,0,0-1.49.79A6.94,6.94,0,0,0,1371.14,1357.49Z" fill="#fff"/>
                            </g>
                          </g>
                        </g>
                        <path d="M1381.73,1346.83a2.82,2.82,0,1,0,2.45,2.8A2.64,2.64,0,0,0,1381.73,1346.83Zm0,4.9a2.12,2.12,0,1,1,1.84-2.1A2,2,0,0,1,1381.73,1351.73Z" fill="#fff"/>
                        <path id="Path_76-4" data-name="Path 76-4" d="M1350.58,1357.83s-8.6,3.6-2.6,9,15.6,4.9,15.6,4.9" fill="none" stroke="#fdfeff" stroke-linecap="round" stroke-width="2"/>
                        <path id="Path_77-4" data-name="Path 77-4" d="M1360.2,1368.54l.34.34.81.81,1,1,.85.85.41.41h0v-.53l-.34.34-.81.81-1,1-.84.84-.41.41h0a.39.39,0,0,0,0,.55.39.39,0,0,0,.54,0l.34-.34.81-.8,1-1,.85-.85.41-.41h0a.38.38,0,0,0,0-.54l-.34-.34-.81-.81-1-1-.84-.84-.41-.41h0a.38.38,0,0,0-.54,0,.39.39,0,0,0,0,.54h0Z" fill="#fff" stroke="#fdfeff"/>
                        <path id="Path_78-4" data-name="Path 78-4" d="M1369.57,1371.74s12-1.42,14.52-6.27-3.19-7.26-3.19-7.26" fill="none" stroke="#fdfeff" stroke-linecap="round" stroke-width="2"/>
                      </g>
                    </g>
                    <g>
                      <g>
                        <path d="M1345.21,1398.28l-1.92,9.85h4.93l-.27,1.35h-6.36l2.19-11.2Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1359.81,1402.68a7.09,7.09,0,0,1-2.42,5.57,5.36,5.36,0,0,1-3.76,1.36c-2.31,0-4-1.65-4-4.53a7.07,7.07,0,0,1,2.42-5.57,5.34,5.34,0,0,1,3.76-1.36C1358.16,1398.15,1359.81,1399.8,1359.81,1402.68Zm-7.09-1.88a6,6,0,0,0-1.57,4.15c0,2.15,1,3.31,2.72,3.31a3.94,3.94,0,0,0,2.9-1.31,6.05,6.05,0,0,0,1.56-4.15c0-2.14-1-3.31-2.72-3.31A3.94,3.94,0,0,0,1352.72,1400.8Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1366.81,1398.28c1.79,0,2.77.91,2.77,2.49a2.76,2.76,0,0,1-1.78,2.61,2.37,2.37,0,0,1,1.42,2.27c0,2.53-1.8,3.83-4.21,3.83h-4l2.19-11.2Zm-4,9.85h2.32c1.53,0,2.67-.7,2.67-2.2,0-1.14-.69-1.75-1.9-1.75h-2.32Zm1.65-8.51-.63,3.22H1366a1.89,1.89,0,0,0,2.13-1.81c0-1-.58-1.41-1.54-1.41Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1376.46,1398.28c1.79,0,2.77.91,2.77,2.49a2.76,2.76,0,0,1-1.78,2.61,2.37,2.37,0,0,1,1.42,2.27c0,2.53-1.81,3.83-4.21,3.83h-4l2.19-11.2Zm-4,9.85h2.32c1.53,0,2.67-.7,2.67-2.2,0-1.14-.69-1.75-1.9-1.75h-2.32Zm1.65-8.51-.63,3.22h2.24a1.89,1.89,0,0,0,2.13-1.81c0-1-.58-1.41-1.54-1.41Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1380.59,1398.28h1.5l1.46,5,3.36-5h1.58l-4.46,6.37-1,4.83h-1.42l.94-4.83Z" fill="#59585b"/>
                      </g>
                    </g>
                  </g>
                  <g className="suites__vr" data-vr="https://astoria-tours.web.app/Amenities_Ground_Floor/#maker_space" id="vrMakerArea" >
                    <g>
                      <circle cx="1563.73" cy="1341.34" r="26.03" fill="#baa6cc"/>
                      <g id="Group_125-3" data-name="Group 125-3">
                        <g id="Light-3">
                          <g>
                            <g>
                              <path d="M1550.5,1341.53l1.35-.18a3,3,0,0,0,.79,1.66,2,2,0,0,0,1.37.51,2.19,2.19,0,0,0,1.61-.67,2.22,2.22,0,0,0,.66-1.64,2,2,0,0,0-.61-1.53,2.09,2.09,0,0,0-1.55-.61,3.89,3.89,0,0,0-.95.15l.15-1.19.21,0a2.79,2.79,0,0,0,1.56-.45,1.55,1.55,0,0,0,.69-1.39,1.64,1.64,0,0,0-.5-1.24,1.82,1.82,0,0,0-1.3-.49,1.85,1.85,0,0,0-1.32.5,2.5,2.5,0,0,0-.67,1.49l-1.36-.24a3.48,3.48,0,0,1,1.13-2.11,3.27,3.27,0,0,1,2.19-.75,3.59,3.59,0,0,1,1.66.39,2.82,2.82,0,0,1,1.16,1,2.71,2.71,0,0,1,.4,1.43,2.3,2.3,0,0,1-.38,1.3,2.58,2.58,0,0,1-1.13.93,2.51,2.51,0,0,1,1.51.94,2.77,2.77,0,0,1,.55,1.77,3.26,3.26,0,0,1-1.06,2.45,3.69,3.69,0,0,1-2.66,1,3.44,3.44,0,0,1-2.41-.86A3.33,3.33,0,0,1,1550.5,1341.53Z" fill="#fff"/>
                              <path d="M1566.08,1336.12l-1.35.1a2.56,2.56,0,0,0-.51-1.16,1.9,1.9,0,0,0-2.49-.21,3.14,3.14,0,0,0-1,1.36,7.2,7.2,0,0,0-.38,2.55,3.09,3.09,0,0,1,1.2-1.11,3.2,3.2,0,0,1,1.48-.36,3,3,0,0,1,2.3,1,3.54,3.54,0,0,1,1,2.57,4.22,4.22,0,0,1-.45,1.93,3.17,3.17,0,0,1-1.22,1.36,3.37,3.37,0,0,1-1.78.48,3.47,3.47,0,0,1-2.76-1.25,6.28,6.28,0,0,1-1.07-4.1,7.41,7.41,0,0,1,1.18-4.65,3.42,3.42,0,0,1,2.78-1.26,3.13,3.13,0,0,1,2.13.73A3.09,3.09,0,0,1,1566.08,1336.12Zm-5.53,4.75a3.15,3.15,0,0,0,.3,1.34,2.2,2.2,0,0,0,.83,1,2.1,2.1,0,0,0,1.12.34,1.88,1.88,0,0,0,1.47-.7,2.71,2.71,0,0,0,.62-1.88,2.53,2.53,0,0,0-.61-1.8,2,2,0,0,0-1.53-.66,2.09,2.09,0,0,0-1.56.66A2.37,2.37,0,0,0,1560.55,1340.87Z" fill="#fff"/>
                              <path d="M1567.62,1339a10,10,0,0,1,.4-3.14,3.9,3.9,0,0,1,1.2-1.84,3.05,3.05,0,0,1,2-.65,3.24,3.24,0,0,1,1.56.36,3,3,0,0,1,1.11,1,5.62,5.62,0,0,1,.68,1.64,10.59,10.59,0,0,1,.25,2.6,9.92,9.92,0,0,1-.4,3.13,4,4,0,0,1-1.19,1.85,3.09,3.09,0,0,1-2,.65,3,3,0,0,1-2.5-1.15A7.22,7.22,0,0,1,1567.62,1339Zm1.39,0a6.87,6.87,0,0,0,.64,3.62,1.81,1.81,0,0,0,3.13,0,10.5,10.5,0,0,0,0-7.22,1.88,1.88,0,0,0-1.58-.9,1.73,1.73,0,0,0-1.49.79A6.94,6.94,0,0,0,1569,1339Z" fill="#fff"/>
                            </g>
                          </g>
                        </g>
                        <path d="M1579.6,1328.34a2.82,2.82,0,1,0,2.45,2.8A2.64,2.64,0,0,0,1579.6,1328.34Zm0,4.9a2.12,2.12,0,1,1,1.84-2.1A2,2,0,0,1,1579.6,1333.24Z" fill="#fff"/>
                        <path id="Path_76-3" data-name="Path 76-3" d="M1548.45,1339.34s-8.6,3.6-2.6,9,15.6,4.9,15.6,4.9" fill="none" stroke="#fdfeff" stroke-linecap="round" stroke-width="2"/>
                        <path id="Path_77-3" data-name="Path 77-3" d="M1558.07,1350.05l.34.34.81.81,1,1,.85.85.41.41h0v-.53l-.34.34-.81.81-1,1-.84.84-.41.41h0a.39.39,0,0,0,0,.55.39.39,0,0,0,.54,0l.34-.34.81-.8,1-1,.85-.85.41-.41h0a.38.38,0,0,0,0-.54l-.34-.34-.81-.81-1-1-.84-.84-.41-.41h0a.38.38,0,0,0-.54,0,.39.39,0,0,0,0,.54h0Z" fill="#fff" stroke="#fdfeff"/>
                        <path id="Path_78-3" data-name="Path 78-3" d="M1567.44,1353.25s12-1.42,14.52-6.27-3.19-7.26-3.19-7.26" fill="none" stroke="#fdfeff" stroke-linecap="round" stroke-width="2"/>
                      </g>
                    </g>
                    <g>
                      <g>
                        <path d="M1557.37,1371.45l1.47,7.89,4.47-7.89H1565l-2.2,11.2h-1.42l1.6-8.16-4.07,7.14h-1l-1.36-7.14-1.61,8.16h-1.42l2.19-11.2Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1571.77,1371.45l1.7,11.2H1572l-.4-3h-4.17l-1.59,3h-1.42l6-11.2Zm-3.6,6.86h3.28l-.72-5Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1581.05,1382.65l-3.3-5.65-1.11,5.65h-1.42l2.19-11.2h1.43l-.88,4.49,4.77-4.49h1.92l-5.46,5.09,3.59,6.11Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1592.78,1371.45l-.27,1.34h-4.91L1587,1376h4l-.25,1.34h-4l-.77,4H1591l-.27,1.34h-6.5l2.19-11.2Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1597,1377.37h-1.64l-1,5.28h-1.42l2.19-11.2h3.56c1.77,0,2.85,1,2.85,2.51a3.15,3.15,0,0,1-3,3.31l2.29,5.38h-1.58Zm-.76-4.58-.63,3.23h2.23c1.34,0,2.17-.59,2.17-1.81,0-1-.56-1.42-1.6-1.42Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1565.38,1388.45l1.7,11.2h-1.43l-.4-3h-4.17l-1.59,3h-1.42l6-11.2Zm-3.6,6.86h3.28l-.72-5Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1573,1394.37h-1.64l-1,5.28h-1.42l2.19-11.2h3.56c1.77,0,2.85,1,2.85,2.51a3.15,3.15,0,0,1-3,3.31l2.29,5.38h-1.58Zm-.76-4.58-.63,3.23h2.23c1.34,0,2.17-.59,2.17-1.81,0-1-.56-1.42-1.6-1.42Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1586.86,1388.45l-.27,1.34h-4.91l-.63,3.22h4l-.25,1.34h-4l-.77,4h5.07l-.27,1.34h-6.5l2.19-11.2Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1593.46,1388.45l1.7,11.2h-1.43l-.4-3h-4.17l-1.59,3h-1.42l5.95-11.2Zm-3.6,6.86h3.28l-.72-5Z" fill="#59585b"/>
                      </g>
                    </g>
                  </g>
                  <g className="suites__vr" data-vr="https://astoria-tours.web.app/Amenities_Ground_Floor/#horticulture_room" id="vrHorticultureRoom" >
                    <g>
                      <circle cx="1779.75" cy="1104.04" r="26.03" fill="#baa6cc"/>
                      <g id="Group_125-2" data-name="Group 125-2">
                        <g id="Light-2">
                          <g>
                            <g>
                              <path d="M1766.52,1104.22l1.35-.18a3,3,0,0,0,.79,1.66,2,2,0,0,0,1.37.51,2.19,2.19,0,0,0,1.61-.67,2.22,2.22,0,0,0,.66-1.64,2,2,0,0,0-.61-1.53,2.09,2.09,0,0,0-1.55-.61,3.89,3.89,0,0,0-1,.15l.15-1.19.21,0a2.79,2.79,0,0,0,1.56-.45,1.55,1.55,0,0,0,.69-1.39,1.64,1.64,0,0,0-.5-1.24,1.82,1.82,0,0,0-1.3-.49,1.85,1.85,0,0,0-1.32.5,2.5,2.5,0,0,0-.67,1.49l-1.36-.24a3.48,3.48,0,0,1,1.13-2.11,3.27,3.27,0,0,1,2.19-.75,3.59,3.59,0,0,1,1.66.39,2.82,2.82,0,0,1,1.16,1,2.71,2.71,0,0,1,.4,1.43,2.3,2.3,0,0,1-.38,1.3,2.58,2.58,0,0,1-1.13.93,2.51,2.51,0,0,1,1.51.94,2.77,2.77,0,0,1,.55,1.77,3.26,3.26,0,0,1-1.06,2.45,3.7,3.7,0,0,1-2.66,1,3.45,3.45,0,0,1-2.41-.87A3.33,3.33,0,0,1,1766.52,1104.22Z" fill="#fff"/>
                              <path d="M1782.1,1098.81l-1.35.1a2.56,2.56,0,0,0-.51-1.16,1.9,1.9,0,0,0-2.49-.21,3.14,3.14,0,0,0-1,1.36,7.2,7.2,0,0,0-.38,2.55,3.09,3.09,0,0,1,1.2-1.11,3.2,3.2,0,0,1,1.48-.36,3,3,0,0,1,2.3,1,3.54,3.54,0,0,1,1,2.57,4.22,4.22,0,0,1-.45,1.93,3.17,3.17,0,0,1-1.22,1.36,3.37,3.37,0,0,1-1.78.48,3.47,3.47,0,0,1-2.76-1.25,6.28,6.28,0,0,1-1.07-4.1,7.41,7.41,0,0,1,1.18-4.65,3.42,3.42,0,0,1,2.78-1.26,3,3,0,0,1,3.13,2.75Zm-5.53,4.75a3.15,3.15,0,0,0,.3,1.34,2.2,2.2,0,0,0,.83,1,2.1,2.1,0,0,0,1.12.34,1.92,1.92,0,0,0,1.47-.7,2.71,2.71,0,0,0,.62-1.88,2.53,2.53,0,0,0-.61-1.8,2,2,0,0,0-1.53-.66,2.09,2.09,0,0,0-1.56.66A2.37,2.37,0,0,0,1776.57,1103.56Z" fill="#fff"/>
                              <path d="M1783.64,1101.69a10,10,0,0,1,.4-3.14,3.9,3.9,0,0,1,1.2-1.84,3.05,3.05,0,0,1,2-.65,3.24,3.24,0,0,1,1.56.36,3,3,0,0,1,1.11,1,5.62,5.62,0,0,1,.68,1.64,10.59,10.59,0,0,1,.25,2.6,10,10,0,0,1-.4,3.14,4,4,0,0,1-1.19,1.84,3.09,3.09,0,0,1-2,.65,3,3,0,0,1-2.5-1.15A7.22,7.22,0,0,1,1783.64,1101.69Zm1.39,0a6.87,6.87,0,0,0,.64,3.62,1.81,1.81,0,0,0,3.13,0,10.5,10.5,0,0,0,0-7.22,1.88,1.88,0,0,0-1.58-.9,1.73,1.73,0,0,0-1.49.79A6.94,6.94,0,0,0,1785,1101.69Z" fill="#fff"/>
                            </g>
                          </g>
                        </g>
                        <path d="M1795.62,1091a2.82,2.82,0,1,0,2.45,2.8A2.64,2.64,0,0,0,1795.62,1091Zm0,4.9a2.12,2.12,0,1,1,1.84-2.1A2,2,0,0,1,1795.62,1095.93Z" fill="#fff"/>
                        <path id="Path_76-2" data-name="Path 76-2" d="M1764.47,1102s-8.6,3.6-2.6,9,15.6,4.9,15.6,4.9" fill="none" stroke="#fdfeff" stroke-linecap="round" stroke-width="2"/>
                        <path id="Path_77-2" data-name="Path 77-2" d="M1774.09,1112.75l.34.34.81.81,1,1,.85.85.41.41h0v-.53l-.34.34-.81.81-1,1-.84.84-.41.41h0a.39.39,0,0,0,0,.55.39.39,0,0,0,.54,0l.34-.34.81-.8,1-1,.85-.85.41-.41h0a.38.38,0,0,0,0-.54l-.34-.34-.81-.81-1-1-.84-.84-.41-.41h0a.38.38,0,0,0-.54,0,.39.39,0,0,0,0,.54h0Z" fill="#fff" stroke="#fdfeff"/>
                        <path id="Path_78-2" data-name="Path 78-2" d="M1783.46,1115.94s12-1.42,14.52-6.27-3.19-7.26-3.19-7.26" fill="none" stroke="#fdfeff" stroke-linecap="round" stroke-width="2"/>
                      </g>
                    </g>
                    <g>
                      <g>
                        <path d="M1735,1141.1l-2.19,11.2h-1.42l1-5.28h-5l-1,5.28H1725l2.19-11.2h1.43l-.9,4.57h5l.9-4.57Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1746.16,1145.5a7.09,7.09,0,0,1-2.42,5.57,5.38,5.38,0,0,1-3.76,1.36c-2.31,0-4-1.65-4-4.53a7.07,7.07,0,0,1,2.42-5.57,5.34,5.34,0,0,1,3.76-1.36C1744.51,1141,1746.16,1142.62,1746.16,1145.5Zm-7.09-1.88a6,6,0,0,0-1.57,4.15c0,2.15,1,3.31,2.72,3.31a3.94,3.94,0,0,0,2.9-1.31,6.05,6.05,0,0,0,1.56-4.15c0-2.14-1-3.31-2.72-3.31A3.94,3.94,0,0,0,1739.07,1143.62Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1751.53,1147h-1.64l-1.05,5.28h-1.42l2.19-11.2h3.56c1.77,0,2.85,1,2.85,2.51a3.15,3.15,0,0,1-3,3.31l2.29,5.38h-1.58Zm-.76-4.58-.63,3.23h2.23c1.34,0,2.17-.59,2.17-1.81,0-1-.56-1.42-1.6-1.42Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1758.14,1141.1h7.73l-.27,1.34h-3.16l-1.92,9.86h-1.42l1.92-9.86h-3.15Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1769.45,1141.1l-2.2,11.2h-1.42l2.19-11.2Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1779.22,1149c-1,2.21-2.57,3.4-4.83,3.4s-4.07-1.55-4.07-4.48c0-3.56,2.29-7,6.22-7a3.55,3.55,0,0,1,3.63,3.39l-1.36.37c-.27-1.68-1.11-2.42-2.53-2.42-3,0-4.48,2.8-4.48,5.48,0,2.11.94,3.29,2.77,3.29,1.47,0,2.48-.7,3.41-2.45Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1783.35,1141.1h1.43l-1.46,7.49a4.64,4.64,0,0,0-.07.64c0,1.18.6,1.85,2,1.85a2.84,2.84,0,0,0,3-2.49l1.45-7.49h1.43l-1.43,7.39c-.54,2.85-2.26,3.94-4.61,3.94a2.88,2.88,0,0,1-3.2-2.87,5.84,5.84,0,0,1,.1-1.07Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1795,1141.1,1793,1151H1798l-.27,1.34h-6.36l2.19-11.2Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1798.92,1141.1h7.73l-.27,1.34h-3.16l-1.92,9.86h-1.42l1.92-9.86h-3.15Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1809.09,1141.1h1.43l-1.46,7.49a4.62,4.62,0,0,0-.06.64c0,1.18.59,1.85,2,1.85a2.82,2.82,0,0,0,3-2.49l1.46-7.49h1.43l-1.43,7.39c-.54,2.85-2.26,3.94-4.61,3.94a2.88,2.88,0,0,1-3.2-2.87,5.84,5.84,0,0,1,.1-1.07Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1821.19,1147h-1.64l-1,5.28h-1.42l2.19-11.2h3.56c1.77,0,2.85,1,2.85,2.51a3.15,3.15,0,0,1-3,3.31l2.29,5.38h-1.58Zm-.76-4.58-.63,3.23H1822c1.34,0,2.17-.59,2.17-1.81,0-1-.56-1.42-1.6-1.42Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1835.1,1141.1l-.27,1.34h-4.91l-.63,3.22h4L1833,1147h-4l-.77,4h5.07l-.27,1.34h-6.5l2.19-11.2Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1761.77,1164h-1.64l-1.05,5.28h-1.42l2.19-11.2h3.56c1.77,0,2.85,1,2.85,2.51a3.15,3.15,0,0,1-3,3.31l2.29,5.38H1764Zm-.76-4.58-.63,3.23h2.23c1.34,0,2.17-.59,2.17-1.81,0-1-.56-1.42-1.6-1.42Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1777.32,1162.5a7.09,7.09,0,0,1-2.42,5.57,5.38,5.38,0,0,1-3.76,1.36c-2.31,0-4-1.65-4-4.53a7.07,7.07,0,0,1,2.42-5.57,5.34,5.34,0,0,1,3.76-1.36C1775.67,1158,1777.32,1159.62,1777.32,1162.5Zm-7.09-1.88a6,6,0,0,0-1.57,4.15c0,2.15,1,3.31,2.72,3.31a3.94,3.94,0,0,0,2.9-1.31,6.05,6.05,0,0,0,1.56-4.15c0-2.14-1-3.31-2.72-3.31A3.94,3.94,0,0,0,1770.23,1160.62Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1789,1162.5a7.09,7.09,0,0,1-2.42,5.57,5.38,5.38,0,0,1-3.76,1.36c-2.31,0-4-1.65-4-4.53a7.07,7.07,0,0,1,2.42-5.57,5.34,5.34,0,0,1,3.76-1.36C1787.38,1158,1789,1159.62,1789,1162.5Zm-7.09-1.88a6,6,0,0,0-1.57,4.15c0,2.15,1,3.31,2.72,3.31a3.94,3.94,0,0,0,2.9-1.31,6.05,6.05,0,0,0,1.56-4.15c0-2.14-1-3.31-2.72-3.31A3.94,3.94,0,0,0,1781.94,1160.62Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1794.28,1158.1l1.47,7.89,4.47-7.89h1.72l-2.19,11.2h-1.42l1.6-8.16-4.07,7.14h-1l-1.36-7.14-1.61,8.16h-1.42l2.19-11.2Z" fill="#59585b"/>
                      </g>
                    </g>
                  </g>
                  <g className="suites__vr" data-vr="https://astoria-tours.web.app/Amenities_Ground_Floor/#dining_room_lounge" id="vrDining" >
                    <g>
                      <circle cx="1895.16" cy="878.51" r="26.03" fill="#baa6cc"/>
                      <g id="Group_125-5" data-name="Group 125-5">
                        <g id="Light-5">
                          <g>
                            <g>
                              <path d="M1881.93,878.7l1.35-.18a3,3,0,0,0,.79,1.66,2,2,0,0,0,1.37.51,2.19,2.19,0,0,0,1.61-.67,2.22,2.22,0,0,0,.66-1.64,2,2,0,0,0-.61-1.53,2.09,2.09,0,0,0-1.55-.61,3.89,3.89,0,0,0-1,.15l.15-1.19.21,0a2.79,2.79,0,0,0,1.56-.45,1.55,1.55,0,0,0,.69-1.39,1.68,1.68,0,0,0-.5-1.24,1.82,1.82,0,0,0-1.3-.49,1.85,1.85,0,0,0-1.32.5,2.5,2.5,0,0,0-.67,1.49l-1.36-.24a3.48,3.48,0,0,1,1.13-2.11,3.27,3.27,0,0,1,2.19-.75,3.59,3.59,0,0,1,1.66.39,2.82,2.82,0,0,1,1.16,1.05,2.71,2.71,0,0,1,.4,1.43,2.3,2.3,0,0,1-.38,1.3,2.61,2.61,0,0,1-1.14.93,2.51,2.51,0,0,1,1.52.94,2.83,2.83,0,0,1,.55,1.77,3.26,3.26,0,0,1-1.06,2.45,3.7,3.7,0,0,1-2.66,1,3.45,3.45,0,0,1-2.41-.87A3.33,3.33,0,0,1,1881.93,878.7Z" fill="#fff"/>
                              <path d="M1897.51,873.29l-1.35.1a2.56,2.56,0,0,0-.51-1.16,1.9,1.9,0,0,0-2.49-.21,3.14,3.14,0,0,0-1,1.36,7.2,7.2,0,0,0-.38,2.55,3.09,3.09,0,0,1,1.2-1.11,3.2,3.2,0,0,1,1.48-.36,3,3,0,0,1,2.3,1,3.54,3.54,0,0,1,1,2.57,4.22,4.22,0,0,1-.45,1.93,3.17,3.17,0,0,1-1.22,1.36,3.37,3.37,0,0,1-1.78.48,3.47,3.47,0,0,1-2.76-1.25,6.28,6.28,0,0,1-1.07-4.1,7.41,7.41,0,0,1,1.18-4.65,3.42,3.42,0,0,1,2.78-1.26,3,3,0,0,1,3.13,2.75ZM1892,878a3.15,3.15,0,0,0,.3,1.34,2.2,2.2,0,0,0,.83,1,2.1,2.1,0,0,0,1.12.34,1.92,1.92,0,0,0,1.47-.7,2.71,2.71,0,0,0,.62-1.88,2.53,2.53,0,0,0-.61-1.8,2,2,0,0,0-1.53-.66,2.09,2.09,0,0,0-1.56.66A2.37,2.37,0,0,0,1892,878Z" fill="#fff"/>
                              <path d="M1899.05,876.17a10,10,0,0,1,.4-3.14,3.9,3.9,0,0,1,1.2-1.84,3.05,3.05,0,0,1,2-.65,3.24,3.24,0,0,1,1.56.36,3,3,0,0,1,1.11,1,5.62,5.62,0,0,1,.68,1.64,10.59,10.59,0,0,1,.25,2.6,10,10,0,0,1-.4,3.14,4,4,0,0,1-1.19,1.84,3.09,3.09,0,0,1-2,.65,3,3,0,0,1-2.5-1.15A7.22,7.22,0,0,1,1899.05,876.17Zm1.39,0a6.87,6.87,0,0,0,.64,3.62,1.81,1.81,0,0,0,3.13,0,10.5,10.5,0,0,0,0-7.22,1.88,1.88,0,0,0-1.58-.9,1.73,1.73,0,0,0-1.49.79A6.94,6.94,0,0,0,1900.44,876.17Z" fill="#fff"/>
                            </g>
                          </g>
                        </g>
                        <path d="M1911,865.51a2.83,2.83,0,1,0,2.45,2.8A2.64,2.64,0,0,0,1911,865.51Zm0,4.9a2.12,2.12,0,1,1,1.84-2.1A2,2,0,0,1,1911,870.41Z" fill="#fff"/>
                        <path id="Path_76-5" data-name="Path 76-5" d="M1879.88,876.51s-8.6,3.6-2.6,9,15.6,4.9,15.6,4.9" fill="none" stroke="#fdfeff" stroke-linecap="round" stroke-width="2"/>
                        <path id="Path_77-5" data-name="Path 77-5" d="M1889.5,887.22l.34.34.81.81,1,1,.85.85c.14.14.27.28.41.41h0v-.53l-.34.34-.81.81-1,1-.84.84-.41.41h0a.39.39,0,0,0,0,.55.39.39,0,0,0,.54,0l.34-.34.81-.8,1-1,.85-.85.41-.41h0a.38.38,0,0,0,0-.54l-.34-.34-.81-.81-1-1-.84-.84c-.14-.14-.27-.28-.41-.41h0a.38.38,0,0,0-.54,0,.39.39,0,0,0,0,.54h0Z" fill="#fff" stroke="#fdfeff"/>
                        <path id="Path_78-5" data-name="Path 78-5" d="M1898.86,890.42s12-1.42,14.52-6.27-3.19-7.26-3.19-7.26" fill="none" stroke="#fdfeff" stroke-linecap="round" stroke-width="2"/>
                      </g>
                    </g>
                    <g>
                      <g>
                        <path d="M1875,914.84c2.6,0,4.42,1.47,4.42,4.46,0,3.75-2.56,6.74-6.38,6.74h-3.11l2.19-11.2Zm-1.72,1.34-1.65,8.52H1873c3,0,4.85-2.32,4.85-5.25,0-2.2-1.15-3.27-3.2-3.27Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1883.82,914.84l-2.2,11.2h-1.42l2.19-11.2Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1894.45,914.84l-2.19,11.2h-1.59l-3.28-8.72-1.7,8.72h-1.42l2.19-11.2h1.59l3.26,8.75,1.72-8.75Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1898.64,914.84l-2.2,11.2H1895l2.19-11.2Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1909.26,914.84l-2.19,11.2h-1.59l-3.28-8.72-1.7,8.72h-1.42l2.19-11.2h1.59l3.26,8.75,1.72-8.75Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1915.94,920.39h3.77l-1.1,5.65h-1.22l.15-1.54a3.88,3.88,0,0,1-3.41,1.67c-2,0-4-1.44-4-4.56,0-3.59,2.26-6.9,6.2-6.9a3.47,3.47,0,0,1,3.68,3.37l-1.41.37a2.38,2.38,0,0,0-2.53-2.4c-2.72,0-4.46,2.56-4.46,5.35,0,2.22,1,3.42,2.75,3.42a3.76,3.76,0,0,0,3.59-3.09h-2.31Z" fill="#59585b"/>
                      </g>
                    </g>
                  </g>
                  <g className="suites__vr" data-vr="https://astoria-tours.web.app/Amenities_Ground_Floor/#quiet_room" id="vrOpenFlexArea" >
                    <g>
                      <circle cx="1711.67" cy="467.55" r="26.03" fill="#baa6cc"/>
                      <g id="Group_125" data-name="Group 125">
                        <g id="Light">
                          <g>
                            <g>
                              <path d="M1698.44,467.73l1.35-.18a3,3,0,0,0,.79,1.66,2,2,0,0,0,1.37.51,2.27,2.27,0,0,0,2.27-2.31,2,2,0,0,0-.61-1.53,2.09,2.09,0,0,0-1.55-.61,3.89,3.89,0,0,0-1,.15l.15-1.19.21,0a2.79,2.79,0,0,0,1.56-.45,1.55,1.55,0,0,0,.69-1.39,1.64,1.64,0,0,0-.5-1.24,1.82,1.82,0,0,0-1.3-.49,1.85,1.85,0,0,0-1.32.5,2.5,2.5,0,0,0-.67,1.49l-1.36-.24a3.48,3.48,0,0,1,1.13-2.11,3.27,3.27,0,0,1,2.19-.75,3.59,3.59,0,0,1,1.66.39,2.78,2.78,0,0,1,1.16,1.06,2.68,2.68,0,0,1,.4,1.42,2.3,2.3,0,0,1-.38,1.3,2.58,2.58,0,0,1-1.13.93,2.51,2.51,0,0,1,1.51.94,2.77,2.77,0,0,1,.55,1.77,3.26,3.26,0,0,1-1.06,2.45,3.7,3.7,0,0,1-2.66,1,3.45,3.45,0,0,1-2.41-.87A3.33,3.33,0,0,1,1698.44,467.73Z" fill="#fff"/>
                              <path d="M1714,462.32l-1.35.1a2.56,2.56,0,0,0-.51-1.16,1.9,1.9,0,0,0-2.49-.21,3.14,3.14,0,0,0-1,1.36,7.2,7.2,0,0,0-.38,2.55,3.09,3.09,0,0,1,1.2-1.11,3.2,3.2,0,0,1,1.48-.36,3,3,0,0,1,2.3,1,3.54,3.54,0,0,1,1,2.57,4.22,4.22,0,0,1-.45,1.93,3.17,3.17,0,0,1-1.22,1.36,3.37,3.37,0,0,1-1.78.48,3.47,3.47,0,0,1-2.76-1.25,6.28,6.28,0,0,1-1.07-4.1,7.41,7.41,0,0,1,1.18-4.65,3.42,3.42,0,0,1,2.78-1.26,3,3,0,0,1,3.13,2.75Zm-5.53,4.75a3.15,3.15,0,0,0,.3,1.34,2.2,2.2,0,0,0,.83,1,2.1,2.1,0,0,0,1.12.34,1.92,1.92,0,0,0,1.47-.7,2.71,2.71,0,0,0,.62-1.88,2.53,2.53,0,0,0-.61-1.8,2,2,0,0,0-1.53-.65,2.08,2.08,0,0,0-1.56.65A2.37,2.37,0,0,0,1708.49,467.07Z" fill="#fff"/>
                              <path d="M1715.56,465.2a10,10,0,0,1,.4-3.14,3.9,3.9,0,0,1,1.2-1.84,3.05,3.05,0,0,1,2-.65,3.24,3.24,0,0,1,1.56.36,3,3,0,0,1,1.11,1,5.62,5.62,0,0,1,.68,1.64,10.59,10.59,0,0,1,.25,2.6,10,10,0,0,1-.4,3.14,4,4,0,0,1-1.19,1.84,3.09,3.09,0,0,1-2,.65,3,3,0,0,1-2.5-1.14A7.28,7.28,0,0,1,1715.56,465.2Zm1.39,0a6.87,6.87,0,0,0,.64,3.62,1.81,1.81,0,0,0,3.13,0,10.5,10.5,0,0,0,0-7.22,1.88,1.88,0,0,0-1.58-.9,1.73,1.73,0,0,0-1.49.79A6.94,6.94,0,0,0,1717,465.2Z" fill="#fff"/>
                            </g>
                          </g>
                        </g>
                        <path d="M1727.53,454.54a2.82,2.82,0,1,0,2.45,2.8A2.64,2.64,0,0,0,1727.53,454.54Zm0,4.9a2.12,2.12,0,1,1,1.84-2.1A2,2,0,0,1,1727.53,459.44Z" fill="#fff"/>
                        <path id="Path_76" data-name="Path 76" d="M1696.39,465.54s-8.6,3.6-2.6,9,15.6,4.9,15.6,4.9" fill="none" stroke="#fdfeff" stroke-linecap="round" stroke-width="2"/>
                        <path id="Path_77" data-name="Path 77" d="M1706,476.25l.34.34.81.81,1,1,.85.85c.14.14.27.28.41.41h0v-.53l-.34.34-.81.81-1,1-.84.84-.41.41h0a.39.39,0,0,0,0,.55.39.39,0,0,0,.54,0l.34-.34.81-.8,1-1,.85-.85.41-.41h0a.38.38,0,0,0,0-.54l-.34-.34-.81-.81-1-1-.84-.84c-.14-.14-.27-.28-.41-.41h0a.38.38,0,0,0-.54,0,.39.39,0,0,0,0,.54h0Z" fill="#fff" stroke="#fdfeff"/>
                        <path id="Path_78" data-name="Path 78" d="M1715.37,479.45s12-1.42,14.52-6.27-3.19-7.26-3.19-7.26" fill="none" stroke="#fdfeff" stroke-linecap="round" stroke-width="2"/>
                      </g>
                    </g>
                    <g>
                      <g>
                        <path d="M1682.51,508.25a7.09,7.09,0,0,1-2.42,5.57,5.38,5.38,0,0,1-3.76,1.36c-2.31,0-4-1.65-4-4.53a7.07,7.07,0,0,1,2.42-5.57,5.34,5.34,0,0,1,3.76-1.36C1680.86,503.72,1682.51,505.37,1682.51,508.25Zm-7.09-1.88a6,6,0,0,0-1.57,4.15c0,2.15,1,3.31,2.72,3.31a3.94,3.94,0,0,0,2.9-1.31,6,6,0,0,0,1.56-4.14c0-2.15-1-3.32-2.72-3.32A3.94,3.94,0,0,0,1675.42,506.37Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1686.14,510.25l-1,4.8h-1.42l2.19-11.2h3.59c1.81,0,3,1,3,2.65,0,2.5-1.66,3.75-4.13,3.75Zm4.91-3.49a1.42,1.42,0,0,0-1.6-1.57h-2.33l-.72,3.71h2.44A2,2,0,0,0,1691.05,506.76Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1701.38,503.85l-.27,1.34h-4.91l-.63,3.22h4l-.25,1.34h-4l-.77,4h5.07l-.27,1.34h-6.5l2.19-11.2Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1711.66,503.85l-2.19,11.2h-1.59l-3.28-8.72-1.7,8.72h-1.42l2.19-11.2h1.59l3.26,8.75,1.72-8.75Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1725.56,503.85l-.27,1.34h-5l-.64,3.35h4l-.28,1.34h-4l-1,5.17H1717l2.19-11.2Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1729,503.85l-1.92,9.86H1732l-.28,1.34h-6.35l2.19-11.2Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1742.23,503.85l-.27,1.34h-4.91l-.63,3.22h4l-.25,1.34h-4l-.77,4h5.07l-.27,1.34h-6.5l2.19-11.2Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1745.77,503.85l1.38,3.74,2.83-3.74h1.57l-3.89,5.13,2.27,6.07h-1.47l-1.79-4.75-3.56,4.75h-1.61l4.65-6.15-1.89-5Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1699.29,520.85l1.7,11.2h-1.43l-.4-3H1695l-1.59,3H1692l6-11.2Zm-3.6,6.86H1699l-.72-5Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1706.85,526.77h-1.64l-1,5.28h-1.43l2.19-11.2h3.56c1.77,0,2.85,1,2.85,2.51a3.15,3.15,0,0,1-3,3.31l2.29,5.38h-1.58Zm-.76-4.58-.63,3.23h2.23c1.34,0,2.17-.59,2.17-1.81,0-1-.56-1.42-1.6-1.42Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1720.76,520.85l-.27,1.34h-4.91l-.63,3.22h4l-.25,1.34h-4l-.77,4H1719l-.27,1.34h-6.5l2.19-11.2Z" fill="#59585b"/>
                      </g>
                      <g>
                        <path d="M1727.36,520.85l1.7,11.2h-1.43l-.4-3h-4.17l-1.59,3h-1.42l6-11.2Zm-3.6,6.86H1727l-.72-5Z" fill="#59585b"/>
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
            </div>
        </div>
    )
}

export default GroundFloor;