const PropertyTourOrbit = ({ isActive }) => {
    const classes = ['propertyTour__section'];
    isActive && classes.push('active');

    return (
        <div className={classes.join(' ')} data-section="orbit">
            <iframe src="https://app.helloastoria.com/assets/orbit/2210_Orbit_@.html" frameBorder="0" title="iframe"></iframe>
        </div>
    )
}

export default PropertyTourOrbit;