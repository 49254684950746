import { useEffect, useContext } from 'react';
import { Context } from '../context/context';
import Floorplate from '../assets/floorplates/floor2test.png';
import { suitesData } from '../data/suitesData';
import LowerLevel from '../floorplates/LowerLevel.component';
import GroundFloor from '../floorplates/GroundFloor.component';
import FloorTwo from '../floorplates/FloorTwo.component';
import FloorThreeFourFive from '../floorplates/FloorsThreeFourFive.component';
import FloorSixSevenEight from '../floorplates/FloorsSixSevenEight.component';

const SutiesFloorplateOverlays = ({ handleVrClick, floor }) => {
    switch (floor) {
        case 'lowerLevel':
            return <LowerLevel handleVrClick={handleVrClick} />;

        case 'groundFloor':
            return <GroundFloor handleVrClick={handleVrClick} />

        case 'floorTwo':
            return <FloorTwo />

        case 'floorsThreeFourFive':
            return <FloorThreeFourFive />

        case 'floorsSixSevenEight':
            return <FloorSixSevenEight />

        default:
            return;
    }
}

export default SutiesFloorplateOverlays;