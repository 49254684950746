import { useContext, useState, useEffect } from 'react';

import { Context } from '../context/context';

import { floors } from '../data/floors';
import SuitesTypeFilter from '../components/SuitesTypeFilter.component';
import SuitesFloorplanPopup from './SuitesFloorplanPopup.component';

import SuiteKeyplan from './SuiteKeyplan.component';

import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { suitesData } from '../data/suitesData';
import SuitesFloorplateOverlays from './SuitesFloorplateOverlays.component';
import VrTourPopup from './VrTourPopup.component';
import { isCompositeComponent } from 'react-dom/test-utils';

const SuitesFloors = ({ currentSubsection, currentFloor }) => {
    const { activeSuite, setActiveSuite, activeSuiteTypeFilters } = useContext(Context);

    const classes = ['suites__content'];
    currentSubsection === 'floors' && classes.push('active');

    const [popupActive, setPopupActive] = useState(false);
    const [vrTourActive, setVrTourActive] = useState(false);
    const [currentVrTour, setCurrentVrTour] = useState('');

    const handleSuiteClick = (e, id) => {
        e.preventDefault();

        const isValidId = suitesData.find(s => s.id === id) ? true : false;

        if (isValidId) {
            setActiveSuite(id);
            setPopupActive(true);    
        } else console.log(`${id} is not a valid Id`)
    }

    const handleVrClick = (vrTour) => {
        if (vrTour) {
            setCurrentVrTour(vrTour);
            setVrTourActive(true);
        }
    }

    useEffect(() => {
        const units = document.querySelectorAll('.suites__unit');
        const vr = document.querySelectorAll('.suites__vr');

        units.forEach(unit => unit.addEventListener('click', (e) =>  {
            const id = unit.getAttribute('id');
            handleSuiteClick(e, id);
        }));

        vr.forEach(vr => vr.addEventListener('click', (e) =>  {
            const vrTour = vr.getAttribute('data-vr');
            vrTour && handleVrClick(vrTour);
        }));
    }, []);

    useEffect(() => {
        const activeFilters = [];
        const units = document.querySelectorAll('.suites__unit');
        
        activeSuiteTypeFilters.forEach(type => {
            if (type.isActive) activeFilters.push(type.id);
        });

        if (activeFilters.length === 0) {
            units.forEach(unit => unit.classList.add('active'));
        } else {
            units.forEach(unit => {
                const id = unit.getAttribute('id');
                const suite = suitesData.find(s => s.id === id);

                if (suite) {
                    if (activeFilters.includes(suite.type)) {
                        unit.classList.add('active');
                    } else {
                        unit.classList.remove('active');
                    }
                }
            })
        }
    }, [activeSuiteTypeFilters]);

    const renderFloor = (floor) => {
        const { id, keyplan } = floor;

        const classes = ['suites__floor'];
        id === currentFloor && classes.push('active');


        return (
            <div className={classes.join(' ')} data-floor={id} key={id}>
                <SuitesFloorplateOverlays handleSuiteClick={handleSuiteClick} handleVrClick={handleVrClick} floor={id} />
                <SuiteKeyplan keyplan={keyplan} />
            </div>
        )
    }

    return (
        <div className={classes.join(' ')} data-subsection="floors">
            <SuitesTypeFilter />
            {floors.map(floor => renderFloor(floor))}

            <Popup open={popupActive} modal nested onClose={() => setPopupActive(false)}>
                {close => (<SuitesFloorplanPopup close={close} id={activeSuite} />)}
            </Popup>

            <Popup open={vrTourActive} modal nested onClose={() => setVrTourActive(false)}>
                {close => (<VrTourPopup title="VR Popup" vrTour={currentVrTour} close={close} />)}
            </Popup>
        </div>
    )
}

export default SuitesFloors;