import GalleryImage1 from '../assets/images/gallery-bar.png'
import GalleryImage2 from '../assets/images/gallery-dining.png'
import GalleryImage3 from '../assets/images/gallery-exterior-1.png'
import GalleryImage4 from '../assets/images/gallery-exterior-2.png'
import GalleryImage5 from '../assets/images/gallery-gym.png'
import GalleryImage6 from '../assets/images/gallery-horticulture-space.png'
import GalleryImage7 from '../assets/images/gallery-lobby.png'
import GalleryImage8 from '../assets/images/gallery-lounge.png'
import GalleryImage9 from '../assets/images/gallery-open-flex-area.png'
import GalleryImage10 from '../assets/images/gallery-pool.png'
import GalleryImage11 from '../assets/images/gallery-putting-green.png'
import GalleryImage12 from '../assets/images/gallery-quiet-room.png'
import GalleryImage13 from '../assets/images/gallery-suite-bathroom-1.png'
import GalleryImage14 from '../assets/images/gallery-suite-bathroom-2.png'
import GalleryImage15 from '../assets/images/gallery-suite-bedroom.png'
import GalleryImage16 from '../assets/images/gallery-suite-kitchen-1.png'
import GalleryImage17 from '../assets/images/gallery-suite-living-room-1.png'
import GalleryImage18 from '../assets/images/gallery-suite-living-room-2.png'
import GalleryImage19 from '../assets/images/gallery-suite-living-room-3.png'
import GalleryImage20 from '../assets/images/gallery-terrace.png'
import GalleryImage21 from '../assets/images/gallery-theatre.png'


const galleryItems = [
{ name: 'Bar', image: GalleryImage1 },
{ name: 'Dining Room', image: GalleryImage2 },
{ name: 'Exterior 1', image: GalleryImage3 },
{ name: 'Exterior 2', image: GalleryImage4 },
{ name: 'Gym', image: GalleryImage5 },
{ name: 'Horticulture Space', image: GalleryImage6 },
{ name: 'Lobby', image: GalleryImage7 },
{ name: 'Lounge', image: GalleryImage8 },
{ name: 'Open Flex Area', image: GalleryImage9 },
{ name: 'Pool', image: GalleryImage10 },
{ name: 'Putting Green', image: GalleryImage11 },
{ name: 'Quiet Room', image: GalleryImage12 },
{ name: 'Suite Bathroom 1', image: GalleryImage13 },
{ name: 'Suite Bathroom 2', image: GalleryImage14 },
{ name: 'Suite Bedroom', image: GalleryImage15 },
{ name: 'Kitchen 1', image: GalleryImage16 },
{ name: 'Suite Living Room 1', image: GalleryImage17 },
{ name: 'Suite Living Room 2', image: GalleryImage18 },
{ name: 'Suite Living Room 3', image: GalleryImage19 },
{ name: 'Terrace', image: GalleryImage20 },
{ name: 'Theatre', image: GalleryImage21 },
];

export default galleryItems;