export const suitesData = [
  {
    "livingStyle": "seniorsapartments",
    "id": "B1",
    "title": "Sedum B1",
    "location": "2nd - 10th Floor",
    "type": "1 Bed + Den",
    "sqft": 740,
    "sqftRange": "700-799",
    "numOfBeds": "onebedden",
    "numOfBaths": "onebathroom",
    "hasDen": true,
    "hasWalkInCloset": false,
    "hasBalcony": true,
    "hasTerrace": false,
    "hasEnsuite": false,
    "floorplan": "Floorplan-SEDUM B1.jpg",
    "keyplan": "Keyplan-SEDUM B1.jpg",
    "isAccessible": false,
    "dataBG": "onebedden",
    "hasVrTour": true,
    "vrTour": "https://astoria-tours.web.app/Suites/Suite_B01/",
    "pdf": "Floorplan-SEDUM B1.pdf"
  },
  {
    "livingStyle": "seniorsapartments",
    "id": "B10a",
    "title": "Daffodil B10",
    "location": "2nd Floor",
    "type": "2 Bed",
    "sqft": 911,
    "sqftRange": "900-999",
    "numOfBeds": "twobed",
    "numOfBaths": "twobathroom",
    "hasDen": false,
    "hasWalkInCloset": false,
    "hasBalcony": false,
    "hasTerrace": true,
    "hasEnsuite": true,
    "floorplan": "Floorplan-DAFFODIL B10-02.jpg",
    "keyplan": "Keyplan-DAFFODIL B10-02.jpg",
    "isAccessible": true,
    "dataBG": "twobed",
    "hasVrTour": true,
    "vrTour": "https://astoria-tours.web.app/Suites/Suite_B09/",
    "pdf": "Floorplan-DAFFODIL B10-02.pdf"
  },
  {
    "livingStyle": "seniorsapartments",
    "id": "B10b",
    "title": "Daffodil B10",
    "location": "3rd - 8th Floor",
    "type": "2 Bed",
    "sqft": 911,
    "sqftRange": "900-999",
    "numOfBeds": "twobed",
    "numOfBaths": "twobathroom",
    "hasDen": false,
    "hasWalkInCloset": false,
    "hasBalcony": true,
    "hasTerrace": false,
    "hasEnsuite": true,
    "floorplan": "Floorplan-DAFFODIL B10-01.jpg",
    "keyplan": "Keyplan-DAFFODIL B10-01.jpg",
    "isAccessible": true,
    "dataBG": "twobed",
    "hasVrTour": true,
    "vrTour": "https://astoria-tours.web.app/Suites/Suite_B09/",
    "pdf": "Floorplan-DAFFODIL B10-01.pdf"
  },
  {
    "livingStyle": "seniorsapartments",
    "id": "B11a",
    "title": "Dianthus B11",
    "location": "2nd Floor",
    "type": "2 Bed + Den",
    "sqft": 1152,
    "sqftRange": "1000+",
    "numOfBeds": "twobedden",
    "numOfBaths": "twobathroom",
    "hasDen": true,
    "hasWalkInCloset": true,
    "hasBalcony": false,
    "hasTerrace": true,
    "hasEnsuite": true,
    "floorplan": "Floorplan-DIANTHUS B11-01.jpg",
    "keyplan": "Keyplan-DIANTHUS B11-01.jpg",
    "isAccessible": false,
    "dataBG": "twobedden",
    "hasVrTour": true,
    "vrTour": "https://astoria-tours.web.app/Suites/Suite_B11/",
    "pdf": "Floorplan-DIANTHUS B11-01.pdf"
  },
  {
    "livingStyle": "seniorsapartments",
    "id": "B11b",
    "title": "Dianthus B11",
    "location": "3rd - 10th Floor",
    "type": "2 Bed + Den",
    "sqft": 1040,
    "sqftRange": "1000+",
    "numOfBeds": "twobedden",
    "numOfBaths": "twobathroom",
    "hasDen": true,
    "hasWalkInCloset": true,
    "hasBalcony": true,
    "hasTerrace": false,
    "hasEnsuite": true,
    "floorplan": "Floorplan-DIANTHUS B11-02.jpg",
    "keyplan": "Keyplan-DIANTHUS B11-02.jpg",
    "isAccessible": false,
    "dataBG": "twobedden",
    "hasVrTour": true,
    "vrTour": "https://astoria-tours.web.app/Suites/Suite_B11/",
    "pdf": "Floorplan-DIANTHUS B11-02.pdf"
  },
  {
    "livingStyle": "seniorsapartments",
    "id": "B12a",
    "title": "Gazania B12",
    "location": "2nd Floor",
    "type": "1 Bed + Den",
    "sqft": 720,
    "sqftRange": "700-799",
    "numOfBeds": "onebedden",
    "numOfBaths": "onebathroom",
    "hasDen": true,
    "hasWalkInCloset": false,
    "hasBalcony": false,
    "hasTerrace": true,
    "hasEnsuite": false,
    "floorplan": "Floorplan-GAZANIA B12-01.jpg",
    "keyplan": "Keyplan-GAZANIA B12-01.jpg",
    "isAccessible": false,
    "dataBG": "onebedden",
    "hasVrTour": false,
    "vrTour": "",
    "pdf": "Floorplan-GAZANIA B12-01.pdf"
  },
  {
    "livingStyle": "seniorsapartments",
    "id": "B12b",
    "title": "Gazania B12",
    "location": "3rd - 10th Floor",
    "type": "1 Bed + Den",
    "sqft": 720,
    "sqftRange": "700-799",
    "numOfBeds": "onebedden",
    "numOfBaths": "onebathroom",
    "hasDen": true,
    "hasWalkInCloset": false,
    "hasBalcony": true,
    "hasTerrace": false,
    "hasEnsuite": false,
    "floorplan": "Floorplan-GAZANIA B12-02.jpg",
    "keyplan": "Keyplan-GAZANIA B12-02.jpg",
    "isAccessible": false,
    "dataBG": "onebedden",
    "hasVrTour": false,
    "vrTour": "",
    "pdf": "Floorplan-GAZANIA B12-02.pdf"
  },
  {
    "livingStyle": "seniorsapartments",
    "id": "B13a",
    "title": "Gerbera B13",
    "location": "2nd Floor",
    "type": "1 Bed",
    "sqft": 473,
    "sqftRange": "400-499",
    "numOfBeds": "onebed",
    "numOfBaths": "onebathroom",
    "hasDen": false,
    "hasWalkInCloset": false,
    "hasBalcony": false,
    "hasTerrace": true,
    "hasEnsuite": false,
    "floorplan": "Floorplan-GERBERA B13-01.jpg",
    "keyplan": "Keyplan-GERBERA B13-01.jpg",
    "isAccessible": false,
    "dataBG": "onebed",
    "hasVrTour": false,
    "vrTour": "",
    "pdf": "Floorplan-GERBERA B13-01.pdf"
  },
  {
    "livingStyle": "seniorsapartments",
    "id": "B13b",
    "title": "Gerbera B13",
    "location": "3rd - 10th Floor",
    "type": "1 Bed",
    "sqft": 473,
    "sqftRange": "400-499",
    "numOfBeds": "onebed",
    "numOfBaths": "onebathroom",
    "hasDen": false,
    "hasWalkInCloset": false,
    "hasBalcony": true,
    "hasTerrace": false,
    "hasEnsuite": false,
    "floorplan": "Floorplan-GERBERA B13-02.jpg",
    "keyplan": "Keyplan-GERBERA B13-02.jpg",
    "isAccessible": false,
    "dataBG": "onebed",
    "hasVrTour": false,
    "vrTour": "",
    "pdf": "Floorplan-GERBERA B13-02.pdf"
  },
  {
    "livingStyle": "seniorsapartments",
    "id": "B14a",
    "title": "Jade B14",
    "location": "2nd Floor",
    "type": "1 Bed + Den",
    "sqft": 793,
    "sqftRange": "700-799",
    "numOfBeds": "onebedden",
    "numOfBaths": "onebathroom",
    "hasDen": true,
    "hasWalkInCloset": false,
    "hasBalcony": false,
    "hasTerrace": true,
    "hasEnsuite": false,
    "floorplan": "Floorplan-JADE B14-01.jpg",
    "keyplan": "Keyplan-JADE B14-01.jpg",
    "isAccessible": true,
    "dataBG": "onebedden",
    "hasVrTour": true,
    "vrTour": "https://astoria-tours.web.app/Suites/Suite_B14/",
    "pdf": "Floorplan-JADE B14-01.pdf"
  },
  {
    "livingStyle": "seniorsapartments",
    "id": "B14b",
    "title": "Jade B14",
    "location": "3rd - 10th Floor",
    "type": "1 Bed + Den",
    "sqft": 793,
    "sqftRange": "700-799",
    "numOfBeds": "onebedden",
    "numOfBaths": "onebathroom",
    "hasDen": true,
    "hasWalkInCloset": false,
    "hasBalcony": true,
    "hasTerrace": false,
    "hasEnsuite": false,
    "floorplan": "Floorplan-JADE B14-02.jpg",
    "keyplan": "Keyplan-JADE B14-02.jpg",
    "isAccessible": true,
    "dataBG": "onebedden",
    "hasVrTour": true,
    "vrTour": "https://astoria-tours.web.app/Suites/Suite_B14/",
    "pdf": "Floorplan-JADE B14-02.pdf"
  },
  {
    "livingStyle": "seniorsapartments",
    "id": "B15a",
    "title": "Lamium B15",
    "location": "2nd Floor",
    "type": "1 Bed + Den",
    "sqft": 791,
    "sqftRange": "700-799",
    "numOfBeds": "onebedden",
    "numOfBaths": "onebathroom",
    "hasDen": true,
    "hasWalkInCloset": false,
    "hasBalcony": false,
    "hasTerrace": true,
    "hasEnsuite": false,
    "floorplan": "Floorplan-LAMIUM B15-01.jpg",
    "keyplan": "Keyplan-LAMIUM B15-01.jpg",
    "isAccessible": false,
    "dataBG": "onebedden",
    "hasVrTour": false,
    "vrTour": "",
    "pdf": "Floorplan-LAMIUM B15-01.pdf"
  },
  {
    "livingStyle": "seniorsapartments",
    "id": "B15b",
    "title": "Lamium B15",
    "location": "3rd - 10th Floor",
    "type": "1 Bed + Den",
    "sqft": 791,
    "sqftRange": "700-799",
    "numOfBeds": "onebedden",
    "numOfBaths": "onebathroom",
    "hasDen": true,
    "hasWalkInCloset": false,
    "hasBalcony": true,
    "hasTerrace": false,
    "hasEnsuite": false,
    "floorplan": "Floorplan-LAMIUM B15-02.jpg",
    "keyplan": "Keyplan-LAMIUM B15-02.jpg",
    "isAccessible": false,
    "dataBG": "onebedden",
    "hasVrTour": false,
    "vrTour": "",
    "pdf": "Floorplan-LAMIUM B15-02.pdf"
  },
  {
    "livingStyle": "seniorsapartments",
    "id": "B16",
    "title": "Lantana B16",
    "location": "2nd - 10th Floor",
    "type": "1 Bed",
    "sqft": 467,
    "sqftRange": "400-499",
    "numOfBeds": "onebed",
    "numOfBaths": "onebathroom",
    "hasDen": false,
    "hasWalkInCloset": false,
    "hasBalcony": true,
    "hasTerrace": false,
    "hasEnsuite": false,
    "floorplan": "Floorplan-LANTANA B16.jpg",
    "keyplan": "Keyplan-LANTANA B16.jpg",
    "isAccessible": false,
    "dataBG": "onebed",
    "hasVrTour": false,
    "vrTour": "",
    "pdf": "Floorplan-LANTANA B16.pdf"
  },
  {
    "livingStyle": "seniorsapartments",
    "id": "B17",
    "title": "Liriope B17",
    "location": "2nd - 10th Floor",
    "type": "1 Bed + Den",
    "sqft": 715,
    "sqftRange": "700-799",
    "numOfBeds": "onebedden",
    "numOfBaths": "onebathroom",
    "hasDen": true,
    "hasWalkInCloset": false,
    "hasBalcony": true,
    "hasTerrace": false,
    "hasEnsuite": false,
    "floorplan": "Floorplan-LIRIOPE B17.jpg",
    "keyplan": "Keyplan-LIRIOPE B17.jpg",
    "isAccessible": true,
    "dataBG": "onebedden",
    "hasVrTour": false,
    "vrTour": "",
    "pdf": "Floorplan-LIRIOPE B17.pdf"
  },
  {
    "livingStyle": "seniorsapartments",
    "id": "B18",
    "title": "Lobelia B18",
    "location": "2nd - 10th Floor",
    "type": "1 Bed + Den",
    "sqft": 640,
    "sqftRange": "600-699",
    "numOfBeds": "onebedden",
    "numOfBaths": "onebathroom",
    "hasDen": true,
    "hasWalkInCloset": false,
    "hasBalcony": true,
    "hasTerrace": false,
    "hasEnsuite": false,
    "floorplan": "Floorplan-LOBELIA B18.jpg",
    "keyplan": "Keyplan-LOBELIA B18.jpg",
    "isAccessible": false,
    "dataBG": "onebedden",
    "hasVrTour": false,
    "vrTour": "",
    "pdf": "Floorplan-LOBELIA B18.pdf"
  },
  {
    "livingStyle": "seniorsapartments",
    "id": "B19",
    "title": "Pansy B19",
    "location": "2nd - 10th Floor",
    "type": "1 Bed + Den",
    "sqft": 741,
    "sqftRange": "700-799",
    "numOfBeds": "onebedden",
    "numOfBaths": "onebathroom",
    "hasDen": true,
    "hasWalkInCloset": false,
    "hasBalcony": true,
    "hasTerrace": false,
    "hasEnsuite": false,
    "floorplan": "Floorplan-PANSY B19.jpg",
    "keyplan": "Keyplan-PANSY B19.jpg",
    "isAccessible": true,
    "dataBG": "onebedden",
    "hasVrTour": false,
    "vrTour": "",
    "pdf": "Floorplan-PANSY B19.pdf"
  },
  {
    "livingStyle": "seniorsapartments",
    "id": "B2",
    "title": "Silene B2",
    "location": "2nd - 10th Floor",
    "type": "1 Bed + Den",
    "sqft": 690,
    "sqftRange": "600-699",
    "numOfBeds": "onebedden",
    "numOfBaths": "onebathroom",
    "hasDen": true,
    "hasWalkInCloset": false,
    "hasBalcony": true,
    "hasTerrace": false,
    "hasEnsuite": false,
    "floorplan": "Floorplan-SILENE B2.jpg",
    "keyplan": "Keyplan-SILENE B2.jpg",
    "isAccessible": false,
    "dataBG": "onebedden",
    "hasVrTour": true,
    "vrTour": "https://astoria-tours.web.app/Suites/Suite_B02/",
    "pdf": "Floorplan-SILENE B2.pdf"
  },
  {
    "livingStyle": "seniorsapartments",
    "id": "B20",
    "title": "Zinnia B20",
    "location": "2nd - 10th Floor",
    "type": "2 Bed",
    "sqft": 865,
    "sqftRange": "800-899",
    "numOfBeds": "twobed",
    "numOfBaths": "twobathroom",
    "hasDen": false,
    "hasWalkInCloset": true,
    "hasBalcony": true,
    "hasTerrace": false,
    "hasEnsuite": true,
    "floorplan": "Floorplan-ZINNIA B20.jpg",
    "keyplan": "Keyplan-ZINNIA B20.jpg",
    "isAccessible": false,
    "dataBG": "twobed",
    "hasVrTour": true,
    "vrTour": "https://astoria-tours.web.app/Suites/Suite_B20/",
    "pdf": "Floorplan-ZINNIA B20.pdf"
  },
  {
    "livingStyle": "seniorsapartments",
    "id": "B3a",
    "title": "Hebe B3",
    "location": "2nd Floor",
    "type": "1 Bed",
    "sqft": 462,
    "sqftRange": "400-499",
    "numOfBeds": "onebed",
    "numOfBaths": "onebathroom",
    "hasDen": false,
    "hasWalkInCloset": false,
    "hasBalcony": false,
    "hasTerrace": true,
    "hasEnsuite": false,
    "floorplan": "Floorplan-HEBE B3-01.jpg",
    "keyplan": "Keyplan-HEBE B3-01.jpg",
    "isAccessible": false,
    "dataBG": "onebed",
    "hasVrTour": false,
    "vrTour": "",
    "pdf": "Floorplan-HEBE B3-01.pdf"
  },
  {
    "livingStyle": "seniorsapartments",
    "id": "B3b",
    "title": "Hebe B3",
    "location": "3rd - 10th Floor",
    "type": "1 Bed",
    "sqft": 462,
    "sqftRange": "400-499",
    "numOfBeds": "onebed",
    "numOfBaths": "onebathroom",
    "hasDen": false,
    "hasWalkInCloset": false,
    "hasBalcony": true,
    "hasTerrace": "",
    "hasEnsuite": false,
    "floorplan": "Floorplan-HEBE B3-02.jpg",
    "keyplan": "Keyplan-HEBE B3-02.jpg",
    "isAccessible": false,
    "dataBG": "onebed",
    "hasVrTour": false,
    "vrTour": "",
    "pdf": "Floorplan-HEBE B3-02.pdf"
  },
  {
    "livingStyle": "seniorsapartments",
    "id": "B4a",
    "title": "MayFlower B4",
    "location": "2nd Floor",
    "type": "1 Bed + Den",
    "sqft": 789,
    "sqftRange": "700-799",
    "numOfBeds": "onebedden",
    "numOfBaths": "onebathroom",
    "hasDen": true,
    "hasWalkInCloset": false,
    "hasBalcony": false,
    "hasTerrace": true,
    "hasEnsuite": false,
    "floorplan": "Floorplan-MAYFLOWER B4-01.jpg",
    "keyplan": "Keyplan-MAYFLOWER B4-01.jpg",
    "isAccessible": false,
    "dataBG": "onebedden",
    "hasVrTour": false,
    "vrTour": "",
    "pdf": "Floorplan-MAYFLOWER B4-01.pdf"
  },
  {
    "livingStyle": "seniorsapartments",
    "id": "B4b",
    "title": "MayFlower B4",
    "location": "3rd - 10th Floor",
    "type": "1 Bed + Den",
    "sqft": 790,
    "sqftRange": "700-799",
    "numOfBeds": "onebedden",
    "numOfBaths": "onebathroom",
    "hasDen": true,
    "hasWalkInCloset": false,
    "hasBalcony": true,
    "hasTerrace": "",
    "hasEnsuite": false,
    "floorplan": "Floorplan-MAYFLOWER B4-02.jpg",
    "keyplan": "Keyplan-MAYFLOWER B4-02.jpg",
    "isAccessible": false,
    "dataBG": "onebedden",
    "hasVrTour": false,
    "vrTour": "",
    "pdf": "Floorplan-MAYFLOWER B4-02.pdf"
  },
  {
    "livingStyle": "seniorsapartments",
    "id": "B5a",
    "title": "Anthurium B5",
    "location": "2nd Floor",
    "type": "1 Bed + Den",
    "sqft": 789,
    "sqftRange": "700-799",
    "numOfBeds": "onebedden",
    "numOfBaths": "onebathroom",
    "hasDen": true,
    "hasWalkInCloset": false,
    "hasBalcony": false,
    "hasTerrace": true,
    "hasEnsuite": false,
    "floorplan": "Floorplan-ANTHURIUM B5-01.jpg",
    "keyplan": "Keyplan-ANTHURIUM B5-01.jpg",
    "isAccessible": false,
    "dataBG": "onebedden",
    "hasVrTour": false,
    "vrTour": "",
    "pdf": "Floorplan-ANTHURIUM B5-01.pdf"
  },
  {
    "livingStyle": "seniorsapartments",
    "id": "B5b",
    "title": "Anthurium B5",
    "location": "3rd - 10th Floor",
    "type": "1 Bed + Den",
    "sqft": 790,
    "sqftRange": "700-799",
    "numOfBeds": "onebedden",
    "numOfBaths": "onebathroom",
    "hasDen": true,
    "hasWalkInCloset": false,
    "hasBalcony": true,
    "hasTerrace": "",
    "hasEnsuite": false,
    "floorplan": "Floorplan-ANTHURIUM B5-02.jpg",
    "keyplan": "Keyplan-ANTHURIUM B5-02.jpg",
    "isAccessible": false,
    "dataBG": "onebedden",
    "hasVrTour": false,
    "vrTour": "",
    "pdf": "Floorplan-ANTHURIUM B5-02.pdf"
  },
  {
    "livingStyle": "seniorsapartments",
    "id": "B6a",
    "title": "Willow B6",
    "location": "2nd Floor",
    "type": "1 Bed",
    "sqft": 470,
    "sqftRange": "400-499",
    "numOfBeds": "onebed",
    "numOfBaths": "onebathroom",
    "hasDen": false,
    "hasWalkInCloset": false,
    "hasBalcony": false,
    "hasTerrace": true,
    "hasEnsuite": false,
    "floorplan": "Floorplan-WILLOW B6-01.jpg",
    "keyplan": "Keyplan-WILLOW B6-01.jpg",
    "isAccessible": false,
    "dataBG": "onebed",
    "hasVrTour": false,
    "vrTour": "",
    "pdf": "Floorplan-WILLOW B6-01.pdf"
  },
  {
    "livingStyle": "seniorsapartments",
    "id": "B6b",
    "title": "Willow B6",
    "location": "3rd - 10th Floor",
    "type": "1 Bed",
    "sqft": 467,
    "sqftRange": "400-499",
    "numOfBeds": "onebed",
    "numOfBaths": "onebathroom",
    "hasDen": false,
    "hasWalkInCloset": false,
    "hasBalcony": true,
    "hasTerrace": "",
    "hasEnsuite": false,
    "floorplan": "Floorplan-WILLOW B6-02.jpg",
    "keyplan": "Keyplan-WILLOW B6-02.jpg",
    "isAccessible": false,
    "dataBG": "onebed",
    "hasVrTour": true,
    "vrTour": "https://astoria-tours.web.app/Suites/Suite_B06/",
    "pdf": "Floorplan-WILLOW B6-02.pdf"
  },
  {
    "livingStyle": "seniorsapartments",
    "id": "B7a",
    "title": "Aster B7",
    "location": "2nd Floor",
    "type": "1 Bed + Den",
    "sqft": 720,
    "sqftRange": "700-799",
    "numOfBeds": "onebedden",
    "numOfBaths": "onebathroom",
    "hasDen": true,
    "hasWalkInCloset": false,
    "hasBalcony": false,
    "hasTerrace": true,
    "hasEnsuite": false,
    "floorplan": "Floorplan-ASTER B7-01.jpg",
    "keyplan": "Keyplan-ASTER B7-01.jpg",
    "isAccessible": false,
    "dataBG": "onebedden",
    "hasVrTour": true,
    "vrTour": "https://astoria-tours.web.app/Suites/Suite_B07/",
    "pdf": "Floorplan-ASTER B7-01.pdf"
  },
  {
    "livingStyle": "seniorsapartments",
    "id": "B7b",
    "title": "Aster B7",
    "location": "3rd - 10th Floor",
    "type": "1 Bed + Den",
    "sqft": 710,
    "sqftRange": "700-799",
    "numOfBeds": "onebedden",
    "numOfBaths": "onebathroom",
    "hasDen": true,
    "hasWalkInCloset": false,
    "hasBalcony": true,
    "hasTerrace": "",
    "hasEnsuite": false,
    "floorplan": "Floorplan-ASTER B7-02.jpg",
    "keyplan": "Keyplan-ASTER B7-02.jpg",
    "isAccessible": false,
    "dataBG": "onebedden",
    "hasVrTour": true,
    "vrTour": "https://astoria-tours.web.app/Suites/Suite_B07/",
    "pdf": "Floorplan-ASTER B7-02.pdf"
  },
  {
    "livingStyle": "seniorsapartments",
    "id": "B8a",
    "title": "Azalea B8",
    "location": "2nd Floor",
    "type": "1 Bed + Den",
    "sqft": 648,
    "sqftRange": "600-699",
    "numOfBeds": "onebedden",
    "numOfBaths": "onebathroom",
    "hasDen": true,
    "hasWalkInCloset": false,
    "hasBalcony": false,
    "hasTerrace": true,
    "hasEnsuite": false,
    "floorplan": "Floorplan-AZALEA B8-01.jpg",
    "keyplan": "Keyplan-AZALEA B8-01.jpg",
    "isAccessible": false,
    "dataBG": "onebedden",
    "hasVrTour": false,
    "vrTour": "",
    "pdf": "Floorplan-AZALEA B8-01.pdf"
  },
  {
    "livingStyle": "seniorsapartments",
    "id": "B8b",
    "title": "Azalea B8",
    "location": "3rd - 10th Floor",
    "type": "1 Bed + Den",
    "sqft": 630,
    "sqftRange": "600-699",
    "numOfBeds": "onebedden",
    "numOfBaths": "onebathroom",
    "hasDen": true,
    "hasWalkInCloset": false,
    "hasBalcony": true,
    "hasTerrace": false,
    "hasEnsuite": false,
    "floorplan": "Floorplan-AZALEA B8-02.jpg",
    "keyplan": "Keyplan-AZALEA B8-02.jpg",
    "isAccessible": false,
    "dataBG": "onebedden",
    "hasVrTour": false,
    "vrTour": "",
    "pdf": "Floorplan-AZALEA B8-02.pdf"
  },
  {
    "livingStyle": "seniorsapartments",
    "id": "B9",
    "title": "Cardinal B9",
    "location": "2nd - 10th Floor",
    "type": "Studio",
    "sqft": 528,
    "sqftRange": "500-599",
    "numOfBeds": "studio",
    "numOfBaths": "onebathroom",
    "hasDen": false,
    "hasWalkInCloset": false,
    "hasBalcony": false,
    "hasTerrace": false,
    "hasEnsuite": false,
    "floorplan": "Floorplan-CARDINAL B9.jpg",
    "keyplan": "Keyplan-CARDINAL B9.jpg",
    "isAccessible": false,
    "dataBG": "studio",
    "hasVrTour": true,
    "vrTour": "https://astoria-tours.web.app/Suites/Suite_B09/",
    "pdf": "Floorplan-CARDINAL B9.pdf"
  },
  {
    "livingStyle": "luxuryapartments",
    "id": "C1",
    "title": "Merry C1",
    "location": "2nd - 5th Floor",
    "type": "1 Bed + Den",
    "sqft": 747,
    "sqftRange": "700-799",
    "numOfBeds": "onebedden",
    "numOfBaths": "onebathroom",
    "hasDen": true,
    "hasWalkInCloset": false,
    "hasBalcony": true,
    "hasTerrace": false,
    "hasEnsuite": false,
    "floorplan": "Floorplan-MERRY C1.jpg",
    "keyplan": "",
    "isAccessible": false,
    "dataBG": "onebedden",
    "hasVrTour": false,
    "vrTour": "",
    "pdf": "Floorplan-MERRY C1.pdf"
  },
  {
    "livingStyle": "luxuryapartments",
    "id": "C1-2",
    "title": "Vinca C1/2",
    "location": "6th - 8th Floor",
    "type": "2 Bed + Den",
    "sqft": 1445,
    "sqftRange": "1000+",
    "numOfBeds": "twobedden",
    "numOfBaths": "twobathroom",
    "hasDen": true,
    "hasWalkInCloset": true,
    "hasBalcony": true,
    "hasTerrace": false,
    "hasEnsuite": true,
    "floorplan": "Floorplan-VINCA C1-2.jpg",
    "keyplan": "Keyplan-VINCA C1-2.jpg",
    "isAccessible": false,
    "dataBG": "twobedden",
    "hasVrTour": false,
    "vrTour": "",
    "pdf": "Floorplan-VINCA C1-2.pdf"
  },
  {
    "livingStyle": "luxuryapartments",
    "id": "C10",
    "title": "Calico C10",
    "location": "2nd - 8th Floor",
    "type": "2 Bed",
    "sqft": 913,
    "sqftRange": "900-999",
    "numOfBeds": "twobed",
    "numOfBaths": "twobathroom",
    "hasDen": false,
    "hasWalkInCloset": false,
    "hasBalcony": true,
    "hasTerrace": false,
    "hasEnsuite": true,
    "floorplan": "Floorplan-CALICO C10.jpg",
    "keyplan": "Keyplan-CALICO C10.jpg",
    "isAccessible": true,
    "dataBG": "twobed",
    "hasVrTour": false,
    "vrTour": "",
    "pdf": "Floorplan-CALICO C10.pdf"
  },
  {
    "livingStyle": "luxuryapartments",
    "id": "C11a",
    "title": "Glory C11",
    "location": "2nd Floor",
    "type": "2 Bed + Den",
    "sqft": 1040,
    "sqftRange": "1000+",
    "numOfBeds": "twobedden",
    "numOfBaths": "twobathroom",
    "hasDen": true,
    "hasWalkInCloset": true,
    "hasBalcony": false,
    "hasTerrace": true,
    "hasEnsuite": true,
    "floorplan": "Floorplan-GLORY C11-01.jpg",
    "keyplan": "Keyplan-GLORY C11-01.jpg",
    "isAccessible": false,
    "dataBG": "twobedden",
    "hasVrTour": false,
    "vrTour": "",
    "pdf": "Floorplan-GLORY C11-01.pdf"
  },
  {
    "livingStyle": "luxuryapartments",
    "id": "C11b",
    "title": "Glory C11",
    "location": "3rd - 8th Floor",
    "type": "2 Bed + Den",
    "sqft": 1040,
    "sqftRange": "1000+",
    "numOfBeds": "twobedden",
    "numOfBaths": "twobathroom",
    "hasDen": true,
    "hasWalkInCloset": true,
    "hasBalcony": true,
    "hasTerrace": false,
    "hasEnsuite": true,
    "floorplan": "Floorplan-GLORY C11-02.jpg",
    "keyplan": "Keyplan-GLORY C11-02.jpg",
    "isAccessible": false,
    "dataBG": "twobedden",
    "hasVrTour": false,
    "vrTour": "",
    "pdf": "Floorplan-GLORY C11-02.pdf"
  },
  {
    "livingStyle": "luxuryapartments",
    "id": "C12a",
    "title": "Peony C12",
    "location": "2nd Floor",
    "type": "1 Bed + Den",
    "sqft": 724,
    "sqftRange": "700-799",
    "numOfBeds": "onebedden",
    "numOfBaths": "onebathroom",
    "hasDen": true,
    "hasWalkInCloset": false,
    "hasBalcony": false,
    "hasTerrace": true,
    "hasEnsuite": false,
    "floorplan": "Floorplan-PEONY C12-01.jpg",
    "keyplan": "Keyplan-PEONY C12-01.jpg",
    "isAccessible": false,
    "dataBG": "onebedden",
    "hasVrTour": false,
    "vrTour": "",
    "pdf": "Floorplan-PEONY C12-01.pdf"
  },
  {
    "livingStyle": "luxuryapartments",
    "id": "C12b",
    "title": "Peony C12",
    "location": "3rd - 8th Floor",
    "type": "1 Bed + Den",
    "sqft": 724,
    "sqftRange": "700-799",
    "numOfBeds": "onebedden",
    "numOfBaths": "onebathroom",
    "hasDen": true,
    "hasWalkInCloset": false,
    "hasBalcony": true,
    "hasTerrace": false,
    "hasEnsuite": false,
    "floorplan": "Floorplan-PEONY C12-02.jpg",
    "keyplan": "Keyplan-PEONY C12-02.jpg",
    "isAccessible": false,
    "dataBG": "onebedden",
    "hasVrTour": false,
    "vrTour": "",
    "pdf": "Floorplan-PEONY C12-02.pdf"
  },
  {
    "livingStyle": "luxuryapartments",
    "id": "C13a",
    "title": "Tulip C13",
    "location": "2nd Floor",
    "type": "1 Bed",
    "sqft": 473,
    "sqftRange": "400-499",
    "numOfBeds": "onebed",
    "numOfBaths": "onebathroom",
    "hasDen": false,
    "hasWalkInCloset": false,
    "hasBalcony": false,
    "hasTerrace": true,
    "hasEnsuite": false,
    "floorplan": "Floorplan-TULIP C13-01.jpg",
    "keyplan": "Keyplan-TULIP C13-01.jpg",
    "isAccessible": false,
    "dataBG": "onebed",
    "hasVrTour": false,
    "vrTour": "",
    "pdf": "Floorplan-TULIP C13-01.pdf"
  },
  {
    "livingStyle": "luxuryapartments",
    "id": "C13b",
    "title": "Tulip C13",
    "location": "3rd - 8th Floor",
    "type": "1 Bed",
    "sqft": 473,
    "sqftRange": "400-499",
    "numOfBeds": "onebed",
    "numOfBaths": "onebathroom",
    "hasDen": false,
    "hasWalkInCloset": false,
    "hasBalcony": true,
    "hasTerrace": false,
    "hasEnsuite": false,
    "floorplan": "Floorplan-TULIP C13-02.jpg",
    "keyplan": "Keyplan-TULIP C13-02.jpg",
    "isAccessible": false,
    "dataBG": "onebed",
    "hasVrTour": false,
    "vrTour": "",
    "pdf": "Floorplan-TULIP C13-02.pdf"
  },
  {
    "livingStyle": "luxuryapartments",
    "id": "C14a",
    "title": "Daisy C14",
    "location": "2nd Floor",
    "type": "1 Bed + Den",
    "sqft": 791,
    "sqftRange": "700-799",
    "numOfBeds": "onebedden",
    "numOfBaths": "onebathroom",
    "hasDen": true,
    "hasWalkInCloset": false,
    "hasBalcony": false,
    "hasTerrace": true,
    "hasEnsuite": false,
    "floorplan": "Floorplan-DAISY C14-01.jpg",
    "keyplan": "Keyplan-DAISY C-1.jpg",
    "isAccessible": true,
    "dataBG": "onebedden",
    "hasVrTour": false,
    "vrTour": "",
    "pdf": "Floorplan-DAISY C14-01.pdf"
  },
  {
    "livingStyle": "luxuryapartments",
    "id": "C14b",
    "title": "Daisy C14",
    "location": "3rd - 8th Floor",
    "type": "1 Bed + Den",
    "sqft": 791,
    "sqftRange": "700-799",
    "numOfBeds": "onebedden",
    "numOfBaths": "onebathroom",
    "hasDen": true,
    "hasWalkInCloset": false,
    "hasBalcony": true,
    "hasTerrace": false,
    "hasEnsuite": false,
    "floorplan": "Floorplan-DAISY C14-01.jpg",
    "keyplan": "Keyplan-DAISY C14-01.jpg",
    "isAccessible": true,
    "dataBG": "onebedden",
    "hasVrTour": false,
    "vrTour": "",
    "pdf": "Floorplan-DAISY C14-01.pdf"
  },
  {
    "livingStyle": "luxuryapartments",
    "id": "C15a",
    "title": "Marigold c15",
    "location": "2nd Floor",
    "type": "1 Bed + Den",
    "sqft": 790,
    "sqftRange": "700-799",
    "numOfBeds": "onebedden",
    "numOfBaths": "onebathroom",
    "hasDen": true,
    "hasWalkInCloset": false,
    "hasBalcony": false,
    "hasTerrace": true,
    "hasEnsuite": false,
    "floorplan": "Floorplan-MARIGOLD C15-01.jpg",
    "keyplan": "Keyplan-MARIGOLD C15-01.jpg",
    "isAccessible": false,
    "dataBG": "onebedden",
    "hasVrTour": false,
    "vrTour": "",
    "pdf": "Floorplan-MARIGOLD C15-01.pdf"
  },
  {
    "livingStyle": "luxuryapartments",
    "id": "C15b",
    "title": "Marigold c15",
    "location": "3rd - 8th Floor",
    "type": "1 Bed + Den",
    "sqft": 790,
    "sqftRange": "700-799",
    "numOfBeds": "onebedden",
    "numOfBaths": "onebathroom",
    "hasDen": true,
    "hasWalkInCloset": false,
    "hasBalcony": true,
    "hasTerrace": false,
    "hasEnsuite": false,
    "floorplan": "Floorplan-MARIGOLD C15-02.jpg",
    "keyplan": "Keyplan-MARIGOLD C15-02.jpg",
    "isAccessible": false,
    "dataBG": "onebedden",
    "hasVrTour": false,
    "vrTour": "",
    "pdf": "Floorplan-MARIGOLD C15-02.pdf"
  },
  {
    "livingStyle": "luxuryapartments",
    "id": "C16a",
    "title": "Jasmine C16",
    "location": "2nd Floor",
    "type": "1 Bed",
    "sqft": 470,
    "sqftRange": "400-499",
    "numOfBeds": "onebed",
    "numOfBaths": "onebathroom",
    "hasDen": false,
    "hasWalkInCloset": false,
    "hasBalcony": false,
    "hasTerrace": true,
    "hasEnsuite": false,
    "floorplan": "Floorplan-JASMINE C16-01.jpg",
    "keyplan": "Keyplan-JASMINE C16-01.jpg",
    "isAccessible": false,
    "dataBG": "onebed",
    "hasVrTour": false,
    "vrTour": "",
    "pdf": "Floorplan-JASMINE C16-01.pdf"
  },
  {
    "livingStyle": "luxuryapartments",
    "id": "C16b",
    "title": "Jasmine C16",
    "location": "3rd - 8th Floor",
    "type": "1 Bed",
    "sqft": 470,
    "sqftRange": "400-499",
    "numOfBeds": "onebed",
    "numOfBaths": "onebathroom",
    "hasDen": false,
    "hasWalkInCloset": false,
    "hasBalcony": true,
    "hasTerrace": false,
    "hasEnsuite": false,
    "floorplan": "Floorplan-JASMINE C16-02.jpg",
    "keyplan": "Keyplan-JASMINE C16-02.jpg",
    "isAccessible": false,
    "dataBG": "onebed",
    "hasVrTour": false,
    "vrTour": "",
    "pdf": "Floorplan-JASMINE C16-02.pdf"
  },
  {
    "livingStyle": "luxuryapartments",
    "id": "C17a",
    "title": "Poppy C17",
    "location": "2nd Floor",
    "type": "1 Bed + Den",
    "sqft": 719,
    "sqftRange": "700-799",
    "numOfBeds": "onebedden",
    "numOfBaths": "onebathroom",
    "hasDen": true,
    "hasWalkInCloset": false,
    "hasBalcony": false,
    "hasTerrace": true,
    "hasEnsuite": false,
    "floorplan": "Floorplan-POPPY C17-01.jpg",
    "keyplan": "Keyplan-POPPY C17-01.jpg",
    "isAccessible": true,
    "dataBG": "onebedden",
    "hasVrTour": false,
    "vrTour": "",
    "pdf": "Floorplan-POPPY C17-01.pdf"
  },
  {
    "livingStyle": "luxuryapartments",
    "id": "C17b",
    "title": "Poppy C17",
    "location": "3rd - 8th Floor",
    "type": "1 Bed + Den",
    "sqft": 719,
    "sqftRange": "700-799",
    "numOfBeds": "onebedden",
    "numOfBaths": "onebathroom",
    "hasDen": true,
    "hasWalkInCloset": false,
    "hasBalcony": true,
    "hasTerrace": false,
    "hasEnsuite": false,
    "floorplan": "Floorplan-POPPY C17-02.jpg",
    "keyplan": "Keyplan-POPPY C17-02.jpg",
    "isAccessible": true,
    "dataBG": "onebedden",
    "hasVrTour": false,
    "vrTour": "",
    "pdf": "Floorplan-POPPY C17-02.pdf"
  },
  {
    "livingStyle": "luxuryapartments",
    "id": "C18a",
    "title": "Dahlia C18",
    "location": "2nd Floor",
    "type": "1 Bed + Den",
    "sqft": 644,
    "sqftRange": "600-699",
    "numOfBeds": "onebedden",
    "numOfBaths": "onebathroom",
    "hasDen": true,
    "hasWalkInCloset": false,
    "hasBalcony": false,
    "hasTerrace": true,
    "hasEnsuite": false,
    "floorplan": "Floorplan-DAHLIA C18-01.jpg",
    "keyplan": "Keyplan-DAHLIA C18-01.jpg",
    "isAccessible": false,
    "dataBG": "onebedden",
    "hasVrTour": false,
    "vrTour": "",
    "pdf": "Floorplan-DAHLIA C18-01.pdf"
  },
  {
    "livingStyle": "luxuryapartments",
    "id": "C18b",
    "title": "Dahlia C18",
    "location": "3rd - 8th Floor",
    "type": "1 Bed + Den",
    "sqft": 644,
    "sqftRange": "600-699",
    "numOfBeds": "onebedden",
    "numOfBaths": "onebathroom",
    "hasDen": true,
    "hasWalkInCloset": false,
    "hasBalcony": true,
    "hasTerrace": false,
    "hasEnsuite": false,
    "floorplan": "Floorplan-DAHLIA C18-02.jpg",
    "keyplan": "Keyplan-DAHLIA C18-02.jpg",
    "isAccessible": false,
    "dataBG": "onebedden",
    "hasVrTour": false,
    "vrTour": "",
    "pdf": "Floorplan-DAHLIA C18-02.pdf"
  },
  {
    "livingStyle": "luxuryapartments",
    "id": "C19a",
    "title": "Iris C19",
    "location": "2nd Floor",
    "type": "1 Bed + Den",
    "sqft": 749,
    "sqftRange": "700-799",
    "numOfBeds": "onebedden",
    "numOfBaths": "onebathroom",
    "hasDen": true,
    "hasWalkInCloset": false,
    "hasBalcony": false,
    "hasTerrace": true,
    "hasEnsuite": false,
    "floorplan": "Floorplan-IRIS C19-01.jpg",
    "keyplan": "Keyplan-IRIS C19-01.jpg",
    "isAccessible": true,
    "dataBG": "onebedden",
    "hasVrTour": false,
    "vrTour": "",
    "pdf": "Floorplan-IRIS C19-01.pdf"
  },
  {
    "livingStyle": "luxuryapartments",
    "id": "C19b",
    "title": "Iris C19",
    "location": "3rd - 8th Floor",
    "type": "1 Bed + Den",
    "sqft": 749,
    "sqftRange": "700-799",
    "numOfBeds": "onebedden",
    "numOfBaths": "onebathroom",
    "hasDen": true,
    "hasWalkInCloset": false,
    "hasBalcony": true,
    "hasTerrace": false,
    "hasEnsuite": false,
    "floorplan": "Floorplan-IRIS C19-02.jpg",
    "keyplan": "Keyplan-IRIS C19-02.jpg",
    "isAccessible": true,
    "dataBG": "onebedden",
    "hasVrTour": false,
    "vrTour": "",
    "pdf": "Floorplan-IRIS C19-02.pdf"
  },
  {
    "livingStyle": "luxuryapartments",
    "id": "C20",
    "title": "Freesia C20",
    "location": "2nd - 8th Floor",
    "type": "1 Bed + Den",
    "sqft": 860,
    "sqftRange": "800-899",
    "numOfBeds": "onebedden",
    "numOfBaths": "twobathroom",
    "hasDen": true,
    "hasWalkInCloset": true,
    "hasBalcony": false,
    "hasTerrace": false,
    "hasEnsuite": true,
    "floorplan": "Floorplan-FREESIA C20.jpg",
    "keyplan": "Keyplan-FREESIA C20.jpg",
    "isAccessible": false,
    "dataBG": "onebedden",
    "hasVrTour": false,
    "vrTour": "",
    "pdf": "Floorplan-FREESIA C20.pdf"
  },
  {
    "livingStyle": "luxuryapartments",
    "id": "C2",
    "title": "Stellar C2",
    "location": "2nd - 5th Floor",
    "type": "1 Bed + Den",
    "sqft": 719,
    "sqftRange": "700-799",
    "numOfBeds": "onebedden",
    "numOfBaths": "onebathroom",
    "hasDen": true,
    "hasWalkInCloset": false,
    "hasBalcony": true,
    "hasTerrace": false,
    "hasEnsuite": false,
    "floorplan": "Floorplan-STELLAR C2.jpg",
    "keyplan": "Keyplan-STELLAR C2.jpg",
    "isAccessible": false,
    "dataBG": "onebedden",
    "hasVrTour": false,
    "vrTour": "",
    "pdf": "Floorplan-STELLAR C2.pdf"
  },
  {
    "livingStyle": "luxuryapartments",
    "id": "C3",
    "title": "Inspire C3",
    "location": "2nd - 8th Floor",
    "type": "1 Bed",
    "sqft": 475,
    "sqftRange": "400-499",
    "numOfBeds": "onebed",
    "numOfBaths": "onebathroom",
    "hasDen": false,
    "hasWalkInCloset": false,
    "hasBalcony": true,
    "hasTerrace": false,
    "hasEnsuite": false,
    "floorplan": "Floorplan-INSPIRE C3.jpg",
    "keyplan": "keyplan-INSPIRE C3.jpg",
    "isAccessible": false,
    "dataBG": "onebed",
    "hasVrTour": false,
    "vrTour": "",
    "pdf": "Floorplan-INSPIRE C3.pdf"
  },
  {
    "livingStyle": "luxuryapartments",
    "id": "C4",
    "title": "Primrose C4",
    "location": "2nd - 8th Floor",
    "type": "1 Bed + Den",
    "sqft": 791,
    "sqftRange": "700-799",
    "numOfBeds": "onebedden",
    "numOfBaths": "onebathroom",
    "hasDen": true,
    "hasWalkInCloset": false,
    "hasBalcony": true,
    "hasTerrace": false,
    "hasEnsuite": false,
    "floorplan": "Floorplan-PRIMROSE C4.jpg",
    "keyplan": "Keyplan-PRIMROSE C4.jpg",
    "isAccessible": false,
    "dataBG": "onebedden",
    "hasVrTour": false,
    "vrTour": "",
    "pdf": "Floorplan-PRIMROSE C4.pdf"
  },
  {
    "livingStyle": "luxuryapartments",
    "id": "C5-6",
    "title": "Viola C5/6",
    "location": "2nd - 8th Floor",
    "type": "2 Bed",
    "sqft": 1293,
    "sqftRange": "1000+",
    "numOfBeds": "twobed",
    "numOfBaths": "twobathroom",
    "hasDen": false,
    "hasWalkInCloset": false,
    "hasBalcony": true,
    "hasTerrace": false,
    "hasEnsuite": true,
    "floorplan": "Floorplan-VIOLA C5-6.jpg",
    "keyplan": "Keyplan-VIOLA C5-6.jpg",
    "isAccessible": false,
    "dataBG": "twobed",
    "hasVrTour": false,
    "vrTour": "",
    "pdf": "Floorplan-VIOLA C5-6.pdf"
  },
  {
    "livingStyle": "luxuryapartments",
    "id": "C7",
    "title": "Rose C7",
    "location": "2nd - 8th Floor",
    "type": "1 Bed + Den",
    "sqft": 695,
    "sqftRange": "600-699",
    "numOfBeds": "onebedden",
    "numOfBaths": "onebathroom",
    "hasDen": true,
    "hasWalkInCloset": false,
    "hasBalcony": true,
    "hasTerrace": false,
    "hasEnsuite": false,
    "floorplan": "Floorplan-ROSE C7.jpg",
    "keyplan": "Keyplan-ROSE C7.jpg",
    "isAccessible": false,
    "dataBG": "onebedden",
    "hasVrTour": false,
    "vrTour": "",
    "pdf": "Floorplan-ROSE C7.pdf"
  },
  {
    "livingStyle": "luxuryapartments",
    "id": "C8",
    "title": "Aster C8",
    "location": "2nd - 8th Floor",
    "type": "1 Bed + Den",
    "sqft": 603,
    "sqftRange": "600-699",
    "numOfBeds": "onebedden",
    "numOfBaths": "onebathroom",
    "hasDen": true,
    "hasWalkInCloset": false,
    "hasBalcony": true,
    "hasTerrace": false,
    "hasEnsuite": false,
    "floorplan": "Floorplan-ASTER C8.jpg",
    "keyplan": "Keyplan-ASTER C8.jpg",
    "isAccessible": false,
    "dataBG": "onebedden",
    "hasVrTour": false,
    "vrTour": "",
    "pdf": "Floorplan-ASTER C8.pdf"
  },
  {
    "livingStyle": "luxuryapartments",
    "id": "C9",
    "title": "Daylily C9",
    "location": "2nd - 8th Floor",
    "type": "Studio",
    "sqft": 528,
    "sqftRange": "500-599",
    "numOfBeds": "studio",
    "numOfBaths": "onebathroom",
    "hasDen": false,
    "hasWalkInCloset": false,
    "hasBalcony": false,
    "hasTerrace": false,
    "hasEnsuite": false,
    "floorplan": "Floorplan-DAYLILY C9.jpg",
    "keyplan": "Keyplan-DAYLILY C9.jpg",
    "isAccessible": false,
    "dataBG": "studio",
    "hasVrTour": false,
    "vrTour": "",
    "pdf": "Floorplan-DAYLILY C9.pdf"
  },
  {
    "livingStyle": "luxuryapartments",
    "id": "GC6",
    "title": "Muscari GC 6",
    "location": "Ground Floor",
    "type": "1 Bed",
    "sqft": 555,
    "sqftRange": "500-599",
    "numOfBeds": "onebed",
    "numOfBaths": "onebathroom",
    "hasDen": false,
    "hasWalkInCloset": false,
    "hasBalcony": false,
    "hasTerrace": true,
    "hasEnsuite": false,
    "floorplan": "Floorplan-MUSCARI GC 6.jpg",
    "keyplan": "Keyplan-MUSCARI GC 6.jpg",
    "isAccessible": false,
    "dataBG": "onebed",
    "hasVrTour": false,
    "vrTour": "",
    "pdf": "Floorplan-MUSCARI GC 6.pdf"
  },
  {
    "livingStyle": "seniorsapartments",
    "id": "GB11",
    "title": "Nerine GB 11",
    "location": "Ground Floor",
    "type": "1 Bed + Den",
    "sqft": 810,
    "sqftRange": "800-899",
    "numOfBeds": "onebedden",
    "numOfBaths": "onebathroom",
    "hasDen": true,
    "hasWalkInCloset": false,
    "hasBalcony": false,
    "hasTerrace": true,
    "hasEnsuite": false,
    "floorplan": "Floorplan-NERINE GB 11.jpg",
    "keyplan": "Keyplan-NERINE GB 11.jpg",
    "isAccessible": false,
    "dataBG": "onebedden",
    "hasVrTour": false,
    "vrTour": "",
    "pdf": "Floorplan-NERINE GB 11.pdf"
  },
  {
    "livingStyle": "seniorsapartments",
    "id": "GB12",
    "title": "BlueBell GB 12",
    "location": "Ground Floor",
    "type": "1 Bed + Den",
    "sqft": 825,
    "sqftRange": "800-899",
    "numOfBeds": "onebedden",
    "numOfBaths": "onebathroom",
    "hasDen": true,
    "hasWalkInCloset": false,
    "hasBalcony": false,
    "hasTerrace": true,
    "hasEnsuite": false,
    "floorplan": "Floorplan-BLUEBELL GB 12.jpg",
    "keyplan": "Keyplan-BLUEBELL GB 12.jpg",
    "isAccessible": false,
    "dataBG": "onebedden",
    "hasVrTour": false,
    "vrTour": "",
    "pdf": "Floorplan-BLUEBELL GB 12.pdf"
  },
  {
    "livingStyle": "seniorsapartments",
    "id": "GB13",
    "title": "Celosia GB 13",
    "location": "Ground Floor",
    "type": "1 Bed",
    "sqft": 542,
    "sqftRange": "500-599",
    "numOfBeds": "onebed",
    "numOfBaths": "onebathroom",
    "hasDen": false,
    "hasWalkInCloset": false,
    "hasBalcony": false,
    "hasTerrace": true,
    "hasEnsuite": false,
    "floorplan": "Floorplan-CELOSIA GB 13.jpg",
    "keyplan": "Keyplan-CELOSIA GB 13.jpg",
    "isAccessible": false,
    "dataBG": "onebed",
    "hasVrTour": false,
    "vrTour": "",
    "pdf": "Floorplan-CELOSIA GB 13.pdf"
  },
  {
    "livingStyle": "seniorsapartments",
    "id": "GB14",
    "title": "Clover GB 14",
    "location": "Ground Floor",
    "type": "1 Bed + Den",
    "sqft": 836,
    "sqftRange": "800-899",
    "numOfBeds": "onebedden",
    "numOfBaths": "onebathroom",
    "hasDen": true,
    "hasWalkInCloset": false,
    "hasBalcony": false,
    "hasTerrace": true,
    "hasEnsuite": false,
    "floorplan": "Floorplan-CLOVER GB 14.jpg",
    "keyplan": "Keyplan-CLOVER GB 14.jpg",
    "isAccessible": true,
    "dataBG": "onebedden",
    "hasVrTour": false,
    "vrTour": "",
    "pdf": "Floorplan-CLOVER GB 14.pdf"
  },
  {
    "livingStyle": "seniorsapartments",
    "id": "GB15",
    "title": "Geranium GB 15",
    "location": "Ground Floor",
    "type": "1 Bed",
    "sqft": 830,
    "sqftRange": "800-899",
    "numOfBeds": "onebed",
    "numOfBaths": "onebathroom",
    "hasDen": false,
    "hasWalkInCloset": false,
    "hasBalcony": false,
    "hasTerrace": true,
    "hasEnsuite": false,
    "floorplan": "Floorplan-GERANIUM GB 15.jpg",
    "keyplan": "Keyplan-GERANIUM GB 15.jpg",
    "isAccessible": false,
    "dataBG": "onebed",
    "hasVrTour": false,
    "vrTour": "",
    "pdf": "Floorplan-GERANIUM GB 15.pdf"
  },
  {
    "livingStyle": "seniorsapartments",
    "id": "GB16",
    "title": "Narcissus GB 16",
    "location": "Ground Floor",
    "type": "1 Bed",
    "sqft": 457,
    "sqftRange": "400-499",
    "numOfBeds": "onebed",
    "numOfBaths": "onebathroom",
    "hasDen": false,
    "hasWalkInCloset": false,
    "hasBalcony": true,
    "hasTerrace": false,
    "hasEnsuite": false,
    "floorplan": "Floorplan-NARCISSUS GB 16.jpg",
    "keyplan": "Keyplan-NARCISSUS GB 16.jpg",
    "isAccessible": false,
    "dataBG": "onebed",
    "hasVrTour": false,
    "vrTour": "",
    "pdf": "Floorplan-NARCISSUS GB 16.pdf"
  },
  {
    "livingStyle": "seniorsapartments",
    "id": "GB17",
    "title": "FoxGlove GB 17",
    "location": "Ground Floor",
    "type": "1 Bed + Den",
    "sqft": 705,
    "sqftRange": "700-799",
    "numOfBeds": "onebedden",
    "numOfBaths": "onebathroom",
    "hasDen": true,
    "hasWalkInCloset": false,
    "hasBalcony": true,
    "hasTerrace": false,
    "hasEnsuite": false,
    "floorplan": "Floorplan-FOXGLOVE GB 17.jpg",
    "keyplan": "Keyplan-FOXGLOVE GB 17.jpg",
    "isAccessible": true,
    "dataBG": "onebedden",
    "hasVrTour": false,
    "vrTour": "",
    "pdf": "Floorplan-FOXGLOVE GB 17.pdf"
  },
  {
    "livingStyle": "seniorsapartments",
    "id": "GB18",
    "title": "Fuschia GB 18",
    "location": "Ground Floor",
    "type": "1 Bed + Den",
    "sqft": 638,
    "sqftRange": "600-699",
    "numOfBeds": "onebedden",
    "numOfBaths": "onebathroom",
    "hasDen": true,
    "hasWalkInCloset": false,
    "hasBalcony": true,
    "hasTerrace": false,
    "hasEnsuite": false,
    "floorplan": "Floorplan-FUSCHIA GB 18.jpg",
    "keyplan": "Keyplan-FUSCHIA GB 18.jpg",
    "isAccessible": false,
    "dataBG": "onebedden",
    "hasVrTour": false,
    "vrTour": "",
    "pdf": "Floorplan-FUSCHIA GB 18.pdf"
  },
  {
    "livingStyle": "seniorsapartments",
    "id": "GB19",
    "title": "Gardenia GB 19",
    "location": "Ground Floor",
    "type": "1 Bed + Den",
    "sqft": 743,
    "sqftRange": "700-799",
    "numOfBeds": "onebedden",
    "numOfBaths": "onebathroom",
    "hasDen": true,
    "hasWalkInCloset": false,
    "hasBalcony": true,
    "hasTerrace": false,
    "hasEnsuite": false,
    "floorplan": "Floorplan-GARDENIA GB 19.jpg",
    "keyplan": "Keyplan-GARDENIA GB 19.jpg",
    "isAccessible": true,
    "dataBG": "onebedden",
    "hasVrTour": false,
    "vrTour": "",
    "pdf": "Floorplan-GARDENIA GB 19.pdf"
  },
  {
    "livingStyle": "seniorsapartments",
    "id": "GB20",
    "title": "Gaura GB 20",
    "location": "Ground Floor",
    "type": "2 Bed",
    "sqft": 965,
    "sqftRange": "900-999",
    "numOfBeds": "twobed",
    "numOfBaths": "twobathroom",
    "hasDen": false,
    "hasWalkInCloset": true,
    "hasBalcony": true,
    "hasTerrace": false,
    "hasEnsuite": true,
    "floorplan": "Floorplan-GAURA GB 20.jpg",
    "keyplan": "Keyplan-GAURA GB 20.jpg",
    "isAccessible": false,
    "dataBG": "twobed",
    "hasVrTour": false,
    "vrTour": "",
    "pdf": "Floorplan-GAURA GB 20.pdf"
  },
  {
    "livingStyle": "luxuryapartments",
    "id": "GC1",
    "title": "Lavender GC 1",
    "location": "Ground Floor",
    "type": "1 Bed + Den",
    "sqft": 640,
    "sqftRange": "600-699",
    "numOfBeds": "onebedden",
    "numOfBaths": "onebathroom",
    "hasDen": true,
    "hasWalkInCloset": false,
    "hasBalcony": false,
    "hasTerrace": false,
    "hasEnsuite": false,
    "floorplan": "Floorplan-LAVENDER GC 1.jpg",
    "keyplan": "Keyplan-LAVENDER GC 1.jpg",
    "isAccessible": false,
    "dataBG": "onebedden",
    "hasVrTour": false,
    "vrTour": "",
    "pdf": "Floorplan-LAVENDER GC 1.pdf"
  },
  {
    "livingStyle": "luxuryapartments",
    "id": "GC2",
    "title": "Lilac GC 2",
    "location": "Ground Floor",
    "type": "1 Bed + Den",
    "sqft": 605,
    "sqftRange": "600-699",
    "numOfBeds": "onebedden",
    "numOfBaths": "onebathroom",
    "hasDen": true,
    "hasWalkInCloset": false,
    "hasBalcony": false,
    "hasTerrace": false,
    "hasEnsuite": false,
    "floorplan": "Floorplan-LILAC GC 2.jpg",
    "keyplan": "Keyplan-LILAC GC 2.jpg",
    "isAccessible": false,
    "dataBG": "onebedden",
    "hasVrTour": false,
    "vrTour": "",
    "pdf": "Floorplan-LILAC GC 2.pdf"
  },
  {
    "livingStyle": "luxuryapartments",
    "id": "GC3",
    "title": "Lotus GC 3",
    "location": "Ground Floor",
    "type": "1 Bed",
    "sqft": 407,
    "sqftRange": "400-499",
    "numOfBeds": "onebed",
    "numOfBaths": "onebathroom",
    "hasDen": false,
    "hasWalkInCloset": false,
    "hasBalcony": false,
    "hasTerrace": false,
    "hasEnsuite": false,
    "floorplan": "Floorplan-LOTUS GC 3.jpg",
    "keyplan": "Keyplan-LOTUS GC 3.jpg",
    "isAccessible": false,
    "dataBG": "onebed",
    "hasVrTour": false,
    "vrTour": "",
    "pdf": "Floorplan-LOTUS GC 3.pdf"
  },
  {
    "livingStyle": "luxuryapartments",
    "id": "GC4",
    "title": "Lupine GC 4",
    "location": "Ground Floor",
    "type": "1 Bed + Den",
    "sqft": 834,
    "sqftRange": "800-899",
    "numOfBeds": "onebedden",
    "numOfBaths": "onebathroom",
    "hasDen": true,
    "hasWalkInCloset": false,
    "hasBalcony": false,
    "hasTerrace": true,
    "hasEnsuite": false,
    "floorplan": "Floorplan-LUPINE GC 4.jpg",
    "keyplan": "Keyplan-LUPINE GC 4.jpg",
    "isAccessible": true,
    "dataBG": "onebedden",
    "hasVrTour": false,
    "vrTour": "",
    "pdf": "Floorplan-LUPINE GC 4.pdf"
  },
  {
    "livingStyle": "luxuryapartments",
    "id": "GC5",
    "title": "Magnolia GC 5",
    "location": "Ground Floor",
    "type": "1 Bed + Den",
    "sqft": 698,
    "sqftRange": "600-699",
    "numOfBeds": "onebedden",
    "numOfBaths": "onebathroom",
    "hasDen": true,
    "hasWalkInCloset": false,
    "hasBalcony": false,
    "hasTerrace": true,
    "hasEnsuite": false,
    "floorplan": "Floorplan-MAGNOLIA GC 5.jpg",
    "keyplan": "Keyplan-MAGNOLIA GC 5.jpg",
    "isAccessible": false,
    "dataBG": "onebedden",
    "hasVrTour": false,
    "vrTour": "",
    "pdf": "Floorplan-MAGNOLIA GC 5.pdf"
  },
  {
    "livingStyle": "luxuryapartments",
    "id": "LC1",
    "title": "GoldenRod LC 1",
    "location": "Lower Level",
    "type": "1 Bed + Den",
    "sqft": 1038,
    "sqftRange": "1000+",
    "numOfBeds": "onebedden",
    "numOfBaths": "onebathroom",
    "hasDen": true,
    "hasWalkInCloset": false,
    "hasBalcony": false,
    "hasTerrace": true,
    "hasEnsuite": false,
    "floorplan": "Floorplan-GOLDENROD LC 1.jpg",
    "keyplan": "Keyplan-GOLDENROD LC 1 – 31.jpg",
    "isAccessible": false,
    "dataBG": "onebedden",
    "hasVrTour": false,
    "vrTour": "",
    "pdf": "Floorplan-GOLDENROD LC 1.pdf"
  },
  {
    "livingStyle": "luxuryapartments",
    "id": "LC16",
    "title": "Honesty LC 16",
    "location": "Lower Level",
    "type": "2 Bed",
    "sqft": 1438,
    "sqftRange": "1000+",
    "numOfBeds": "twobed",
    "numOfBaths": "twobathroom",
    "hasDen": false,
    "hasWalkInCloset": true,
    "hasBalcony": false,
    "hasTerrace": true,
    "hasEnsuite": true,
    "floorplan": "Floorplan-HIBISCUS LC 3-02.jpg",
    "keyplan": "",
    "isAccessible": false,
    "dataBG": "twobed",
    "hasVrTour": false,
    "vrTour": "",
    "pdf": "Floorplan-HIBISCUS LC 3-02.pdf"
  },
  {
    "livingStyle": "luxuryapartments",
    "id": "LC17",
    "title": "Iberis LC 17",
    "location": "Lower Level",
    "type": "1 Bed + Den",
    "sqft": 976,
    "sqftRange": "900-999",
    "numOfBeds": "onebedden",
    "numOfBaths": "onebathroom",
    "hasDen": true,
    "hasWalkInCloset": false,
    "hasBalcony": false,
    "hasTerrace": true,
    "hasEnsuite": false,
    "floorplan": "Floorplan-IBERIS LC 17.jpg",
    "keyplan": "Keyplan-IBERIS LC 17.jpg",
    "isAccessible": true,
    "dataBG": "onebedden",
    "hasVrTour": false,
    "vrTour": "",
    "pdf": "Floorplan-IBERIS LC 17.pdf"
  },
  {
    "livingStyle": "luxuryapartments",
    "id": "LC18",
    "title": "Mallow LC 18",
    "location": "Lower Level",
    "type": "1 Bed + Den",
    "sqft": 916,
    "sqftRange": "900-999",
    "numOfBeds": "onebedden",
    "numOfBaths": "onebathroom",
    "hasDen": true,
    "hasWalkInCloset": false,
    "hasBalcony": false,
    "hasTerrace": true,
    "hasEnsuite": false,
    "floorplan": "Floorplan-MALLOW LC 18.jpg",
    "keyplan": "Keyplan-MALLOW LC 18.jpg",
    "isAccessible": false,
    "dataBG": "onebedden",
    "hasVrTour": false,
    "vrTour": "",
    "pdf": "Floorplan-MALLOW LC 18.pdf"
  },
  {
    "livingStyle": "luxuryapartments",
    "id": "LC19",
    "title": "Violet LC 19",
    "location": "Lower Level",
    "type": "1 Bed + Den",
    "sqft": 1026,
    "sqftRange": "1000+",
    "numOfBeds": "onebedden",
    "numOfBaths": "onebathroom",
    "hasDen": true,
    "hasWalkInCloset": false,
    "hasBalcony": false,
    "hasTerrace": true,
    "hasEnsuite": false,
    "floorplan": "Floorplan-VIOLET LC 19.jpg",
    "keyplan": "Keyplan-VIOLET LC 19.jpg",
    "isAccessible": true,
    "dataBG": "onebedden",
    "hasVrTour": false,
    "vrTour": "",
    "pdf": "Floorplan-VIOLET LC 19.pdf"
  },
  {
    "livingStyle": "luxuryapartments",
    "id": "LC20",
    "title": "Kalmia LC 20",
    "location": "Lower Level",
    "type": "2 Bed",
    "sqft": 1397,
    "sqftRange": "1000+",
    "numOfBeds": "twobed",
    "numOfBaths": "twobathroom",
    "hasDen": false,
    "hasWalkInCloset": true,
    "hasBalcony": false,
    "hasTerrace": true,
    "hasEnsuite": true,
    "floorplan": "Floorplan-KALMIA LC 20.jpg",
    "keyplan": "Keyplan-KALMIA LC 20.jpg",
    "isAccessible": false,
    "dataBG": "twobed",
    "hasVrTour": false,
    "vrTour": "",
    "pdf": "Floorplan-KALMIA LC 20.pdf"
  },
  {
    "livingStyle": "luxuryapartments",
    "id": "LC2",
    "title": "Trillium LC 2",
    "location": "Lower Level",
    "type": "1 Bed + Den",
    "sqft": 838,
    "sqftRange": "800-899",
    "numOfBeds": "onebedden",
    "numOfBaths": "onebathroom",
    "hasDen": true,
    "hasWalkInCloset": false,
    "hasBalcony": false,
    "hasTerrace": true,
    "hasEnsuite": false,
    "floorplan": "Floorplan-TRILLIUM LC 2.jpg",
    "keyplan": "Keyplan-TRILLIUM LC 2.jpg",
    "isAccessible": false,
    "dataBG": "onebedden",
    "hasVrTour": false,
    "vrTour": "",
    "pdf": "Floorplan-TRILLIUM LC 2.pdf"
  },
  {
    "livingStyle": "luxuryapartments",
    "id": "LC3",
    "title": "Hibiscus LC 3",
    "location": "Lower Level",
    "type": "1 Bed",
    "sqft": 503,
    "sqftRange": "500-599",
    "numOfBeds": "onebed",
    "numOfBaths": "onebathroom",
    "hasDen": false,
    "hasWalkInCloset": false,
    "hasBalcony": false,
    "hasTerrace": true,
    "hasEnsuite": false,
    "floorplan": "Floorplan-HIBISCUS LC 3-01.jpg",
    "keyplan": "Keyplan-HIBISCUS LC 3-01.jpg",
    "isAccessible": false,
    "dataBG": "onebed",
    "hasVrTour": false,
    "vrTour": "",
    "pdf": "Floorplan-HIBISCUS LC 3-01.pdf"
  }
];