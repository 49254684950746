import FloorSixSevenEightFloorplate from '../assets/floorplates/floorplate-floor-6-8.png';

const FloorSixSevenEight = () => {
    return (
        <div className="suites__floorplate">
            <img src={FloorSixSevenEightFloorplate} alt="" />
            <div className="suites__floorplate--units">
            <svg id="Units" xmlns="http://www.w3.org/2000/svg" width="3855" height="2155" viewBox="-10 -3 3855 2155">
  <rect width="3840" height="2160" fill="none"/>
  <g className="suites__unit" id="B1">
    <polygon id="B1_UNIT_DREAM-3" data-name="B1 UNIT DREAM-3" points="1638.31 442.98 1638.39 507.04 1645.25 507.03 1645.28 541.53 1713.58 541.49 1713.58 535.69 1728.48 535.68 1728.42 437.02 1638.04 437.08 1638.04 442.98 1638.31 442.98" fill="#dadada"/>
    <g>
      <g>
        <path d="M1684.5,471.8c1.9,0,3.2,1,3.2,3a2.26,2.26,0,0,1-1.1,2.07,2.68,2.68,0,0,1,1.68,2.73c0,2.18-1.41,3.38-3.7,3.38h-4.18V471.8Zm-2,1.92v2.43h1.91c.83,0,1.31-.31,1.31-1.22s-.48-1.21-1.36-1.21Zm0,4.35v3h1.91c1.29,0,1.8-.54,1.8-1.51s-.51-1.5-1.8-1.5Z" fill="#59585b"/>
        <path d="M1694.19,471.8V483h-2v-8.39l-2.15,1.28v-2.17l3.28-1.92Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1668.78,491.32c-.35-1-.87-1.31-1.54-1.31a1.42,1.42,0,0,0-1.55,1.55c0,1,.7,1.44,1.78,2,1.28.67,2.12,1.24,2.12,2.86a3.28,3.28,0,0,1-1,2.66,3.89,3.89,0,0,1-3,1,3.11,3.11,0,0,1-3-3.08l1.28-.68c.1,1.33.72,2.41,2.05,2.41a2,2,0,0,0,2.24-2.09c0-1.11-.64-1.52-1.71-2.05a3.25,3.25,0,0,1-2.19-2.87,3,3,0,0,1,3.28-3.1,2.57,2.57,0,0,1,2.5,2Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1679.39,488.8l-.27,1.34h-4.91l-.63,3.22h4l-.25,1.34h-4l-.77,4h5.07l-.27,1.34h-6.5l2.19-11.2Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1684.55,488.8c2.6,0,4.42,1.47,4.42,4.46,0,3.75-2.56,6.74-6.38,6.74h-3.11l2.19-11.2Zm-1.72,1.34-1.65,8.52h1.42c3,0,4.85-2.33,4.85-5.25,0-2.2-1.15-3.27-3.2-3.27Z" fill="#59585b"/>
        <path d="M1692,488.8h1.43l-1.46,7.49a4.64,4.64,0,0,0-.07.64c0,1.18.6,1.85,2,1.85a2.82,2.82,0,0,0,3-2.49l1.46-7.49h1.43l-1.43,7.39c-.54,2.85-2.26,3.94-4.61,3.94a2.88,2.88,0,0,1-3.2-2.87,5.84,5.84,0,0,1,.1-1.07Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1704.2,488.8l1.47,7.89,4.47-7.89h1.72l-2.19,11.2h-1.42l1.6-8.16-4.07,7.14h-1l-1.36-7.14-1.61,8.16h-1.42l2.19-11.2Z" fill="#59585b"/>
      </g>
    </g>
  </g>
  <g className="suites__unit" id="B2">
    <polygon id="B2_UNIT_INSPIRED-3" data-name="B2 UNIT INSPIRED-3" points="1831.97 535.62 1831.96 515.09 1816.76 515.09 1816.73 436.97 1732.42 437.02 1732.48 535.68 1831.97 535.62" fill="#dadada"/>
    <g>
      <g>
        <path d="M1772.49,471.8c1.9,0,3.2,1,3.2,3a2.25,2.25,0,0,1-1.11,2.07,2.67,2.67,0,0,1,1.69,2.73c0,2.18-1.41,3.38-3.7,3.38h-4.18V471.8Zm-2,1.92v2.43h1.91c.83,0,1.31-.31,1.31-1.22s-.48-1.21-1.36-1.21Zm0,4.35v3h1.91c1.29,0,1.8-.54,1.8-1.51s-.51-1.5-1.8-1.5Z" fill="#59585b"/>
        <path d="M1778,482.2l4-5.17a3.06,3.06,0,0,0,.86-2c0-.92-.46-1.48-1.37-1.48s-1.33.53-1.46,1.48l-1.86-.36a3.22,3.22,0,0,1,3.37-3,3.08,3.08,0,0,1,3.34,3.25,4.29,4.29,0,0,1-1,2.65l-2.74,3.57h3.94V483H1778Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1758.69,491.32c-.35-1-.87-1.31-1.54-1.31a1.42,1.42,0,0,0-1.55,1.55c0,1,.7,1.44,1.78,2,1.28.67,2.12,1.24,2.12,2.86a3.28,3.28,0,0,1-1,2.66,3.89,3.89,0,0,1-3,1,3.11,3.11,0,0,1-3-3.08l1.28-.68c.1,1.33.72,2.41,2,2.41a2,2,0,0,0,2.24-2.09c0-1.11-.64-1.52-1.71-2.05a3.25,3.25,0,0,1-2.19-2.87,3,3,0,0,1,3.28-3.1,2.55,2.55,0,0,1,2.49,2Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1764.39,488.8l-2.2,11.2h-1.42l2.19-11.2Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1768.45,488.8l-1.92,9.86h4.93l-.27,1.34h-6.36l2.19-11.2Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1781.7,488.8l-.27,1.34h-4.91l-.63,3.22h4l-.25,1.34h-4l-.77,4h5.07l-.27,1.34h-6.5l2.19-11.2Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1792,488.8l-2.19,11.2h-1.59l-3.28-8.72-1.7,8.72h-1.42l2.19-11.2h1.59l3.26,8.75,1.72-8.75Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1801.07,488.8l-.27,1.34h-4.91l-.63,3.22h4l-.25,1.34h-4l-.77,4h5.07L1799,500h-6.5l2.19-11.2Z" fill="#59585b"/>
      </g>
    </g>
  </g>
  <g className="suites__unit" id="B3b">
    <polygon id="B3_UNIT_SUNSET-3" data-name="B3 UNIT SUNSET-3" points="1831.95 511.09 1831.95 511.06 1862.59 511.04 1862.58 501.38 1895.7 501.36 1895.68 479.28 1906.17 479.27 1906.17 432.49 1903.52 432.5 1903.52 436.91 1820.73 436.96 1820.76 511.09 1831.95 511.09" fill="#dadada"/>
    <g>
      <g>
        <path d="M1858.79,453.76c1.9,0,3.2,1,3.2,3a2.26,2.26,0,0,1-1.1,2.07,2.68,2.68,0,0,1,1.68,2.73c0,2.18-1.41,3.38-3.7,3.38h-4.18v-11.2Zm-2,1.92v2.43h1.91c.83,0,1.31-.31,1.31-1.22s-.48-1.21-1.36-1.21Zm0,4.35v3h1.91c1.29,0,1.8-.54,1.8-1.51s-.51-1.5-1.8-1.5Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1866,461.6a1.81,1.81,0,0,0,1.81,1.63,1.93,1.93,0,0,0,0-3.84,2,2,0,0,0-1,.24l-.8-1.12,2.11-2.9h-3.57v-1.85H1871v.7l-2.64,3.57a3,3,0,0,1,.45,0c1.73,0,2.79,1.36,2.79,3.33a3.79,3.79,0,0,1-7.54.52Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1854.52,470.76l-2.19,11.2h-1.42l1-5.28h-5l-1,5.28h-1.42l2.19-11.2h1.43l-.9,4.57h5l.9-4.57Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1863.64,470.76l-.27,1.34h-4.91l-.63,3.22h4l-.25,1.34h-4l-.77,4h5.07l-.27,1.34h-6.5l2.19-11.2Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1869.49,470.76c1.79,0,2.77.91,2.77,2.49a2.76,2.76,0,0,1-1.78,2.61,2.37,2.37,0,0,1,1.42,2.27c0,2.53-1.8,3.83-4.21,3.83h-4l2.19-11.2Zm-4,9.86h2.32c1.53,0,2.67-.71,2.67-2.21,0-1.14-.69-1.75-1.9-1.75h-2.32Zm1.65-8.52-.63,3.22h2.24a1.89,1.89,0,0,0,2.13-1.81c0-1-.58-1.41-1.54-1.41Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1881.92,470.76l-.27,1.34h-4.91l-.63,3.22h4l-.25,1.34h-4l-.77,4h5.07l-.27,1.34h-6.5l2.19-11.2Z" fill="#59585b"/>
      </g>
    </g>
  </g>
  <g className="suites__unit" id="B4b">
    <polygon id="B4_UNIT_SUNRISE-3" data-name="B4 UNIT SUNRISE-3" points="1899.7 505.35 1866.58 505.37 1866.59 513.03 1866.59 513.03 1866.6 533.36 1919.59 533.36 1919.6 549.09 1987.35 549.04 1987.28 438.51 1957.75 438.53 1957.75 432.46 1910.17 432.49 1910.17 483.27 1899.69 483.28 1899.7 505.35" fill="#dadada"/>
    <g>
      <g>
        <path d="M1929.09,493.47c1.9,0,3.2,1,3.2,3a2.26,2.26,0,0,1-1.1,2.07,2.68,2.68,0,0,1,1.68,2.73c0,2.18-1.41,3.38-3.7,3.38H1925v-11.2Zm-2,1.92v2.43H1929c.83,0,1.31-.31,1.31-1.22s-.48-1.21-1.36-1.21Zm0,4.35v3H1929c1.29,0,1.81-.54,1.81-1.51s-.52-1.5-1.81-1.5Z" fill="#59585b"/>
        <path d="M1939.3,502.59h-4.82v-1l4.72-8.09h2.11v7.26h1.25v1.86h-1.25v2.08h-2Zm0-1.86v-4.27l-2.37,4.27Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1892.82,510.47l1.47,7.89,4.47-7.89h1.72l-2.19,11.2h-1.42l1.6-8.16-4.07,7.14h-1.05l-1.36-7.14-1.61,8.16H1889l2.19-11.2Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1907.21,510.47l1.7,11.2h-1.43l-.4-3h-4.17l-1.59,3h-1.42l5.95-11.2Zm-3.6,6.86h3.28l-.72-5Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1910.48,510.47h1.5l1.46,5,3.36-5h1.58l-4.46,6.37-1,4.83h-1.42l.94-4.83Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1926.61,510.47l-.27,1.34h-5l-.64,3.35h4l-.28,1.34h-4l-1,5.17H1918l2.19-11.2Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1930,510.47l-1.92,9.86H1933l-.27,1.34h-6.36l2.19-11.2Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1944.63,514.87a7.09,7.09,0,0,1-2.42,5.57,5.38,5.38,0,0,1-3.76,1.36c-2.31,0-4-1.65-4-4.53a7.07,7.07,0,0,1,2.42-5.57,5.34,5.34,0,0,1,3.76-1.36C1943,510.34,1944.63,512,1944.63,514.87Zm-7.09-1.88a6,6,0,0,0-1.57,4.15c0,2.15,1,3.31,2.72,3.31a3.9,3.9,0,0,0,2.89-1.31,6,6,0,0,0,1.57-4.14c0-2.15-1-3.32-2.72-3.32A3.94,3.94,0,0,0,1937.54,513Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1960.18,510.47l-5.11,11.2h-1.42l-.89-7.68-3.61,7.68h-1.43l-.83-11.2h1.49l.45,9.12,3.73-7.94h1.33l.8,7.94,4-9.12Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1968.74,510.47l-.27,1.34h-4.91l-.63,3.22h4l-.25,1.34h-4l-.77,4H1967l-.27,1.34h-6.5l2.19-11.2Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1972.94,516.39h-1.65l-1,5.28h-1.42l2.19-11.2h3.56c1.77,0,2.85,1,2.85,2.51a3.15,3.15,0,0,1-3,3.31l2.29,5.38h-1.58Zm-.76-4.58-.63,3.23h2.23c1.34,0,2.17-.59,2.17-1.81,0-1-.56-1.42-1.6-1.42Z" fill="#59585b"/>
      </g>
    </g>
  </g>
  <g className="suites__unit" id="B5b">
    <polygon id="B5_UNIT_AMUSE-3" data-name="B5 UNIT AMUSE-3" points="1919.6 553.09 1919.61 570.2 1865.8 570.22 1865.81 587.48 1865.81 587.48 1865.81 597.41 1902.24 597.39 1902.26 621.4 1906.4 621.4 1906.4 628.85 1911.37 628.84 1911.39 669.41 1957.9 669.39 1957.89 662.21 1987.42 662.19 1987.35 553.04 1919.6 553.09" fill="#dadada"/>
    <g>
      <g>
        <path d="M1937.79,565.94c1.9,0,3.2,1,3.2,3a2.25,2.25,0,0,1-1.11,2.07,2.67,2.67,0,0,1,1.69,2.73c0,2.18-1.41,3.38-3.7,3.38h-4.18v-11.2Zm-2,1.92v2.43h1.91c.83,0,1.31-.31,1.31-1.22s-.48-1.21-1.36-1.21Zm0,4.35v3h1.91c1.29,0,1.8-.54,1.8-1.51s-.51-1.5-1.8-1.5Z" fill="#59585b"/>
        <path d="M1945.29,573.86c.24,1.23,1,1.55,1.68,1.55,1.09,0,1.67-.72,1.67-2s-.63-1.94-1.76-1.94a2.75,2.75,0,0,0-2,1l-1.26-.26.62-6.3H1950v1.85h-4.11l-.26,2.34a3.11,3.11,0,0,1,1.59-.42c2.33,0,3.42,1.52,3.42,3.72a3.57,3.57,0,0,1-3.68,3.84,3.4,3.4,0,0,1-3.58-3.06Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1905.19,582.94l1.7,11.2h-1.43l-.4-3h-4.17l-1.59,3h-1.42l5.95-11.2Zm-3.6,6.86h3.28l-.72-5Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1918.82,582.94l-2.19,11.2H1915l-3.28-8.72-1.7,8.72h-1.42l2.19-11.2h1.59l3.26,8.75,1.72-8.75Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1920.55,582.94h7.73l-.27,1.34h-3.16l-1.92,9.86h-1.42l1.92-9.86h-3.15Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1938.24,582.94l-2.19,11.2h-1.42l1-5.28h-5l-1,5.28h-1.43l2.19-11.2h1.43l-.9,4.57h5l.9-4.57Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1941,582.94h1.43l-1.46,7.49a4.62,4.62,0,0,0-.06.64c0,1.18.59,1.85,2,1.85a2.84,2.84,0,0,0,3-2.49l1.45-7.49h1.43l-1.43,7.39c-.54,2.85-2.25,3.94-4.61,3.94a2.88,2.88,0,0,1-3.2-2.87,5.84,5.84,0,0,1,.1-1.07Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1953.14,588.86h-1.64l-1,5.28H1949l2.19-11.2h3.56c1.77,0,2.85,1,2.85,2.51a3.15,3.15,0,0,1-3,3.31l2.29,5.38h-1.58Zm-.76-4.58-.63,3.23H1954c1.34,0,2.17-.59,2.17-1.81,0-1-.56-1.42-1.6-1.42Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1962.13,582.94l-2.2,11.2h-1.42l2.19-11.2Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1964.79,582.94h1.43l-1.46,7.49a4.62,4.62,0,0,0-.06.64c0,1.18.59,1.85,2,1.85a2.82,2.82,0,0,0,3-2.49l1.46-7.49h1.43l-1.43,7.39c-.54,2.85-2.25,3.94-4.61,3.94a2.88,2.88,0,0,1-3.2-2.87,5.84,5.84,0,0,1,.1-1.07Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1976.9,582.94l1.47,7.89,4.47-7.89h1.72l-2.19,11.2H1981l1.6-8.16-4.07,7.14h-1l-1.36-7.14-1.61,8.16H1973l2.19-11.2Z" fill="#59585b"/>
      </g>
    </g>
  </g>
  <g className="suites__unit" id="B6b">
    <polygon id="B6_UNIT_BLISS-3" data-name="B6 UNIT BLISS-3" points="1902.4 632.86 1902.4 625.4 1898.26 625.4 1898.24 601.39 1861.82 601.42 1861.81 591.48 1822.47 591.48 1822.53 664.23 1903.39 664.18 1903.39 669.42 1907.39 669.42 1907.37 632.85 1902.4 632.86" fill="#dadada"/>
    <g>
      <g>
        <path d="M1856.24,611.65c1.9,0,3.2,1,3.2,3a2.25,2.25,0,0,1-1.11,2.07,2.67,2.67,0,0,1,1.69,2.73c0,2.18-1.41,3.38-3.7,3.38h-4.18v-11.2Zm-2,1.92V616h1.91c.83,0,1.31-.31,1.31-1.22s-.48-1.21-1.36-1.21Zm0,4.35v3h1.91c1.29,0,1.8-.54,1.8-1.51s-.51-1.5-1.8-1.5Z" fill="#59585b"/>
        <path d="M1865.68,615.68a3.51,3.51,0,0,1,3.62,3.65,3.75,3.75,0,0,1-7.5,0,4.19,4.19,0,0,1,.55-2l3.26-5.84h2.24l-2.43,4.16Zm-1.86,3.65a1.73,1.73,0,1,0,3.46,0,1.73,1.73,0,1,0-3.46,0Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1844.84,628.65l-5.11,11.2h-1.42l-.89-7.68-3.61,7.68h-1.43l-.83-11.2H1833l.45,9.12,3.73-7.94h1.33l.8,7.94,4-9.12Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1848.48,628.65l-2.2,11.2h-1.42l2.19-11.2Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1852.54,628.65l-1.92,9.86h4.93l-.28,1.34h-6.35l2.19-11.2Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1860.88,628.65l-1.92,9.86h4.93l-.27,1.34h-6.36l2.19-11.2Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1875.48,633.05a7.09,7.09,0,0,1-2.42,5.57,5.36,5.36,0,0,1-3.76,1.36c-2.31,0-4-1.65-4-4.53a7.07,7.07,0,0,1,2.42-5.57,5.34,5.34,0,0,1,3.76-1.36C1873.83,628.52,1875.48,630.17,1875.48,633.05Zm-7.09-1.88a6,6,0,0,0-1.57,4.15c0,2.15,1,3.31,2.72,3.31a3.94,3.94,0,0,0,2.9-1.31,6,6,0,0,0,1.56-4.14c0-2.15-1-3.32-2.72-3.32A3.94,3.94,0,0,0,1868.39,631.17Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1891,628.65l-5.11,11.2h-1.42l-.89-7.68-3.61,7.68h-1.43l-.83-11.2h1.49l.45,9.12,3.73-7.94h1.33l.8,7.94,4-9.12Z" fill="#59585b"/>
      </g>
    </g>
  </g>
  <g className="suites__unit" id="B7b">
    <polygon id="B7_UNIT_BRILLIANT-3" data-name="B7 UNIT BRILLIANT-3" points="1732.78 566.45 1732.78 572.52 1732.77 572.52 1732.51 664.28 1818.53 664.23 1818.45 566.4 1732.78 566.45" fill="#dadada"/>
    <g>
      <g>
        <path d="M1770.7,600.6c1.9,0,3.2,1,3.2,3a2.26,2.26,0,0,1-1.1,2.07,2.68,2.68,0,0,1,1.68,2.73c0,2.18-1.41,3.38-3.7,3.38h-4.18V600.6Zm-2,1.92V605h1.91c.83,0,1.31-.31,1.31-1.22s-.48-1.21-1.36-1.21Zm0,4.35v3h1.91c1.29,0,1.81-.55,1.81-1.51s-.52-1.5-1.81-1.5Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1782.51,601.43l-4.22,10.37h-2l3.83-9.35h-4.41V600.6h6.79Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1757.82,617.6l1.7,11.2h-1.43l-.4-3h-4.17l-1.59,3h-1.42l6-11.2Zm-3.6,6.86h3.28l-.72-5Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1767.63,620.12c-.35-1-.87-1.31-1.54-1.31a1.42,1.42,0,0,0-1.55,1.55c0,1,.7,1.44,1.78,2,1.28.67,2.13,1.24,2.13,2.86a3.28,3.28,0,0,1-1.06,2.66,3.89,3.89,0,0,1-3,1,3.11,3.11,0,0,1-3-3.08l1.28-.68c.1,1.33.72,2.41,2,2.41a2,2,0,0,0,2.24-2.09c0-1.11-.64-1.52-1.71-2a3.25,3.25,0,0,1-2.19-2.87,3,3,0,0,1,3.28-3.1,2.55,2.55,0,0,1,2.49,2Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1770.62,617.6h7.73l-.27,1.34h-3.16L1773,628.8h-1.42l1.92-9.86h-3.15Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1786.84,617.6l-.27,1.34h-4.91l-.63,3.22h4l-.25,1.34h-4l-.77,4h5.07l-.27,1.34h-6.5l2.19-11.2Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1791.05,623.52h-1.64l-1.05,5.28h-1.42l2.19-11.2h3.56c1.77,0,2.85,1,2.85,2.51a3.15,3.15,0,0,1-3,3.31l2.29,5.38h-1.58Zm-.76-4.58-.63,3.23h2.23c1.34,0,2.17-.59,2.17-1.81,0-1-.56-1.42-1.6-1.42Z" fill="#59585b"/>
      </g>
    </g>
  </g>
  <g className="suites__unit" id="B8b">
    <polygon id="B8_UNIT_DELIGHT-3" data-name="B8 UNIT DELIGHT-3" points="1646.48 664.34 1728.51 664.28 1728.77 572.52 1647 572.57 1646.48 664.34" fill="#dadada"/>
    <g>
      <g>
        <path d="M1683.67,599.53c1.9,0,3.2,1,3.2,3a2.26,2.26,0,0,1-1.1,2.07,2.68,2.68,0,0,1,1.68,2.73c0,2.18-1.41,3.38-3.7,3.38h-4.18v-11.2Zm-2,1.92v2.43h1.91c.83,0,1.31-.31,1.31-1.22s-.48-1.21-1.36-1.21Zm0,4.35v3h1.91c1.29,0,1.8-.54,1.8-1.51s-.51-1.5-1.8-1.5Z" fill="#59585b"/>
        <path d="M1696.08,602.45a2.81,2.81,0,0,1-1,2.25,2.91,2.91,0,0,1,1.49,2.68,3.65,3.65,0,0,1-7.3,0,2.9,2.9,0,0,1,1.47-2.68,2.8,2.8,0,0,1-1-2.25,3.22,3.22,0,0,1,6.43,0Zm-4.84,4.93a1.63,1.63,0,1,0,1.63-1.61A1.53,1.53,0,0,0,1691.24,607.38Zm.28-4.89a1.35,1.35,0,1,0,2.69,0,1.35,1.35,0,1,0-2.69,0Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1666.79,616.53l1.7,11.2h-1.43l-.4-3h-4.17l-1.59,3h-1.42l6-11.2Zm-3.6,6.86h3.28l-.72-5Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1671.92,616.53h7l-.14.67-6.79,9.19h5.3l-.27,1.34h-7.29l.13-.67,6.79-9.19h-5Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1685.35,616.53l1.7,11.2h-1.43l-.4-3h-4.17l-1.59,3H1678l6-11.2Zm-3.6,6.86H1685l-.72-5Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1692.43,616.53l-1.92,9.86h4.93l-.27,1.34h-6.36l2.19-11.2Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1705.68,616.53l-.27,1.34h-4.91l-.63,3.22h4l-.25,1.34h-4l-.77,4h5.07l-.27,1.34h-6.5l2.19-11.2Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1712.28,616.53l1.7,11.2h-1.43l-.4-3H1708l-1.59,3H1705l6-11.2Zm-3.6,6.86H1712l-.72-5Z" fill="#59585b"/>
      </g>
    </g>
  </g>
  <g className="suites__unit" id="B9">
    <polygon id="B9_UNIT_FAB-3" data-name="B9 UNIT FAB-3" points="1621.15 566.79 1588.72 566.81 1588.73 591.93 1565.83 591.94 1565.85 634.03 1580.48 634.02 1580.49 642.58 1595.39 642.57 1595.42 693.35 1642.48 693.32 1642.46 667.83 1642.46 667.83 1642.46 667.79 1642.46 664.34 1642.48 664.34 1643 572.58 1621.15 572.59 1621.15 566.79" fill="#dadada"/>
    <g>
      <g>
        <path d="M1600.59,601.68c1.9,0,3.2,1,3.2,3a2.26,2.26,0,0,1-1.1,2.07,2.68,2.68,0,0,1,1.68,2.73c0,2.18-1.41,3.38-3.7,3.38h-4.18v-11.2Zm-2,1.92V606h1.91c.83,0,1.31-.31,1.31-1.22s-.48-1.21-1.36-1.21Zm0,4.35v3h1.91c1.29,0,1.81-.54,1.81-1.51s-.52-1.5-1.81-1.5Z" fill="#59585b"/>
        <path d="M1609.79,608.85a3.51,3.51,0,0,1-3.62-3.65,3.75,3.75,0,0,1,7.49,0,4.18,4.18,0,0,1-.54,2l-3.27,5.84h-2.24l2.43-4.16Zm1.85-3.65a1.73,1.73,0,1,0-3.45,0,1.73,1.73,0,1,0,3.45,0Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1577.86,626.61c-1,2.21-2.57,3.4-4.83,3.4s-4.07-1.55-4.07-4.48c0-3.56,2.29-7,6.21-7a3.55,3.55,0,0,1,3.64,3.39l-1.36.37c-.27-1.68-1.11-2.42-2.53-2.42-3,0-4.48,2.8-4.48,5.48,0,2.11.94,3.29,2.77,3.29,1.47,0,2.48-.7,3.41-2.45Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1585.91,618.68l1.7,11.2h-1.43l-.4-3h-4.17l-1.59,3h-1.42l6-11.2Zm-3.6,6.86h3.28l-.72-5Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1593.47,624.6h-1.64l-1,5.28h-1.43l2.19-11.2h3.56c1.77,0,2.85,1,2.85,2.51a3.15,3.15,0,0,1-3,3.31l2.29,5.38h-1.58Zm-.76-4.58-.63,3.23h2.23c1.34,0,2.17-.59,2.17-1.81,0-1-.56-1.42-1.6-1.42Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1603.92,618.68c2.6,0,4.42,1.47,4.42,4.46,0,3.75-2.56,6.74-6.38,6.74h-3.11l2.19-11.2ZM1602.2,620l-1.65,8.52H1602c3,0,4.85-2.33,4.85-5.25,0-2.2-1.15-3.27-3.2-3.27Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1612.77,618.68l-2.19,11.2h-1.43l2.19-11.2Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1623.4,618.68l-2.19,11.2h-1.59l-3.28-8.72-1.69,8.72h-1.43l2.19-11.2H1617l3.26,8.75,1.72-8.75Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1630,618.68l1.7,11.2h-1.43l-.4-3h-4.17l-1.59,3h-1.42l6-11.2Zm-3.6,6.86h3.28l-.72-5Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1637.08,618.68l-1.92,9.86h4.93l-.28,1.34h-6.35l2.19-11.2Z" fill="#59585b"/>
      </g>
    </g>
  </g>
  <g className="suites__unit" id="B10b">
    <polygon id="B10_UNIT_FLOURISH-3" data-name="B10 UNIT FLOURISH-3" points="1576.49 646.58 1576.48 638.02 1561.86 638.03 1561.83 587.95 1584.73 587.93 1584.72 566.82 1555.88 566.83 1555.88 572.63 1533.11 572.64 1533.1 549.46 1460.66 549.51 1460.72 648.17 1502.11 648.14 1502.13 672.43 1549.87 672.4 1549.89 693.37 1591.42 693.35 1591.39 646.57 1576.49 646.58" fill="#dadada"/>
    <g>
      <g>
        <path d="M1507.08,584.6c1.9,0,3.2,1,3.2,3a2.26,2.26,0,0,1-1.1,2.07,2.68,2.68,0,0,1,1.68,2.73c0,2.18-1.41,3.38-3.7,3.38H1503V584.6Zm-2,1.92V589H1507c.83,0,1.31-.31,1.31-1.22s-.48-1.21-1.36-1.21Zm0,4.35v3H1507c1.29,0,1.8-.55,1.8-1.51s-.51-1.5-1.8-1.5Z" fill="#59585b"/>
        <path d="M1516.77,584.6v11.2h-2v-8.39l-2.15,1.28v-2.17l3.28-1.92Z" fill="#59585b"/>
        <path d="M1527.4,590.2c0,3.58-1.46,5.73-4.13,5.73s-4.13-2.15-4.13-5.73,1.46-5.73,4.13-5.73S1527.4,586.61,1527.4,590.2Zm-6.28,0c0,2.49.68,3.87,2.15,3.87s2.14-1.38,2.14-3.87-.67-3.88-2.14-3.88S1521.12,587.7,1521.12,590.2Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1485.27,601.6c2.6,0,4.42,1.47,4.42,4.46,0,3.75-2.56,6.74-6.38,6.74h-3.11l2.19-11.2Zm-1.72,1.34-1.65,8.52h1.42c3,0,4.85-2.33,4.85-5.25,0-2.2-1.15-3.27-3.2-3.27Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1496.29,601.6l1.7,11.2h-1.43l-.4-3H1492l-1.59,3H1489l6-11.2Zm-3.6,6.86H1496l-.72-5Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1508.33,601.6l-.27,1.34h-5l-.64,3.35h4l-.28,1.34h-4l-1,5.17h-1.42l2.19-11.2Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1516.71,601.6l-.27,1.34h-5l-.65,3.35h4l-.28,1.34h-4l-1,5.17h-1.42l2.19-11.2Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1526.89,606a7.09,7.09,0,0,1-2.42,5.57,5.38,5.38,0,0,1-3.76,1.36c-2.31,0-4-1.65-4-4.53a7.07,7.07,0,0,1,2.42-5.57,5.34,5.34,0,0,1,3.76-1.36C1525.24,601.47,1526.89,603.12,1526.89,606Zm-7.09-1.88a6,6,0,0,0-1.57,4.15c0,2.15,1,3.31,2.72,3.31a3.94,3.94,0,0,0,2.9-1.31,6.05,6.05,0,0,0,1.56-4.15c0-2.14-1-3.31-2.72-3.31A3.94,3.94,0,0,0,1519.8,604.12Z" fill="#59585b"/>
        <path d="M1532.44,601.6c2.59,0,4.41,1.47,4.41,4.46,0,3.75-2.56,6.74-6.38,6.74h-3.11l2.2-11.2Zm-1.73,1.34-1.65,8.52h1.42c3,0,4.85-2.33,4.85-5.25,0-2.2-1.15-3.27-3.2-3.27Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1542.07,601.6l-2.19,11.2h-1.43l2.19-11.2Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1546.13,601.6l-1.92,9.86h4.93l-.27,1.34h-6.36l2.19-11.2Z" fill="#59585b"/>
      </g>
    </g>
  </g>
  <g className="suites__unit" id="B11b">
    <rect id="B11_UNIT_FLORA-4" data-name="B11 UNIT FLORA-4" x="1327.39" y="549.55" width="129.29" height="98.66" fill="#dadada"/>
    <g>
      <g>
        <path d="M1384.38,584.89c1.9,0,3.2,1,3.2,3a2.26,2.26,0,0,1-1.1,2.07,2.68,2.68,0,0,1,1.68,2.73c0,2.18-1.41,3.38-3.7,3.38h-4.18v-11.2Zm-2,1.92v2.43h1.91c.83,0,1.31-.31,1.31-1.22s-.48-1.21-1.36-1.21Zm0,4.35v3h1.91c1.29,0,1.8-.55,1.8-1.51s-.51-1.5-1.8-1.5Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1394.07,584.89v11.2h-2V587.7l-2.14,1.28v-2.17l3.28-1.92Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1400.33,584.89v11.2h-2V587.7l-2.14,1.28v-2.17l3.28-1.92Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1359.41,601.89c2.6,0,4.42,1.47,4.42,4.46,0,3.75-2.56,6.74-6.38,6.74h-3.11l2.19-11.2Zm-1.72,1.34-1.65,8.52h1.42c3,0,4.85-2.33,4.85-5.25,0-2.2-1.15-3.27-3.2-3.27Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1368.27,601.89l-2.2,11.2h-1.42l2.19-11.2Z" fill="#59585b"/>
        <path d="M1374.91,601.89l1.69,11.2h-1.42l-.4-3h-4.18l-1.58,3h-1.43l6-11.2Zm-3.6,6.86h3.28l-.72-5Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1388.46,601.89l-2.19,11.2h-1.59l-3.28-8.72-1.69,8.72h-1.43l2.19-11.2h1.59l3.26,8.75,1.72-8.75Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1390.18,601.89h7.73l-.27,1.34h-3.16l-1.92,9.86h-1.42l1.92-9.86h-3.15Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1407.88,601.89l-2.19,11.2h-1.42l1-5.28h-5l-1,5.28h-1.42l2.19-11.2h1.43l-.9,4.57h5l.9-4.57Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1410.68,601.89h1.43l-1.46,7.49a4.64,4.64,0,0,0-.07.64c0,1.18.6,1.85,2,1.85a2.84,2.84,0,0,0,3-2.49l1.45-7.49h1.43l-1.43,7.39c-.54,2.85-2.26,3.94-4.61,3.94a2.88,2.88,0,0,1-3.2-2.87,5.84,5.84,0,0,1,.1-1.07Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1425,604.41c-.35-1-.87-1.31-1.54-1.31a1.42,1.42,0,0,0-1.55,1.55c0,1,.7,1.44,1.78,2,1.28.67,2.13,1.24,2.13,2.86a3.28,3.28,0,0,1-1.06,2.66,3.89,3.89,0,0,1-3,1,3.11,3.11,0,0,1-3-3.08l1.28-.68c.1,1.33.72,2.41,2,2.41a2,2,0,0,0,2.24-2.09c0-1.11-.64-1.52-1.71-2a3.25,3.25,0,0,1-2.19-2.87,3,3,0,0,1,3.28-3.1,2.57,2.57,0,0,1,2.5,2Z" fill="#59585b"/>
      </g>
    </g>
  </g>
  <g className="suites__unit" id="B12b">
    <polygon id="B12_UNIT_GLOW-4" data-name="B12 UNIT GLOW-4" points="1225.25 549.66 1225.27 570.77 1239.34 570.76 1239.39 648.31 1323.42 648.26 1323.36 549.59 1225.25 549.66" fill="#dadada"/>
    <g>
      <g>
        <path d="M1272.47,584.89c1.9,0,3.2,1,3.2,3a2.26,2.26,0,0,1-1.1,2.07,2.68,2.68,0,0,1,1.68,2.73c0,2.18-1.41,3.38-3.7,3.38h-4.18v-11.2Zm-2,1.92v2.43h1.91c.83,0,1.31-.31,1.31-1.22s-.48-1.21-1.36-1.21Zm0,4.35v3h1.91c1.29,0,1.81-.55,1.81-1.51s-.52-1.5-1.81-1.5Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1282.16,584.89v11.2h-2V587.7L1278,589v-2.17l3.28-1.92Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1284.35,595.29l4-5.17a3.06,3.06,0,0,0,.86-2,1.29,1.29,0,0,0-1.38-1.48c-.8,0-1.32.53-1.45,1.48l-1.86-.36a3.21,3.21,0,0,1,3.36-3,3.09,3.09,0,0,1,3.35,3.25,4.29,4.29,0,0,1-1,2.65l-2.74,3.57h3.94v1.86h-7.13Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1254.07,607.44h3.77l-1.1,5.65h-1.22l.15-1.54a3.88,3.88,0,0,1-3.41,1.67c-2,0-4-1.44-4-4.56,0-3.59,2.26-6.9,6.2-6.9a3.47,3.47,0,0,1,3.68,3.37l-1.41.37a2.38,2.38,0,0,0-2.53-2.4c-2.72,0-4.46,2.56-4.46,5.35,0,2.22,1,3.42,2.75,3.42a3.76,3.76,0,0,0,3.59-3.09h-2.31Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1265.83,601.89l1.7,11.2h-1.43l-.4-3h-4.17l-1.59,3h-1.42l6-11.2Zm-3.6,6.86h3.28l-.72-5Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1271,601.89h7l-.14.67-6.79,9.19h5.3l-.27,1.34h-7.29l.13-.67,6.79-9.19h-5Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1284.38,601.89l1.7,11.2h-1.43l-.4-3h-4.17l-1.59,3h-1.42l6-11.2Zm-3.6,6.86h3.28l-.72-5Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1298,601.89l-2.19,11.2h-1.59l-3.28-8.72-1.7,8.72h-1.42l2.19-11.2h1.59l3.26,8.75,1.72-8.75Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1302.21,601.89l-2.2,11.2h-1.42l2.19-11.2Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1308.77,601.89l1.7,11.2H1309l-.4-3h-4.17l-1.59,3h-1.42l6-11.2Zm-3.6,6.86h3.28l-.72-5Z" fill="#59585b"/>
      </g>
    </g>
  </g>
  <g className="suites__unit" id="B17">
    <polygon id="B17_UNIT_JOY-4" data-name="B17 UNIT JOY-4" points="1226.89 521.92 1323.62 521.88 1323.62 423.2 1238.19 423.25 1238.19 499.17 1226.88 499.17 1226.89 521.92" fill="#dadada"/>
    <g>
      <g>
        <path d="M1275.09,457.72c1.9,0,3.2,1,3.2,3a2.26,2.26,0,0,1-1.1,2.07,2.68,2.68,0,0,1,1.68,2.73c0,2.18-1.41,3.38-3.7,3.38H1271v-11.2Zm-2,1.92v2.43H1275c.83,0,1.31-.31,1.31-1.22s-.48-1.21-1.36-1.21Zm0,4.35v3H1275c1.29,0,1.81-.54,1.81-1.51s-.52-1.5-1.81-1.5Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1284.78,457.72v11.2h-2v-8.39l-2.14,1.28v-2.17l3.28-1.92Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1293.58,458.55l-4.22,10.37h-2l3.83-9.35h-4.41v-1.85h6.79Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1258,474.72l-1.92,9.86H1261l-.28,1.34h-6.35l2.19-11.2Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1266.31,474.72l-2.2,11.2h-1.42l2.19-11.2Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1270.87,480.64h-1.64l-1,5.28h-1.43l2.19-11.2h3.56c1.77,0,2.85,1,2.85,2.51a3.15,3.15,0,0,1-3,3.31l2.29,5.38h-1.58Zm-.76-4.58-.63,3.23h2.23c1.34,0,2.17-.59,2.17-1.81,0-1-.56-1.42-1.6-1.42Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1279.87,474.72l-2.2,11.2h-1.42l2.19-11.2Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1290.88,479.12a7.09,7.09,0,0,1-2.42,5.57,5.38,5.38,0,0,1-3.76,1.36c-2.31,0-4-1.65-4-4.53a7.07,7.07,0,0,1,2.42-5.57,5.34,5.34,0,0,1,3.76-1.36C1289.23,474.59,1290.88,476.24,1290.88,479.12Zm-7.09-1.88a6,6,0,0,0-1.57,4.15c0,2.15,1,3.31,2.72,3.31a3.94,3.94,0,0,0,2.9-1.31,6,6,0,0,0,1.56-4.14c0-2.15-1-3.32-2.72-3.32A3.94,3.94,0,0,0,1283.79,477.24Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1294.5,481.12l-1,4.8h-1.42l2.19-11.2h3.59c1.81,0,3,1,3,2.65,0,2.5-1.66,3.75-4.13,3.75Zm4.91-3.49a1.42,1.42,0,0,0-1.6-1.57h-2.33l-.73,3.71h2.45A2,2,0,0,0,1299.41,477.63Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1309.75,474.72l-.27,1.34h-4.91l-.63,3.22h4l-.25,1.34h-4l-.77,4H1308l-.27,1.34h-6.5l2.19-11.2Z" fill="#59585b"/>
      </g>
    </g>
  </g>
  <g className="suites__unit" id="B18">
    <polygon id="B19_UNIT_LOVE-4" data-name="B19 UNIT LOVE-4" points="1403.07 423.15 1327.62 423.2 1327.62 521.88 1403.04 521.85 1403.07 423.15" fill="#dadada"/>
    <g>
      <g>
        <path d="M1356.62,457.72c1.9,0,3.2,1,3.2,3a2.26,2.26,0,0,1-1.1,2.07,2.68,2.68,0,0,1,1.68,2.73c0,2.18-1.41,3.38-3.7,3.38h-4.18v-11.2Zm-2,1.92v2.43h1.91c.83,0,1.31-.31,1.31-1.22s-.48-1.21-1.36-1.21Zm0,4.35v3h1.91c1.29,0,1.8-.54,1.8-1.51s-.51-1.5-1.8-1.5Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1366.31,457.72v11.2h-2v-8.39l-2.14,1.28v-2.17l3.28-1.92Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1375.56,460.64a2.78,2.78,0,0,1-1.06,2.25,2.9,2.9,0,0,1,1.49,2.68,3.65,3.65,0,0,1-7.3,0,2.89,2.89,0,0,1,1.48-2.68,2.77,2.77,0,0,1-1-2.25,3.22,3.22,0,0,1,6.43,0Zm-4.85,4.93a1.64,1.64,0,1,0,1.63-1.61A1.53,1.53,0,0,0,1370.71,465.57Zm.29-4.89a1.35,1.35,0,1,0,1.34-1.4A1.3,1.3,0,0,0,1371,460.68Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1338.32,474.72l-1.92,9.86h4.93l-.27,1.34h-6.36l2.19-11.2Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1352.92,479.12a7.09,7.09,0,0,1-2.42,5.57,5.38,5.38,0,0,1-3.76,1.36c-2.31,0-4-1.65-4-4.53a7.07,7.07,0,0,1,2.42-5.57,5.34,5.34,0,0,1,3.76-1.36C1351.27,474.59,1352.92,476.24,1352.92,479.12Zm-7.09-1.88a6,6,0,0,0-1.57,4.15c0,2.15,1,3.31,2.72,3.31a3.94,3.94,0,0,0,2.9-1.31,6,6,0,0,0,1.56-4.14c0-2.15-1-3.32-2.72-3.32A3.94,3.94,0,0,0,1345.83,477.24Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1359.93,474.72c1.79,0,2.77.91,2.77,2.49a2.76,2.76,0,0,1-1.78,2.61,2.37,2.37,0,0,1,1.42,2.27c0,2.53-1.8,3.83-4.21,3.83h-4l2.19-11.2Zm-4,9.86h2.32c1.53,0,2.67-.71,2.67-2.21,0-1.14-.69-1.75-1.9-1.75h-2.32Zm1.65-8.52-.63,3.22h2.24a1.89,1.89,0,0,0,2.13-1.81c0-1-.58-1.41-1.54-1.41Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1372.35,474.72l-.27,1.34h-4.91l-.63,3.22h4l-.25,1.34h-4l-.77,4h5.07l-.27,1.34h-6.5l2.19-11.2Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1376.07,474.72l-1.92,9.86h4.93l-.27,1.34h-6.36l2.19-11.2Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1384.4,474.72l-2.19,11.2h-1.43l2.19-11.2Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1391,474.72l1.7,11.2h-1.43l-.4-3h-4.17l-1.59,3h-1.42l5.95-11.2Zm-3.6,6.86h3.28l-.72-5Z" fill="#59585b"/>
      </g>
    </g>
  </g>
  <g className="suites__unit" id="B19">
    <polygon id="B18_UNIT_KEEN-4" data-name="B18 UNIT KEEN-4" points="1499.9 414.81 1498.93 414.81 1498.94 423.09 1407.07 423.15 1407.04 521.85 1499.9 521.81 1499.9 414.81" fill="#dadada"/>
    <g>
      <g>
        <path d="M1448.85,457.72c1.9,0,3.2,1,3.2,3a2.26,2.26,0,0,1-1.1,2.07,2.68,2.68,0,0,1,1.68,2.73c0,2.18-1.41,3.38-3.7,3.38h-4.18v-11.2Zm-2,1.92v2.43h1.91c.83,0,1.31-.31,1.31-1.22s-.48-1.21-1.36-1.21Zm0,4.35v3h1.91c1.29,0,1.8-.54,1.8-1.51s-.51-1.5-1.8-1.5Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1458.54,457.72v11.2h-2v-8.39l-2.14,1.28v-2.17l3.28-1.92Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1464.39,464.89a3.51,3.51,0,0,1-3.61-3.65,3.75,3.75,0,0,1,7.49,0,4.19,4.19,0,0,1-.55,2l-3.26,5.84h-2.24l2.43-4.16Zm1.86-3.65a1.73,1.73,0,1,0-3.46,0,1.73,1.73,0,1,0,3.46,0Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1436.67,481.12l-.94,4.8h-1.43l2.19-11.2h3.59c1.81,0,3,1,3,2.65,0,2.5-1.66,3.75-4.13,3.75Zm4.91-3.49a1.42,1.42,0,0,0-1.6-1.57h-2.33l-.72,3.71h2.44A2,2,0,0,0,1441.58,477.63Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1448.49,474.72l1.7,11.2h-1.43l-.4-3h-4.17l-1.59,3h-1.42l6-11.2Zm-3.6,6.86h3.28l-.72-5Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1462.13,474.72l-2.19,11.2h-1.59l-3.28-8.72-1.69,8.72H1452l2.19-11.2h1.59l3.26,8.75,1.72-8.75Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1469.11,477.24c-.35-1-.87-1.31-1.54-1.31a1.42,1.42,0,0,0-1.55,1.55c0,1,.7,1.44,1.78,2,1.28.67,2.12,1.24,2.12,2.86a3.28,3.28,0,0,1-1.05,2.66,3.89,3.89,0,0,1-3,1,3.11,3.11,0,0,1-3-3.08l1.28-.68c.1,1.33.72,2.41,2,2.41a2,2,0,0,0,2.24-2.09c0-1.11-.64-1.52-1.71-2.05a3.25,3.25,0,0,1-2.19-2.87,3,3,0,0,1,3.28-3.1,2.57,2.57,0,0,1,2.5,2Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1471.51,474.72h1.5l1.46,5,3.36-5h1.58l-4.46,6.37-1,4.83h-1.42l.94-4.83Z" fill="#59585b"/>
      </g>
    </g>
  </g>
  <g className="suites__unit" id="B13b">
    <polygon id="B13_UNIT_GRAND-4" data-name="B13 UNIT GRAND-4" points="1225.27 574.77 1225.27 577.25 1193.26 577.27 1193.26 584.45 1160.42 584.47 1160.43 607.65 1150.22 607.66 1150.22 654.16 1153.43 654.16 1153.42 648.36 1235.39 648.31 1235.34 574.76 1225.27 574.77" fill="#dadada"/>
    <g>
      <g>
        <path d="M1188,600.07c1.9,0,3.2,1,3.2,3a2.26,2.26,0,0,1-1.1,2.07,2.68,2.68,0,0,1,1.68,2.73c0,2.18-1.41,3.38-3.7,3.38h-4.18v-11.2Zm-2,1.92v2.43h1.91c.83,0,1.31-.31,1.31-1.22s-.48-1.21-1.36-1.21Zm0,4.35v3h1.91c1.29,0,1.8-.54,1.8-1.51s-.51-1.5-1.8-1.5Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1197.69,600.07v11.2h-2v-8.39l-2.14,1.28V602l3.28-1.92Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1201.69,607.91a1.81,1.81,0,0,0,1.81,1.63,1.93,1.93,0,0,0,0-3.84,2,2,0,0,0-1,.24l-.8-1.12,2.11-2.9h-3.57v-1.85h6.42v.7l-2.64,3.57a3,3,0,0,1,.45,0c1.73,0,2.79,1.36,2.79,3.33a3.79,3.79,0,0,1-7.54.52Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1168,622.62h3.77l-1.1,5.65h-1.22l.15-1.54a3.88,3.88,0,0,1-3.41,1.67c-2,0-4-1.44-4-4.56,0-3.59,2.26-6.9,6.2-6.9a3.47,3.47,0,0,1,3.68,3.37l-1.41.37a2.38,2.38,0,0,0-2.53-2.4c-2.72,0-4.46,2.56-4.46,5.35,0,2.22,1,3.42,2.75,3.42A3.76,3.76,0,0,0,1170,624h-2.31Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1181.93,617.07l-.27,1.34h-4.91l-.63,3.22h4l-.25,1.34h-4l-.77,4h5.07l-.27,1.34h-6.5l2.19-11.2Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1186.13,623h-1.64l-1,5.28H1182l2.19-11.2h3.56c1.77,0,2.85,1,2.85,2.51a3.15,3.15,0,0,1-3,3.31l2.29,5.38h-1.58Zm-.76-4.58-.63,3.23H1187c1.34,0,2.17-.59,2.17-1.81,0-1-.56-1.42-1.6-1.42Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1197.26,617.07c1.79,0,2.77.91,2.77,2.49a2.76,2.76,0,0,1-1.78,2.61,2.37,2.37,0,0,1,1.42,2.27c0,2.53-1.8,3.83-4.21,3.83h-4l2.19-11.2Zm-4,9.86h2.32c1.53,0,2.67-.71,2.67-2.21,0-1.14-.69-1.75-1.9-1.75H1194Zm1.65-8.52-.63,3.22h2.24a1.89,1.89,0,0,0,2.13-1.81c0-1-.58-1.41-1.54-1.41Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1209.69,617.07l-.27,1.34h-4.91l-.63,3.22h4l-.25,1.34h-4l-.77,4h5.07l-.27,1.34h-6.5l2.19-11.2Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1213.89,623h-1.64l-1,5.28h-1.43l2.19-11.2h3.56c1.77,0,2.85,1,2.85,2.51a3.15,3.15,0,0,1-3,3.31l2.29,5.38h-1.58Zm-.76-4.58-.63,3.23h2.23c1.34,0,2.17-.59,2.17-1.81,0-1-.56-1.42-1.6-1.42Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1226.08,617.07l1.7,11.2h-1.43l-.4-3h-4.17l-1.59,3h-1.42l6-11.2Zm-3.6,6.86h3.28l-.72-5Z" fill="#59585b"/>
      </g>
    </g>
  </g>
  <g className="suites__unit" id="B14b">
    <polygon id="B14_UNIT_GRACE-4" data-name="B14 UNIT GRACE-4" points="1156.42 580.47 1189.26 580.45 1189.24 555.2 1135.7 555.23 1135.69 537.29 1069.04 537.36 1069.11 648.14 1098.09 648.12 1098.09 654.19 1146.22 654.16 1146.22 603.66 1156.43 603.65 1156.42 580.47" fill="#dadada"/>
    <g>
      <g>
        <path d="M1101.88,572.38c1.9,0,3.2,1,3.2,3a2.26,2.26,0,0,1-1.1,2.07,2.68,2.68,0,0,1,1.68,2.73c0,2.18-1.41,3.38-3.7,3.38h-4.18v-11.2Zm-2,1.92v2.43h1.91c.83,0,1.31-.31,1.31-1.22s-.48-1.21-1.36-1.21Zm0,4.35v3h1.91c1.29,0,1.8-.54,1.8-1.51s-.51-1.5-1.8-1.5Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1111.57,572.38v11.2h-2v-8.39l-2.14,1.28V574.3l3.28-1.92Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1118.36,581.5h-4.82v-1l4.73-8.09h2.11v7.26h1.25v1.86h-1.25v2.08h-2Zm0-1.86v-4.27l-2.37,4.27Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1097.86,589.38h1.43l-1.6,8.24a3.5,3.5,0,0,1-3.65,3.09,2.84,2.84,0,0,1-2.85-2.93l1.42-.31c0,1.17.5,1.89,1.59,1.89s1.86-.65,2.18-2.29Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1105.41,589.38l1.7,11.2h-1.43l-.4-3h-4.17l-1.59,3h-1.42l6-11.2Zm-3.6,6.86h3.28l-.72-5Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1113.94,589.38c2.6,0,4.42,1.47,4.42,4.46,0,3.75-2.56,6.74-6.38,6.74h-3.11l2.19-11.2Zm-1.72,1.34-1.65,8.52H1112c3,0,4.85-2.32,4.85-5.25,0-2.2-1.15-3.27-3.2-3.27Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1127.7,589.38l-.27,1.34h-4.91l-.63,3.22h4l-.25,1.34h-4l-.77,4h5.07l-.27,1.34h-6.5l2.19-11.2Z" fill="#59585b"/>
      </g>
    </g>
  </g>
  <g className="suites__unit" id="B15b">
    <polygon id="B15_UNIT_HOPE-4" data-name="B15 UNIT HOPE-4" points="1135.68 516.73 1191.98 516.7 1191.96 492 1156.91 492.02 1156.9 469.39 1151.1 469.39 1151.1 458.63 1145.85 458.63 1145.85 417.51 1099.33 417.54 1099.33 422.51 1068.97 422.53 1069.04 533.36 1135.69 533.29 1135.68 516.73" fill="#dadada"/>
    <g>
      <g>
        <path d="M1102.05,469.54c1.9,0,3.2,1,3.2,3a2.26,2.26,0,0,1-1.1,2.07,2.68,2.68,0,0,1,1.68,2.73c0,2.18-1.41,3.38-3.7,3.38H1098v-11.2Zm-2,1.92v2.43h1.91c.83,0,1.31-.31,1.31-1.22s-.48-1.21-1.36-1.21Zm0,4.35v3h1.91c1.29,0,1.81-.54,1.81-1.51s-.52-1.5-1.81-1.5Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1111.74,469.54v11.2h-2v-8.39l-2.14,1.28v-2.17l3.28-1.92Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1116,477.46c.24,1.23.94,1.55,1.68,1.55,1.09,0,1.66-.72,1.66-2s-.62-1.94-1.76-1.94a2.75,2.75,0,0,0-2,1l-1.27-.26.63-6.3h5.76v1.85h-4.11l-.26,2.34a3.07,3.07,0,0,1,1.58-.42c2.34,0,3.43,1.52,3.43,3.72a3.57,3.57,0,0,1-3.68,3.84,3.4,3.4,0,0,1-3.59-3.06Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1084.79,486.54l-1.92,9.86h4.93l-.28,1.34h-6.35l2.19-11.2Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1096,486.54l1.7,11.2h-1.43l-.4-3h-4.17l-1.59,3h-1.42l6-11.2Zm-3.6,6.86h3.28l-.72-5Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1103.38,486.54l1.47,7.89,4.47-7.89H1111l-2.19,11.2h-1.42l1.6-8.16-4.07,7.14h-1l-1.36-7.14-1.61,8.16h-1.42l2.19-11.2Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1115.27,486.54l-2.2,11.2h-1.42l2.19-11.2Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1117.92,486.54h1.43l-1.46,7.49a4.62,4.62,0,0,0-.06.64c0,1.18.59,1.85,2,1.85a2.82,2.82,0,0,0,3-2.49l1.46-7.49h1.43l-1.43,7.39c-.54,2.85-2.25,3.94-4.61,3.94a2.88,2.88,0,0,1-3.2-2.87,5.84,5.84,0,0,1,.1-1.07Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1130,486.54l1.47,7.89,4.47-7.89h1.72l-2.19,11.2h-1.42l1.6-8.16-4.07,7.14h-1l-1.36-7.14-1.61,8.16h-1.42l2.19-11.2Z" fill="#59585b"/>
      </g>
    </g>
  </g>
  <g className="suites__unit" id="B16">
    <polygon id="B16_UNIT_IMAGINE-4" data-name="B16 UNIT IMAGINE-4" points="1155.1 465.39 1160.9 465.39 1160.91 488.02 1195.96 488 1195.96 495.17 1234.19 495.17 1234.19 423.25 1154.66 423.3 1154.66 417.51 1149.85 417.51 1149.85 454.63 1155.1 454.63 1155.1 465.39" fill="#dadada"/>
    <g>
      <g>
        <path d="M1186.4,437.77c1.9,0,3.2,1,3.2,3a2.26,2.26,0,0,1-1.1,2.07,2.68,2.68,0,0,1,1.68,2.73c0,2.18-1.41,3.38-3.7,3.38h-4.18v-11.2Zm-2,1.92v2.43h1.91c.83,0,1.31-.31,1.31-1.22s-.48-1.21-1.36-1.21Zm0,4.35v3h1.91c1.29,0,1.8-.55,1.8-1.51s-.51-1.5-1.8-1.5Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1196.09,437.77V449h-2v-8.39l-2.14,1.28v-2.17l3.28-1.92Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1202.25,441.8a3.51,3.51,0,0,1,3.62,3.65,3.75,3.75,0,0,1-7.49,0,4.18,4.18,0,0,1,.54-2l3.27-5.84h2.24L1202,441.8Zm-1.85,3.65a1.73,1.73,0,1,0,3.45,0,1.73,1.73,0,1,0-3.45,0Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1164.73,454.77l-1.92,9.85h4.93l-.28,1.35h-6.35l2.19-11.2Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1175.94,454.77l1.7,11.2h-1.43l-.4-3h-4.17l-1.59,3h-1.42l5.95-11.2Zm-3.6,6.86h3.28l-.72-5Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1189.58,454.77l-2.19,11.2h-1.59l-3.28-8.72-1.7,8.72h-1.42l2.19-11.2h1.59l3.26,8.75,1.72-8.75Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1191.3,454.77H1199l-.27,1.34h-3.16l-1.92,9.86h-1.42l1.92-9.86H1191Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1203.75,454.77l1.7,11.2H1204l-.4-3h-4.17l-1.59,3h-1.42l6-11.2Zm-3.6,6.86h3.28l-.72-5Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1217.38,454.77l-2.19,11.2h-1.59l-3.28-8.72-1.7,8.72h-1.42l2.19-11.2H1211l3.26,8.75,1.72-8.75Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1224,454.77l1.7,11.2h-1.43l-.4-3h-4.17l-1.59,3h-1.42l6-11.2Zm-3.6,6.86h3.28l-.72-5Z" fill="#59585b"/>
      </g>
    </g>
  </g>
  <g className="suites__unit" id="B20">
    <polygon id="B20_UNIT_LUCKY-4" data-name="B20 UNIT LUCKY-4" points="1565.64 507.08 1634.39 507.04 1634.31 444.53 1589.33 444.56 1589.31 414.75 1503.9 414.81 1503.9 521.81 1565.65 521.79 1565.64 507.08" fill="#dadada"/>
    <g>
      <g>
        <path d="M1550.28,452c1.9,0,3.2,1,3.2,3a2.26,2.26,0,0,1-1.1,2.07,2.68,2.68,0,0,1,1.68,2.73c0,2.18-1.41,3.38-3.7,3.38h-4.18V452Zm-2,1.92v2.43h1.91c.83,0,1.31-.31,1.31-1.22s-.48-1.21-1.36-1.21Zm0,4.35v3h1.91c1.29,0,1.8-.54,1.8-1.51s-.51-1.5-1.8-1.5Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1555.74,462.44l4-5.17a3.06,3.06,0,0,0,.86-2,1.29,1.29,0,0,0-1.38-1.48c-.8,0-1.32.53-1.45,1.48l-1.86-.36a3.21,3.21,0,0,1,3.36-3,3.09,3.09,0,0,1,3.35,3.25,4.29,4.29,0,0,1-1,2.65l-2.74,3.57h3.94v1.86h-7.13Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1573.14,457.64c0,3.58-1.45,5.73-4.13,5.73s-4.13-2.15-4.13-5.73,1.46-5.73,4.13-5.73S1573.14,454.05,1573.14,457.64Zm-6.27,0c0,2.49.67,3.87,2.14,3.87s2.15-1.38,2.15-3.87-.68-3.88-2.15-3.88S1566.87,455.14,1566.87,457.64Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1537.2,469h7l-.14.67-6.79,9.19h5.3l-.27,1.34H1535l.13-.67,6.79-9.19h-5Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1548.37,469l-2.2,11.2h-1.42l2.19-11.2Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1559,469l-2.19,11.2h-1.59l-3.28-8.72-1.7,8.72h-1.42L1551,469h1.59l3.26,8.75,1.72-8.75Z" fill="#59585b"/>
        <path d="M1569.2,469l-2.19,11.2h-1.59l-3.28-8.72-1.7,8.72H1559l2.19-11.2h1.59l3.26,8.75,1.71-8.75Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1573.94,469l-2.19,11.2h-1.43l2.19-11.2Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1580.49,469l1.7,11.2h-1.43l-.4-3h-4.17l-1.59,3h-1.42l6-11.2Zm-3.6,6.86h3.28l-.72-5Z" fill="#59585b"/>
      </g>
    </g>
  </g>
  <g className="suites__unit" id="C1-2" data-name="C1/2">
    <polygon points="1465.97 1523.51 1414 1476.68 1409.38 1481.8 1383.75 1458.71 1338.03 1509.45 1342.33 1513.33 1263.08 1601.28 1278.35 1615.04 1288.5 1603.74 1346.53 1656.05 1465.97 1523.51" fill="#dadada"/>
    <g>
      <g>
        <path d="M1353.12,1546.4a2.72,2.72,0,0,0-2.77-2c-1.73,0-3.19,1.22-3.19,3.81s1.46,3.81,3.19,3.81a2.73,2.73,0,0,0,2.77-2l1.87.47a4.39,4.39,0,0,1-4.64,3.4c-2.81,0-5.3-2-5.3-5.73s2.49-5.73,5.3-5.73a4.39,4.39,0,0,1,4.64,3.39Z" fill="#59585b"/>
        <path d="M1360.73,1542.66v11.2h-2v-8.39l-2.14,1.28v-2.17l3.28-1.92Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1371.13,1541.86l-7,12.8h-1.92l7-12.8Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1371.81,1553.06l4-5.17a3.06,3.06,0,0,0,.86-2,1.29,1.29,0,0,0-1.38-1.48c-.8,0-1.32.53-1.45,1.48l-1.86-.36a3.21,3.21,0,0,1,3.36-3,3.09,3.09,0,0,1,3.35,3.25,4.29,4.29,0,0,1-1,2.65L1375,1552h3.94v1.86h-7.13Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1349.69,1559.66l-6,11.2h-1.42l-1.55-11.2h1.48l1.16,9.2,4.8-9.2Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1353,1559.66l-2.2,11.2h-1.42l2.19-11.2Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1363.66,1559.66l-2.19,11.2h-1.59l-3.28-8.72-1.7,8.72h-1.42l2.19-11.2h1.59l3.26,8.75,1.72-8.75Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1373.46,1567.59c-1,2.21-2.57,3.4-4.83,3.4s-4.07-1.55-4.07-4.48c0-3.56,2.29-7,6.21-7a3.55,3.55,0,0,1,3.64,3.39l-1.36.37c-.27-1.68-1.11-2.42-2.53-2.42-3,0-4.48,2.8-4.48,5.48,0,2.11.94,3.29,2.77,3.29,1.47,0,2.48-.7,3.41-2.44Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1381.51,1559.66l1.7,11.2h-1.43l-.4-3h-4.17l-1.59,3h-1.42l6-11.2Zm-3.6,6.86h3.28l-.72-5Z" fill="#59585b"/>
      </g>
    </g>
  </g>
  <g className="suites__unit" id="C3">
    <polygon id="C3_UNIT_INSPIRE-4" data-name="C3 UNIT INSPIRE-4" points="1281.32 1617.72 1281.32 1617.72 1260.81 1640.48 1267.99 1646.95 1245.82 1671.55 1262.22 1686.33 1255.2 1694.12 1289.95 1725.43 1291.71 1723.48 1288.43 1720.53 1343.85 1659.02 1288.8 1609.39 1281.32 1617.72" fill="#dadada"/>
    <g>
      <g>
        <path d="M1290.46,1650.39a2.72,2.72,0,0,0-2.77-2c-1.73,0-3.19,1.21-3.19,3.81s1.46,3.81,3.19,3.81a2.73,2.73,0,0,0,2.77-2l1.87.48a4.39,4.39,0,0,1-4.64,3.4c-2.8,0-5.3-2-5.3-5.73s2.5-5.73,5.3-5.73a4.39,4.39,0,0,1,4.64,3.39Z" fill="#59585b"/>
        <path d="M1295.65,1654.49a1.81,1.81,0,0,0,1.81,1.63,1.93,1.93,0,0,0,0-3.84,2,2,0,0,0-1,.24l-.8-1.12,2.11-2.9h-3.56v-1.85h6.41v.7l-2.64,3.57a3,3,0,0,1,.45,0c1.73,0,2.79,1.36,2.79,3.33a3.79,3.79,0,0,1-7.54.52Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1268.62,1663.65l-2.2,11.2H1265l2.19-11.2Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1279.24,1663.65l-2.19,11.2h-1.59l-3.28-8.72-1.7,8.72h-1.42l2.19-11.2h1.59l3.26,8.75,1.72-8.75Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1286.22,1666.17c-.35-1-.87-1.31-1.54-1.31a1.42,1.42,0,0,0-1.55,1.55c0,1,.7,1.44,1.78,2,1.28.67,2.13,1.24,2.13,2.86a3.28,3.28,0,0,1-1.06,2.66,3.89,3.89,0,0,1-3,1,3.11,3.11,0,0,1-3-3.08l1.28-.68c.1,1.32.72,2.41,2,2.41a2,2,0,0,0,2.24-2.09c0-1.11-.64-1.52-1.71-2a3.25,3.25,0,0,1-2.19-2.87,3,3,0,0,1,3.28-3.1,2.57,2.57,0,0,1,2.5,2Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1290.66,1670.05l-1,4.8h-1.42l2.19-11.2h3.59c1.81,0,3,1,3,2.65,0,2.5-1.66,3.75-4.13,3.75Zm4.91-3.49a1.42,1.42,0,0,0-1.6-1.57h-2.33l-.72,3.71h2.44A2,2,0,0,0,1295.57,1666.56Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1301,1663.65l-2.2,11.2h-1.42l2.19-11.2Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1305.55,1669.57h-1.64l-1.05,5.28h-1.42l2.19-11.2h3.56c1.77,0,2.85,1,2.85,2.51a3.15,3.15,0,0,1-3,3.31l2.29,5.38h-1.58Zm-.76-4.58-.63,3.23h2.23c1.34,0,2.17-.59,2.17-1.81,0-1-.56-1.42-1.6-1.42Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1319.46,1663.65l-.27,1.34h-4.91l-.63,3.22h4l-.25,1.34h-4l-.77,4h5.07l-.27,1.35h-6.5l2.19-11.2Z" fill="#59585b"/>
      </g>
    </g>
  </g>
  <g className="suites__unit" id="C4">
    <polygon id="C4_UNIT_PRIMROSE-4" data-name="C4 UNIT PRIMROSE-4" points="1256.57 1686.62 1240.17 1671.84 1262.34 1647.24 1256.65 1642.11 1241.53 1628.49 1206.06 1667.85 1194.37 1657.32 1149.02 1707.65 1231.13 1781.64 1250.89 1759.71 1255.4 1763.77 1287.27 1728.4 1249.55 1694.41 1256.57 1686.62" fill="#dadada"/>
    <g>
      <g>
        <path d="M1217,1705a2.72,2.72,0,0,0-2.77-2c-1.73,0-3.19,1.21-3.19,3.81s1.46,3.81,3.19,3.81a2.73,2.73,0,0,0,2.77-2l1.87.47a4.39,4.39,0,0,1-4.64,3.4c-2.8,0-5.3-2-5.3-5.73s2.5-5.73,5.3-5.73a4.39,4.39,0,0,1,4.64,3.39Z" fill="#59585b"/>
        <path d="M1225.07,1710.37h-4.82v-1l4.73-8.09h2.11v7.26h1.25v1.86h-1.25v2.08h-2Zm0-1.86v-4.27l-2.37,4.27Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1185.06,1724.65l-1,4.8h-1.42l2.19-11.2h3.59c1.81,0,3,1,3,2.65,0,2.5-1.66,3.75-4.13,3.75Zm4.91-3.49a1.42,1.42,0,0,0-1.6-1.57H1186l-.73,3.71h2.45A2,2,0,0,0,1190,1721.16Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1195.89,1724.17h-1.64l-1,5.28h-1.43l2.19-11.2h3.56c1.77,0,2.85,1,2.85,2.51a3.15,3.15,0,0,1-3,3.31l2.29,5.38h-1.58Zm-.76-4.58-.63,3.23h2.23c1.34,0,2.17-.59,2.17-1.81,0-1-.56-1.42-1.6-1.42Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1204.89,1718.25l-2.2,11.2h-1.42l2.19-11.2Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1209.13,1718.25l1.47,7.89,4.47-7.89h1.72l-2.19,11.2h-1.42l1.6-8.16-4.07,7.14h-1l-1.36-7.14-1.61,8.16h-1.42l2.19-11.2Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1221.51,1724.17h-1.64l-1,5.28h-1.42l2.19-11.2h3.56c1.77,0,2.85,1,2.85,2.51a3.15,3.15,0,0,1-3,3.31l2.29,5.38h-1.58Zm-.76-4.58-.63,3.23h2.23c1.34,0,2.17-.59,2.17-1.81,0-1-.56-1.42-1.6-1.42Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1237.05,1722.65a7.09,7.09,0,0,1-2.42,5.57,5.38,5.38,0,0,1-3.76,1.36c-2.31,0-4-1.65-4-4.53a7.07,7.07,0,0,1,2.42-5.57,5.34,5.34,0,0,1,3.76-1.36C1235.4,1718.12,1237.05,1719.77,1237.05,1722.65Zm-7.09-1.88a6,6,0,0,0-1.57,4.15c0,2.15,1,3.31,2.72,3.31a3.94,3.94,0,0,0,2.9-1.31,6,6,0,0,0,1.56-4.14c0-2.15-1-3.32-2.72-3.32A3.94,3.94,0,0,0,1230,1720.77Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1244.55,1720.77c-.35-1-.87-1.31-1.54-1.31a1.42,1.42,0,0,0-1.55,1.55c0,1,.7,1.45,1.78,2,1.28.67,2.13,1.24,2.13,2.86a3.28,3.28,0,0,1-1.06,2.66,3.89,3.89,0,0,1-3,1,3.11,3.11,0,0,1-3-3.08l1.28-.68c.1,1.33.72,2.41,2.05,2.41a2,2,0,0,0,2.24-2.09c0-1.11-.64-1.52-1.71-2.05a3.25,3.25,0,0,1-2.19-2.87,3,3,0,0,1,3.28-3.1,2.55,2.55,0,0,1,2.49,2Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1255.16,1718.25l-.27,1.34H1250l-.63,3.22h4l-.25,1.34h-4l-.77,4h5.07l-.27,1.34h-6.5l2.19-11.2Z" fill="#59585b"/>
      </g>
    </g>
  </g>
  <g className="suites__unit" id="C5-6" data-name="C5/6">
    <path d="M1173.92,1508.11l-54.13,60.07-3.9-3.51-36.49,40.49,5.33,4.8L1065,1631.9,1146,1705l45.36-50.33-12.71-11.45,36-40L1198.93,1589h0l25.2-28,.41.37,3.79-4.2-54.43-49Z" fill="#dadada"/>
    <g>
      <g>
        <path d="M1125.76,1622.59a2.72,2.72,0,0,0-2.77-1.95c-1.73,0-3.19,1.21-3.19,3.81s1.46,3.81,3.19,3.81a2.73,2.73,0,0,0,2.77-2l1.87.48a4.39,4.39,0,0,1-4.64,3.4c-2.8,0-5.3-2-5.3-5.73s2.5-5.73,5.3-5.73a4.39,4.39,0,0,1,4.64,3.39Z" fill="#59585b"/>
        <path d="M1131.18,1626.77c.24,1.23.94,1.55,1.68,1.55,1.09,0,1.66-.72,1.66-2s-.62-1.94-1.76-1.94a2.75,2.75,0,0,0-2,1l-1.27-.26.63-6.3h5.76v1.85h-4.11l-.26,2.34a3.07,3.07,0,0,1,1.58-.42c2.34,0,3.43,1.52,3.43,3.72a3.57,3.57,0,0,1-3.68,3.84,3.4,3.4,0,0,1-3.59-3.06Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1146.48,1618l-7,12.81h-1.92l7-12.81Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1150,1622.88a3.51,3.51,0,0,1,3.62,3.65,3.75,3.75,0,0,1-7.49,0,4.18,4.18,0,0,1,.54-2l3.27-5.84h2.24l-2.43,4.16Zm-1.85,3.65a1.73,1.73,0,1,0,3.45,0,1.73,1.73,0,1,0-3.45,0Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1123.91,1635.85l-6,11.2h-1.42l-1.55-11.2h1.49l1.15,9.2,4.8-9.2Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1127.25,1635.85l-2.2,11.2h-1.42l2.19-11.2Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1138.26,1640.25a7.09,7.09,0,0,1-2.42,5.57,5.38,5.38,0,0,1-3.76,1.36c-2.31,0-4-1.65-4-4.53a7.07,7.07,0,0,1,2.42-5.57,5.34,5.34,0,0,1,3.76-1.36C1136.61,1635.72,1138.26,1637.37,1138.26,1640.25Zm-7.09-1.88a6,6,0,0,0-1.57,4.15c0,2.15,1,3.31,2.72,3.31a3.94,3.94,0,0,0,2.9-1.31,6,6,0,0,0,1.56-4.14c0-2.15-1-3.32-2.72-3.32A3.94,3.94,0,0,0,1131.17,1638.37Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1143.14,1635.85l-1.92,9.86h4.93l-.27,1.34h-6.36l2.19-11.2Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1154.35,1635.85l1.7,11.2h-1.43l-.4-3h-4.17l-1.59,3H1147l6-11.2Zm-3.6,6.86H1154l-.72-5Z" fill="#59585b"/>
      </g>
    </g>
    <g>
      <g>
        <path d="M1162.39,1547.5a2.72,2.72,0,0,0-2.77-2c-1.73,0-3.19,1.21-3.19,3.81s1.46,3.81,3.19,3.81a2.73,2.73,0,0,0,2.77-2l1.87.48a4.39,4.39,0,0,1-4.64,3.4c-2.8,0-5.3-2-5.3-5.73s2.5-5.73,5.3-5.73a4.39,4.39,0,0,1,4.64,3.39Z" fill="#59585b"/>
        <path d="M1167.81,1551.68c.24,1.23.94,1.55,1.68,1.55,1.09,0,1.66-.72,1.66-2s-.62-1.94-1.76-1.94a2.75,2.75,0,0,0-2,1l-1.27-.26.63-6.3h5.76v1.85h-4.11l-.26,2.34a3.07,3.07,0,0,1,1.58-.42c2.34,0,3.43,1.52,3.43,3.72a3.57,3.57,0,0,1-3.68,3.84,3.4,3.4,0,0,1-3.59-3.06Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1183.11,1543l-7,12.8h-1.92l7-12.8Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1186.6,1547.79a3.51,3.51,0,0,1,3.62,3.65,3.75,3.75,0,0,1-7.49,0,4.3,4.3,0,0,1,.54-2l3.27-5.84h2.24l-2.43,4.16Zm-1.85,3.65a1.73,1.73,0,1,0,3.46,0,1.73,1.73,0,1,0-3.46,0Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1160.54,1560.76l-6,11.2h-1.42l-1.55-11.2h1.48l1.16,9.2,4.8-9.2Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1163.88,1560.76l-2.19,11.2h-1.43l2.19-11.2Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1174.89,1565.16a7.09,7.09,0,0,1-2.42,5.57,5.38,5.38,0,0,1-3.76,1.36c-2.31,0-4-1.65-4-4.53a7.07,7.07,0,0,1,2.42-5.57,5.34,5.34,0,0,1,3.76-1.36C1173.24,1560.63,1174.89,1562.28,1174.89,1565.16Zm-7.09-1.88a6,6,0,0,0-1.57,4.15c0,2.15,1,3.31,2.72,3.31a3.94,3.94,0,0,0,2.9-1.31,6,6,0,0,0,1.56-4.14c0-2.15-1-3.32-2.72-3.32A3.94,3.94,0,0,0,1167.8,1563.28Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1179.77,1560.76l-1.92,9.86h4.93l-.28,1.34h-6.35l2.19-11.2Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1191,1560.76l1.7,11.2h-1.43l-.4-3h-4.17l-1.59,3h-1.42l5.95-11.2Zm-3.6,6.86h3.28l-.72-5Z" fill="#59585b"/>
      </g>
    </g>
  </g>
  <g className="suites__unit" id="C7">
    <polygon id="C7_UNIT_ROSE-3" data-name="C7 UNIT ROSE-3" points="1249.28 1570.63 1306.64 1506.98 1302.12 1502.91 1302.31 1502.71 1234.14 1441.28 1176.59 1505.14 1232.51 1555.53 1249.28 1570.63" fill="#dadada"/>
    <g>
      <g>
        <path d="M1242,1491.86a2.72,2.72,0,0,0-2.77-1.95c-1.73,0-3.19,1.21-3.19,3.81s1.46,3.81,3.19,3.81a2.73,2.73,0,0,0,2.77-2l1.87.47a4.39,4.39,0,0,1-4.64,3.4c-2.81,0-5.3-2-5.3-5.73s2.49-5.73,5.3-5.73a4.39,4.39,0,0,1,4.64,3.39Z" fill="#59585b"/>
        <path d="M1252,1489l-4.22,10.37h-2l3.83-9.35h-4.41v-1.85H1252Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1228.3,1511h-1.64l-1.05,5.28h-1.42l2.19-11.2h3.56c1.77,0,2.85,1,2.85,2.51a3.15,3.15,0,0,1-3,3.31l2.29,5.38h-1.58Zm-.76-4.58-.63,3.23h2.23c1.34,0,2.17-.59,2.17-1.81,0-1-.56-1.42-1.6-1.42Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1243.85,1509.52a7.09,7.09,0,0,1-2.42,5.57,5.38,5.38,0,0,1-3.76,1.36c-2.31,0-4-1.65-4-4.53a7.07,7.07,0,0,1,2.42-5.57,5.34,5.34,0,0,1,3.76-1.36C1242.2,1505,1243.85,1506.64,1243.85,1509.52Zm-7.09-1.88a6,6,0,0,0-1.57,4.15c0,2.15,1,3.31,2.72,3.31a3.94,3.94,0,0,0,2.9-1.31,6,6,0,0,0,1.56-4.14c0-2.15-1-3.32-2.72-3.32A3.94,3.94,0,0,0,1236.76,1507.64Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1251.35,1507.64c-.35-1-.87-1.31-1.54-1.31a1.42,1.42,0,0,0-1.55,1.55c0,1,.7,1.45,1.78,2,1.28.67,2.13,1.24,2.13,2.86a3.28,3.28,0,0,1-1.06,2.66,3.89,3.89,0,0,1-3,1,3.11,3.11,0,0,1-3-3.07l1.28-.69c.1,1.33.72,2.41,2,2.41a2,2,0,0,0,2.24-2.09c0-1.11-.64-1.52-1.71-2a3.25,3.25,0,0,1-2.19-2.87,3,3,0,0,1,3.28-3.1,2.55,2.55,0,0,1,2.49,2Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1262,1505.12l-.27,1.34h-4.91l-.63,3.22h4l-.25,1.34h-4l-.77,4h5.07l-.27,1.34h-6.5l2.19-11.2Z" fill="#59585b"/>
      </g>
    </g>
  </g>
  <g className="suites__unit" id="C8">
    <rect id="C8_UNIT_ASTER-3" data-name="C8 UNIT ASTER-3" x="1257.42" y="1392.73" width="81.55" height="91.76" transform="translate(-639.56 1440.06) rotate(-47.98)" fill="#dadada"/>
    <g>
      <g>
        <path d="M1297.61,1420.09a2.72,2.72,0,0,0-2.77-1.95c-1.73,0-3.19,1.21-3.19,3.81s1.46,3.81,3.19,3.81a2.73,2.73,0,0,0,2.77-2l1.87.48a4.39,4.39,0,0,1-4.64,3.4c-2.8,0-5.3-2-5.3-5.73s2.5-5.73,5.3-5.73a4.39,4.39,0,0,1,4.64,3.39Z" fill="#59585b"/>
        <path d="M1307.94,1419.27a2.81,2.81,0,0,1-1,2.25,2.91,2.91,0,0,1,1.48,2.68,3.65,3.65,0,0,1-7.3,0,2.89,2.89,0,0,1,1.48-2.68,2.77,2.77,0,0,1-1-2.25,3.22,3.22,0,0,1,6.43,0Zm-4.85,4.93a1.64,1.64,0,1,0,1.63-1.61A1.53,1.53,0,0,0,1303.09,1424.2Zm.29-4.89a1.35,1.35,0,1,0,1.34-1.4A1.3,1.3,0,0,0,1303.38,1419.31Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1282.91,1433.35l1.7,11.2h-1.43l-.4-3h-4.17l-1.59,3h-1.42l6-11.2Zm-3.6,6.86h3.28l-.72-5Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1292.73,1435.87c-.35-1-.87-1.31-1.54-1.31a1.42,1.42,0,0,0-1.55,1.55c0,1,.7,1.44,1.78,2,1.28.67,2.12,1.24,2.12,2.86a3.28,3.28,0,0,1-1,2.66,3.89,3.89,0,0,1-3,1,3.11,3.11,0,0,1-3-3.08l1.28-.68c.1,1.33.72,2.41,2,2.41a2,2,0,0,0,2.24-2.09c0-1.11-.64-1.52-1.71-2a3.25,3.25,0,0,1-2.19-2.87,3,3,0,0,1,3.28-3.1,2.55,2.55,0,0,1,2.49,2Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1295.72,1433.35h7.73l-.27,1.34H1300l-1.92,9.86h-1.42l1.92-9.86h-3.15Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1311.94,1433.35l-.27,1.34h-4.91l-.63,3.22h4l-.25,1.34h-4l-.77,4h5.07l-.27,1.34h-6.5l2.19-11.2Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1316.14,1439.27h-1.64l-1,5.28H1312l2.19-11.2h3.56c1.77,0,2.85,1,2.85,2.51a3.15,3.15,0,0,1-3,3.31l2.29,5.38h-1.58Zm-.76-4.58-.63,3.23H1317c1.34,0,2.17-.59,2.17-1.81,0-1-.56-1.42-1.6-1.42Z" fill="#59585b"/>
      </g>
    </g>
  </g>
  <g className="suites__unit" id="C9">
    <polygon id="C9_UNIT_DAYLILY-3" data-name="C9 UNIT DAYLILY-3" points="1294.09 1374.76 1362.26 1436.19 1376.85 1419.99 1381.16 1423.87 1402.87 1399.78 1384.21 1382.97 1399.55 1365.95 1368.28 1337.78 1358.49 1348.64 1352.13 1342.91 1342.16 1353.98 1304.43 1319.99 1272.93 1354.95 1294.46 1374.35 1294.09 1374.76" fill="#dadada"/>
    <g>
      <g>
        <path d="M1350.79,1369.41a2.72,2.72,0,0,0-2.77-2c-1.73,0-3.19,1.21-3.19,3.81s1.46,3.81,3.19,3.81a2.73,2.73,0,0,0,2.77-2l1.87.48a4.39,4.39,0,0,1-4.64,3.4c-2.81,0-5.3-2-5.3-5.73s2.49-5.73,5.3-5.73a4.39,4.39,0,0,1,4.64,3.39Z" fill="#59585b"/>
        <path d="M1357.9,1372.84a3.51,3.51,0,0,1-3.61-3.65,3.75,3.75,0,0,1,7.49,0,4.19,4.19,0,0,1-.55,2L1358,1377h-2.24l2.43-4.16Zm1.86-3.65a1.73,1.73,0,1,0-3.46,0,1.73,1.73,0,1,0,3.46,0Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1330.69,1382.67c2.6,0,4.42,1.47,4.42,4.46,0,3.75-2.56,6.74-6.38,6.74h-3.11l2.19-11.2ZM1329,1384l-1.65,8.52h1.42c3,0,4.85-2.32,4.85-5.25,0-2.2-1.15-3.27-3.2-3.27Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1341.72,1382.67l1.7,11.2H1342l-.4-3h-4.17l-1.59,3h-1.42l5.95-11.2Zm-3.6,6.86h3.28l-.72-5Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1345,1382.67h1.5l1.46,5,3.36-5h1.58l-4.46,6.37-1,4.83h-1.42l.94-4.83Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1356.15,1382.67l-1.92,9.86h4.93l-.28,1.34h-6.35l2.19-11.2Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1364.49,1382.67l-2.2,11.2h-1.42l2.19-11.2Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1368.55,1382.67l-1.92,9.86h4.93l-.27,1.34h-6.36l2.19-11.2Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1372.33,1382.67h1.5l1.46,5,3.36-5h1.58l-4.46,6.37-1,4.83h-1.42l.94-4.83Z" fill="#59585b"/>
      </g>
    </g>
  </g>
  <g className="suites__unit" id="C10">
    <polygon id="C10_UNIT_CALICO-3" data-name="C10 UNIT CALICO-3" points="1351.84 1337.27 1358.2 1342.99 1367.99 1332.13 1405.2 1365.66 1389.86 1382.67 1405.55 1396.81 1424.85 1375.38 1420.55 1371.5 1435.79 1354.59 1453.01 1370.11 1501.51 1316.29 1428.21 1250.24 1400.5 1281 1382.46 1264.74 1350.5 1300.21 1334.92 1286.16 1307.11 1317.02 1341.86 1348.34 1351.84 1337.27" fill="#dadada"/>
    <g>
      <g>
        <path d="M1413.74,1303.07a2.72,2.72,0,0,0-2.77-2c-1.73,0-3.19,1.21-3.19,3.81s1.46,3.81,3.19,3.81a2.73,2.73,0,0,0,2.77-2l1.87.47a4.39,4.39,0,0,1-4.64,3.4c-2.8,0-5.3-2-5.3-5.73s2.5-5.73,5.3-5.73a4.39,4.39,0,0,1,4.64,3.39Z" fill="#59585b"/>
        <path d="M1421.35,1299.33v11.2h-2v-8.39l-2.14,1.28v-2.17l3.28-1.92Z" fill="#59585b"/>
        <path d="M1432,1304.93c0,3.58-1.46,5.73-4.13,5.73s-4.13-2.15-4.13-5.73,1.45-5.73,4.13-5.73S1432,1301.34,1432,1304.93Zm-6.28,0c0,2.49.67,3.87,2.15,3.87s2.14-1.38,2.14-3.87-.67-3.88-2.14-3.88S1425.7,1302.43,1425.7,1304.93Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1400.33,1324.26c-1,2.21-2.57,3.4-4.83,3.4s-4.07-1.55-4.07-4.48c0-3.56,2.29-7,6.22-7a3.55,3.55,0,0,1,3.63,3.39l-1.36.37c-.27-1.68-1.11-2.42-2.53-2.42-3,0-4.48,2.8-4.48,5.48,0,2.11.94,3.29,2.77,3.29,1.47,0,2.48-.7,3.41-2.44Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1408.38,1316.33l1.7,11.2h-1.43l-.4-3h-4.17l-1.59,3h-1.42l6-11.2Zm-3.6,6.86h3.28l-.72-5Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1415.46,1316.33l-1.92,9.86h4.93l-.27,1.34h-6.36l2.19-11.2Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1423.79,1316.33l-2.2,11.2h-1.42l2.19-11.2Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1433.56,1324.26c-1,2.21-2.57,3.4-4.83,3.4s-4.07-1.55-4.07-4.48c0-3.56,2.29-7,6.22-7a3.55,3.55,0,0,1,3.63,3.39l-1.36.37c-.27-1.68-1.11-2.42-2.53-2.42-2.95,0-4.48,2.8-4.48,5.48,0,2.11.94,3.29,2.77,3.29,1.47,0,2.48-.7,3.41-2.44Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1446,1320.73a7.09,7.09,0,0,1-2.42,5.57,5.38,5.38,0,0,1-3.76,1.36c-2.31,0-4-1.65-4-4.53a7.07,7.07,0,0,1,2.42-5.57,5.34,5.34,0,0,1,3.76-1.36C1444.35,1316.2,1446,1317.85,1446,1320.73Zm-7.09-1.88a6,6,0,0,0-1.57,4.15c0,2.15,1,3.31,2.72,3.31a3.94,3.94,0,0,0,2.9-1.31,6,6,0,0,0,1.56-4.14c0-2.15-1-3.32-2.72-3.32A3.94,3.94,0,0,0,1438.91,1318.85Z" fill="#59585b"/>
      </g>
    </g>
  </g>
  <g className="suites__unit" id="C11b">
    <rect id="C11_UNIT_GLORY-3" data-name="C11 UNIT GLORY-3" x="1446.14" y="1182.81" width="129.3" height="98.66" transform="translate(-415.89 1529.75) rotate(-47.98)" fill="#dadada"/>
    <g>
      <g>
        <path d="M1509.5,1217.24a2.72,2.72,0,0,0-2.77-2c-1.73,0-3.19,1.21-3.19,3.81s1.46,3.81,3.19,3.81a2.73,2.73,0,0,0,2.77-2l1.87.47a4.39,4.39,0,0,1-4.64,3.4c-2.8,0-5.3-2-5.3-5.73s2.5-5.73,5.3-5.73a4.39,4.39,0,0,1,4.64,3.39Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1517.11,1213.5v11.2h-2v-8.39l-2.14,1.28v-2.17l3.28-1.92Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1523.37,1213.5v11.2h-2v-8.39l-2.14,1.28v-2.17l3.28-1.92Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1494.75,1236.05h3.77l-1.1,5.65h-1.22l.15-1.54a3.88,3.88,0,0,1-3.41,1.67c-2,0-4-1.44-4-4.56,0-3.59,2.26-6.9,6.2-6.9a3.47,3.47,0,0,1,3.68,3.38l-1.41.36a2.38,2.38,0,0,0-2.53-2.4c-2.72,0-4.46,2.56-4.46,5.35,0,2.22,1,3.42,2.75,3.42a3.76,3.76,0,0,0,3.59-3.09h-2.31Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1503.79,1230.5l-1.92,9.86h4.93l-.28,1.34h-6.35l2.19-11.2Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1518.39,1234.9a7.09,7.09,0,0,1-2.42,5.57,5.38,5.38,0,0,1-3.76,1.36c-2.31,0-4-1.65-4-4.53a7.07,7.07,0,0,1,2.42-5.57,5.34,5.34,0,0,1,3.76-1.36C1516.74,1230.37,1518.39,1232,1518.39,1234.9Zm-7.09-1.88a6,6,0,0,0-1.57,4.15c0,2.15,1,3.31,2.72,3.31a3.94,3.94,0,0,0,2.9-1.31,6,6,0,0,0,1.56-4.14c0-2.15-1-3.32-2.72-3.32A3.94,3.94,0,0,0,1511.3,1233Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1523.75,1236.42h-1.64l-1,5.28h-1.42l2.19-11.2h3.56c1.77,0,2.85,1,2.85,2.51a3.15,3.15,0,0,1-3,3.31l2.29,5.38H1526Zm-.76-4.58-.63,3.23h2.23c1.34,0,2.17-.59,2.17-1.81,0-1-.56-1.42-1.6-1.42Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1529.84,1230.5h1.5l1.46,5,3.36-5h1.58l-4.46,6.37-1,4.83h-1.42l.94-4.83Z" fill="#59585b"/>
      </g>
    </g>
  </g>
  <g className="suites__unit" id="C12b">
    <polygon id="C12_UNIT_PEONY-3" data-name="C12 UNIT PEONY-3" points="1659.09 1141.42 1643.41 1127.28 1633.99 1137.74 1576.38 1085.83 1520.12 1148.25 1593.42 1214.3 1659.09 1141.42" fill="#dadada"/>
    <g>
      <g>
        <path d="M1584,1136a2.72,2.72,0,0,0-2.77-2c-1.73,0-3.19,1.21-3.19,3.81s1.46,3.81,3.19,3.81a2.73,2.73,0,0,0,2.77-2l1.87.47a4.39,4.39,0,0,1-4.64,3.4c-2.8,0-5.3-2-5.3-5.73s2.5-5.73,5.3-5.73a4.39,4.39,0,0,1,4.64,3.39Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1591.64,1132.24v11.2h-2v-8.39l-2.14,1.28v-2.17l3.28-1.92Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1593.83,1142.64l4-5.17a3.06,3.06,0,0,0,.86-2,1.29,1.29,0,0,0-1.38-1.48c-.8,0-1.32.53-1.45,1.48l-1.86-.36a3.21,3.21,0,0,1,3.36-3,3.09,3.09,0,0,1,3.35,3.25,4.29,4.29,0,0,1-1,2.65l-2.74,3.57H1601v1.86h-7.13Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1567.33,1155.64l-.95,4.8H1565l2.19-11.2h3.59c1.81,0,3,1,3,2.65,0,2.5-1.66,3.75-4.13,3.75Zm4.91-3.49a1.43,1.43,0,0,0-1.6-1.57h-2.33l-.73,3.71H1570A2,2,0,0,0,1572.24,1152.15Z" fill="#59585b"/>
        <path d="M1582.23,1149.24l-.27,1.34H1577l-.62,3.22h4l-.26,1.34h-4l-.77,4h5.08l-.28,1.34h-6.49l2.19-11.2Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1593.05,1153.64a7.09,7.09,0,0,1-2.42,5.57,5.38,5.38,0,0,1-3.76,1.36c-2.31,0-4-1.65-4-4.53a7.07,7.07,0,0,1,2.42-5.57,5.34,5.34,0,0,1,3.76-1.36C1591.4,1149.11,1593.05,1150.76,1593.05,1153.64Zm-7.09-1.88a6,6,0,0,0-1.57,4.15c0,2.14,1,3.31,2.72,3.31a3.94,3.94,0,0,0,2.9-1.31,6,6,0,0,0,1.56-4.14c0-2.15-1-3.32-2.72-3.32A3.94,3.94,0,0,0,1586,1151.76Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1604.48,1149.24l-2.19,11.2h-1.59l-3.28-8.72-1.7,8.72h-1.42l2.19-11.2h1.59l3.26,8.75,1.72-8.75Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1605.67,1149.24h1.5l1.46,5,3.36-5h1.58l-4.46,6.37-.95,4.83h-1.42l.94-4.83Z" fill="#59585b"/>
      </g>
    </g>
  </g>
  <g className="suites__unit" id="C13b">
    <polygon id="C13_UNIT_TULIP-3" data-name="C13 UNIT TULIP-3" points="1640.44 1124.61 1638.59 1122.94 1660.02 1099.16 1654.69 1094.36 1676.68 1069.96 1659.46 1054.44 1666.29 1046.86 1631.75 1015.73 1629.62 1018.09 1633.93 1021.97 1579.06 1082.85 1633.69 1132.09 1640.44 1124.61" fill="#dadada"/>
    <g>
      <g>
        <path d="M1627.76,1061.54a2.72,2.72,0,0,0-2.77-2c-1.73,0-3.19,1.21-3.19,3.81s1.46,3.81,3.19,3.81a2.73,2.73,0,0,0,2.77-2l1.87.48a4.39,4.39,0,0,1-4.64,3.4c-2.8,0-5.3-2-5.3-5.73s2.5-5.73,5.3-5.73a4.39,4.39,0,0,1,4.64,3.39Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1635.37,1057.8V1069h-2v-8.39l-2.14,1.28v-2.17l3.28-1.92Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1639.37,1065.64a1.81,1.81,0,0,0,1.81,1.63,1.93,1.93,0,0,0,0-3.84,2,2,0,0,0-1,.24l-.8-1.12,2.11-2.9H1638v-1.85h6.42v.7l-2.64,3.57a3,3,0,0,1,.45,0c1.73,0,2.79,1.36,2.79,3.33a3.79,3.79,0,0,1-7.54.52Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1613.39,1074.8h7.73l-.27,1.34h-3.16l-1.92,9.86h-1.42l1.92-9.86h-3.15Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1623.57,1074.8H1625l-1.46,7.49a4.62,4.62,0,0,0-.06.64c0,1.18.59,1.85,2,1.85a2.84,2.84,0,0,0,3-2.49l1.45-7.49h1.43l-1.43,7.39c-.54,2.85-2.26,3.94-4.61,3.94a2.88,2.88,0,0,1-3.2-2.87,5.84,5.84,0,0,1,.1-1.07Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1635.17,1074.8l-1.92,9.86h4.93l-.27,1.34h-6.36l2.19-11.2Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1643.51,1074.8l-2.2,11.2h-1.42l2.19-11.2Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1646.32,1081.2l-.94,4.8H1644l2.19-11.2h3.59c1.81,0,3,1,3,2.65,0,2.5-1.66,3.75-4.13,3.75Zm4.91-3.49a1.42,1.42,0,0,0-1.6-1.57h-2.33l-.72,3.71H1649A2,2,0,0,0,1651.23,1077.71Z" fill="#59585b"/>
      </g>
    </g>
  </g>
  <g className="suites__unit" id="C14b">
    <polygon id="C14_UNIT_DAISY-3" data-name="C14 UNIT DAISY-3" points="1665.11 1054.15 1682.33 1069.67 1660.34 1094.06 1679.1 1110.97 1714.94 1071.2 1728.27 1083.21 1772.89 1033.7 1690.57 959.52 1671.17 981.04 1666.66 976.98 1634.42 1012.76 1671.94 1046.56 1665.11 1054.15" fill="#dadada"/>
    <g>
      <g>
        <path d="M1700.39,1022.74a2.72,2.72,0,0,0-2.77-2c-1.73,0-3.19,1.21-3.19,3.81s1.46,3.81,3.19,3.81a2.73,2.73,0,0,0,2.77-2l1.87.47a4.39,4.39,0,0,1-4.64,3.4c-2.8,0-5.3-2-5.3-5.73s2.5-5.73,5.3-5.73a4.39,4.39,0,0,1,4.64,3.39Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1708,1019v11.2h-2v-8.39l-2.14,1.28v-2.17l3.28-1.92Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1714.78,1028.12H1710v-1l4.73-8.09h2.11v7.26h1.25v1.86h-1.25v2.08h-2Zm0-1.86V1022l-2.37,4.27Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1690.82,1036c2.6,0,4.42,1.47,4.42,4.46,0,3.75-2.56,6.74-6.38,6.74h-3.11l2.19-11.2Zm-1.72,1.34-1.65,8.52h1.42c3,0,4.85-2.32,4.85-5.25,0-2.2-1.15-3.27-3.2-3.27Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1701.85,1036l1.7,11.2h-1.43l-.4-3h-4.17l-1.59,3h-1.42l6-11.2Zm-3.6,6.86h3.28l-.72-5Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1708.92,1036l-2.2,11.2h-1.42l2.19-11.2Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1715.63,1038.52c-.35-1-.87-1.31-1.54-1.31a1.42,1.42,0,0,0-1.55,1.55c0,1,.7,1.45,1.78,2,1.28.67,2.13,1.24,2.13,2.86a3.28,3.28,0,0,1-1.06,2.66,3.89,3.89,0,0,1-3,1,3.11,3.11,0,0,1-3-3.08l1.28-.68c.1,1.33.72,2.41,2,2.41a2,2,0,0,0,2.24-2.09c0-1.11-.64-1.52-1.71-2.05a3.25,3.25,0,0,1-2.19-2.87,3,3,0,0,1,3.28-3.1,2.55,2.55,0,0,1,2.49,2Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1718,1036h1.5l1.46,5,3.36-5h1.58l-4.46,6.37-1,4.83h-1.42l.94-4.83Z" fill="#59585b"/>
      </g>
    </g>
  </g>
  <g className="suites__unit" id="C15b">
    <polygon id="C15_UNIT_MARYGOLD-3" data-name="C15 UNIT MARYGOLD-3" points="1858.17 1110.55 1775.86 1036.37 1731.24 1085.89 1743.54 1096.97 1705.85 1138.79 1724.2 1155.33 1747.66 1129.29 1764.48 1144.44 1768.36 1140.14 1776.35 1147.34 1779.86 1143.44 1810.41 1170.97 1841.54 1136.43 1837.85 1133.1 1858.17 1110.55" fill="#dadada"/>
    <g>
      <g>
        <path d="M1783.9,1089.66a2.72,2.72,0,0,0-2.77-2c-1.73,0-3.19,1.21-3.19,3.81s1.46,3.81,3.19,3.81a2.73,2.73,0,0,0,2.77-1.95l1.87.47a4.39,4.39,0,0,1-4.64,3.4c-2.8,0-5.3-2-5.3-5.73s2.5-5.73,5.3-5.73a4.39,4.39,0,0,1,4.64,3.39Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1791.51,1085.92v11.2h-2v-8.39l-2.14,1.28v-2.17l3.28-1.92Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1795.75,1093.84c.24,1.23.94,1.55,1.68,1.55,1.09,0,1.66-.72,1.66-2s-.62-1.94-1.76-1.94a2.75,2.75,0,0,0-2,1l-1.27-.26.63-6.3h5.76v1.85h-4.11l-.26,2.34a3.07,3.07,0,0,1,1.58-.42c2.34,0,3.43,1.52,3.43,3.72a3.57,3.57,0,0,1-3.68,3.84,3.4,3.4,0,0,1-3.59-3.06Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1752.39,1102.92l1.47,7.89,4.47-7.89h1.73l-2.2,11.2h-1.42l1.6-8.16-4.07,7.14h-1l-1.36-7.14-1.6,8.16h-1.43l2.19-11.2Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1766.79,1102.92l1.7,11.2h-1.43l-.4-3h-4.17l-1.59,3h-1.42l6-11.2Zm-3.6,6.86h3.28l-.72-5Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1774.35,1108.84h-1.64l-1,5.28h-1.43l2.19-11.2H1776c1.77,0,2.85,1,2.85,2.51a3.15,3.15,0,0,1-3,3.31l2.29,5.38h-1.58Zm-.76-4.58-.63,3.23h2.23c1.34,0,2.17-.59,2.17-1.81,0-1-.56-1.42-1.6-1.42Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1780.44,1102.92h1.5l1.46,5,3.36-5h1.58l-4.46,6.37-1,4.83h-1.42l.94-4.83Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1793.66,1108.47h3.77l-1.1,5.65h-1.22l.15-1.54a3.88,3.88,0,0,1-3.41,1.67c-2,0-4-1.44-4-4.56,0-3.59,2.26-6.9,6.2-6.9a3.47,3.47,0,0,1,3.68,3.38l-1.41.36a2.38,2.38,0,0,0-2.53-2.4c-2.72,0-4.46,2.56-4.46,5.35,0,2.22,1,3.42,2.75,3.42a3.76,3.76,0,0,0,3.59-3.09h-2.31Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1809.42,1107.32a7.09,7.09,0,0,1-2.42,5.57,5.36,5.36,0,0,1-3.76,1.36c-2.31,0-4-1.65-4-4.53a7.07,7.07,0,0,1,2.42-5.57,5.34,5.34,0,0,1,3.76-1.36C1807.77,1102.79,1809.42,1104.44,1809.42,1107.32Zm-7.09-1.88a6,6,0,0,0-1.57,4.15c0,2.15,1,3.31,2.72,3.31a3.94,3.94,0,0,0,2.9-1.31,6,6,0,0,0,1.56-4.14c0-2.15-1-3.32-2.72-3.32A3.94,3.94,0,0,0,1802.33,1105.44Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1814.3,1102.92l-1.92,9.86h4.93l-.27,1.34h-6.36l2.19-11.2Z" fill="#59585b"/>
        <path d="M1824,1102.92c2.59,0,4.41,1.47,4.41,4.46,0,3.75-2.56,6.74-6.38,6.74h-3.11l2.19-11.2Zm-1.73,1.34-1.65,8.52H1822c3,0,4.85-2.32,4.85-5.25,0-2.2-1.15-3.27-3.2-3.27Z" fill="#59585b"/>
      </g>
    </g>
  </g>
  <g className="suites__unit" id="C16b">
    <polygon id="C16_UNIT_JASMINE-3" data-name="C16 UNIT JASMINE-3" points="1776.64 1152.99 1768.65 1145.78 1764.77 1150.09 1747.96 1134.94 1724.5 1160.98 1719.17 1156.17 1693.58 1184.57 1746.98 1232.7 1800.19 1173.65 1804.5 1177.53 1807.73 1173.94 1780.16 1149.09 1776.64 1152.99" fill="#dadada"/>
    <g>
      <g>
        <path d="M1744.81,1164.55a2.72,2.72,0,0,0-2.77-2c-1.73,0-3.19,1.21-3.19,3.81s1.46,3.81,3.19,3.81a2.73,2.73,0,0,0,2.77-2l1.87.48a4.39,4.39,0,0,1-4.64,3.4c-2.8,0-5.3-2-5.3-5.73s2.5-5.73,5.3-5.73a4.39,4.39,0,0,1,4.64,3.39Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1752.42,1160.81V1172h-2v-8.39l-2.14,1.28v-2.17l3.28-1.92Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1758.58,1164.84a3.51,3.51,0,0,1,3.62,3.65,3.75,3.75,0,0,1-7.49,0,4.18,4.18,0,0,1,.54-2l3.27-5.84h2.24l-2.43,4.16Zm-1.85,3.65a1.73,1.73,0,1,0,3.45,0,1.73,1.73,0,1,0-3.45,0Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1725.32,1177.81h1.43l-1.6,8.24a3.5,3.5,0,0,1-3.65,3.09,2.84,2.84,0,0,1-2.85-2.93l1.42-.31c0,1.17.5,1.89,1.59,1.89s1.86-.65,2.18-2.29Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1732.87,1177.81l1.7,11.2h-1.43l-.4-3h-4.17l-1.59,3h-1.42l6-11.2Zm-3.6,6.86h3.28l-.72-5Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1742.68,1180.33c-.35-1-.87-1.31-1.54-1.31a1.42,1.42,0,0,0-1.55,1.55c0,1,.7,1.44,1.78,2,1.28.67,2.13,1.24,2.13,2.86a3.28,3.28,0,0,1-1.06,2.66,3.89,3.89,0,0,1-3,1,3.11,3.11,0,0,1-3-3.08l1.28-.68c.1,1.33.72,2.41,2,2.41a2,2,0,0,0,2.24-2.09c0-1.11-.64-1.52-1.71-2a3.25,3.25,0,0,1-2.19-2.87,3,3,0,0,1,3.28-3.1,2.57,2.57,0,0,1,2.5,2Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1748.89,1177.81l1.47,7.89,4.47-7.89h1.72l-2.19,11.2h-1.42l1.6-8.16-4.07,7.14h-1l-1.36-7.14-1.6,8.16H1745l2.19-11.2Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1760.78,1177.81l-2.2,11.2h-1.42l2.19-11.2Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1771.41,1177.81l-2.19,11.2h-1.59l-3.28-8.72-1.7,8.72h-1.42l2.19-11.2H1765l3.26,8.75,1.72-8.75Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1780.51,1177.81l-.27,1.34h-4.91l-.63,3.22h4l-.25,1.34h-4l-.77,4h5.07l-.27,1.34H1772l2.19-11.2Z" fill="#59585b"/>
      </g>
    </g>
  </g>
  <g className="suites__unit" id="C19b">
    <polygon id="C19_UNIT_IRIS-3" data-name="C19 UNIT IRIS-3" points="1495.78 1364.07 1575.22 1435.66 1575.87 1434.94 1569.72 1429.4 1571.06 1427.92 1631.24 1361.13 1557.95 1295.09 1495.78 1364.07" fill="#dadada"/>
    <g>
      <g>
        <path d="M1559.05,1350.9a2.72,2.72,0,0,0-2.77-2c-1.73,0-3.19,1.22-3.19,3.81s1.46,3.81,3.19,3.81a2.73,2.73,0,0,0,2.77-2l1.87.47a4.39,4.39,0,0,1-4.64,3.4c-2.8,0-5.3-2-5.3-5.73s2.5-5.73,5.3-5.73a4.39,4.39,0,0,1,4.64,3.39Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1566.66,1347.16v11.2h-2V1350l-2.14,1.28v-2.17l3.28-1.92Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1572.51,1354.33a3.5,3.5,0,0,1-3.61-3.65,3.75,3.75,0,0,1,7.49,0,4.19,4.19,0,0,1-.55,2l-3.26,5.84h-2.24l2.43-4.16Zm1.86-3.65a1.73,1.73,0,1,0-3.46,0,1.73,1.73,0,1,0,3.46,0Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1554.8,1364.16l-2.2,11.2h-1.42l2.19-11.2Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1559.35,1370.08h-1.64l-1,5.28h-1.43l2.19-11.2H1561c1.77,0,2.85,1,2.85,2.51a3.15,3.15,0,0,1-3,3.31l2.29,5.38h-1.58Zm-.76-4.58-.63,3.23h2.23c1.34,0,2.17-.59,2.17-1.81,0-1-.56-1.42-1.6-1.42Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1568.35,1364.16l-2.2,11.2h-1.42l2.19-11.2Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1575.05,1366.68c-.35-1-.87-1.31-1.54-1.31a1.42,1.42,0,0,0-1.55,1.55c0,1,.7,1.45,1.78,2,1.28.67,2.13,1.24,2.13,2.86a3.28,3.28,0,0,1-1.06,2.66,3.89,3.89,0,0,1-3,1,3.11,3.11,0,0,1-3-3.07l1.28-.69c.1,1.33.72,2.41,2.05,2.41a2,2,0,0,0,2.24-2.09c0-1.11-.64-1.52-1.71-2a3.25,3.25,0,0,1-2.19-2.87,3,3,0,0,1,3.28-3.1,2.55,2.55,0,0,1,2.49,2Z" fill="#59585b"/>
      </g>
    </g>
  </g>
  <g className="suites__unit" id="C20">
    <polygon id="C20_UNIT_FREESIA-3" data-name="C20 UNIT FREESIA-3" points="1451.81 1412.87 1462.68 1422.66 1416.67 1473.71 1463.11 1515.55 1493.22 1482.14 1515.37 1502.09 1572.55 1438.64 1493.1 1367.04 1451.81 1412.87" fill="#dadada"/>
    <g>
      <g>
        <path d="M1498.12,1416.34a2.72,2.72,0,0,0-2.77-1.95c-1.73,0-3.19,1.21-3.19,3.81s1.46,3.81,3.19,3.81a2.73,2.73,0,0,0,2.77-2l1.87.48a4.39,4.39,0,0,1-4.64,3.4c-2.81,0-5.3-2-5.3-5.73s2.49-5.73,5.3-5.73a4.39,4.39,0,0,1,4.64,3.39Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1501.5,1423l4-5.17a3.06,3.06,0,0,0,.86-2,1.29,1.29,0,0,0-1.38-1.48c-.8,0-1.32.53-1.45,1.48l-1.86-.36a3.21,3.21,0,0,1,3.36-3,3.09,3.09,0,0,1,3.35,3.25,4.29,4.29,0,0,1-1,2.65l-2.74,3.57h3.94v1.86h-7.13Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1518.9,1418.2c0,3.58-1.46,5.73-4.13,5.73s-4.13-2.15-4.13-5.73,1.46-5.73,4.13-5.73S1518.9,1414.61,1518.9,1418.2Zm-6.27,0c0,2.49.67,3.87,2.14,3.87s2.15-1.38,2.15-3.87-.68-3.88-2.15-3.88S1512.63,1415.7,1512.63,1418.2Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1485.08,1429.6l-.27,1.34h-5l-.64,3.35h4l-.28,1.34h-4l-1,5.17h-1.42l2.19-11.2Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1489,1435.52h-1.64l-1,5.28h-1.42l2.19-11.2h3.56c1.77,0,2.85,1,2.85,2.51a3.15,3.15,0,0,1-3,3.31l2.29,5.38h-1.58Zm-.76-4.58-.63,3.23h2.23c1.34,0,2.17-.59,2.17-1.81,0-1-.56-1.42-1.6-1.42Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1502.91,1429.6l-.27,1.34h-4.91l-.63,3.22h4l-.25,1.34h-4l-.77,4h5.07l-.27,1.34h-6.5l2.19-11.2Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1511.53,1429.6l-.27,1.34h-4.91l-.63,3.22h4l-.25,1.34h-4l-.77,4h5.07l-.27,1.34H1503l2.19-11.2Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1517.9,1432.12c-.35-1-.87-1.31-1.54-1.31a1.42,1.42,0,0,0-1.55,1.55c0,1,.7,1.44,1.78,2,1.28.67,2.12,1.24,2.12,2.86a3.28,3.28,0,0,1-1,2.66,3.89,3.89,0,0,1-3,1,3.11,3.11,0,0,1-3-3.08l1.28-.68c.1,1.33.72,2.41,2,2.41a2,2,0,0,0,2.24-2.09c0-1.11-.64-1.52-1.71-2a3.25,3.25,0,0,1-2.19-2.87,3,3,0,0,1,3.28-3.1,2.57,2.57,0,0,1,2.5,2Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1523.6,1429.6l-2.2,11.2H1520l2.19-11.2Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1530.15,1429.6l1.7,11.2h-1.43l-.4-3h-4.17l-1.59,3h-1.42l6-11.2Zm-3.6,6.86h3.28l-.72-5Z" fill="#59585b"/>
      </g>
    </g>
  </g>
  <g id="C17b" className='suites__unit'>
    <polygon points="1687.37 1299.9 1744.57 1236.5 1688.17 1185.7 1695.77 1177.3 1678.87 1162 1614.07 1233.9 1687.37 1299.9" fill="#dadada"/>
    <g>
      <path d="M1673.09,1223.87a2.71,2.71,0,0,0-2.77-1.95c-1.72,0-3.18,1.21-3.18,3.81s1.46,3.81,3.18,3.81a2.71,2.71,0,0,0,2.77-2l1.88.48a4.4,4.4,0,0,1-4.65,3.4c-2.8,0-5.29-2-5.29-5.73s2.49-5.73,5.29-5.73a4.4,4.4,0,0,1,4.65,3.39Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1680.71,1220.13v11.2h-2v-8.39l-2.14,1.28v-2.17l3.28-1.92Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1689.51,1221l-4.23,10.37h-2l3.82-9.35h-4.4v-1.85h6.79Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1656.1,1243.53l-.95,4.8h-1.42l2.19-11.2h3.59c1.81,0,3,1,3,2.65,0,2.5-1.67,3.75-4.13,3.75ZM1661,1240a1.42,1.42,0,0,0-1.6-1.57h-2.34l-.72,3.71h2.45A2,2,0,0,0,1661,1240Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1673.46,1241.53a7.08,7.08,0,0,1-2.41,5.57,5.38,5.38,0,0,1-3.76,1.36c-2.31,0-4-1.65-4-4.53a7.07,7.07,0,0,1,2.42-5.57,5.3,5.3,0,0,1,3.76-1.36C1671.82,1237,1673.46,1238.65,1673.46,1241.53Zm-7.09-1.88a6.08,6.08,0,0,0-1.57,4.15c0,2.15,1,3.31,2.73,3.31a3.92,3.92,0,0,0,2.89-1.31,6,6,0,0,0,1.57-4.14c0-2.15-1-3.32-2.72-3.32A4,4,0,0,0,1666.37,1239.65Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1677.09,1243.53l-.95,4.8h-1.42l2.19-11.2h3.59c1.81,0,3,1,3,2.65,0,2.5-1.66,3.75-4.13,3.75ZM1682,1240a1.42,1.42,0,0,0-1.6-1.57h-2.34l-.72,3.71h2.45A2,2,0,0,0,1682,1240Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1686.18,1243.53l-1,4.8h-1.42l2.19-11.2h3.59c1.81,0,3,1,3,2.65,0,2.5-1.66,3.75-4.13,3.75Zm4.91-3.49a1.42,1.42,0,0,0-1.6-1.57h-2.34l-.72,3.71h2.45A2,2,0,0,0,1691.09,1240Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1693.77,1237.13h1.51l1.46,5,3.36-5h1.58l-4.46,6.37-1,4.83h-1.42l.94-4.83Z" fill="#59585b"/>
    </g>
  </g>
  <g id="C18b" className="suites__unit">
    <polygon points="1684.97 1302.6 1634.17 1359 1560.87 1292.9 1611.67 1236.6 1684.97 1302.6" fill="#dadada"/>
    <line x1="1689.07" y1="1303.7" x2="1611.27" y2="1233.6" fill="none" stroke="#c8b274" stroke-miterlimit="10" stroke-width="4"/>
    <g>
      <path d="M1619,1282.21a2.71,2.71,0,0,0-2.77-2c-1.72,0-3.18,1.21-3.18,3.81s1.46,3.81,3.18,3.81a2.71,2.71,0,0,0,2.77-2l1.88.48a4.4,4.4,0,0,1-4.65,3.4c-2.8,0-5.29-2-5.29-5.73s2.49-5.73,5.29-5.73a4.4,4.4,0,0,1,4.65,3.39Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1626.65,1278.47v11.2h-2v-8.39l-2.14,1.28v-2.17l3.28-1.92Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1635.9,1281.39a2.78,2.78,0,0,1-1.06,2.25,2.9,2.9,0,0,1,1.49,2.68,3.65,3.65,0,0,1-7.3,0,2.89,2.89,0,0,1,1.48-2.68,2.78,2.78,0,0,1-1-2.25,3.22,3.22,0,0,1,6.44,0Zm-4.85,4.93a1.63,1.63,0,1,0,1.63-1.61A1.53,1.53,0,0,0,1631.05,1286.32Zm.29-4.89a1.35,1.35,0,1,0,1.34-1.4A1.3,1.3,0,0,0,1631.34,1281.43Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1603.3,1295.47c2.6,0,4.42,1.47,4.42,4.46,0,3.75-2.56,6.74-6.39,6.74h-3.1l2.19-11.2Zm-1.73,1.34-1.65,8.52h1.43c3,0,4.85-2.33,4.85-5.25,0-2.2-1.15-3.27-3.2-3.27Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1614.33,1295.47l1.7,11.2h-1.43l-.4-3H1610l-1.58,3H1607l6-11.2Zm-3.6,6.86H1614l-.72-5Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1627.78,1295.47l-2.19,11.2h-1.42l1-5.28h-5l-1,5.28h-1.42l2.19-11.2h1.43l-.9,4.57h5l.9-4.57Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1632,1295.47l-1.92,9.86H1635l-.28,1.34h-6.35l2.19-11.2Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1640.33,1295.47l-2.2,11.2h-1.42l2.19-11.2Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1646.89,1295.47l1.7,11.2h-1.43l-.4-3h-4.18l-1.58,3h-1.42l6-11.2Zm-3.6,6.86h3.28l-.72-5Z" fill="#59585b"/>
    </g>
  </g>
</svg>

            </div>
        </div>
    )
}

export default FloorSixSevenEight;