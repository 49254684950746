export const initUserSettings = {
    firstName: '',
    lastName: '',
    postalCode: '',
    email: '',
    notes: '',
    livingStyle: '',
    numOfBeds: '',
    sqftRange: '',
    numOfBaths: '',
    hasDen: false,
    hasBalcony: false,
    hasEnsuite: false,
    hasWalkInCloset: false,
    isAccessible: false,
    hasVirtualTour: false,
};