
  import { useEffect, useState } from "react";
  import LoadingImage from '../assets/images/loading.jpg';
  
  const Loading = ({ loading, setLoading }) => {
    const [percentLoaded, setPercentLoaded] = useState(0);

    const classes = ['content__wrapper'];
    loading && classes.push('active');

    useEffect(() => {
        const loadingTime = 10000;
        setTimeout(() => setLoading(false), loadingTime);

        let loadingTimer = setTimeout(() => {
           setPercentLoaded(percentLoaded + 1);
        }, loadingTime / 100)
      

      return () => {
        clearTimeout(loadingTimer)
      }
    }, [percentLoaded]);

    return (
      <div className={classes.join(' ')} data-screen="loading">
        <img src={LoadingImage} alt="Loading..." />
        <div className="loadingBar">
          <span id="progress" style={{ width: `${percentLoaded}%` }} className="loadingBar__progress"></span>
        </div>
      </div>
    )
  }

  export default Loading;