export const sunStudies = [
    {
        "id": "SS_1_March_NorthEast_denoised_ocl",
        "key": "northspring",
        "items": [
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthEast_denoised_ocl/SS_1_March_NorthEast_denoised_ocl_00001.jpg",
                "key": "00001"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthEast_denoised_ocl/SS_1_March_NorthEast_denoised_ocl_00002.jpg",
                "key": "00002"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthEast_denoised_ocl/SS_1_March_NorthEast_denoised_ocl_00003.jpg",
                "key": "00003"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthEast_denoised_ocl/SS_1_March_NorthEast_denoised_ocl_00004.jpg",
                "key": "00004"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthEast_denoised_ocl/SS_1_March_NorthEast_denoised_ocl_00005.jpg",
                "key": "00005"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthEast_denoised_ocl/SS_1_March_NorthEast_denoised_ocl_00006.jpg",
                "key": "00006"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthEast_denoised_ocl/SS_1_March_NorthEast_denoised_ocl_00007.jpg",
                "key": "00007"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthEast_denoised_ocl/SS_1_March_NorthEast_denoised_ocl_00008.jpg",
                "key": "00008"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthEast_denoised_ocl/SS_1_March_NorthEast_denoised_ocl_00009.jpg",
                "key": "00009"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthEast_denoised_ocl/SS_1_March_NorthEast_denoised_ocl_00010.jpg",
                "key": "00010"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthEast_denoised_ocl/SS_1_March_NorthEast_denoised_ocl_00011.jpg",
                "key": "00011"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthEast_denoised_ocl/SS_1_March_NorthEast_denoised_ocl_00012.jpg",
                "key": "00012"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthEast_denoised_ocl/SS_1_March_NorthEast_denoised_ocl_00013.jpg",
                "key": "00013"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthEast_denoised_ocl/SS_1_March_NorthEast_denoised_ocl_00014.jpg",
                "key": "00014"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthEast_denoised_ocl/SS_1_March_NorthEast_denoised_ocl_00015.jpg",
                "key": "00015"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthEast_denoised_ocl/SS_1_March_NorthEast_denoised_ocl_00016.jpg",
                "key": "00016"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthEast_denoised_ocl/SS_1_March_NorthEast_denoised_ocl_00017.jpg",
                "key": "00017"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthEast_denoised_ocl/SS_1_March_NorthEast_denoised_ocl_00018.jpg",
                "key": "00018"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthEast_denoised_ocl/SS_1_March_NorthEast_denoised_ocl_00019.jpg",
                "key": "00019"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthEast_denoised_ocl/SS_1_March_NorthEast_denoised_ocl_00020.jpg",
                "key": "00020"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthEast_denoised_ocl/SS_1_March_NorthEast_denoised_ocl_00021.jpg",
                "key": "00021"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthEast_denoised_ocl/SS_1_March_NorthEast_denoised_ocl_00022.jpg",
                "key": "00022"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthEast_denoised_ocl/SS_1_March_NorthEast_denoised_ocl_00023.jpg",
                "key": "00023"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthEast_denoised_ocl/SS_1_March_NorthEast_denoised_ocl_00024.jpg",
                "key": "00024"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthEast_denoised_ocl/SS_1_March_NorthEast_denoised_ocl_00025.jpg",
                "key": "00025"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthEast_denoised_ocl/SS_1_March_NorthEast_denoised_ocl_00026.jpg",
                "key": "00026"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthEast_denoised_ocl/SS_1_March_NorthEast_denoised_ocl_00027.jpg",
                "key": "00027"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthEast_denoised_ocl/SS_1_March_NorthEast_denoised_ocl_00028.jpg",
                "key": "00028"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthEast_denoised_ocl/SS_1_March_NorthEast_denoised_ocl_00029.jpg",
                "key": "00029"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthEast_denoised_ocl/SS_1_March_NorthEast_denoised_ocl_00030.jpg",
                "key": "00030"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthEast_denoised_ocl/SS_1_March_NorthEast_denoised_ocl_00031.jpg",
                "key": "00031"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthEast_denoised_ocl/SS_1_March_NorthEast_denoised_ocl_00032.jpg",
                "key": "00032"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthEast_denoised_ocl/SS_1_March_NorthEast_denoised_ocl_00033.jpg",
                "key": "00033"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthEast_denoised_ocl/SS_1_March_NorthEast_denoised_ocl_00034.jpg",
                "key": "00034"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthEast_denoised_ocl/SS_1_March_NorthEast_denoised_ocl_00035.jpg",
                "key": "00035"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthEast_denoised_ocl/SS_1_March_NorthEast_denoised_ocl_00036.jpg",
                "key": "00036"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthEast_denoised_ocl/SS_1_March_NorthEast_denoised_ocl_00037.jpg",
                "key": "00037"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthEast_denoised_ocl/SS_1_March_NorthEast_denoised_ocl_00038.jpg",
                "key": "00038"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthEast_denoised_ocl/SS_1_March_NorthEast_denoised_ocl_00039.jpg",
                "key": "00039"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthEast_denoised_ocl/SS_1_March_NorthEast_denoised_ocl_00040.jpg",
                "key": "00040"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthEast_denoised_ocl/SS_1_March_NorthEast_denoised_ocl_00041.jpg",
                "key": "00041"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthEast_denoised_ocl/SS_1_March_NorthEast_denoised_ocl_00042.jpg",
                "key": "00042"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthEast_denoised_ocl/SS_1_March_NorthEast_denoised_ocl_00043.jpg",
                "key": "00043"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthEast_denoised_ocl/SS_1_March_NorthEast_denoised_ocl_00044.jpg",
                "key": "00044"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthEast_denoised_ocl/SS_1_March_NorthEast_denoised_ocl_00045.jpg",
                "key": "00045"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthEast_denoised_ocl/SS_1_March_NorthEast_denoised_ocl_00046.jpg",
                "key": "00046"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthEast_denoised_ocl/SS_1_March_NorthEast_denoised_ocl_00047.jpg",
                "key": "00047"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthEast_denoised_ocl/SS_1_March_NorthEast_denoised_ocl_00048.jpg",
                "key": "00048"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthEast_denoised_ocl/SS_1_March_NorthEast_denoised_ocl_00049.jpg",
                "key": "00049"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthEast_denoised_ocl/SS_1_March_NorthEast_denoised_ocl_00050.jpg",
                "key": "00050"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthEast_denoised_ocl/SS_1_March_NorthEast_denoised_ocl_00051.jpg",
                "key": "00051"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthEast_denoised_ocl/SS_1_March_NorthEast_denoised_ocl_00052.jpg",
                "key": "00052"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthEast_denoised_ocl/SS_1_March_NorthEast_denoised_ocl_00053.jpg",
                "key": "00053"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthEast_denoised_ocl/SS_1_March_NorthEast_denoised_ocl_00054.jpg",
                "key": "00054"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthEast_denoised_ocl/SS_1_March_NorthEast_denoised_ocl_00055.jpg",
                "key": "00055"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthEast_denoised_ocl/SS_1_March_NorthEast_denoised_ocl_00056.jpg",
                "key": "00056"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthEast_denoised_ocl/SS_1_March_NorthEast_denoised_ocl_00057.jpg",
                "key": "00057"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthEast_denoised_ocl/SS_1_March_NorthEast_denoised_ocl_00058.jpg",
                "key": "00058"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthEast_denoised_ocl/SS_1_March_NorthEast_denoised_ocl_00059.jpg",
                "key": "00059"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthEast_denoised_ocl/SS_1_March_NorthEast_denoised_ocl_00060.jpg",
                "key": "00060"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthEast_denoised_ocl/SS_1_March_NorthEast_denoised_ocl_00061.jpg",
                "key": "00061"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthEast_denoised_ocl/SS_1_March_NorthEast_denoised_ocl_00062.jpg",
                "key": "00062"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthEast_denoised_ocl/SS_1_March_NorthEast_denoised_ocl_00063.jpg",
                "key": "00063"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthEast_denoised_ocl/SS_1_March_NorthEast_denoised_ocl_00064.jpg",
                "key": "00064"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthEast_denoised_ocl/SS_1_March_NorthEast_denoised_ocl_00065.jpg",
                "key": "00065"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthEast_denoised_ocl/SS_1_March_NorthEast_denoised_ocl_00066.jpg",
                "key": "00066"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthEast_denoised_ocl/SS_1_March_NorthEast_denoised_ocl_00067.jpg",
                "key": "00067"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthEast_denoised_ocl/SS_1_March_NorthEast_denoised_ocl_00068.jpg",
                "key": "00068"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthEast_denoised_ocl/SS_1_March_NorthEast_denoised_ocl_00069.jpg",
                "key": "00069"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthEast_denoised_ocl/SS_1_March_NorthEast_denoised_ocl_00070.jpg",
                "key": "00070"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthEast_denoised_ocl/SS_1_March_NorthEast_denoised_ocl_00071.jpg",
                "key": "00071"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthEast_denoised_ocl/SS_1_March_NorthEast_denoised_ocl_00072.jpg",
                "key": "00072"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthEast_denoised_ocl/SS_1_March_NorthEast_denoised_ocl_00073.jpg",
                "key": "00073"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthEast_denoised_ocl/SS_1_March_NorthEast_denoised_ocl_00074.jpg",
                "key": "00074"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthEast_denoised_ocl/SS_1_March_NorthEast_denoised_ocl_00075.jpg",
                "key": "00075"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthEast_denoised_ocl/SS_1_March_NorthEast_denoised_ocl_00076.jpg",
                "key": "00076"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthEast_denoised_ocl/SS_1_March_NorthEast_denoised_ocl_00077.jpg",
                "key": "00077"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthEast_denoised_ocl/SS_1_March_NorthEast_denoised_ocl_00078.jpg",
                "key": "00078"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthEast_denoised_ocl/SS_1_March_NorthEast_denoised_ocl_00079.jpg",
                "key": "00079"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthEast_denoised_ocl/SS_1_March_NorthEast_denoised_ocl_00080.jpg",
                "key": "00080"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthEast_denoised_ocl/SS_1_March_NorthEast_denoised_ocl_00081.jpg",
                "key": "00081"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthEast_denoised_ocl/SS_1_March_NorthEast_denoised_ocl_00082.jpg",
                "key": "00082"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthEast_denoised_ocl/SS_1_March_NorthEast_denoised_ocl_00083.jpg",
                "key": "00083"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthEast_denoised_ocl/SS_1_March_NorthEast_denoised_ocl_00084.jpg",
                "key": "00084"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthEast_denoised_ocl/SS_1_March_NorthEast_denoised_ocl_00085.jpg",
                "key": "00085"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthEast_denoised_ocl/SS_1_March_NorthEast_denoised_ocl_00086.jpg",
                "key": "00086"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthEast_denoised_ocl/SS_1_March_NorthEast_denoised_ocl_00087.jpg",
                "key": "00087"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthEast_denoised_ocl/SS_1_March_NorthEast_denoised_ocl_00088.jpg",
                "key": "00088"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthEast_denoised_ocl/SS_1_March_NorthEast_denoised_ocl_00089.jpg",
                "key": "00089"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthEast_denoised_ocl/SS_1_March_NorthEast_denoised_ocl_00090.jpg",
                "key": "00090"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthEast_denoised_ocl/SS_1_March_NorthEast_denoised_ocl_00091.jpg",
                "key": "00091"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthEast_denoised_ocl/SS_1_March_NorthEast_denoised_ocl_00092.jpg",
                "key": "00092"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthEast_denoised_ocl/SS_1_March_NorthEast_denoised_ocl_00093.jpg",
                "key": "00093"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthEast_denoised_ocl/SS_1_March_NorthEast_denoised_ocl_00094.jpg",
                "key": "00094"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthEast_denoised_ocl/SS_1_March_NorthEast_denoised_ocl_00095.jpg",
                "key": "00095"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthEast_denoised_ocl/SS_1_March_NorthEast_denoised_ocl_00096.jpg",
                "key": "00096"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthEast_denoised_ocl/SS_1_March_NorthEast_denoised_ocl_00097.jpg",
                "key": "00097"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthEast_denoised_ocl/SS_1_March_NorthEast_denoised_ocl_00098.jpg",
                "key": "00098"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthEast_denoised_ocl/SS_1_March_NorthEast_denoised_ocl_00099.jpg",
                "key": "00099"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthEast_denoised_ocl/SS_1_March_NorthEast_denoised_ocl_00100.jpg",
                "key": "00100"
            }
        ]
    },
    {
        "id": "SS_1_March_NorthWest_denoised_ocl",
        "key": "westspring",
        "items": [
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthWest_denoised_ocl/SS_1_March_NorthWest_denoised_ocl_00001.jpg",
                "key": "00001"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthWest_denoised_ocl/SS_1_March_NorthWest_denoised_ocl_00002.jpg",
                "key": "00002"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthWest_denoised_ocl/SS_1_March_NorthWest_denoised_ocl_00003.jpg",
                "key": "00003"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthWest_denoised_ocl/SS_1_March_NorthWest_denoised_ocl_00004.jpg",
                "key": "00004"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthWest_denoised_ocl/SS_1_March_NorthWest_denoised_ocl_00005.jpg",
                "key": "00005"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthWest_denoised_ocl/SS_1_March_NorthWest_denoised_ocl_00006.jpg",
                "key": "00006"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthWest_denoised_ocl/SS_1_March_NorthWest_denoised_ocl_00007.jpg",
                "key": "00007"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthWest_denoised_ocl/SS_1_March_NorthWest_denoised_ocl_00008.jpg",
                "key": "00008"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthWest_denoised_ocl/SS_1_March_NorthWest_denoised_ocl_00009.jpg",
                "key": "00009"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthWest_denoised_ocl/SS_1_March_NorthWest_denoised_ocl_00010.jpg",
                "key": "00010"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthWest_denoised_ocl/SS_1_March_NorthWest_denoised_ocl_00011.jpg",
                "key": "00011"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthWest_denoised_ocl/SS_1_March_NorthWest_denoised_ocl_00012.jpg",
                "key": "00012"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthWest_denoised_ocl/SS_1_March_NorthWest_denoised_ocl_00013.jpg",
                "key": "00013"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthWest_denoised_ocl/SS_1_March_NorthWest_denoised_ocl_00014.jpg",
                "key": "00014"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthWest_denoised_ocl/SS_1_March_NorthWest_denoised_ocl_00015.jpg",
                "key": "00015"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthWest_denoised_ocl/SS_1_March_NorthWest_denoised_ocl_00016.jpg",
                "key": "00016"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthWest_denoised_ocl/SS_1_March_NorthWest_denoised_ocl_00017.jpg",
                "key": "00017"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthWest_denoised_ocl/SS_1_March_NorthWest_denoised_ocl_00018.jpg",
                "key": "00018"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthWest_denoised_ocl/SS_1_March_NorthWest_denoised_ocl_00019.jpg",
                "key": "00019"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthWest_denoised_ocl/SS_1_March_NorthWest_denoised_ocl_00020.jpg",
                "key": "00020"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthWest_denoised_ocl/SS_1_March_NorthWest_denoised_ocl_00021.jpg",
                "key": "00021"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthWest_denoised_ocl/SS_1_March_NorthWest_denoised_ocl_00022.jpg",
                "key": "00022"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthWest_denoised_ocl/SS_1_March_NorthWest_denoised_ocl_00023.jpg",
                "key": "00023"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthWest_denoised_ocl/SS_1_March_NorthWest_denoised_ocl_00024.jpg",
                "key": "00024"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthWest_denoised_ocl/SS_1_March_NorthWest_denoised_ocl_00025.jpg",
                "key": "00025"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthWest_denoised_ocl/SS_1_March_NorthWest_denoised_ocl_00026.jpg",
                "key": "00026"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthWest_denoised_ocl/SS_1_March_NorthWest_denoised_ocl_00027.jpg",
                "key": "00027"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthWest_denoised_ocl/SS_1_March_NorthWest_denoised_ocl_00028.jpg",
                "key": "00028"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthWest_denoised_ocl/SS_1_March_NorthWest_denoised_ocl_00029.jpg",
                "key": "00029"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthWest_denoised_ocl/SS_1_March_NorthWest_denoised_ocl_00030.jpg",
                "key": "00030"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthWest_denoised_ocl/SS_1_March_NorthWest_denoised_ocl_00031.jpg",
                "key": "00031"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthWest_denoised_ocl/SS_1_March_NorthWest_denoised_ocl_00032.jpg",
                "key": "00032"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthWest_denoised_ocl/SS_1_March_NorthWest_denoised_ocl_00033.jpg",
                "key": "00033"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthWest_denoised_ocl/SS_1_March_NorthWest_denoised_ocl_00034.jpg",
                "key": "00034"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthWest_denoised_ocl/SS_1_March_NorthWest_denoised_ocl_00035.jpg",
                "key": "00035"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthWest_denoised_ocl/SS_1_March_NorthWest_denoised_ocl_00036.jpg",
                "key": "00036"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthWest_denoised_ocl/SS_1_March_NorthWest_denoised_ocl_00037.jpg",
                "key": "00037"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthWest_denoised_ocl/SS_1_March_NorthWest_denoised_ocl_00038.jpg",
                "key": "00038"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthWest_denoised_ocl/SS_1_March_NorthWest_denoised_ocl_00039.jpg",
                "key": "00039"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthWest_denoised_ocl/SS_1_March_NorthWest_denoised_ocl_00040.jpg",
                "key": "00040"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthWest_denoised_ocl/SS_1_March_NorthWest_denoised_ocl_00041.jpg",
                "key": "00041"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthWest_denoised_ocl/SS_1_March_NorthWest_denoised_ocl_00042.jpg",
                "key": "00042"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthWest_denoised_ocl/SS_1_March_NorthWest_denoised_ocl_00043.jpg",
                "key": "00043"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthWest_denoised_ocl/SS_1_March_NorthWest_denoised_ocl_00044.jpg",
                "key": "00044"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthWest_denoised_ocl/SS_1_March_NorthWest_denoised_ocl_00045.jpg",
                "key": "00045"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthWest_denoised_ocl/SS_1_March_NorthWest_denoised_ocl_00046.jpg",
                "key": "00046"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthWest_denoised_ocl/SS_1_March_NorthWest_denoised_ocl_00047.jpg",
                "key": "00047"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthWest_denoised_ocl/SS_1_March_NorthWest_denoised_ocl_00048.jpg",
                "key": "00048"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthWest_denoised_ocl/SS_1_March_NorthWest_denoised_ocl_00049.jpg",
                "key": "00049"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthWest_denoised_ocl/SS_1_March_NorthWest_denoised_ocl_00050.jpg",
                "key": "00050"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthWest_denoised_ocl/SS_1_March_NorthWest_denoised_ocl_00051.jpg",
                "key": "00051"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthWest_denoised_ocl/SS_1_March_NorthWest_denoised_ocl_00052.jpg",
                "key": "00052"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthWest_denoised_ocl/SS_1_March_NorthWest_denoised_ocl_00053.jpg",
                "key": "00053"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthWest_denoised_ocl/SS_1_March_NorthWest_denoised_ocl_00054.jpg",
                "key": "00054"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthWest_denoised_ocl/SS_1_March_NorthWest_denoised_ocl_00055.jpg",
                "key": "00055"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthWest_denoised_ocl/SS_1_March_NorthWest_denoised_ocl_00056.jpg",
                "key": "00056"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthWest_denoised_ocl/SS_1_March_NorthWest_denoised_ocl_00057.jpg",
                "key": "00057"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthWest_denoised_ocl/SS_1_March_NorthWest_denoised_ocl_00058.jpg",
                "key": "00058"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthWest_denoised_ocl/SS_1_March_NorthWest_denoised_ocl_00059.jpg",
                "key": "00059"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthWest_denoised_ocl/SS_1_March_NorthWest_denoised_ocl_00060.jpg",
                "key": "00060"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthWest_denoised_ocl/SS_1_March_NorthWest_denoised_ocl_00061.jpg",
                "key": "00061"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthWest_denoised_ocl/SS_1_March_NorthWest_denoised_ocl_00062.jpg",
                "key": "00062"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthWest_denoised_ocl/SS_1_March_NorthWest_denoised_ocl_00063.jpg",
                "key": "00063"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthWest_denoised_ocl/SS_1_March_NorthWest_denoised_ocl_00064.jpg",
                "key": "00064"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthWest_denoised_ocl/SS_1_March_NorthWest_denoised_ocl_00065.jpg",
                "key": "00065"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthWest_denoised_ocl/SS_1_March_NorthWest_denoised_ocl_00066.jpg",
                "key": "00066"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthWest_denoised_ocl/SS_1_March_NorthWest_denoised_ocl_00067.jpg",
                "key": "00067"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthWest_denoised_ocl/SS_1_March_NorthWest_denoised_ocl_00068.jpg",
                "key": "00068"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthWest_denoised_ocl/SS_1_March_NorthWest_denoised_ocl_00069.jpg",
                "key": "00069"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthWest_denoised_ocl/SS_1_March_NorthWest_denoised_ocl_00070.jpg",
                "key": "00070"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthWest_denoised_ocl/SS_1_March_NorthWest_denoised_ocl_00071.jpg",
                "key": "00071"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthWest_denoised_ocl/SS_1_March_NorthWest_denoised_ocl_00072.jpg",
                "key": "00072"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthWest_denoised_ocl/SS_1_March_NorthWest_denoised_ocl_00073.jpg",
                "key": "00073"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthWest_denoised_ocl/SS_1_March_NorthWest_denoised_ocl_00074.jpg",
                "key": "00074"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthWest_denoised_ocl/SS_1_March_NorthWest_denoised_ocl_00075.jpg",
                "key": "00075"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthWest_denoised_ocl/SS_1_March_NorthWest_denoised_ocl_00076.jpg",
                "key": "00076"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthWest_denoised_ocl/SS_1_March_NorthWest_denoised_ocl_00077.jpg",
                "key": "00077"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthWest_denoised_ocl/SS_1_March_NorthWest_denoised_ocl_00078.jpg",
                "key": "00078"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthWest_denoised_ocl/SS_1_March_NorthWest_denoised_ocl_00079.jpg",
                "key": "00079"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthWest_denoised_ocl/SS_1_March_NorthWest_denoised_ocl_00080.jpg",
                "key": "00080"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthWest_denoised_ocl/SS_1_March_NorthWest_denoised_ocl_00081.jpg",
                "key": "00081"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthWest_denoised_ocl/SS_1_March_NorthWest_denoised_ocl_00082.jpg",
                "key": "00082"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthWest_denoised_ocl/SS_1_March_NorthWest_denoised_ocl_00083.jpg",
                "key": "00083"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthWest_denoised_ocl/SS_1_March_NorthWest_denoised_ocl_00084.jpg",
                "key": "00084"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthWest_denoised_ocl/SS_1_March_NorthWest_denoised_ocl_00085.jpg",
                "key": "00085"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthWest_denoised_ocl/SS_1_March_NorthWest_denoised_ocl_00086.jpg",
                "key": "00086"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthWest_denoised_ocl/SS_1_March_NorthWest_denoised_ocl_00087.jpg",
                "key": "00087"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthWest_denoised_ocl/SS_1_March_NorthWest_denoised_ocl_00088.jpg",
                "key": "00088"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthWest_denoised_ocl/SS_1_March_NorthWest_denoised_ocl_00089.jpg",
                "key": "00089"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthWest_denoised_ocl/SS_1_March_NorthWest_denoised_ocl_00090.jpg",
                "key": "00090"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthWest_denoised_ocl/SS_1_March_NorthWest_denoised_ocl_00091.jpg",
                "key": "00091"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthWest_denoised_ocl/SS_1_March_NorthWest_denoised_ocl_00092.jpg",
                "key": "00092"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthWest_denoised_ocl/SS_1_March_NorthWest_denoised_ocl_00093.jpg",
                "key": "00093"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthWest_denoised_ocl/SS_1_March_NorthWest_denoised_ocl_00094.jpg",
                "key": "00094"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthWest_denoised_ocl/SS_1_March_NorthWest_denoised_ocl_00095.jpg",
                "key": "00095"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthWest_denoised_ocl/SS_1_March_NorthWest_denoised_ocl_00096.jpg",
                "key": "00096"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthWest_denoised_ocl/SS_1_March_NorthWest_denoised_ocl_00097.jpg",
                "key": "00097"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthWest_denoised_ocl/SS_1_March_NorthWest_denoised_ocl_00098.jpg",
                "key": "00098"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthWest_denoised_ocl/SS_1_March_NorthWest_denoised_ocl_00099.jpg",
                "key": "00099"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_NorthWest_denoised_ocl/SS_1_March_NorthWest_denoised_ocl_00100.jpg",
                "key": "00100"
            }
        ]
    },
    {
        "id": "SS_1_March_SouthEast_denoised_ocl",
        "key": "eastspring",
        "items": [
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthEast_denoised_ocl/SS_1_March_SouthEast_denoised_ocl_00001.jpg",
                "key": "00001"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthEast_denoised_ocl/SS_1_March_SouthEast_denoised_ocl_00002.jpg",
                "key": "00002"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthEast_denoised_ocl/SS_1_March_SouthEast_denoised_ocl_00003.jpg",
                "key": "00003"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthEast_denoised_ocl/SS_1_March_SouthEast_denoised_ocl_00004.jpg",
                "key": "00004"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthEast_denoised_ocl/SS_1_March_SouthEast_denoised_ocl_00005.jpg",
                "key": "00005"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthEast_denoised_ocl/SS_1_March_SouthEast_denoised_ocl_00006.jpg",
                "key": "00006"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthEast_denoised_ocl/SS_1_March_SouthEast_denoised_ocl_00007.jpg",
                "key": "00007"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthEast_denoised_ocl/SS_1_March_SouthEast_denoised_ocl_00008.jpg",
                "key": "00008"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthEast_denoised_ocl/SS_1_March_SouthEast_denoised_ocl_00009.jpg",
                "key": "00009"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthEast_denoised_ocl/SS_1_March_SouthEast_denoised_ocl_00010.jpg",
                "key": "00010"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthEast_denoised_ocl/SS_1_March_SouthEast_denoised_ocl_00011.jpg",
                "key": "00011"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthEast_denoised_ocl/SS_1_March_SouthEast_denoised_ocl_00012.jpg",
                "key": "00012"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthEast_denoised_ocl/SS_1_March_SouthEast_denoised_ocl_00013.jpg",
                "key": "00013"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthEast_denoised_ocl/SS_1_March_SouthEast_denoised_ocl_00014.jpg",
                "key": "00014"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthEast_denoised_ocl/SS_1_March_SouthEast_denoised_ocl_00015.jpg",
                "key": "00015"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthEast_denoised_ocl/SS_1_March_SouthEast_denoised_ocl_00016.jpg",
                "key": "00016"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthEast_denoised_ocl/SS_1_March_SouthEast_denoised_ocl_00017.jpg",
                "key": "00017"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthEast_denoised_ocl/SS_1_March_SouthEast_denoised_ocl_00018.jpg",
                "key": "00018"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthEast_denoised_ocl/SS_1_March_SouthEast_denoised_ocl_00019.jpg",
                "key": "00019"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthEast_denoised_ocl/SS_1_March_SouthEast_denoised_ocl_00020.jpg",
                "key": "00020"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthEast_denoised_ocl/SS_1_March_SouthEast_denoised_ocl_00021.jpg",
                "key": "00021"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthEast_denoised_ocl/SS_1_March_SouthEast_denoised_ocl_00022.jpg",
                "key": "00022"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthEast_denoised_ocl/SS_1_March_SouthEast_denoised_ocl_00023.jpg",
                "key": "00023"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthEast_denoised_ocl/SS_1_March_SouthEast_denoised_ocl_00024.jpg",
                "key": "00024"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthEast_denoised_ocl/SS_1_March_SouthEast_denoised_ocl_00025.jpg",
                "key": "00025"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthEast_denoised_ocl/SS_1_March_SouthEast_denoised_ocl_00026.jpg",
                "key": "00026"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthEast_denoised_ocl/SS_1_March_SouthEast_denoised_ocl_00027.jpg",
                "key": "00027"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthEast_denoised_ocl/SS_1_March_SouthEast_denoised_ocl_00028.jpg",
                "key": "00028"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthEast_denoised_ocl/SS_1_March_SouthEast_denoised_ocl_00029.jpg",
                "key": "00029"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthEast_denoised_ocl/SS_1_March_SouthEast_denoised_ocl_00030.jpg",
                "key": "00030"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthEast_denoised_ocl/SS_1_March_SouthEast_denoised_ocl_00031.jpg",
                "key": "00031"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthEast_denoised_ocl/SS_1_March_SouthEast_denoised_ocl_00032.jpg",
                "key": "00032"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthEast_denoised_ocl/SS_1_March_SouthEast_denoised_ocl_00033.jpg",
                "key": "00033"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthEast_denoised_ocl/SS_1_March_SouthEast_denoised_ocl_00034.jpg",
                "key": "00034"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthEast_denoised_ocl/SS_1_March_SouthEast_denoised_ocl_00035.jpg",
                "key": "00035"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthEast_denoised_ocl/SS_1_March_SouthEast_denoised_ocl_00036.jpg",
                "key": "00036"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthEast_denoised_ocl/SS_1_March_SouthEast_denoised_ocl_00037.jpg",
                "key": "00037"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthEast_denoised_ocl/SS_1_March_SouthEast_denoised_ocl_00038.jpg",
                "key": "00038"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthEast_denoised_ocl/SS_1_March_SouthEast_denoised_ocl_00039.jpg",
                "key": "00039"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthEast_denoised_ocl/SS_1_March_SouthEast_denoised_ocl_00040.jpg",
                "key": "00040"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthEast_denoised_ocl/SS_1_March_SouthEast_denoised_ocl_00041.jpg",
                "key": "00041"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthEast_denoised_ocl/SS_1_March_SouthEast_denoised_ocl_00042.jpg",
                "key": "00042"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthEast_denoised_ocl/SS_1_March_SouthEast_denoised_ocl_00043.jpg",
                "key": "00043"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthEast_denoised_ocl/SS_1_March_SouthEast_denoised_ocl_00044.jpg",
                "key": "00044"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthEast_denoised_ocl/SS_1_March_SouthEast_denoised_ocl_00045.jpg",
                "key": "00045"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthEast_denoised_ocl/SS_1_March_SouthEast_denoised_ocl_00046.jpg",
                "key": "00046"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthEast_denoised_ocl/SS_1_March_SouthEast_denoised_ocl_00047.jpg",
                "key": "00047"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthEast_denoised_ocl/SS_1_March_SouthEast_denoised_ocl_00048.jpg",
                "key": "00048"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthEast_denoised_ocl/SS_1_March_SouthEast_denoised_ocl_00049.jpg",
                "key": "00049"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthEast_denoised_ocl/SS_1_March_SouthEast_denoised_ocl_00050.jpg",
                "key": "00050"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthEast_denoised_ocl/SS_1_March_SouthEast_denoised_ocl_00051.jpg",
                "key": "00051"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthEast_denoised_ocl/SS_1_March_SouthEast_denoised_ocl_00052.jpg",
                "key": "00052"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthEast_denoised_ocl/SS_1_March_SouthEast_denoised_ocl_00053.jpg",
                "key": "00053"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthEast_denoised_ocl/SS_1_March_SouthEast_denoised_ocl_00054.jpg",
                "key": "00054"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthEast_denoised_ocl/SS_1_March_SouthEast_denoised_ocl_00055.jpg",
                "key": "00055"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthEast_denoised_ocl/SS_1_March_SouthEast_denoised_ocl_00056.jpg",
                "key": "00056"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthEast_denoised_ocl/SS_1_March_SouthEast_denoised_ocl_00057.jpg",
                "key": "00057"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthEast_denoised_ocl/SS_1_March_SouthEast_denoised_ocl_00058.jpg",
                "key": "00058"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthEast_denoised_ocl/SS_1_March_SouthEast_denoised_ocl_00059.jpg",
                "key": "00059"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthEast_denoised_ocl/SS_1_March_SouthEast_denoised_ocl_00060.jpg",
                "key": "00060"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthEast_denoised_ocl/SS_1_March_SouthEast_denoised_ocl_00061.jpg",
                "key": "00061"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthEast_denoised_ocl/SS_1_March_SouthEast_denoised_ocl_00062.jpg",
                "key": "00062"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthEast_denoised_ocl/SS_1_March_SouthEast_denoised_ocl_00063.jpg",
                "key": "00063"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthEast_denoised_ocl/SS_1_March_SouthEast_denoised_ocl_00064.jpg",
                "key": "00064"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthEast_denoised_ocl/SS_1_March_SouthEast_denoised_ocl_00065.jpg",
                "key": "00065"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthEast_denoised_ocl/SS_1_March_SouthEast_denoised_ocl_00066.jpg",
                "key": "00066"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthEast_denoised_ocl/SS_1_March_SouthEast_denoised_ocl_00067.jpg",
                "key": "00067"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthEast_denoised_ocl/SS_1_March_SouthEast_denoised_ocl_00068.jpg",
                "key": "00068"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthEast_denoised_ocl/SS_1_March_SouthEast_denoised_ocl_00069.jpg",
                "key": "00069"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthEast_denoised_ocl/SS_1_March_SouthEast_denoised_ocl_00070.jpg",
                "key": "00070"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthEast_denoised_ocl/SS_1_March_SouthEast_denoised_ocl_00071.jpg",
                "key": "00071"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthEast_denoised_ocl/SS_1_March_SouthEast_denoised_ocl_00072.jpg",
                "key": "00072"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthEast_denoised_ocl/SS_1_March_SouthEast_denoised_ocl_00073.jpg",
                "key": "00073"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthEast_denoised_ocl/SS_1_March_SouthEast_denoised_ocl_00074.jpg",
                "key": "00074"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthEast_denoised_ocl/SS_1_March_SouthEast_denoised_ocl_00075.jpg",
                "key": "00075"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthEast_denoised_ocl/SS_1_March_SouthEast_denoised_ocl_00076.jpg",
                "key": "00076"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthEast_denoised_ocl/SS_1_March_SouthEast_denoised_ocl_00077.jpg",
                "key": "00077"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthEast_denoised_ocl/SS_1_March_SouthEast_denoised_ocl_00078.jpg",
                "key": "00078"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthEast_denoised_ocl/SS_1_March_SouthEast_denoised_ocl_00079.jpg",
                "key": "00079"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthEast_denoised_ocl/SS_1_March_SouthEast_denoised_ocl_00080.jpg",
                "key": "00080"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthEast_denoised_ocl/SS_1_March_SouthEast_denoised_ocl_00081.jpg",
                "key": "00081"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthEast_denoised_ocl/SS_1_March_SouthEast_denoised_ocl_00082.jpg",
                "key": "00082"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthEast_denoised_ocl/SS_1_March_SouthEast_denoised_ocl_00083.jpg",
                "key": "00083"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthEast_denoised_ocl/SS_1_March_SouthEast_denoised_ocl_00084.jpg",
                "key": "00084"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthEast_denoised_ocl/SS_1_March_SouthEast_denoised_ocl_00085.jpg",
                "key": "00085"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthEast_denoised_ocl/SS_1_March_SouthEast_denoised_ocl_00086.jpg",
                "key": "00086"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthEast_denoised_ocl/SS_1_March_SouthEast_denoised_ocl_00087.jpg",
                "key": "00087"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthEast_denoised_ocl/SS_1_March_SouthEast_denoised_ocl_00088.jpg",
                "key": "00088"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthEast_denoised_ocl/SS_1_March_SouthEast_denoised_ocl_00089.jpg",
                "key": "00089"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthEast_denoised_ocl/SS_1_March_SouthEast_denoised_ocl_00090.jpg",
                "key": "00090"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthEast_denoised_ocl/SS_1_March_SouthEast_denoised_ocl_00091.jpg",
                "key": "00091"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthEast_denoised_ocl/SS_1_March_SouthEast_denoised_ocl_00092.jpg",
                "key": "00092"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthEast_denoised_ocl/SS_1_March_SouthEast_denoised_ocl_00093.jpg",
                "key": "00093"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthEast_denoised_ocl/SS_1_March_SouthEast_denoised_ocl_00094.jpg",
                "key": "00094"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthEast_denoised_ocl/SS_1_March_SouthEast_denoised_ocl_00095.jpg",
                "key": "00095"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthEast_denoised_ocl/SS_1_March_SouthEast_denoised_ocl_00096.jpg",
                "key": "00096"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthEast_denoised_ocl/SS_1_March_SouthEast_denoised_ocl_00097.jpg",
                "key": "00097"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthEast_denoised_ocl/SS_1_March_SouthEast_denoised_ocl_00098.jpg",
                "key": "00098"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthEast_denoised_ocl/SS_1_March_SouthEast_denoised_ocl_00099.jpg",
                "key": "00099"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthEast_denoised_ocl/SS_1_March_SouthEast_denoised_ocl_00100.jpg",
                "key": "00100"
            }
        ]
    },
    {
        "id": "SS_1_March_SouthWest_denoised_ocl",
        "key": "southspring",
        "items": [
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthWest_denoised_ocl/SS_1_March_SouthWest_denoised_ocl_00001.jpg",
                "key": "00001"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthWest_denoised_ocl/SS_1_March_SouthWest_denoised_ocl_00002.jpg",
                "key": "00002"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthWest_denoised_ocl/SS_1_March_SouthWest_denoised_ocl_00003.jpg",
                "key": "00003"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthWest_denoised_ocl/SS_1_March_SouthWest_denoised_ocl_00004.jpg",
                "key": "00004"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthWest_denoised_ocl/SS_1_March_SouthWest_denoised_ocl_00005.jpg",
                "key": "00005"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthWest_denoised_ocl/SS_1_March_SouthWest_denoised_ocl_00006.jpg",
                "key": "00006"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthWest_denoised_ocl/SS_1_March_SouthWest_denoised_ocl_00007.jpg",
                "key": "00007"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthWest_denoised_ocl/SS_1_March_SouthWest_denoised_ocl_00008.jpg",
                "key": "00008"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthWest_denoised_ocl/SS_1_March_SouthWest_denoised_ocl_00009.jpg",
                "key": "00009"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthWest_denoised_ocl/SS_1_March_SouthWest_denoised_ocl_00010.jpg",
                "key": "00010"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthWest_denoised_ocl/SS_1_March_SouthWest_denoised_ocl_00011.jpg",
                "key": "00011"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthWest_denoised_ocl/SS_1_March_SouthWest_denoised_ocl_00012.jpg",
                "key": "00012"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthWest_denoised_ocl/SS_1_March_SouthWest_denoised_ocl_00013.jpg",
                "key": "00013"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthWest_denoised_ocl/SS_1_March_SouthWest_denoised_ocl_00014.jpg",
                "key": "00014"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthWest_denoised_ocl/SS_1_March_SouthWest_denoised_ocl_00015.jpg",
                "key": "00015"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthWest_denoised_ocl/SS_1_March_SouthWest_denoised_ocl_00016.jpg",
                "key": "00016"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthWest_denoised_ocl/SS_1_March_SouthWest_denoised_ocl_00017.jpg",
                "key": "00017"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthWest_denoised_ocl/SS_1_March_SouthWest_denoised_ocl_00018.jpg",
                "key": "00018"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthWest_denoised_ocl/SS_1_March_SouthWest_denoised_ocl_00019.jpg",
                "key": "00019"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthWest_denoised_ocl/SS_1_March_SouthWest_denoised_ocl_00020.jpg",
                "key": "00020"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthWest_denoised_ocl/SS_1_March_SouthWest_denoised_ocl_00021.jpg",
                "key": "00021"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthWest_denoised_ocl/SS_1_March_SouthWest_denoised_ocl_00022.jpg",
                "key": "00022"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthWest_denoised_ocl/SS_1_March_SouthWest_denoised_ocl_00023.jpg",
                "key": "00023"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthWest_denoised_ocl/SS_1_March_SouthWest_denoised_ocl_00024.jpg",
                "key": "00024"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthWest_denoised_ocl/SS_1_March_SouthWest_denoised_ocl_00025.jpg",
                "key": "00025"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthWest_denoised_ocl/SS_1_March_SouthWest_denoised_ocl_00026.jpg",
                "key": "00026"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthWest_denoised_ocl/SS_1_March_SouthWest_denoised_ocl_00027.jpg",
                "key": "00027"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthWest_denoised_ocl/SS_1_March_SouthWest_denoised_ocl_00028.jpg",
                "key": "00028"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthWest_denoised_ocl/SS_1_March_SouthWest_denoised_ocl_00029.jpg",
                "key": "00029"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthWest_denoised_ocl/SS_1_March_SouthWest_denoised_ocl_00030.jpg",
                "key": "00030"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthWest_denoised_ocl/SS_1_March_SouthWest_denoised_ocl_00031.jpg",
                "key": "00031"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthWest_denoised_ocl/SS_1_March_SouthWest_denoised_ocl_00032.jpg",
                "key": "00032"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthWest_denoised_ocl/SS_1_March_SouthWest_denoised_ocl_00033.jpg",
                "key": "00033"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthWest_denoised_ocl/SS_1_March_SouthWest_denoised_ocl_00034.jpg",
                "key": "00034"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthWest_denoised_ocl/SS_1_March_SouthWest_denoised_ocl_00035.jpg",
                "key": "00035"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthWest_denoised_ocl/SS_1_March_SouthWest_denoised_ocl_00036.jpg",
                "key": "00036"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthWest_denoised_ocl/SS_1_March_SouthWest_denoised_ocl_00037.jpg",
                "key": "00037"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthWest_denoised_ocl/SS_1_March_SouthWest_denoised_ocl_00038.jpg",
                "key": "00038"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthWest_denoised_ocl/SS_1_March_SouthWest_denoised_ocl_00039.jpg",
                "key": "00039"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthWest_denoised_ocl/SS_1_March_SouthWest_denoised_ocl_00040.jpg",
                "key": "00040"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthWest_denoised_ocl/SS_1_March_SouthWest_denoised_ocl_00041.jpg",
                "key": "00041"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthWest_denoised_ocl/SS_1_March_SouthWest_denoised_ocl_00042.jpg",
                "key": "00042"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthWest_denoised_ocl/SS_1_March_SouthWest_denoised_ocl_00043.jpg",
                "key": "00043"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthWest_denoised_ocl/SS_1_March_SouthWest_denoised_ocl_00044.jpg",
                "key": "00044"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthWest_denoised_ocl/SS_1_March_SouthWest_denoised_ocl_00045.jpg",
                "key": "00045"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthWest_denoised_ocl/SS_1_March_SouthWest_denoised_ocl_00046.jpg",
                "key": "00046"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthWest_denoised_ocl/SS_1_March_SouthWest_denoised_ocl_00047.jpg",
                "key": "00047"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthWest_denoised_ocl/SS_1_March_SouthWest_denoised_ocl_00048.jpg",
                "key": "00048"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthWest_denoised_ocl/SS_1_March_SouthWest_denoised_ocl_00049.jpg",
                "key": "00049"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthWest_denoised_ocl/SS_1_March_SouthWest_denoised_ocl_00050.jpg",
                "key": "00050"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthWest_denoised_ocl/SS_1_March_SouthWest_denoised_ocl_00051.jpg",
                "key": "00051"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthWest_denoised_ocl/SS_1_March_SouthWest_denoised_ocl_00052.jpg",
                "key": "00052"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthWest_denoised_ocl/SS_1_March_SouthWest_denoised_ocl_00053.jpg",
                "key": "00053"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthWest_denoised_ocl/SS_1_March_SouthWest_denoised_ocl_00054.jpg",
                "key": "00054"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthWest_denoised_ocl/SS_1_March_SouthWest_denoised_ocl_00055.jpg",
                "key": "00055"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthWest_denoised_ocl/SS_1_March_SouthWest_denoised_ocl_00056.jpg",
                "key": "00056"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthWest_denoised_ocl/SS_1_March_SouthWest_denoised_ocl_00057.jpg",
                "key": "00057"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthWest_denoised_ocl/SS_1_March_SouthWest_denoised_ocl_00058.jpg",
                "key": "00058"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthWest_denoised_ocl/SS_1_March_SouthWest_denoised_ocl_00059.jpg",
                "key": "00059"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthWest_denoised_ocl/SS_1_March_SouthWest_denoised_ocl_00060.jpg",
                "key": "00060"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthWest_denoised_ocl/SS_1_March_SouthWest_denoised_ocl_00061.jpg",
                "key": "00061"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthWest_denoised_ocl/SS_1_March_SouthWest_denoised_ocl_00062.jpg",
                "key": "00062"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthWest_denoised_ocl/SS_1_March_SouthWest_denoised_ocl_00063.jpg",
                "key": "00063"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthWest_denoised_ocl/SS_1_March_SouthWest_denoised_ocl_00064.jpg",
                "key": "00064"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthWest_denoised_ocl/SS_1_March_SouthWest_denoised_ocl_00065.jpg",
                "key": "00065"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthWest_denoised_ocl/SS_1_March_SouthWest_denoised_ocl_00066.jpg",
                "key": "00066"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthWest_denoised_ocl/SS_1_March_SouthWest_denoised_ocl_00067.jpg",
                "key": "00067"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthWest_denoised_ocl/SS_1_March_SouthWest_denoised_ocl_00068.jpg",
                "key": "00068"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthWest_denoised_ocl/SS_1_March_SouthWest_denoised_ocl_00069.jpg",
                "key": "00069"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthWest_denoised_ocl/SS_1_March_SouthWest_denoised_ocl_00070.jpg",
                "key": "00070"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthWest_denoised_ocl/SS_1_March_SouthWest_denoised_ocl_00071.jpg",
                "key": "00071"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthWest_denoised_ocl/SS_1_March_SouthWest_denoised_ocl_00072.jpg",
                "key": "00072"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthWest_denoised_ocl/SS_1_March_SouthWest_denoised_ocl_00073.jpg",
                "key": "00073"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthWest_denoised_ocl/SS_1_March_SouthWest_denoised_ocl_00074.jpg",
                "key": "00074"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthWest_denoised_ocl/SS_1_March_SouthWest_denoised_ocl_00075.jpg",
                "key": "00075"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthWest_denoised_ocl/SS_1_March_SouthWest_denoised_ocl_00076.jpg",
                "key": "00076"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthWest_denoised_ocl/SS_1_March_SouthWest_denoised_ocl_00077.jpg",
                "key": "00077"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthWest_denoised_ocl/SS_1_March_SouthWest_denoised_ocl_00078.jpg",
                "key": "00078"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthWest_denoised_ocl/SS_1_March_SouthWest_denoised_ocl_00079.jpg",
                "key": "00079"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthWest_denoised_ocl/SS_1_March_SouthWest_denoised_ocl_00080.jpg",
                "key": "00080"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthWest_denoised_ocl/SS_1_March_SouthWest_denoised_ocl_00081.jpg",
                "key": "00081"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthWest_denoised_ocl/SS_1_March_SouthWest_denoised_ocl_00082.jpg",
                "key": "00082"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthWest_denoised_ocl/SS_1_March_SouthWest_denoised_ocl_00083.jpg",
                "key": "00083"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthWest_denoised_ocl/SS_1_March_SouthWest_denoised_ocl_00084.jpg",
                "key": "00084"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthWest_denoised_ocl/SS_1_March_SouthWest_denoised_ocl_00085.jpg",
                "key": "00085"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthWest_denoised_ocl/SS_1_March_SouthWest_denoised_ocl_00086.jpg",
                "key": "00086"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthWest_denoised_ocl/SS_1_March_SouthWest_denoised_ocl_00087.jpg",
                "key": "00087"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthWest_denoised_ocl/SS_1_March_SouthWest_denoised_ocl_00088.jpg",
                "key": "00088"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthWest_denoised_ocl/SS_1_March_SouthWest_denoised_ocl_00089.jpg",
                "key": "00089"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthWest_denoised_ocl/SS_1_March_SouthWest_denoised_ocl_00090.jpg",
                "key": "00090"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthWest_denoised_ocl/SS_1_March_SouthWest_denoised_ocl_00091.jpg",
                "key": "00091"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthWest_denoised_ocl/SS_1_March_SouthWest_denoised_ocl_00092.jpg",
                "key": "00092"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthWest_denoised_ocl/SS_1_March_SouthWest_denoised_ocl_00093.jpg",
                "key": "00093"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthWest_denoised_ocl/SS_1_March_SouthWest_denoised_ocl_00094.jpg",
                "key": "00094"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthWest_denoised_ocl/SS_1_March_SouthWest_denoised_ocl_00095.jpg",
                "key": "00095"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthWest_denoised_ocl/SS_1_March_SouthWest_denoised_ocl_00096.jpg",
                "key": "00096"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthWest_denoised_ocl/SS_1_March_SouthWest_denoised_ocl_00097.jpg",
                "key": "00097"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthWest_denoised_ocl/SS_1_March_SouthWest_denoised_ocl_00098.jpg",
                "key": "00098"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthWest_denoised_ocl/SS_1_March_SouthWest_denoised_ocl_00099.jpg",
                "key": "00099"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_1_March_SouthWest_denoised_ocl/SS_1_March_SouthWest_denoised_ocl_00100.jpg",
                "key": "00100"
            }
        ]
    },
    {
        "id": "SS_2_June_NorthEast_denoised_ocl",
        "key": "northsummer",
        "items": [
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthEast_denoised_ocl/SS_2_June_NorthEast_denoised_ocl_00001.jpg",
                "key": "00001"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthEast_denoised_ocl/SS_2_June_NorthEast_denoised_ocl_00002.jpg",
                "key": "00002"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthEast_denoised_ocl/SS_2_June_NorthEast_denoised_ocl_00003.jpg",
                "key": "00003"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthEast_denoised_ocl/SS_2_June_NorthEast_denoised_ocl_00004.jpg",
                "key": "00004"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthEast_denoised_ocl/SS_2_June_NorthEast_denoised_ocl_00005.jpg",
                "key": "00005"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthEast_denoised_ocl/SS_2_June_NorthEast_denoised_ocl_00006.jpg",
                "key": "00006"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthEast_denoised_ocl/SS_2_June_NorthEast_denoised_ocl_00007.jpg",
                "key": "00007"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthEast_denoised_ocl/SS_2_June_NorthEast_denoised_ocl_00008.jpg",
                "key": "00008"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthEast_denoised_ocl/SS_2_June_NorthEast_denoised_ocl_00009.jpg",
                "key": "00009"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthEast_denoised_ocl/SS_2_June_NorthEast_denoised_ocl_00010.jpg",
                "key": "00010"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthEast_denoised_ocl/SS_2_June_NorthEast_denoised_ocl_00011.jpg",
                "key": "00011"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthEast_denoised_ocl/SS_2_June_NorthEast_denoised_ocl_00012.jpg",
                "key": "00012"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthEast_denoised_ocl/SS_2_June_NorthEast_denoised_ocl_00013.jpg",
                "key": "00013"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthEast_denoised_ocl/SS_2_June_NorthEast_denoised_ocl_00014.jpg",
                "key": "00014"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthEast_denoised_ocl/SS_2_June_NorthEast_denoised_ocl_00015.jpg",
                "key": "00015"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthEast_denoised_ocl/SS_2_June_NorthEast_denoised_ocl_00016.jpg",
                "key": "00016"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthEast_denoised_ocl/SS_2_June_NorthEast_denoised_ocl_00017.jpg",
                "key": "00017"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthEast_denoised_ocl/SS_2_June_NorthEast_denoised_ocl_00018.jpg",
                "key": "00018"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthEast_denoised_ocl/SS_2_June_NorthEast_denoised_ocl_00019.jpg",
                "key": "00019"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthEast_denoised_ocl/SS_2_June_NorthEast_denoised_ocl_00020.jpg",
                "key": "00020"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthEast_denoised_ocl/SS_2_June_NorthEast_denoised_ocl_00021.jpg",
                "key": "00021"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthEast_denoised_ocl/SS_2_June_NorthEast_denoised_ocl_00022.jpg",
                "key": "00022"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthEast_denoised_ocl/SS_2_June_NorthEast_denoised_ocl_00023.jpg",
                "key": "00023"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthEast_denoised_ocl/SS_2_June_NorthEast_denoised_ocl_00024.jpg",
                "key": "00024"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthEast_denoised_ocl/SS_2_June_NorthEast_denoised_ocl_00025.jpg",
                "key": "00025"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthEast_denoised_ocl/SS_2_June_NorthEast_denoised_ocl_00026.jpg",
                "key": "00026"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthEast_denoised_ocl/SS_2_June_NorthEast_denoised_ocl_00027.jpg",
                "key": "00027"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthEast_denoised_ocl/SS_2_June_NorthEast_denoised_ocl_00028.jpg",
                "key": "00028"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthEast_denoised_ocl/SS_2_June_NorthEast_denoised_ocl_00029.jpg",
                "key": "00029"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthEast_denoised_ocl/SS_2_June_NorthEast_denoised_ocl_00030.jpg",
                "key": "00030"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthEast_denoised_ocl/SS_2_June_NorthEast_denoised_ocl_00031.jpg",
                "key": "00031"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthEast_denoised_ocl/SS_2_June_NorthEast_denoised_ocl_00032.jpg",
                "key": "00032"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthEast_denoised_ocl/SS_2_June_NorthEast_denoised_ocl_00033.jpg",
                "key": "00033"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthEast_denoised_ocl/SS_2_June_NorthEast_denoised_ocl_00034.jpg",
                "key": "00034"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthEast_denoised_ocl/SS_2_June_NorthEast_denoised_ocl_00035.jpg",
                "key": "00035"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthEast_denoised_ocl/SS_2_June_NorthEast_denoised_ocl_00036.jpg",
                "key": "00036"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthEast_denoised_ocl/SS_2_June_NorthEast_denoised_ocl_00037.jpg",
                "key": "00037"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthEast_denoised_ocl/SS_2_June_NorthEast_denoised_ocl_00038.jpg",
                "key": "00038"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthEast_denoised_ocl/SS_2_June_NorthEast_denoised_ocl_00039.jpg",
                "key": "00039"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthEast_denoised_ocl/SS_2_June_NorthEast_denoised_ocl_00040.jpg",
                "key": "00040"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthEast_denoised_ocl/SS_2_June_NorthEast_denoised_ocl_00041.jpg",
                "key": "00041"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthEast_denoised_ocl/SS_2_June_NorthEast_denoised_ocl_00042.jpg",
                "key": "00042"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthEast_denoised_ocl/SS_2_June_NorthEast_denoised_ocl_00043.jpg",
                "key": "00043"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthEast_denoised_ocl/SS_2_June_NorthEast_denoised_ocl_00044.jpg",
                "key": "00044"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthEast_denoised_ocl/SS_2_June_NorthEast_denoised_ocl_00045.jpg",
                "key": "00045"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthEast_denoised_ocl/SS_2_June_NorthEast_denoised_ocl_00046.jpg",
                "key": "00046"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthEast_denoised_ocl/SS_2_June_NorthEast_denoised_ocl_00047.jpg",
                "key": "00047"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthEast_denoised_ocl/SS_2_June_NorthEast_denoised_ocl_00048.jpg",
                "key": "00048"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthEast_denoised_ocl/SS_2_June_NorthEast_denoised_ocl_00049.jpg",
                "key": "00049"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthEast_denoised_ocl/SS_2_June_NorthEast_denoised_ocl_00050.jpg",
                "key": "00050"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthEast_denoised_ocl/SS_2_June_NorthEast_denoised_ocl_00051.jpg",
                "key": "00051"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthEast_denoised_ocl/SS_2_June_NorthEast_denoised_ocl_00052.jpg",
                "key": "00052"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthEast_denoised_ocl/SS_2_June_NorthEast_denoised_ocl_00053.jpg",
                "key": "00053"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthEast_denoised_ocl/SS_2_June_NorthEast_denoised_ocl_00054.jpg",
                "key": "00054"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthEast_denoised_ocl/SS_2_June_NorthEast_denoised_ocl_00055.jpg",
                "key": "00055"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthEast_denoised_ocl/SS_2_June_NorthEast_denoised_ocl_00056.jpg",
                "key": "00056"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthEast_denoised_ocl/SS_2_June_NorthEast_denoised_ocl_00057.jpg",
                "key": "00057"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthEast_denoised_ocl/SS_2_June_NorthEast_denoised_ocl_00058.jpg",
                "key": "00058"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthEast_denoised_ocl/SS_2_June_NorthEast_denoised_ocl_00059.jpg",
                "key": "00059"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthEast_denoised_ocl/SS_2_June_NorthEast_denoised_ocl_00060.jpg",
                "key": "00060"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthEast_denoised_ocl/SS_2_June_NorthEast_denoised_ocl_00061.jpg",
                "key": "00061"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthEast_denoised_ocl/SS_2_June_NorthEast_denoised_ocl_00062.jpg",
                "key": "00062"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthEast_denoised_ocl/SS_2_June_NorthEast_denoised_ocl_00063.jpg",
                "key": "00063"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthEast_denoised_ocl/SS_2_June_NorthEast_denoised_ocl_00064.jpg",
                "key": "00064"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthEast_denoised_ocl/SS_2_June_NorthEast_denoised_ocl_00065.jpg",
                "key": "00065"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthEast_denoised_ocl/SS_2_June_NorthEast_denoised_ocl_00066.jpg",
                "key": "00066"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthEast_denoised_ocl/SS_2_June_NorthEast_denoised_ocl_00067.jpg",
                "key": "00067"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthEast_denoised_ocl/SS_2_June_NorthEast_denoised_ocl_00068.jpg",
                "key": "00068"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthEast_denoised_ocl/SS_2_June_NorthEast_denoised_ocl_00069.jpg",
                "key": "00069"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthEast_denoised_ocl/SS_2_June_NorthEast_denoised_ocl_00070.jpg",
                "key": "00070"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthEast_denoised_ocl/SS_2_June_NorthEast_denoised_ocl_00071.jpg",
                "key": "00071"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthEast_denoised_ocl/SS_2_June_NorthEast_denoised_ocl_00072.jpg",
                "key": "00072"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthEast_denoised_ocl/SS_2_June_NorthEast_denoised_ocl_00073.jpg",
                "key": "00073"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthEast_denoised_ocl/SS_2_June_NorthEast_denoised_ocl_00074.jpg",
                "key": "00074"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthEast_denoised_ocl/SS_2_June_NorthEast_denoised_ocl_00075.jpg",
                "key": "00075"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthEast_denoised_ocl/SS_2_June_NorthEast_denoised_ocl_00076.jpg",
                "key": "00076"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthEast_denoised_ocl/SS_2_June_NorthEast_denoised_ocl_00077.jpg",
                "key": "00077"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthEast_denoised_ocl/SS_2_June_NorthEast_denoised_ocl_00078.jpg",
                "key": "00078"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthEast_denoised_ocl/SS_2_June_NorthEast_denoised_ocl_00079.jpg",
                "key": "00079"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthEast_denoised_ocl/SS_2_June_NorthEast_denoised_ocl_00080.jpg",
                "key": "00080"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthEast_denoised_ocl/SS_2_June_NorthEast_denoised_ocl_00081.jpg",
                "key": "00081"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthEast_denoised_ocl/SS_2_June_NorthEast_denoised_ocl_00082.jpg",
                "key": "00082"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthEast_denoised_ocl/SS_2_June_NorthEast_denoised_ocl_00083.jpg",
                "key": "00083"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthEast_denoised_ocl/SS_2_June_NorthEast_denoised_ocl_00084.jpg",
                "key": "00084"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthEast_denoised_ocl/SS_2_June_NorthEast_denoised_ocl_00085.jpg",
                "key": "00085"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthEast_denoised_ocl/SS_2_June_NorthEast_denoised_ocl_00086.jpg",
                "key": "00086"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthEast_denoised_ocl/SS_2_June_NorthEast_denoised_ocl_00087.jpg",
                "key": "00087"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthEast_denoised_ocl/SS_2_June_NorthEast_denoised_ocl_00088.jpg",
                "key": "00088"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthEast_denoised_ocl/SS_2_June_NorthEast_denoised_ocl_00089.jpg",
                "key": "00089"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthEast_denoised_ocl/SS_2_June_NorthEast_denoised_ocl_00090.jpg",
                "key": "00090"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthEast_denoised_ocl/SS_2_June_NorthEast_denoised_ocl_00091.jpg",
                "key": "00091"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthEast_denoised_ocl/SS_2_June_NorthEast_denoised_ocl_00092.jpg",
                "key": "00092"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthEast_denoised_ocl/SS_2_June_NorthEast_denoised_ocl_00093.jpg",
                "key": "00093"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthEast_denoised_ocl/SS_2_June_NorthEast_denoised_ocl_00094.jpg",
                "key": "00094"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthEast_denoised_ocl/SS_2_June_NorthEast_denoised_ocl_00095.jpg",
                "key": "00095"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthEast_denoised_ocl/SS_2_June_NorthEast_denoised_ocl_00096.jpg",
                "key": "00096"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthEast_denoised_ocl/SS_2_June_NorthEast_denoised_ocl_00097.jpg",
                "key": "00097"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthEast_denoised_ocl/SS_2_June_NorthEast_denoised_ocl_00098.jpg",
                "key": "00098"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthEast_denoised_ocl/SS_2_June_NorthEast_denoised_ocl_00099.jpg",
                "key": "00099"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthEast_denoised_ocl/SS_2_June_NorthEast_denoised_ocl_00100.jpg",
                "key": "00100"
            }
        ]
    },
    {
        "id": "SS_2_June_NorthWest_denoised_ocl",
        "key": "westsummer",
        "items": [
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthWest_denoised_ocl/SS_2_June_NorthWest_denoised_ocl_00001.jpg",
                "key": "00001"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthWest_denoised_ocl/SS_2_June_NorthWest_denoised_ocl_00002.jpg",
                "key": "00002"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthWest_denoised_ocl/SS_2_June_NorthWest_denoised_ocl_00003.jpg",
                "key": "00003"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthWest_denoised_ocl/SS_2_June_NorthWest_denoised_ocl_00004.jpg",
                "key": "00004"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthWest_denoised_ocl/SS_2_June_NorthWest_denoised_ocl_00005.jpg",
                "key": "00005"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthWest_denoised_ocl/SS_2_June_NorthWest_denoised_ocl_00006.jpg",
                "key": "00006"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthWest_denoised_ocl/SS_2_June_NorthWest_denoised_ocl_00007.jpg",
                "key": "00007"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthWest_denoised_ocl/SS_2_June_NorthWest_denoised_ocl_00008.jpg",
                "key": "00008"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthWest_denoised_ocl/SS_2_June_NorthWest_denoised_ocl_00009.jpg",
                "key": "00009"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthWest_denoised_ocl/SS_2_June_NorthWest_denoised_ocl_00010.jpg",
                "key": "00010"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthWest_denoised_ocl/SS_2_June_NorthWest_denoised_ocl_00011.jpg",
                "key": "00011"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthWest_denoised_ocl/SS_2_June_NorthWest_denoised_ocl_00012.jpg",
                "key": "00012"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthWest_denoised_ocl/SS_2_June_NorthWest_denoised_ocl_00013.jpg",
                "key": "00013"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthWest_denoised_ocl/SS_2_June_NorthWest_denoised_ocl_00014.jpg",
                "key": "00014"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthWest_denoised_ocl/SS_2_June_NorthWest_denoised_ocl_00015.jpg",
                "key": "00015"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthWest_denoised_ocl/SS_2_June_NorthWest_denoised_ocl_00016.jpg",
                "key": "00016"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthWest_denoised_ocl/SS_2_June_NorthWest_denoised_ocl_00017.jpg",
                "key": "00017"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthWest_denoised_ocl/SS_2_June_NorthWest_denoised_ocl_00018.jpg",
                "key": "00018"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthWest_denoised_ocl/SS_2_June_NorthWest_denoised_ocl_00019.jpg",
                "key": "00019"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthWest_denoised_ocl/SS_2_June_NorthWest_denoised_ocl_00020.jpg",
                "key": "00020"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthWest_denoised_ocl/SS_2_June_NorthWest_denoised_ocl_00021.jpg",
                "key": "00021"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthWest_denoised_ocl/SS_2_June_NorthWest_denoised_ocl_00022.jpg",
                "key": "00022"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthWest_denoised_ocl/SS_2_June_NorthWest_denoised_ocl_00023.jpg",
                "key": "00023"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthWest_denoised_ocl/SS_2_June_NorthWest_denoised_ocl_00024.jpg",
                "key": "00024"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthWest_denoised_ocl/SS_2_June_NorthWest_denoised_ocl_00025.jpg",
                "key": "00025"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthWest_denoised_ocl/SS_2_June_NorthWest_denoised_ocl_00026.jpg",
                "key": "00026"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthWest_denoised_ocl/SS_2_June_NorthWest_denoised_ocl_00027.jpg",
                "key": "00027"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthWest_denoised_ocl/SS_2_June_NorthWest_denoised_ocl_00028.jpg",
                "key": "00028"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthWest_denoised_ocl/SS_2_June_NorthWest_denoised_ocl_00029.jpg",
                "key": "00029"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthWest_denoised_ocl/SS_2_June_NorthWest_denoised_ocl_00030.jpg",
                "key": "00030"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthWest_denoised_ocl/SS_2_June_NorthWest_denoised_ocl_00031.jpg",
                "key": "00031"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthWest_denoised_ocl/SS_2_June_NorthWest_denoised_ocl_00032.jpg",
                "key": "00032"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthWest_denoised_ocl/SS_2_June_NorthWest_denoised_ocl_00033.jpg",
                "key": "00033"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthWest_denoised_ocl/SS_2_June_NorthWest_denoised_ocl_00034.jpg",
                "key": "00034"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthWest_denoised_ocl/SS_2_June_NorthWest_denoised_ocl_00035.jpg",
                "key": "00035"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthWest_denoised_ocl/SS_2_June_NorthWest_denoised_ocl_00036.jpg",
                "key": "00036"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthWest_denoised_ocl/SS_2_June_NorthWest_denoised_ocl_00037.jpg",
                "key": "00037"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthWest_denoised_ocl/SS_2_June_NorthWest_denoised_ocl_00038.jpg",
                "key": "00038"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthWest_denoised_ocl/SS_2_June_NorthWest_denoised_ocl_00039.jpg",
                "key": "00039"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthWest_denoised_ocl/SS_2_June_NorthWest_denoised_ocl_00040.jpg",
                "key": "00040"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthWest_denoised_ocl/SS_2_June_NorthWest_denoised_ocl_00041.jpg",
                "key": "00041"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthWest_denoised_ocl/SS_2_June_NorthWest_denoised_ocl_00042.jpg",
                "key": "00042"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthWest_denoised_ocl/SS_2_June_NorthWest_denoised_ocl_00043.jpg",
                "key": "00043"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthWest_denoised_ocl/SS_2_June_NorthWest_denoised_ocl_00044.jpg",
                "key": "00044"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthWest_denoised_ocl/SS_2_June_NorthWest_denoised_ocl_00045.jpg",
                "key": "00045"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthWest_denoised_ocl/SS_2_June_NorthWest_denoised_ocl_00046.jpg",
                "key": "00046"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthWest_denoised_ocl/SS_2_June_NorthWest_denoised_ocl_00047.jpg",
                "key": "00047"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthWest_denoised_ocl/SS_2_June_NorthWest_denoised_ocl_00048.jpg",
                "key": "00048"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthWest_denoised_ocl/SS_2_June_NorthWest_denoised_ocl_00049.jpg",
                "key": "00049"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthWest_denoised_ocl/SS_2_June_NorthWest_denoised_ocl_00050.jpg",
                "key": "00050"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthWest_denoised_ocl/SS_2_June_NorthWest_denoised_ocl_00051.jpg",
                "key": "00051"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthWest_denoised_ocl/SS_2_June_NorthWest_denoised_ocl_00052.jpg",
                "key": "00052"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthWest_denoised_ocl/SS_2_June_NorthWest_denoised_ocl_00053.jpg",
                "key": "00053"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthWest_denoised_ocl/SS_2_June_NorthWest_denoised_ocl_00054.jpg",
                "key": "00054"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthWest_denoised_ocl/SS_2_June_NorthWest_denoised_ocl_00055.jpg",
                "key": "00055"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthWest_denoised_ocl/SS_2_June_NorthWest_denoised_ocl_00056.jpg",
                "key": "00056"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthWest_denoised_ocl/SS_2_June_NorthWest_denoised_ocl_00057.jpg",
                "key": "00057"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthWest_denoised_ocl/SS_2_June_NorthWest_denoised_ocl_00058.jpg",
                "key": "00058"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthWest_denoised_ocl/SS_2_June_NorthWest_denoised_ocl_00059.jpg",
                "key": "00059"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthWest_denoised_ocl/SS_2_June_NorthWest_denoised_ocl_00060.jpg",
                "key": "00060"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthWest_denoised_ocl/SS_2_June_NorthWest_denoised_ocl_00061.jpg",
                "key": "00061"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthWest_denoised_ocl/SS_2_June_NorthWest_denoised_ocl_00062.jpg",
                "key": "00062"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthWest_denoised_ocl/SS_2_June_NorthWest_denoised_ocl_00063.jpg",
                "key": "00063"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthWest_denoised_ocl/SS_2_June_NorthWest_denoised_ocl_00064.jpg",
                "key": "00064"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthWest_denoised_ocl/SS_2_June_NorthWest_denoised_ocl_00065.jpg",
                "key": "00065"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthWest_denoised_ocl/SS_2_June_NorthWest_denoised_ocl_00066.jpg",
                "key": "00066"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthWest_denoised_ocl/SS_2_June_NorthWest_denoised_ocl_00067.jpg",
                "key": "00067"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthWest_denoised_ocl/SS_2_June_NorthWest_denoised_ocl_00068.jpg",
                "key": "00068"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthWest_denoised_ocl/SS_2_June_NorthWest_denoised_ocl_00069.jpg",
                "key": "00069"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthWest_denoised_ocl/SS_2_June_NorthWest_denoised_ocl_00070.jpg",
                "key": "00070"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthWest_denoised_ocl/SS_2_June_NorthWest_denoised_ocl_00071.jpg",
                "key": "00071"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthWest_denoised_ocl/SS_2_June_NorthWest_denoised_ocl_00072.jpg",
                "key": "00072"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthWest_denoised_ocl/SS_2_June_NorthWest_denoised_ocl_00073.jpg",
                "key": "00073"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthWest_denoised_ocl/SS_2_June_NorthWest_denoised_ocl_00074.jpg",
                "key": "00074"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthWest_denoised_ocl/SS_2_June_NorthWest_denoised_ocl_00075.jpg",
                "key": "00075"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthWest_denoised_ocl/SS_2_June_NorthWest_denoised_ocl_00076.jpg",
                "key": "00076"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthWest_denoised_ocl/SS_2_June_NorthWest_denoised_ocl_00077.jpg",
                "key": "00077"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthWest_denoised_ocl/SS_2_June_NorthWest_denoised_ocl_00078.jpg",
                "key": "00078"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthWest_denoised_ocl/SS_2_June_NorthWest_denoised_ocl_00079.jpg",
                "key": "00079"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthWest_denoised_ocl/SS_2_June_NorthWest_denoised_ocl_00080.jpg",
                "key": "00080"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthWest_denoised_ocl/SS_2_June_NorthWest_denoised_ocl_00081.jpg",
                "key": "00081"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthWest_denoised_ocl/SS_2_June_NorthWest_denoised_ocl_00082.jpg",
                "key": "00082"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthWest_denoised_ocl/SS_2_June_NorthWest_denoised_ocl_00083.jpg",
                "key": "00083"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthWest_denoised_ocl/SS_2_June_NorthWest_denoised_ocl_00084.jpg",
                "key": "00084"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthWest_denoised_ocl/SS_2_June_NorthWest_denoised_ocl_00085.jpg",
                "key": "00085"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthWest_denoised_ocl/SS_2_June_NorthWest_denoised_ocl_00086.jpg",
                "key": "00086"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthWest_denoised_ocl/SS_2_June_NorthWest_denoised_ocl_00087.jpg",
                "key": "00087"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthWest_denoised_ocl/SS_2_June_NorthWest_denoised_ocl_00088.jpg",
                "key": "00088"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthWest_denoised_ocl/SS_2_June_NorthWest_denoised_ocl_00089.jpg",
                "key": "00089"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthWest_denoised_ocl/SS_2_June_NorthWest_denoised_ocl_00090.jpg",
                "key": "00090"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthWest_denoised_ocl/SS_2_June_NorthWest_denoised_ocl_00091.jpg",
                "key": "00091"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthWest_denoised_ocl/SS_2_June_NorthWest_denoised_ocl_00092.jpg",
                "key": "00092"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthWest_denoised_ocl/SS_2_June_NorthWest_denoised_ocl_00093.jpg",
                "key": "00093"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthWest_denoised_ocl/SS_2_June_NorthWest_denoised_ocl_00094.jpg",
                "key": "00094"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthWest_denoised_ocl/SS_2_June_NorthWest_denoised_ocl_00095.jpg",
                "key": "00095"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthWest_denoised_ocl/SS_2_June_NorthWest_denoised_ocl_00096.jpg",
                "key": "00096"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthWest_denoised_ocl/SS_2_June_NorthWest_denoised_ocl_00097.jpg",
                "key": "00097"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthWest_denoised_ocl/SS_2_June_NorthWest_denoised_ocl_00098.jpg",
                "key": "00098"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthWest_denoised_ocl/SS_2_June_NorthWest_denoised_ocl_00099.jpg",
                "key": "00099"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_NorthWest_denoised_ocl/SS_2_June_NorthWest_denoised_ocl_00100.jpg",
                "key": "00100"
            }
        ]
    },
    {
        "id": "SS_2_June_SouthEast_denoised_ocl",
        "key": "eastsummer",
        "items": [
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthEast_denoised_ocl/SS_2_June_SouthEast_denoised_ocl_00001.jpg",
                "key": "00001"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthEast_denoised_ocl/SS_2_June_SouthEast_denoised_ocl_00002.jpg",
                "key": "00002"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthEast_denoised_ocl/SS_2_June_SouthEast_denoised_ocl_00003.jpg",
                "key": "00003"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthEast_denoised_ocl/SS_2_June_SouthEast_denoised_ocl_00004.jpg",
                "key": "00004"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthEast_denoised_ocl/SS_2_June_SouthEast_denoised_ocl_00005.jpg",
                "key": "00005"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthEast_denoised_ocl/SS_2_June_SouthEast_denoised_ocl_00006.jpg",
                "key": "00006"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthEast_denoised_ocl/SS_2_June_SouthEast_denoised_ocl_00007.jpg",
                "key": "00007"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthEast_denoised_ocl/SS_2_June_SouthEast_denoised_ocl_00008.jpg",
                "key": "00008"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthEast_denoised_ocl/SS_2_June_SouthEast_denoised_ocl_00009.jpg",
                "key": "00009"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthEast_denoised_ocl/SS_2_June_SouthEast_denoised_ocl_00010.jpg",
                "key": "00010"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthEast_denoised_ocl/SS_2_June_SouthEast_denoised_ocl_00011.jpg",
                "key": "00011"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthEast_denoised_ocl/SS_2_June_SouthEast_denoised_ocl_00012.jpg",
                "key": "00012"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthEast_denoised_ocl/SS_2_June_SouthEast_denoised_ocl_00013.jpg",
                "key": "00013"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthEast_denoised_ocl/SS_2_June_SouthEast_denoised_ocl_00014.jpg",
                "key": "00014"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthEast_denoised_ocl/SS_2_June_SouthEast_denoised_ocl_00015.jpg",
                "key": "00015"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthEast_denoised_ocl/SS_2_June_SouthEast_denoised_ocl_00016.jpg",
                "key": "00016"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthEast_denoised_ocl/SS_2_June_SouthEast_denoised_ocl_00017.jpg",
                "key": "00017"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthEast_denoised_ocl/SS_2_June_SouthEast_denoised_ocl_00018.jpg",
                "key": "00018"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthEast_denoised_ocl/SS_2_June_SouthEast_denoised_ocl_00019.jpg",
                "key": "00019"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthEast_denoised_ocl/SS_2_June_SouthEast_denoised_ocl_00020.jpg",
                "key": "00020"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthEast_denoised_ocl/SS_2_June_SouthEast_denoised_ocl_00021.jpg",
                "key": "00021"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthEast_denoised_ocl/SS_2_June_SouthEast_denoised_ocl_00022.jpg",
                "key": "00022"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthEast_denoised_ocl/SS_2_June_SouthEast_denoised_ocl_00023.jpg",
                "key": "00023"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthEast_denoised_ocl/SS_2_June_SouthEast_denoised_ocl_00024.jpg",
                "key": "00024"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthEast_denoised_ocl/SS_2_June_SouthEast_denoised_ocl_00025.jpg",
                "key": "00025"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthEast_denoised_ocl/SS_2_June_SouthEast_denoised_ocl_00026.jpg",
                "key": "00026"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthEast_denoised_ocl/SS_2_June_SouthEast_denoised_ocl_00027.jpg",
                "key": "00027"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthEast_denoised_ocl/SS_2_June_SouthEast_denoised_ocl_00028.jpg",
                "key": "00028"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthEast_denoised_ocl/SS_2_June_SouthEast_denoised_ocl_00029.jpg",
                "key": "00029"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthEast_denoised_ocl/SS_2_June_SouthEast_denoised_ocl_00030.jpg",
                "key": "00030"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthEast_denoised_ocl/SS_2_June_SouthEast_denoised_ocl_00031.jpg",
                "key": "00031"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthEast_denoised_ocl/SS_2_June_SouthEast_denoised_ocl_00032.jpg",
                "key": "00032"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthEast_denoised_ocl/SS_2_June_SouthEast_denoised_ocl_00033.jpg",
                "key": "00033"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthEast_denoised_ocl/SS_2_June_SouthEast_denoised_ocl_00034.jpg",
                "key": "00034"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthEast_denoised_ocl/SS_2_June_SouthEast_denoised_ocl_00035.jpg",
                "key": "00035"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthEast_denoised_ocl/SS_2_June_SouthEast_denoised_ocl_00036.jpg",
                "key": "00036"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthEast_denoised_ocl/SS_2_June_SouthEast_denoised_ocl_00037.jpg",
                "key": "00037"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthEast_denoised_ocl/SS_2_June_SouthEast_denoised_ocl_00038.jpg",
                "key": "00038"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthEast_denoised_ocl/SS_2_June_SouthEast_denoised_ocl_00039.jpg",
                "key": "00039"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthEast_denoised_ocl/SS_2_June_SouthEast_denoised_ocl_00040.jpg",
                "key": "00040"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthEast_denoised_ocl/SS_2_June_SouthEast_denoised_ocl_00041.jpg",
                "key": "00041"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthEast_denoised_ocl/SS_2_June_SouthEast_denoised_ocl_00042.jpg",
                "key": "00042"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthEast_denoised_ocl/SS_2_June_SouthEast_denoised_ocl_00043.jpg",
                "key": "00043"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthEast_denoised_ocl/SS_2_June_SouthEast_denoised_ocl_00044.jpg",
                "key": "00044"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthEast_denoised_ocl/SS_2_June_SouthEast_denoised_ocl_00045.jpg",
                "key": "00045"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthEast_denoised_ocl/SS_2_June_SouthEast_denoised_ocl_00046.jpg",
                "key": "00046"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthEast_denoised_ocl/SS_2_June_SouthEast_denoised_ocl_00047.jpg",
                "key": "00047"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthEast_denoised_ocl/SS_2_June_SouthEast_denoised_ocl_00048.jpg",
                "key": "00048"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthEast_denoised_ocl/SS_2_June_SouthEast_denoised_ocl_00049.jpg",
                "key": "00049"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthEast_denoised_ocl/SS_2_June_SouthEast_denoised_ocl_00050.jpg",
                "key": "00050"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthEast_denoised_ocl/SS_2_June_SouthEast_denoised_ocl_00051.jpg",
                "key": "00051"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthEast_denoised_ocl/SS_2_June_SouthEast_denoised_ocl_00052.jpg",
                "key": "00052"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthEast_denoised_ocl/SS_2_June_SouthEast_denoised_ocl_00053.jpg",
                "key": "00053"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthEast_denoised_ocl/SS_2_June_SouthEast_denoised_ocl_00054.jpg",
                "key": "00054"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthEast_denoised_ocl/SS_2_June_SouthEast_denoised_ocl_00055.jpg",
                "key": "00055"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthEast_denoised_ocl/SS_2_June_SouthEast_denoised_ocl_00056.jpg",
                "key": "00056"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthEast_denoised_ocl/SS_2_June_SouthEast_denoised_ocl_00057.jpg",
                "key": "00057"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthEast_denoised_ocl/SS_2_June_SouthEast_denoised_ocl_00058.jpg",
                "key": "00058"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthEast_denoised_ocl/SS_2_June_SouthEast_denoised_ocl_00059.jpg",
                "key": "00059"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthEast_denoised_ocl/SS_2_June_SouthEast_denoised_ocl_00060.jpg",
                "key": "00060"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthEast_denoised_ocl/SS_2_June_SouthEast_denoised_ocl_00061.jpg",
                "key": "00061"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthEast_denoised_ocl/SS_2_June_SouthEast_denoised_ocl_00062.jpg",
                "key": "00062"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthEast_denoised_ocl/SS_2_June_SouthEast_denoised_ocl_00063.jpg",
                "key": "00063"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthEast_denoised_ocl/SS_2_June_SouthEast_denoised_ocl_00064.jpg",
                "key": "00064"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthEast_denoised_ocl/SS_2_June_SouthEast_denoised_ocl_00065.jpg",
                "key": "00065"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthEast_denoised_ocl/SS_2_June_SouthEast_denoised_ocl_00066.jpg",
                "key": "00066"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthEast_denoised_ocl/SS_2_June_SouthEast_denoised_ocl_00067.jpg",
                "key": "00067"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthEast_denoised_ocl/SS_2_June_SouthEast_denoised_ocl_00068.jpg",
                "key": "00068"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthEast_denoised_ocl/SS_2_June_SouthEast_denoised_ocl_00069.jpg",
                "key": "00069"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthEast_denoised_ocl/SS_2_June_SouthEast_denoised_ocl_00070.jpg",
                "key": "00070"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthEast_denoised_ocl/SS_2_June_SouthEast_denoised_ocl_00071.jpg",
                "key": "00071"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthEast_denoised_ocl/SS_2_June_SouthEast_denoised_ocl_00072.jpg",
                "key": "00072"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthEast_denoised_ocl/SS_2_June_SouthEast_denoised_ocl_00073.jpg",
                "key": "00073"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthEast_denoised_ocl/SS_2_June_SouthEast_denoised_ocl_00074.jpg",
                "key": "00074"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthEast_denoised_ocl/SS_2_June_SouthEast_denoised_ocl_00075.jpg",
                "key": "00075"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthEast_denoised_ocl/SS_2_June_SouthEast_denoised_ocl_00076.jpg",
                "key": "00076"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthEast_denoised_ocl/SS_2_June_SouthEast_denoised_ocl_00077.jpg",
                "key": "00077"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthEast_denoised_ocl/SS_2_June_SouthEast_denoised_ocl_00078.jpg",
                "key": "00078"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthEast_denoised_ocl/SS_2_June_SouthEast_denoised_ocl_00079.jpg",
                "key": "00079"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthEast_denoised_ocl/SS_2_June_SouthEast_denoised_ocl_00080.jpg",
                "key": "00080"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthEast_denoised_ocl/SS_2_June_SouthEast_denoised_ocl_00081.jpg",
                "key": "00081"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthEast_denoised_ocl/SS_2_June_SouthEast_denoised_ocl_00082.jpg",
                "key": "00082"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthEast_denoised_ocl/SS_2_June_SouthEast_denoised_ocl_00083.jpg",
                "key": "00083"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthEast_denoised_ocl/SS_2_June_SouthEast_denoised_ocl_00084.jpg",
                "key": "00084"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthEast_denoised_ocl/SS_2_June_SouthEast_denoised_ocl_00085.jpg",
                "key": "00085"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthEast_denoised_ocl/SS_2_June_SouthEast_denoised_ocl_00086.jpg",
                "key": "00086"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthEast_denoised_ocl/SS_2_June_SouthEast_denoised_ocl_00087.jpg",
                "key": "00087"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthEast_denoised_ocl/SS_2_June_SouthEast_denoised_ocl_00088.jpg",
                "key": "00088"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthEast_denoised_ocl/SS_2_June_SouthEast_denoised_ocl_00089.jpg",
                "key": "00089"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthEast_denoised_ocl/SS_2_June_SouthEast_denoised_ocl_00090.jpg",
                "key": "00090"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthEast_denoised_ocl/SS_2_June_SouthEast_denoised_ocl_00091.jpg",
                "key": "00091"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthEast_denoised_ocl/SS_2_June_SouthEast_denoised_ocl_00092.jpg",
                "key": "00092"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthEast_denoised_ocl/SS_2_June_SouthEast_denoised_ocl_00093.jpg",
                "key": "00093"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthEast_denoised_ocl/SS_2_June_SouthEast_denoised_ocl_00094.jpg",
                "key": "00094"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthEast_denoised_ocl/SS_2_June_SouthEast_denoised_ocl_00095.jpg",
                "key": "00095"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthEast_denoised_ocl/SS_2_June_SouthEast_denoised_ocl_00096.jpg",
                "key": "00096"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthEast_denoised_ocl/SS_2_June_SouthEast_denoised_ocl_00097.jpg",
                "key": "00097"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthEast_denoised_ocl/SS_2_June_SouthEast_denoised_ocl_00098.jpg",
                "key": "00098"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthEast_denoised_ocl/SS_2_June_SouthEast_denoised_ocl_00099.jpg",
                "key": "00099"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthEast_denoised_ocl/SS_2_June_SouthEast_denoised_ocl_00100.jpg",
                "key": "00100"
            }
        ]
    },
    {
        "id": "SS_2_June_SouthWest_denoised_ocl",
        "key": "southsummer",
        "items": [
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthWest_denoised_ocl/SS_2_June_SouthWest_denoised_ocl_00001.jpg",
                "key": "00001"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthWest_denoised_ocl/SS_2_June_SouthWest_denoised_ocl_00002.jpg",
                "key": "00002"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthWest_denoised_ocl/SS_2_June_SouthWest_denoised_ocl_00003.jpg",
                "key": "00003"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthWest_denoised_ocl/SS_2_June_SouthWest_denoised_ocl_00004.jpg",
                "key": "00004"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthWest_denoised_ocl/SS_2_June_SouthWest_denoised_ocl_00005.jpg",
                "key": "00005"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthWest_denoised_ocl/SS_2_June_SouthWest_denoised_ocl_00006.jpg",
                "key": "00006"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthWest_denoised_ocl/SS_2_June_SouthWest_denoised_ocl_00007.jpg",
                "key": "00007"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthWest_denoised_ocl/SS_2_June_SouthWest_denoised_ocl_00008.jpg",
                "key": "00008"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthWest_denoised_ocl/SS_2_June_SouthWest_denoised_ocl_00009.jpg",
                "key": "00009"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthWest_denoised_ocl/SS_2_June_SouthWest_denoised_ocl_00010.jpg",
                "key": "00010"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthWest_denoised_ocl/SS_2_June_SouthWest_denoised_ocl_00011.jpg",
                "key": "00011"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthWest_denoised_ocl/SS_2_June_SouthWest_denoised_ocl_00012.jpg",
                "key": "00012"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthWest_denoised_ocl/SS_2_June_SouthWest_denoised_ocl_00013.jpg",
                "key": "00013"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthWest_denoised_ocl/SS_2_June_SouthWest_denoised_ocl_00014.jpg",
                "key": "00014"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthWest_denoised_ocl/SS_2_June_SouthWest_denoised_ocl_00015.jpg",
                "key": "00015"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthWest_denoised_ocl/SS_2_June_SouthWest_denoised_ocl_00016.jpg",
                "key": "00016"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthWest_denoised_ocl/SS_2_June_SouthWest_denoised_ocl_00017.jpg",
                "key": "00017"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthWest_denoised_ocl/SS_2_June_SouthWest_denoised_ocl_00018.jpg",
                "key": "00018"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthWest_denoised_ocl/SS_2_June_SouthWest_denoised_ocl_00019.jpg",
                "key": "00019"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthWest_denoised_ocl/SS_2_June_SouthWest_denoised_ocl_00020.jpg",
                "key": "00020"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthWest_denoised_ocl/SS_2_June_SouthWest_denoised_ocl_00021.jpg",
                "key": "00021"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthWest_denoised_ocl/SS_2_June_SouthWest_denoised_ocl_00022.jpg",
                "key": "00022"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthWest_denoised_ocl/SS_2_June_SouthWest_denoised_ocl_00023.jpg",
                "key": "00023"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthWest_denoised_ocl/SS_2_June_SouthWest_denoised_ocl_00024.jpg",
                "key": "00024"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthWest_denoised_ocl/SS_2_June_SouthWest_denoised_ocl_00025.jpg",
                "key": "00025"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthWest_denoised_ocl/SS_2_June_SouthWest_denoised_ocl_00026.jpg",
                "key": "00026"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthWest_denoised_ocl/SS_2_June_SouthWest_denoised_ocl_00027.jpg",
                "key": "00027"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthWest_denoised_ocl/SS_2_June_SouthWest_denoised_ocl_00028.jpg",
                "key": "00028"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthWest_denoised_ocl/SS_2_June_SouthWest_denoised_ocl_00029.jpg",
                "key": "00029"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthWest_denoised_ocl/SS_2_June_SouthWest_denoised_ocl_00030.jpg",
                "key": "00030"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthWest_denoised_ocl/SS_2_June_SouthWest_denoised_ocl_00031.jpg",
                "key": "00031"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthWest_denoised_ocl/SS_2_June_SouthWest_denoised_ocl_00032.jpg",
                "key": "00032"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthWest_denoised_ocl/SS_2_June_SouthWest_denoised_ocl_00033.jpg",
                "key": "00033"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthWest_denoised_ocl/SS_2_June_SouthWest_denoised_ocl_00034.jpg",
                "key": "00034"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthWest_denoised_ocl/SS_2_June_SouthWest_denoised_ocl_00035.jpg",
                "key": "00035"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthWest_denoised_ocl/SS_2_June_SouthWest_denoised_ocl_00036.jpg",
                "key": "00036"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthWest_denoised_ocl/SS_2_June_SouthWest_denoised_ocl_00037.jpg",
                "key": "00037"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthWest_denoised_ocl/SS_2_June_SouthWest_denoised_ocl_00038.jpg",
                "key": "00038"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthWest_denoised_ocl/SS_2_June_SouthWest_denoised_ocl_00039.jpg",
                "key": "00039"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthWest_denoised_ocl/SS_2_June_SouthWest_denoised_ocl_00040.jpg",
                "key": "00040"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthWest_denoised_ocl/SS_2_June_SouthWest_denoised_ocl_00041.jpg",
                "key": "00041"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthWest_denoised_ocl/SS_2_June_SouthWest_denoised_ocl_00042.jpg",
                "key": "00042"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthWest_denoised_ocl/SS_2_June_SouthWest_denoised_ocl_00043.jpg",
                "key": "00043"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthWest_denoised_ocl/SS_2_June_SouthWest_denoised_ocl_00044.jpg",
                "key": "00044"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthWest_denoised_ocl/SS_2_June_SouthWest_denoised_ocl_00045.jpg",
                "key": "00045"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthWest_denoised_ocl/SS_2_June_SouthWest_denoised_ocl_00046.jpg",
                "key": "00046"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthWest_denoised_ocl/SS_2_June_SouthWest_denoised_ocl_00047.jpg",
                "key": "00047"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthWest_denoised_ocl/SS_2_June_SouthWest_denoised_ocl_00048.jpg",
                "key": "00048"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthWest_denoised_ocl/SS_2_June_SouthWest_denoised_ocl_00049.jpg",
                "key": "00049"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthWest_denoised_ocl/SS_2_June_SouthWest_denoised_ocl_00050.jpg",
                "key": "00050"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthWest_denoised_ocl/SS_2_June_SouthWest_denoised_ocl_00051.jpg",
                "key": "00051"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthWest_denoised_ocl/SS_2_June_SouthWest_denoised_ocl_00052.jpg",
                "key": "00052"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthWest_denoised_ocl/SS_2_June_SouthWest_denoised_ocl_00053.jpg",
                "key": "00053"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthWest_denoised_ocl/SS_2_June_SouthWest_denoised_ocl_00054.jpg",
                "key": "00054"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthWest_denoised_ocl/SS_2_June_SouthWest_denoised_ocl_00055.jpg",
                "key": "00055"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthWest_denoised_ocl/SS_2_June_SouthWest_denoised_ocl_00056.jpg",
                "key": "00056"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthWest_denoised_ocl/SS_2_June_SouthWest_denoised_ocl_00057.jpg",
                "key": "00057"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthWest_denoised_ocl/SS_2_June_SouthWest_denoised_ocl_00058.jpg",
                "key": "00058"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthWest_denoised_ocl/SS_2_June_SouthWest_denoised_ocl_00059.jpg",
                "key": "00059"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthWest_denoised_ocl/SS_2_June_SouthWest_denoised_ocl_00060.jpg",
                "key": "00060"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthWest_denoised_ocl/SS_2_June_SouthWest_denoised_ocl_00061.jpg",
                "key": "00061"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthWest_denoised_ocl/SS_2_June_SouthWest_denoised_ocl_00062.jpg",
                "key": "00062"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthWest_denoised_ocl/SS_2_June_SouthWest_denoised_ocl_00063.jpg",
                "key": "00063"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthWest_denoised_ocl/SS_2_June_SouthWest_denoised_ocl_00064.jpg",
                "key": "00064"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthWest_denoised_ocl/SS_2_June_SouthWest_denoised_ocl_00065.jpg",
                "key": "00065"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthWest_denoised_ocl/SS_2_June_SouthWest_denoised_ocl_00066.jpg",
                "key": "00066"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthWest_denoised_ocl/SS_2_June_SouthWest_denoised_ocl_00067.jpg",
                "key": "00067"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthWest_denoised_ocl/SS_2_June_SouthWest_denoised_ocl_00068.jpg",
                "key": "00068"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthWest_denoised_ocl/SS_2_June_SouthWest_denoised_ocl_00069.jpg",
                "key": "00069"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthWest_denoised_ocl/SS_2_June_SouthWest_denoised_ocl_00070.jpg",
                "key": "00070"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthWest_denoised_ocl/SS_2_June_SouthWest_denoised_ocl_00071.jpg",
                "key": "00071"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthWest_denoised_ocl/SS_2_June_SouthWest_denoised_ocl_00072.jpg",
                "key": "00072"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthWest_denoised_ocl/SS_2_June_SouthWest_denoised_ocl_00073.jpg",
                "key": "00073"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthWest_denoised_ocl/SS_2_June_SouthWest_denoised_ocl_00074.jpg",
                "key": "00074"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthWest_denoised_ocl/SS_2_June_SouthWest_denoised_ocl_00075.jpg",
                "key": "00075"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthWest_denoised_ocl/SS_2_June_SouthWest_denoised_ocl_00076.jpg",
                "key": "00076"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthWest_denoised_ocl/SS_2_June_SouthWest_denoised_ocl_00077.jpg",
                "key": "00077"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthWest_denoised_ocl/SS_2_June_SouthWest_denoised_ocl_00078.jpg",
                "key": "00078"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthWest_denoised_ocl/SS_2_June_SouthWest_denoised_ocl_00079.jpg",
                "key": "00079"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthWest_denoised_ocl/SS_2_June_SouthWest_denoised_ocl_00080.jpg",
                "key": "00080"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthWest_denoised_ocl/SS_2_June_SouthWest_denoised_ocl_00081.jpg",
                "key": "00081"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthWest_denoised_ocl/SS_2_June_SouthWest_denoised_ocl_00082.jpg",
                "key": "00082"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthWest_denoised_ocl/SS_2_June_SouthWest_denoised_ocl_00083.jpg",
                "key": "00083"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthWest_denoised_ocl/SS_2_June_SouthWest_denoised_ocl_00084.jpg",
                "key": "00084"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthWest_denoised_ocl/SS_2_June_SouthWest_denoised_ocl_00085.jpg",
                "key": "00085"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthWest_denoised_ocl/SS_2_June_SouthWest_denoised_ocl_00086.jpg",
                "key": "00086"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthWest_denoised_ocl/SS_2_June_SouthWest_denoised_ocl_00087.jpg",
                "key": "00087"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthWest_denoised_ocl/SS_2_June_SouthWest_denoised_ocl_00088.jpg",
                "key": "00088"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthWest_denoised_ocl/SS_2_June_SouthWest_denoised_ocl_00089.jpg",
                "key": "00089"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthWest_denoised_ocl/SS_2_June_SouthWest_denoised_ocl_00090.jpg",
                "key": "00090"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthWest_denoised_ocl/SS_2_June_SouthWest_denoised_ocl_00091.jpg",
                "key": "00091"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthWest_denoised_ocl/SS_2_June_SouthWest_denoised_ocl_00092.jpg",
                "key": "00092"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthWest_denoised_ocl/SS_2_June_SouthWest_denoised_ocl_00093.jpg",
                "key": "00093"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthWest_denoised_ocl/SS_2_June_SouthWest_denoised_ocl_00094.jpg",
                "key": "00094"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthWest_denoised_ocl/SS_2_June_SouthWest_denoised_ocl_00095.jpg",
                "key": "00095"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthWest_denoised_ocl/SS_2_June_SouthWest_denoised_ocl_00096.jpg",
                "key": "00096"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthWest_denoised_ocl/SS_2_June_SouthWest_denoised_ocl_00097.jpg",
                "key": "00097"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthWest_denoised_ocl/SS_2_June_SouthWest_denoised_ocl_00098.jpg",
                "key": "00098"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthWest_denoised_ocl/SS_2_June_SouthWest_denoised_ocl_00099.jpg",
                "key": "00099"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_2_June_SouthWest_denoised_ocl/SS_2_June_SouthWest_denoised_ocl_00100.jpg",
                "key": "00100"
            }
        ]
    },
    {
        "id": "SS_3_September_NorthEast_denoised_ocl",
        "key": "northfall",
        "items": [
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthEast_denoised_ocl/SS_3_September_NorthEast_denoised_ocl_00001.jpg",
                "key": "00001"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthEast_denoised_ocl/SS_3_September_NorthEast_denoised_ocl_00002.jpg",
                "key": "00002"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthEast_denoised_ocl/SS_3_September_NorthEast_denoised_ocl_00003.jpg",
                "key": "00003"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthEast_denoised_ocl/SS_3_September_NorthEast_denoised_ocl_00004.jpg",
                "key": "00004"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthEast_denoised_ocl/SS_3_September_NorthEast_denoised_ocl_00005.jpg",
                "key": "00005"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthEast_denoised_ocl/SS_3_September_NorthEast_denoised_ocl_00006.jpg",
                "key": "00006"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthEast_denoised_ocl/SS_3_September_NorthEast_denoised_ocl_00007.jpg",
                "key": "00007"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthEast_denoised_ocl/SS_3_September_NorthEast_denoised_ocl_00008.jpg",
                "key": "00008"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthEast_denoised_ocl/SS_3_September_NorthEast_denoised_ocl_00009.jpg",
                "key": "00009"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthEast_denoised_ocl/SS_3_September_NorthEast_denoised_ocl_00010.jpg",
                "key": "00010"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthEast_denoised_ocl/SS_3_September_NorthEast_denoised_ocl_00011.jpg",
                "key": "00011"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthEast_denoised_ocl/SS_3_September_NorthEast_denoised_ocl_00012.jpg",
                "key": "00012"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthEast_denoised_ocl/SS_3_September_NorthEast_denoised_ocl_00013.jpg",
                "key": "00013"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthEast_denoised_ocl/SS_3_September_NorthEast_denoised_ocl_00014.jpg",
                "key": "00014"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthEast_denoised_ocl/SS_3_September_NorthEast_denoised_ocl_00015.jpg",
                "key": "00015"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthEast_denoised_ocl/SS_3_September_NorthEast_denoised_ocl_00016.jpg",
                "key": "00016"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthEast_denoised_ocl/SS_3_September_NorthEast_denoised_ocl_00017.jpg",
                "key": "00017"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthEast_denoised_ocl/SS_3_September_NorthEast_denoised_ocl_00018.jpg",
                "key": "00018"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthEast_denoised_ocl/SS_3_September_NorthEast_denoised_ocl_00019.jpg",
                "key": "00019"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthEast_denoised_ocl/SS_3_September_NorthEast_denoised_ocl_00020.jpg",
                "key": "00020"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthEast_denoised_ocl/SS_3_September_NorthEast_denoised_ocl_00021.jpg",
                "key": "00021"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthEast_denoised_ocl/SS_3_September_NorthEast_denoised_ocl_00022.jpg",
                "key": "00022"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthEast_denoised_ocl/SS_3_September_NorthEast_denoised_ocl_00023.jpg",
                "key": "00023"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthEast_denoised_ocl/SS_3_September_NorthEast_denoised_ocl_00024.jpg",
                "key": "00024"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthEast_denoised_ocl/SS_3_September_NorthEast_denoised_ocl_00025.jpg",
                "key": "00025"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthEast_denoised_ocl/SS_3_September_NorthEast_denoised_ocl_00026.jpg",
                "key": "00026"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthEast_denoised_ocl/SS_3_September_NorthEast_denoised_ocl_00027.jpg",
                "key": "00027"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthEast_denoised_ocl/SS_3_September_NorthEast_denoised_ocl_00028.jpg",
                "key": "00028"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthEast_denoised_ocl/SS_3_September_NorthEast_denoised_ocl_00029.jpg",
                "key": "00029"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthEast_denoised_ocl/SS_3_September_NorthEast_denoised_ocl_00030.jpg",
                "key": "00030"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthEast_denoised_ocl/SS_3_September_NorthEast_denoised_ocl_00031.jpg",
                "key": "00031"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthEast_denoised_ocl/SS_3_September_NorthEast_denoised_ocl_00032.jpg",
                "key": "00032"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthEast_denoised_ocl/SS_3_September_NorthEast_denoised_ocl_00033.jpg",
                "key": "00033"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthEast_denoised_ocl/SS_3_September_NorthEast_denoised_ocl_00034.jpg",
                "key": "00034"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthEast_denoised_ocl/SS_3_September_NorthEast_denoised_ocl_00035.jpg",
                "key": "00035"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthEast_denoised_ocl/SS_3_September_NorthEast_denoised_ocl_00036.jpg",
                "key": "00036"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthEast_denoised_ocl/SS_3_September_NorthEast_denoised_ocl_00037.jpg",
                "key": "00037"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthEast_denoised_ocl/SS_3_September_NorthEast_denoised_ocl_00038.jpg",
                "key": "00038"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthEast_denoised_ocl/SS_3_September_NorthEast_denoised_ocl_00039.jpg",
                "key": "00039"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthEast_denoised_ocl/SS_3_September_NorthEast_denoised_ocl_00040.jpg",
                "key": "00040"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthEast_denoised_ocl/SS_3_September_NorthEast_denoised_ocl_00041.jpg",
                "key": "00041"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthEast_denoised_ocl/SS_3_September_NorthEast_denoised_ocl_00042.jpg",
                "key": "00042"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthEast_denoised_ocl/SS_3_September_NorthEast_denoised_ocl_00043.jpg",
                "key": "00043"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthEast_denoised_ocl/SS_3_September_NorthEast_denoised_ocl_00044.jpg",
                "key": "00044"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthEast_denoised_ocl/SS_3_September_NorthEast_denoised_ocl_00045.jpg",
                "key": "00045"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthEast_denoised_ocl/SS_3_September_NorthEast_denoised_ocl_00046.jpg",
                "key": "00046"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthEast_denoised_ocl/SS_3_September_NorthEast_denoised_ocl_00047.jpg",
                "key": "00047"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthEast_denoised_ocl/SS_3_September_NorthEast_denoised_ocl_00048.jpg",
                "key": "00048"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthEast_denoised_ocl/SS_3_September_NorthEast_denoised_ocl_00049.jpg",
                "key": "00049"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthEast_denoised_ocl/SS_3_September_NorthEast_denoised_ocl_00050.jpg",
                "key": "00050"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthEast_denoised_ocl/SS_3_September_NorthEast_denoised_ocl_00051.jpg",
                "key": "00051"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthEast_denoised_ocl/SS_3_September_NorthEast_denoised_ocl_00052.jpg",
                "key": "00052"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthEast_denoised_ocl/SS_3_September_NorthEast_denoised_ocl_00053.jpg",
                "key": "00053"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthEast_denoised_ocl/SS_3_September_NorthEast_denoised_ocl_00054.jpg",
                "key": "00054"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthEast_denoised_ocl/SS_3_September_NorthEast_denoised_ocl_00055.jpg",
                "key": "00055"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthEast_denoised_ocl/SS_3_September_NorthEast_denoised_ocl_00056.jpg",
                "key": "00056"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthEast_denoised_ocl/SS_3_September_NorthEast_denoised_ocl_00057.jpg",
                "key": "00057"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthEast_denoised_ocl/SS_3_September_NorthEast_denoised_ocl_00058.jpg",
                "key": "00058"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthEast_denoised_ocl/SS_3_September_NorthEast_denoised_ocl_00059.jpg",
                "key": "00059"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthEast_denoised_ocl/SS_3_September_NorthEast_denoised_ocl_00060.jpg",
                "key": "00060"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthEast_denoised_ocl/SS_3_September_NorthEast_denoised_ocl_00061.jpg",
                "key": "00061"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthEast_denoised_ocl/SS_3_September_NorthEast_denoised_ocl_00062.jpg",
                "key": "00062"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthEast_denoised_ocl/SS_3_September_NorthEast_denoised_ocl_00063.jpg",
                "key": "00063"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthEast_denoised_ocl/SS_3_September_NorthEast_denoised_ocl_00064.jpg",
                "key": "00064"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthEast_denoised_ocl/SS_3_September_NorthEast_denoised_ocl_00065.jpg",
                "key": "00065"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthEast_denoised_ocl/SS_3_September_NorthEast_denoised_ocl_00066.jpg",
                "key": "00066"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthEast_denoised_ocl/SS_3_September_NorthEast_denoised_ocl_00067.jpg",
                "key": "00067"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthEast_denoised_ocl/SS_3_September_NorthEast_denoised_ocl_00068.jpg",
                "key": "00068"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthEast_denoised_ocl/SS_3_September_NorthEast_denoised_ocl_00069.jpg",
                "key": "00069"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthEast_denoised_ocl/SS_3_September_NorthEast_denoised_ocl_00070.jpg",
                "key": "00070"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthEast_denoised_ocl/SS_3_September_NorthEast_denoised_ocl_00071.jpg",
                "key": "00071"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthEast_denoised_ocl/SS_3_September_NorthEast_denoised_ocl_00072.jpg",
                "key": "00072"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthEast_denoised_ocl/SS_3_September_NorthEast_denoised_ocl_00073.jpg",
                "key": "00073"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthEast_denoised_ocl/SS_3_September_NorthEast_denoised_ocl_00074.jpg",
                "key": "00074"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthEast_denoised_ocl/SS_3_September_NorthEast_denoised_ocl_00075.jpg",
                "key": "00075"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthEast_denoised_ocl/SS_3_September_NorthEast_denoised_ocl_00076.jpg",
                "key": "00076"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthEast_denoised_ocl/SS_3_September_NorthEast_denoised_ocl_00077.jpg",
                "key": "00077"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthEast_denoised_ocl/SS_3_September_NorthEast_denoised_ocl_00078.jpg",
                "key": "00078"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthEast_denoised_ocl/SS_3_September_NorthEast_denoised_ocl_00079.jpg",
                "key": "00079"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthEast_denoised_ocl/SS_3_September_NorthEast_denoised_ocl_00080.jpg",
                "key": "00080"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthEast_denoised_ocl/SS_3_September_NorthEast_denoised_ocl_00081.jpg",
                "key": "00081"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthEast_denoised_ocl/SS_3_September_NorthEast_denoised_ocl_00082.jpg",
                "key": "00082"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthEast_denoised_ocl/SS_3_September_NorthEast_denoised_ocl_00083.jpg",
                "key": "00083"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthEast_denoised_ocl/SS_3_September_NorthEast_denoised_ocl_00084.jpg",
                "key": "00084"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthEast_denoised_ocl/SS_3_September_NorthEast_denoised_ocl_00085.jpg",
                "key": "00085"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthEast_denoised_ocl/SS_3_September_NorthEast_denoised_ocl_00086.jpg",
                "key": "00086"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthEast_denoised_ocl/SS_3_September_NorthEast_denoised_ocl_00087.jpg",
                "key": "00087"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthEast_denoised_ocl/SS_3_September_NorthEast_denoised_ocl_00088.jpg",
                "key": "00088"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthEast_denoised_ocl/SS_3_September_NorthEast_denoised_ocl_00089.jpg",
                "key": "00089"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthEast_denoised_ocl/SS_3_September_NorthEast_denoised_ocl_00090.jpg",
                "key": "00090"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthEast_denoised_ocl/SS_3_September_NorthEast_denoised_ocl_00091.jpg",
                "key": "00091"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthEast_denoised_ocl/SS_3_September_NorthEast_denoised_ocl_00092.jpg",
                "key": "00092"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthEast_denoised_ocl/SS_3_September_NorthEast_denoised_ocl_00093.jpg",
                "key": "00093"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthEast_denoised_ocl/SS_3_September_NorthEast_denoised_ocl_00094.jpg",
                "key": "00094"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthEast_denoised_ocl/SS_3_September_NorthEast_denoised_ocl_00095.jpg",
                "key": "00095"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthEast_denoised_ocl/SS_3_September_NorthEast_denoised_ocl_00096.jpg",
                "key": "00096"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthEast_denoised_ocl/SS_3_September_NorthEast_denoised_ocl_00097.jpg",
                "key": "00097"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthEast_denoised_ocl/SS_3_September_NorthEast_denoised_ocl_00098.jpg",
                "key": "00098"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthEast_denoised_ocl/SS_3_September_NorthEast_denoised_ocl_00099.jpg",
                "key": "00099"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthEast_denoised_ocl/SS_3_September_NorthEast_denoised_ocl_00100.jpg",
                "key": "00100"
            }
        ]
    },
    {
        "id": "SS_3_September_NorthWest_denoised_ocl",
        "key": "westfall",
        "items": [
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthWest_denoised_ocl/SS_3_September_NorthWest_denoised_ocl_00001.jpg",
                "key": "00001"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthWest_denoised_ocl/SS_3_September_NorthWest_denoised_ocl_00002.jpg",
                "key": "00002"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthWest_denoised_ocl/SS_3_September_NorthWest_denoised_ocl_00003.jpg",
                "key": "00003"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthWest_denoised_ocl/SS_3_September_NorthWest_denoised_ocl_00004.jpg",
                "key": "00004"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthWest_denoised_ocl/SS_3_September_NorthWest_denoised_ocl_00005.jpg",
                "key": "00005"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthWest_denoised_ocl/SS_3_September_NorthWest_denoised_ocl_00006.jpg",
                "key": "00006"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthWest_denoised_ocl/SS_3_September_NorthWest_denoised_ocl_00007.jpg",
                "key": "00007"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthWest_denoised_ocl/SS_3_September_NorthWest_denoised_ocl_00008.jpg",
                "key": "00008"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthWest_denoised_ocl/SS_3_September_NorthWest_denoised_ocl_00009.jpg",
                "key": "00009"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthWest_denoised_ocl/SS_3_September_NorthWest_denoised_ocl_00010.jpg",
                "key": "00010"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthWest_denoised_ocl/SS_3_September_NorthWest_denoised_ocl_00011.jpg",
                "key": "00011"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthWest_denoised_ocl/SS_3_September_NorthWest_denoised_ocl_00012.jpg",
                "key": "00012"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthWest_denoised_ocl/SS_3_September_NorthWest_denoised_ocl_00013.jpg",
                "key": "00013"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthWest_denoised_ocl/SS_3_September_NorthWest_denoised_ocl_00014.jpg",
                "key": "00014"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthWest_denoised_ocl/SS_3_September_NorthWest_denoised_ocl_00015.jpg",
                "key": "00015"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthWest_denoised_ocl/SS_3_September_NorthWest_denoised_ocl_00016.jpg",
                "key": "00016"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthWest_denoised_ocl/SS_3_September_NorthWest_denoised_ocl_00017.jpg",
                "key": "00017"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthWest_denoised_ocl/SS_3_September_NorthWest_denoised_ocl_00018.jpg",
                "key": "00018"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthWest_denoised_ocl/SS_3_September_NorthWest_denoised_ocl_00019.jpg",
                "key": "00019"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthWest_denoised_ocl/SS_3_September_NorthWest_denoised_ocl_00020.jpg",
                "key": "00020"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthWest_denoised_ocl/SS_3_September_NorthWest_denoised_ocl_00021.jpg",
                "key": "00021"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthWest_denoised_ocl/SS_3_September_NorthWest_denoised_ocl_00022.jpg",
                "key": "00022"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthWest_denoised_ocl/SS_3_September_NorthWest_denoised_ocl_00023.jpg",
                "key": "00023"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthWest_denoised_ocl/SS_3_September_NorthWest_denoised_ocl_00024.jpg",
                "key": "00024"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthWest_denoised_ocl/SS_3_September_NorthWest_denoised_ocl_00025.jpg",
                "key": "00025"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthWest_denoised_ocl/SS_3_September_NorthWest_denoised_ocl_00026.jpg",
                "key": "00026"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthWest_denoised_ocl/SS_3_September_NorthWest_denoised_ocl_00027.jpg",
                "key": "00027"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthWest_denoised_ocl/SS_3_September_NorthWest_denoised_ocl_00028.jpg",
                "key": "00028"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthWest_denoised_ocl/SS_3_September_NorthWest_denoised_ocl_00029.jpg",
                "key": "00029"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthWest_denoised_ocl/SS_3_September_NorthWest_denoised_ocl_00030.jpg",
                "key": "00030"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthWest_denoised_ocl/SS_3_September_NorthWest_denoised_ocl_00031.jpg",
                "key": "00031"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthWest_denoised_ocl/SS_3_September_NorthWest_denoised_ocl_00032.jpg",
                "key": "00032"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthWest_denoised_ocl/SS_3_September_NorthWest_denoised_ocl_00033.jpg",
                "key": "00033"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthWest_denoised_ocl/SS_3_September_NorthWest_denoised_ocl_00034.jpg",
                "key": "00034"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthWest_denoised_ocl/SS_3_September_NorthWest_denoised_ocl_00035.jpg",
                "key": "00035"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthWest_denoised_ocl/SS_3_September_NorthWest_denoised_ocl_00036.jpg",
                "key": "00036"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthWest_denoised_ocl/SS_3_September_NorthWest_denoised_ocl_00037.jpg",
                "key": "00037"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthWest_denoised_ocl/SS_3_September_NorthWest_denoised_ocl_00038.jpg",
                "key": "00038"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthWest_denoised_ocl/SS_3_September_NorthWest_denoised_ocl_00039.jpg",
                "key": "00039"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthWest_denoised_ocl/SS_3_September_NorthWest_denoised_ocl_00040.jpg",
                "key": "00040"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthWest_denoised_ocl/SS_3_September_NorthWest_denoised_ocl_00041.jpg",
                "key": "00041"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthWest_denoised_ocl/SS_3_September_NorthWest_denoised_ocl_00042.jpg",
                "key": "00042"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthWest_denoised_ocl/SS_3_September_NorthWest_denoised_ocl_00043.jpg",
                "key": "00043"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthWest_denoised_ocl/SS_3_September_NorthWest_denoised_ocl_00044.jpg",
                "key": "00044"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthWest_denoised_ocl/SS_3_September_NorthWest_denoised_ocl_00045.jpg",
                "key": "00045"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthWest_denoised_ocl/SS_3_September_NorthWest_denoised_ocl_00046.jpg",
                "key": "00046"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthWest_denoised_ocl/SS_3_September_NorthWest_denoised_ocl_00047.jpg",
                "key": "00047"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthWest_denoised_ocl/SS_3_September_NorthWest_denoised_ocl_00048.jpg",
                "key": "00048"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthWest_denoised_ocl/SS_3_September_NorthWest_denoised_ocl_00049.jpg",
                "key": "00049"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthWest_denoised_ocl/SS_3_September_NorthWest_denoised_ocl_00050.jpg",
                "key": "00050"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthWest_denoised_ocl/SS_3_September_NorthWest_denoised_ocl_00051.jpg",
                "key": "00051"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthWest_denoised_ocl/SS_3_September_NorthWest_denoised_ocl_00052.jpg",
                "key": "00052"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthWest_denoised_ocl/SS_3_September_NorthWest_denoised_ocl_00053.jpg",
                "key": "00053"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthWest_denoised_ocl/SS_3_September_NorthWest_denoised_ocl_00054.jpg",
                "key": "00054"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthWest_denoised_ocl/SS_3_September_NorthWest_denoised_ocl_00055.jpg",
                "key": "00055"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthWest_denoised_ocl/SS_3_September_NorthWest_denoised_ocl_00056.jpg",
                "key": "00056"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthWest_denoised_ocl/SS_3_September_NorthWest_denoised_ocl_00057.jpg",
                "key": "00057"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthWest_denoised_ocl/SS_3_September_NorthWest_denoised_ocl_00058.jpg",
                "key": "00058"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthWest_denoised_ocl/SS_3_September_NorthWest_denoised_ocl_00059.jpg",
                "key": "00059"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthWest_denoised_ocl/SS_3_September_NorthWest_denoised_ocl_00060.jpg",
                "key": "00060"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthWest_denoised_ocl/SS_3_September_NorthWest_denoised_ocl_00061.jpg",
                "key": "00061"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthWest_denoised_ocl/SS_3_September_NorthWest_denoised_ocl_00062.jpg",
                "key": "00062"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthWest_denoised_ocl/SS_3_September_NorthWest_denoised_ocl_00063.jpg",
                "key": "00063"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthWest_denoised_ocl/SS_3_September_NorthWest_denoised_ocl_00064.jpg",
                "key": "00064"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthWest_denoised_ocl/SS_3_September_NorthWest_denoised_ocl_00065.jpg",
                "key": "00065"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthWest_denoised_ocl/SS_3_September_NorthWest_denoised_ocl_00066.jpg",
                "key": "00066"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthWest_denoised_ocl/SS_3_September_NorthWest_denoised_ocl_00067.jpg",
                "key": "00067"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthWest_denoised_ocl/SS_3_September_NorthWest_denoised_ocl_00068.jpg",
                "key": "00068"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthWest_denoised_ocl/SS_3_September_NorthWest_denoised_ocl_00069.jpg",
                "key": "00069"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthWest_denoised_ocl/SS_3_September_NorthWest_denoised_ocl_00070.jpg",
                "key": "00070"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthWest_denoised_ocl/SS_3_September_NorthWest_denoised_ocl_00071.jpg",
                "key": "00071"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthWest_denoised_ocl/SS_3_September_NorthWest_denoised_ocl_00072.jpg",
                "key": "00072"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthWest_denoised_ocl/SS_3_September_NorthWest_denoised_ocl_00073.jpg",
                "key": "00073"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthWest_denoised_ocl/SS_3_September_NorthWest_denoised_ocl_00074.jpg",
                "key": "00074"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthWest_denoised_ocl/SS_3_September_NorthWest_denoised_ocl_00075.jpg",
                "key": "00075"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthWest_denoised_ocl/SS_3_September_NorthWest_denoised_ocl_00076.jpg",
                "key": "00076"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthWest_denoised_ocl/SS_3_September_NorthWest_denoised_ocl_00077.jpg",
                "key": "00077"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthWest_denoised_ocl/SS_3_September_NorthWest_denoised_ocl_00078.jpg",
                "key": "00078"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthWest_denoised_ocl/SS_3_September_NorthWest_denoised_ocl_00079.jpg",
                "key": "00079"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthWest_denoised_ocl/SS_3_September_NorthWest_denoised_ocl_00080.jpg",
                "key": "00080"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthWest_denoised_ocl/SS_3_September_NorthWest_denoised_ocl_00081.jpg",
                "key": "00081"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthWest_denoised_ocl/SS_3_September_NorthWest_denoised_ocl_00082.jpg",
                "key": "00082"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthWest_denoised_ocl/SS_3_September_NorthWest_denoised_ocl_00083.jpg",
                "key": "00083"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthWest_denoised_ocl/SS_3_September_NorthWest_denoised_ocl_00084.jpg",
                "key": "00084"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthWest_denoised_ocl/SS_3_September_NorthWest_denoised_ocl_00085.jpg",
                "key": "00085"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthWest_denoised_ocl/SS_3_September_NorthWest_denoised_ocl_00086.jpg",
                "key": "00086"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthWest_denoised_ocl/SS_3_September_NorthWest_denoised_ocl_00087.jpg",
                "key": "00087"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthWest_denoised_ocl/SS_3_September_NorthWest_denoised_ocl_00088.jpg",
                "key": "00088"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthWest_denoised_ocl/SS_3_September_NorthWest_denoised_ocl_00089.jpg",
                "key": "00089"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthWest_denoised_ocl/SS_3_September_NorthWest_denoised_ocl_00090.jpg",
                "key": "00090"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthWest_denoised_ocl/SS_3_September_NorthWest_denoised_ocl_00091.jpg",
                "key": "00091"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthWest_denoised_ocl/SS_3_September_NorthWest_denoised_ocl_00092.jpg",
                "key": "00092"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthWest_denoised_ocl/SS_3_September_NorthWest_denoised_ocl_00093.jpg",
                "key": "00093"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthWest_denoised_ocl/SS_3_September_NorthWest_denoised_ocl_00094.jpg",
                "key": "00094"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthWest_denoised_ocl/SS_3_September_NorthWest_denoised_ocl_00095.jpg",
                "key": "00095"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthWest_denoised_ocl/SS_3_September_NorthWest_denoised_ocl_00096.jpg",
                "key": "00096"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthWest_denoised_ocl/SS_3_September_NorthWest_denoised_ocl_00097.jpg",
                "key": "00097"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthWest_denoised_ocl/SS_3_September_NorthWest_denoised_ocl_00098.jpg",
                "key": "00098"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthWest_denoised_ocl/SS_3_September_NorthWest_denoised_ocl_00099.jpg",
                "key": "00099"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_NorthWest_denoised_ocl/SS_3_September_NorthWest_denoised_ocl_00100.jpg",
                "key": "00100"
            }
        ]
    },
    {
        "id": "SS_3_September_SouthEast_denoised_ocl",
        "key": "eastfall",
        "items": [
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthEast_denoised_ocl/SS_3_September_SouthEast_denoised_ocl_00001.jpg",
                "key": "00001"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthEast_denoised_ocl/SS_3_September_SouthEast_denoised_ocl_00002.jpg",
                "key": "00002"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthEast_denoised_ocl/SS_3_September_SouthEast_denoised_ocl_00003.jpg",
                "key": "00003"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthEast_denoised_ocl/SS_3_September_SouthEast_denoised_ocl_00004.jpg",
                "key": "00004"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthEast_denoised_ocl/SS_3_September_SouthEast_denoised_ocl_00005.jpg",
                "key": "00005"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthEast_denoised_ocl/SS_3_September_SouthEast_denoised_ocl_00006.jpg",
                "key": "00006"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthEast_denoised_ocl/SS_3_September_SouthEast_denoised_ocl_00007.jpg",
                "key": "00007"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthEast_denoised_ocl/SS_3_September_SouthEast_denoised_ocl_00008.jpg",
                "key": "00008"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthEast_denoised_ocl/SS_3_September_SouthEast_denoised_ocl_00009.jpg",
                "key": "00009"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthEast_denoised_ocl/SS_3_September_SouthEast_denoised_ocl_00010.jpg",
                "key": "00010"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthEast_denoised_ocl/SS_3_September_SouthEast_denoised_ocl_00011.jpg",
                "key": "00011"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthEast_denoised_ocl/SS_3_September_SouthEast_denoised_ocl_00012.jpg",
                "key": "00012"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthEast_denoised_ocl/SS_3_September_SouthEast_denoised_ocl_00013.jpg",
                "key": "00013"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthEast_denoised_ocl/SS_3_September_SouthEast_denoised_ocl_00014.jpg",
                "key": "00014"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthEast_denoised_ocl/SS_3_September_SouthEast_denoised_ocl_00015.jpg",
                "key": "00015"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthEast_denoised_ocl/SS_3_September_SouthEast_denoised_ocl_00016.jpg",
                "key": "00016"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthEast_denoised_ocl/SS_3_September_SouthEast_denoised_ocl_00017.jpg",
                "key": "00017"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthEast_denoised_ocl/SS_3_September_SouthEast_denoised_ocl_00018.jpg",
                "key": "00018"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthEast_denoised_ocl/SS_3_September_SouthEast_denoised_ocl_00019.jpg",
                "key": "00019"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthEast_denoised_ocl/SS_3_September_SouthEast_denoised_ocl_00020.jpg",
                "key": "00020"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthEast_denoised_ocl/SS_3_September_SouthEast_denoised_ocl_00021.jpg",
                "key": "00021"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthEast_denoised_ocl/SS_3_September_SouthEast_denoised_ocl_00022.jpg",
                "key": "00022"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthEast_denoised_ocl/SS_3_September_SouthEast_denoised_ocl_00023.jpg",
                "key": "00023"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthEast_denoised_ocl/SS_3_September_SouthEast_denoised_ocl_00024.jpg",
                "key": "00024"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthEast_denoised_ocl/SS_3_September_SouthEast_denoised_ocl_00025.jpg",
                "key": "00025"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthEast_denoised_ocl/SS_3_September_SouthEast_denoised_ocl_00026.jpg",
                "key": "00026"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthEast_denoised_ocl/SS_3_September_SouthEast_denoised_ocl_00027.jpg",
                "key": "00027"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthEast_denoised_ocl/SS_3_September_SouthEast_denoised_ocl_00028.jpg",
                "key": "00028"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthEast_denoised_ocl/SS_3_September_SouthEast_denoised_ocl_00029.jpg",
                "key": "00029"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthEast_denoised_ocl/SS_3_September_SouthEast_denoised_ocl_00030.jpg",
                "key": "00030"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthEast_denoised_ocl/SS_3_September_SouthEast_denoised_ocl_00031.jpg",
                "key": "00031"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthEast_denoised_ocl/SS_3_September_SouthEast_denoised_ocl_00032.jpg",
                "key": "00032"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthEast_denoised_ocl/SS_3_September_SouthEast_denoised_ocl_00033.jpg",
                "key": "00033"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthEast_denoised_ocl/SS_3_September_SouthEast_denoised_ocl_00034.jpg",
                "key": "00034"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthEast_denoised_ocl/SS_3_September_SouthEast_denoised_ocl_00035.jpg",
                "key": "00035"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthEast_denoised_ocl/SS_3_September_SouthEast_denoised_ocl_00036.jpg",
                "key": "00036"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthEast_denoised_ocl/SS_3_September_SouthEast_denoised_ocl_00037.jpg",
                "key": "00037"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthEast_denoised_ocl/SS_3_September_SouthEast_denoised_ocl_00038.jpg",
                "key": "00038"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthEast_denoised_ocl/SS_3_September_SouthEast_denoised_ocl_00039.jpg",
                "key": "00039"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthEast_denoised_ocl/SS_3_September_SouthEast_denoised_ocl_00040.jpg",
                "key": "00040"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthEast_denoised_ocl/SS_3_September_SouthEast_denoised_ocl_00041.jpg",
                "key": "00041"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthEast_denoised_ocl/SS_3_September_SouthEast_denoised_ocl_00042.jpg",
                "key": "00042"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthEast_denoised_ocl/SS_3_September_SouthEast_denoised_ocl_00043.jpg",
                "key": "00043"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthEast_denoised_ocl/SS_3_September_SouthEast_denoised_ocl_00044.jpg",
                "key": "00044"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthEast_denoised_ocl/SS_3_September_SouthEast_denoised_ocl_00045.jpg",
                "key": "00045"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthEast_denoised_ocl/SS_3_September_SouthEast_denoised_ocl_00046.jpg",
                "key": "00046"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthEast_denoised_ocl/SS_3_September_SouthEast_denoised_ocl_00047.jpg",
                "key": "00047"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthEast_denoised_ocl/SS_3_September_SouthEast_denoised_ocl_00048.jpg",
                "key": "00048"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthEast_denoised_ocl/SS_3_September_SouthEast_denoised_ocl_00049.jpg",
                "key": "00049"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthEast_denoised_ocl/SS_3_September_SouthEast_denoised_ocl_00050.jpg",
                "key": "00050"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthEast_denoised_ocl/SS_3_September_SouthEast_denoised_ocl_00051.jpg",
                "key": "00051"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthEast_denoised_ocl/SS_3_September_SouthEast_denoised_ocl_00052.jpg",
                "key": "00052"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthEast_denoised_ocl/SS_3_September_SouthEast_denoised_ocl_00053.jpg",
                "key": "00053"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthEast_denoised_ocl/SS_3_September_SouthEast_denoised_ocl_00054.jpg",
                "key": "00054"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthEast_denoised_ocl/SS_3_September_SouthEast_denoised_ocl_00055.jpg",
                "key": "00055"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthEast_denoised_ocl/SS_3_September_SouthEast_denoised_ocl_00056.jpg",
                "key": "00056"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthEast_denoised_ocl/SS_3_September_SouthEast_denoised_ocl_00057.jpg",
                "key": "00057"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthEast_denoised_ocl/SS_3_September_SouthEast_denoised_ocl_00058.jpg",
                "key": "00058"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthEast_denoised_ocl/SS_3_September_SouthEast_denoised_ocl_00059.jpg",
                "key": "00059"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthEast_denoised_ocl/SS_3_September_SouthEast_denoised_ocl_00060.jpg",
                "key": "00060"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthEast_denoised_ocl/SS_3_September_SouthEast_denoised_ocl_00061.jpg",
                "key": "00061"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthEast_denoised_ocl/SS_3_September_SouthEast_denoised_ocl_00062.jpg",
                "key": "00062"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthEast_denoised_ocl/SS_3_September_SouthEast_denoised_ocl_00063.jpg",
                "key": "00063"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthEast_denoised_ocl/SS_3_September_SouthEast_denoised_ocl_00064.jpg",
                "key": "00064"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthEast_denoised_ocl/SS_3_September_SouthEast_denoised_ocl_00065.jpg",
                "key": "00065"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthEast_denoised_ocl/SS_3_September_SouthEast_denoised_ocl_00066.jpg",
                "key": "00066"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthEast_denoised_ocl/SS_3_September_SouthEast_denoised_ocl_00067.jpg",
                "key": "00067"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthEast_denoised_ocl/SS_3_September_SouthEast_denoised_ocl_00068.jpg",
                "key": "00068"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthEast_denoised_ocl/SS_3_September_SouthEast_denoised_ocl_00069.jpg",
                "key": "00069"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthEast_denoised_ocl/SS_3_September_SouthEast_denoised_ocl_00070.jpg",
                "key": "00070"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthEast_denoised_ocl/SS_3_September_SouthEast_denoised_ocl_00071.jpg",
                "key": "00071"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthEast_denoised_ocl/SS_3_September_SouthEast_denoised_ocl_00072.jpg",
                "key": "00072"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthEast_denoised_ocl/SS_3_September_SouthEast_denoised_ocl_00073.jpg",
                "key": "00073"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthEast_denoised_ocl/SS_3_September_SouthEast_denoised_ocl_00074.jpg",
                "key": "00074"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthEast_denoised_ocl/SS_3_September_SouthEast_denoised_ocl_00075.jpg",
                "key": "00075"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthEast_denoised_ocl/SS_3_September_SouthEast_denoised_ocl_00076.jpg",
                "key": "00076"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthEast_denoised_ocl/SS_3_September_SouthEast_denoised_ocl_00077.jpg",
                "key": "00077"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthEast_denoised_ocl/SS_3_September_SouthEast_denoised_ocl_00078.jpg",
                "key": "00078"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthEast_denoised_ocl/SS_3_September_SouthEast_denoised_ocl_00079.jpg",
                "key": "00079"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthEast_denoised_ocl/SS_3_September_SouthEast_denoised_ocl_00080.jpg",
                "key": "00080"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthEast_denoised_ocl/SS_3_September_SouthEast_denoised_ocl_00081.jpg",
                "key": "00081"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthEast_denoised_ocl/SS_3_September_SouthEast_denoised_ocl_00082.jpg",
                "key": "00082"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthEast_denoised_ocl/SS_3_September_SouthEast_denoised_ocl_00083.jpg",
                "key": "00083"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthEast_denoised_ocl/SS_3_September_SouthEast_denoised_ocl_00084.jpg",
                "key": "00084"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthEast_denoised_ocl/SS_3_September_SouthEast_denoised_ocl_00085.jpg",
                "key": "00085"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthEast_denoised_ocl/SS_3_September_SouthEast_denoised_ocl_00086.jpg",
                "key": "00086"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthEast_denoised_ocl/SS_3_September_SouthEast_denoised_ocl_00087.jpg",
                "key": "00087"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthEast_denoised_ocl/SS_3_September_SouthEast_denoised_ocl_00088.jpg",
                "key": "00088"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthEast_denoised_ocl/SS_3_September_SouthEast_denoised_ocl_00089.jpg",
                "key": "00089"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthEast_denoised_ocl/SS_3_September_SouthEast_denoised_ocl_00090.jpg",
                "key": "00090"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthEast_denoised_ocl/SS_3_September_SouthEast_denoised_ocl_00091.jpg",
                "key": "00091"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthEast_denoised_ocl/SS_3_September_SouthEast_denoised_ocl_00092.jpg",
                "key": "00092"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthEast_denoised_ocl/SS_3_September_SouthEast_denoised_ocl_00093.jpg",
                "key": "00093"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthEast_denoised_ocl/SS_3_September_SouthEast_denoised_ocl_00094.jpg",
                "key": "00094"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthEast_denoised_ocl/SS_3_September_SouthEast_denoised_ocl_00095.jpg",
                "key": "00095"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthEast_denoised_ocl/SS_3_September_SouthEast_denoised_ocl_00096.jpg",
                "key": "00096"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthEast_denoised_ocl/SS_3_September_SouthEast_denoised_ocl_00097.jpg",
                "key": "00097"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthEast_denoised_ocl/SS_3_September_SouthEast_denoised_ocl_00098.jpg",
                "key": "00098"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthEast_denoised_ocl/SS_3_September_SouthEast_denoised_ocl_00099.jpg",
                "key": "00099"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthEast_denoised_ocl/SS_3_September_SouthEast_denoised_ocl_00100.jpg",
                "key": "00100"
            }
        ]
    },
    {
        "id": "SS_3_September_SouthWest",
        "key": "southfall",
        "items": [
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthWest/SS_3_September_SouthWest_00001.jpg",
                "key": "00001"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthWest/SS_3_September_SouthWest_00002.jpg",
                "key": "00002"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthWest/SS_3_September_SouthWest_00003.jpg",
                "key": "00003"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthWest/SS_3_September_SouthWest_00004.jpg",
                "key": "00004"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthWest/SS_3_September_SouthWest_00005.jpg",
                "key": "00005"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthWest/SS_3_September_SouthWest_00006.jpg",
                "key": "00006"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthWest/SS_3_September_SouthWest_00007.jpg",
                "key": "00007"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthWest/SS_3_September_SouthWest_00008.jpg",
                "key": "00008"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthWest/SS_3_September_SouthWest_00009.jpg",
                "key": "00009"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthWest/SS_3_September_SouthWest_00010.jpg",
                "key": "00010"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthWest/SS_3_September_SouthWest_00011.jpg",
                "key": "00011"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthWest/SS_3_September_SouthWest_00012.jpg",
                "key": "00012"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthWest/SS_3_September_SouthWest_00013.jpg",
                "key": "00013"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthWest/SS_3_September_SouthWest_00014.jpg",
                "key": "00014"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthWest/SS_3_September_SouthWest_00015.jpg",
                "key": "00015"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthWest/SS_3_September_SouthWest_00016.jpg",
                "key": "00016"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthWest/SS_3_September_SouthWest_00017.jpg",
                "key": "00017"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthWest/SS_3_September_SouthWest_00018.jpg",
                "key": "00018"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthWest/SS_3_September_SouthWest_00019.jpg",
                "key": "00019"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthWest/SS_3_September_SouthWest_00020.jpg",
                "key": "00020"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthWest/SS_3_September_SouthWest_00021.jpg",
                "key": "00021"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthWest/SS_3_September_SouthWest_00022.jpg",
                "key": "00022"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthWest/SS_3_September_SouthWest_00023.jpg",
                "key": "00023"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthWest/SS_3_September_SouthWest_00024.jpg",
                "key": "00024"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthWest/SS_3_September_SouthWest_00025.jpg",
                "key": "00025"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthWest/SS_3_September_SouthWest_00026.jpg",
                "key": "00026"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthWest/SS_3_September_SouthWest_00027.jpg",
                "key": "00027"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthWest/SS_3_September_SouthWest_00028.jpg",
                "key": "00028"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthWest/SS_3_September_SouthWest_00029.jpg",
                "key": "00029"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthWest/SS_3_September_SouthWest_00030.jpg",
                "key": "00030"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthWest/SS_3_September_SouthWest_00031.jpg",
                "key": "00031"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthWest/SS_3_September_SouthWest_00032.jpg",
                "key": "00032"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthWest/SS_3_September_SouthWest_00033.jpg",
                "key": "00033"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthWest/SS_3_September_SouthWest_00034.jpg",
                "key": "00034"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthWest/SS_3_September_SouthWest_00035.jpg",
                "key": "00035"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthWest/SS_3_September_SouthWest_00036.jpg",
                "key": "00036"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthWest/SS_3_September_SouthWest_00037.jpg",
                "key": "00037"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthWest/SS_3_September_SouthWest_00038.jpg",
                "key": "00038"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthWest/SS_3_September_SouthWest_00039.jpg",
                "key": "00039"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthWest/SS_3_September_SouthWest_00040.jpg",
                "key": "00040"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthWest/SS_3_September_SouthWest_00041.jpg",
                "key": "00041"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthWest/SS_3_September_SouthWest_00042.jpg",
                "key": "00042"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthWest/SS_3_September_SouthWest_00043.jpg",
                "key": "00043"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthWest/SS_3_September_SouthWest_00044.jpg",
                "key": "00044"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthWest/SS_3_September_SouthWest_00045.jpg",
                "key": "00045"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthWest/SS_3_September_SouthWest_00046.jpg",
                "key": "00046"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthWest/SS_3_September_SouthWest_00047.jpg",
                "key": "00047"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthWest/SS_3_September_SouthWest_00048.jpg",
                "key": "00048"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthWest/SS_3_September_SouthWest_00049.jpg",
                "key": "00049"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthWest/SS_3_September_SouthWest_00050.jpg",
                "key": "00050"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthWest/SS_3_September_SouthWest_00051.jpg",
                "key": "00051"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthWest/SS_3_September_SouthWest_00052.jpg",
                "key": "00052"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthWest/SS_3_September_SouthWest_00053.jpg",
                "key": "00053"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthWest/SS_3_September_SouthWest_00054.jpg",
                "key": "00054"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthWest/SS_3_September_SouthWest_00055.jpg",
                "key": "00055"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthWest/SS_3_September_SouthWest_00056.jpg",
                "key": "00056"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthWest/SS_3_September_SouthWest_00057.jpg",
                "key": "00057"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthWest/SS_3_September_SouthWest_00058.jpg",
                "key": "00058"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthWest/SS_3_September_SouthWest_00059.jpg",
                "key": "00059"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthWest/SS_3_September_SouthWest_00060.jpg",
                "key": "00060"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthWest/SS_3_September_SouthWest_00061.jpg",
                "key": "00061"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthWest/SS_3_September_SouthWest_00062.jpg",
                "key": "00062"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthWest/SS_3_September_SouthWest_00063.jpg",
                "key": "00063"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthWest/SS_3_September_SouthWest_00064.jpg",
                "key": "00064"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthWest/SS_3_September_SouthWest_00065.jpg",
                "key": "00065"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthWest/SS_3_September_SouthWest_00066.jpg",
                "key": "00066"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthWest/SS_3_September_SouthWest_00067.jpg",
                "key": "00067"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthWest/SS_3_September_SouthWest_00068.jpg",
                "key": "00068"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthWest/SS_3_September_SouthWest_00069.jpg",
                "key": "00069"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthWest/SS_3_September_SouthWest_00070.jpg",
                "key": "00070"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthWest/SS_3_September_SouthWest_00071.jpg",
                "key": "00071"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthWest/SS_3_September_SouthWest_00072.jpg",
                "key": "00072"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthWest/SS_3_September_SouthWest_00073.jpg",
                "key": "00073"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthWest/SS_3_September_SouthWest_00074.jpg",
                "key": "00074"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthWest/SS_3_September_SouthWest_00075.jpg",
                "key": "00075"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthWest/SS_3_September_SouthWest_00076.jpg",
                "key": "00076"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthWest/SS_3_September_SouthWest_00077.jpg",
                "key": "00077"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthWest/SS_3_September_SouthWest_00078.jpg",
                "key": "00078"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthWest/SS_3_September_SouthWest_00079.jpg",
                "key": "00079"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthWest/SS_3_September_SouthWest_00080.jpg",
                "key": "00080"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthWest/SS_3_September_SouthWest_00081.jpg",
                "key": "00081"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthWest/SS_3_September_SouthWest_00082.jpg",
                "key": "00082"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthWest/SS_3_September_SouthWest_00083.jpg",
                "key": "00083"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthWest/SS_3_September_SouthWest_00084.jpg",
                "key": "00084"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthWest/SS_3_September_SouthWest_00085.jpg",
                "key": "00085"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthWest/SS_3_September_SouthWest_00086.jpg",
                "key": "00086"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthWest/SS_3_September_SouthWest_00087.jpg",
                "key": "00087"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthWest/SS_3_September_SouthWest_00088.jpg",
                "key": "00088"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthWest/SS_3_September_SouthWest_00089.jpg",
                "key": "00089"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthWest/SS_3_September_SouthWest_00090.jpg",
                "key": "00090"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthWest/SS_3_September_SouthWest_00091.jpg",
                "key": "00091"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthWest/SS_3_September_SouthWest_00092.jpg",
                "key": "00092"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthWest/SS_3_September_SouthWest_00093.jpg",
                "key": "00093"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthWest/SS_3_September_SouthWest_00094.jpg",
                "key": "00094"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthWest/SS_3_September_SouthWest_00095.jpg",
                "key": "00095"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthWest/SS_3_September_SouthWest_00096.jpg",
                "key": "00096"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthWest/SS_3_September_SouthWest_00097.jpg",
                "key": "00097"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthWest/SS_3_September_SouthWest_00098.jpg",
                "key": "00098"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthWest/SS_3_September_SouthWest_00099.jpg",
                "key": "00099"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_3_September_SouthWest/SS_3_September_SouthWest_00100.jpg",
                "key": "00100"
            }
        ]
    },
    {
        "id": "SS_4_December_NorthEast_denoised_ocl",
        "key": "northwinter",
        "items": [
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthEast_denoised_ocl/SS_4_December_NorthEast_denoised_ocl_00001.jpg",
                "key": "00001"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthEast_denoised_ocl/SS_4_December_NorthEast_denoised_ocl_00002.jpg",
                "key": "00002"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthEast_denoised_ocl/SS_4_December_NorthEast_denoised_ocl_00003.jpg",
                "key": "00003"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthEast_denoised_ocl/SS_4_December_NorthEast_denoised_ocl_00004.jpg",
                "key": "00004"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthEast_denoised_ocl/SS_4_December_NorthEast_denoised_ocl_00005.jpg",
                "key": "00005"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthEast_denoised_ocl/SS_4_December_NorthEast_denoised_ocl_00006.jpg",
                "key": "00006"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthEast_denoised_ocl/SS_4_December_NorthEast_denoised_ocl_00007.jpg",
                "key": "00007"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthEast_denoised_ocl/SS_4_December_NorthEast_denoised_ocl_00008.jpg",
                "key": "00008"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthEast_denoised_ocl/SS_4_December_NorthEast_denoised_ocl_00009.jpg",
                "key": "00009"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthEast_denoised_ocl/SS_4_December_NorthEast_denoised_ocl_00010.jpg",
                "key": "00010"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthEast_denoised_ocl/SS_4_December_NorthEast_denoised_ocl_00011.jpg",
                "key": "00011"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthEast_denoised_ocl/SS_4_December_NorthEast_denoised_ocl_00012.jpg",
                "key": "00012"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthEast_denoised_ocl/SS_4_December_NorthEast_denoised_ocl_00013.jpg",
                "key": "00013"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthEast_denoised_ocl/SS_4_December_NorthEast_denoised_ocl_00014.jpg",
                "key": "00014"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthEast_denoised_ocl/SS_4_December_NorthEast_denoised_ocl_00015.jpg",
                "key": "00015"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthEast_denoised_ocl/SS_4_December_NorthEast_denoised_ocl_00016.jpg",
                "key": "00016"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthEast_denoised_ocl/SS_4_December_NorthEast_denoised_ocl_00017.jpg",
                "key": "00017"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthEast_denoised_ocl/SS_4_December_NorthEast_denoised_ocl_00018.jpg",
                "key": "00018"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthEast_denoised_ocl/SS_4_December_NorthEast_denoised_ocl_00019.jpg",
                "key": "00019"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthEast_denoised_ocl/SS_4_December_NorthEast_denoised_ocl_00020.jpg",
                "key": "00020"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthEast_denoised_ocl/SS_4_December_NorthEast_denoised_ocl_00021.jpg",
                "key": "00021"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthEast_denoised_ocl/SS_4_December_NorthEast_denoised_ocl_00022.jpg",
                "key": "00022"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthEast_denoised_ocl/SS_4_December_NorthEast_denoised_ocl_00023.jpg",
                "key": "00023"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthEast_denoised_ocl/SS_4_December_NorthEast_denoised_ocl_00024.jpg",
                "key": "00024"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthEast_denoised_ocl/SS_4_December_NorthEast_denoised_ocl_00025.jpg",
                "key": "00025"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthEast_denoised_ocl/SS_4_December_NorthEast_denoised_ocl_00026.jpg",
                "key": "00026"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthEast_denoised_ocl/SS_4_December_NorthEast_denoised_ocl_00027.jpg",
                "key": "00027"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthEast_denoised_ocl/SS_4_December_NorthEast_denoised_ocl_00028.jpg",
                "key": "00028"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthEast_denoised_ocl/SS_4_December_NorthEast_denoised_ocl_00029.jpg",
                "key": "00029"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthEast_denoised_ocl/SS_4_December_NorthEast_denoised_ocl_00030.jpg",
                "key": "00030"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthEast_denoised_ocl/SS_4_December_NorthEast_denoised_ocl_00031.jpg",
                "key": "00031"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthEast_denoised_ocl/SS_4_December_NorthEast_denoised_ocl_00032.jpg",
                "key": "00032"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthEast_denoised_ocl/SS_4_December_NorthEast_denoised_ocl_00033.jpg",
                "key": "00033"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthEast_denoised_ocl/SS_4_December_NorthEast_denoised_ocl_00034.jpg",
                "key": "00034"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthEast_denoised_ocl/SS_4_December_NorthEast_denoised_ocl_00035.jpg",
                "key": "00035"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthEast_denoised_ocl/SS_4_December_NorthEast_denoised_ocl_00036.jpg",
                "key": "00036"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthEast_denoised_ocl/SS_4_December_NorthEast_denoised_ocl_00037.jpg",
                "key": "00037"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthEast_denoised_ocl/SS_4_December_NorthEast_denoised_ocl_00038.jpg",
                "key": "00038"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthEast_denoised_ocl/SS_4_December_NorthEast_denoised_ocl_00039.jpg",
                "key": "00039"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthEast_denoised_ocl/SS_4_December_NorthEast_denoised_ocl_00040.jpg",
                "key": "00040"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthEast_denoised_ocl/SS_4_December_NorthEast_denoised_ocl_00041.jpg",
                "key": "00041"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthEast_denoised_ocl/SS_4_December_NorthEast_denoised_ocl_00042.jpg",
                "key": "00042"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthEast_denoised_ocl/SS_4_December_NorthEast_denoised_ocl_00043.jpg",
                "key": "00043"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthEast_denoised_ocl/SS_4_December_NorthEast_denoised_ocl_00044.jpg",
                "key": "00044"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthEast_denoised_ocl/SS_4_December_NorthEast_denoised_ocl_00045.jpg",
                "key": "00045"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthEast_denoised_ocl/SS_4_December_NorthEast_denoised_ocl_00046.jpg",
                "key": "00046"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthEast_denoised_ocl/SS_4_December_NorthEast_denoised_ocl_00047.jpg",
                "key": "00047"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthEast_denoised_ocl/SS_4_December_NorthEast_denoised_ocl_00048.jpg",
                "key": "00048"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthEast_denoised_ocl/SS_4_December_NorthEast_denoised_ocl_00049.jpg",
                "key": "00049"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthEast_denoised_ocl/SS_4_December_NorthEast_denoised_ocl_00050.jpg",
                "key": "00050"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthEast_denoised_ocl/SS_4_December_NorthEast_denoised_ocl_00051.jpg",
                "key": "00051"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthEast_denoised_ocl/SS_4_December_NorthEast_denoised_ocl_00052.jpg",
                "key": "00052"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthEast_denoised_ocl/SS_4_December_NorthEast_denoised_ocl_00053.jpg",
                "key": "00053"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthEast_denoised_ocl/SS_4_December_NorthEast_denoised_ocl_00054.jpg",
                "key": "00054"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthEast_denoised_ocl/SS_4_December_NorthEast_denoised_ocl_00055.jpg",
                "key": "00055"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthEast_denoised_ocl/SS_4_December_NorthEast_denoised_ocl_00056.jpg",
                "key": "00056"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthEast_denoised_ocl/SS_4_December_NorthEast_denoised_ocl_00057.jpg",
                "key": "00057"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthEast_denoised_ocl/SS_4_December_NorthEast_denoised_ocl_00058.jpg",
                "key": "00058"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthEast_denoised_ocl/SS_4_December_NorthEast_denoised_ocl_00059.jpg",
                "key": "00059"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthEast_denoised_ocl/SS_4_December_NorthEast_denoised_ocl_00060.jpg",
                "key": "00060"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthEast_denoised_ocl/SS_4_December_NorthEast_denoised_ocl_00061.jpg",
                "key": "00061"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthEast_denoised_ocl/SS_4_December_NorthEast_denoised_ocl_00062.jpg",
                "key": "00062"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthEast_denoised_ocl/SS_4_December_NorthEast_denoised_ocl_00063.jpg",
                "key": "00063"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthEast_denoised_ocl/SS_4_December_NorthEast_denoised_ocl_00064.jpg",
                "key": "00064"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthEast_denoised_ocl/SS_4_December_NorthEast_denoised_ocl_00065.jpg",
                "key": "00065"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthEast_denoised_ocl/SS_4_December_NorthEast_denoised_ocl_00066.jpg",
                "key": "00066"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthEast_denoised_ocl/SS_4_December_NorthEast_denoised_ocl_00067.jpg",
                "key": "00067"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthEast_denoised_ocl/SS_4_December_NorthEast_denoised_ocl_00068.jpg",
                "key": "00068"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthEast_denoised_ocl/SS_4_December_NorthEast_denoised_ocl_00069.jpg",
                "key": "00069"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthEast_denoised_ocl/SS_4_December_NorthEast_denoised_ocl_00070.jpg",
                "key": "00070"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthEast_denoised_ocl/SS_4_December_NorthEast_denoised_ocl_00071.jpg",
                "key": "00071"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthEast_denoised_ocl/SS_4_December_NorthEast_denoised_ocl_00072.jpg",
                "key": "00072"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthEast_denoised_ocl/SS_4_December_NorthEast_denoised_ocl_00073.jpg",
                "key": "00073"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthEast_denoised_ocl/SS_4_December_NorthEast_denoised_ocl_00074.jpg",
                "key": "00074"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthEast_denoised_ocl/SS_4_December_NorthEast_denoised_ocl_00075.jpg",
                "key": "00075"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthEast_denoised_ocl/SS_4_December_NorthEast_denoised_ocl_00076.jpg",
                "key": "00076"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthEast_denoised_ocl/SS_4_December_NorthEast_denoised_ocl_00077.jpg",
                "key": "00077"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthEast_denoised_ocl/SS_4_December_NorthEast_denoised_ocl_00078.jpg",
                "key": "00078"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthEast_denoised_ocl/SS_4_December_NorthEast_denoised_ocl_00079.jpg",
                "key": "00079"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthEast_denoised_ocl/SS_4_December_NorthEast_denoised_ocl_00080.jpg",
                "key": "00080"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthEast_denoised_ocl/SS_4_December_NorthEast_denoised_ocl_00081.jpg",
                "key": "00081"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthEast_denoised_ocl/SS_4_December_NorthEast_denoised_ocl_00082.jpg",
                "key": "00082"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthEast_denoised_ocl/SS_4_December_NorthEast_denoised_ocl_00083.jpg",
                "key": "00083"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthEast_denoised_ocl/SS_4_December_NorthEast_denoised_ocl_00084.jpg",
                "key": "00084"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthEast_denoised_ocl/SS_4_December_NorthEast_denoised_ocl_00085.jpg",
                "key": "00085"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthEast_denoised_ocl/SS_4_December_NorthEast_denoised_ocl_00086.jpg",
                "key": "00086"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthEast_denoised_ocl/SS_4_December_NorthEast_denoised_ocl_00087.jpg",
                "key": "00087"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthEast_denoised_ocl/SS_4_December_NorthEast_denoised_ocl_00088.jpg",
                "key": "00088"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthEast_denoised_ocl/SS_4_December_NorthEast_denoised_ocl_00089.jpg",
                "key": "00089"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthEast_denoised_ocl/SS_4_December_NorthEast_denoised_ocl_00090.jpg",
                "key": "00090"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthEast_denoised_ocl/SS_4_December_NorthEast_denoised_ocl_00091.jpg",
                "key": "00091"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthEast_denoised_ocl/SS_4_December_NorthEast_denoised_ocl_00092.jpg",
                "key": "00092"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthEast_denoised_ocl/SS_4_December_NorthEast_denoised_ocl_00093.jpg",
                "key": "00093"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthEast_denoised_ocl/SS_4_December_NorthEast_denoised_ocl_00094.jpg",
                "key": "00094"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthEast_denoised_ocl/SS_4_December_NorthEast_denoised_ocl_00095.jpg",
                "key": "00095"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthEast_denoised_ocl/SS_4_December_NorthEast_denoised_ocl_00096.jpg",
                "key": "00096"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthEast_denoised_ocl/SS_4_December_NorthEast_denoised_ocl_00097.jpg",
                "key": "00097"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthEast_denoised_ocl/SS_4_December_NorthEast_denoised_ocl_00098.jpg",
                "key": "00098"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthEast_denoised_ocl/SS_4_December_NorthEast_denoised_ocl_00099.jpg",
                "key": "00099"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthEast_denoised_ocl/SS_4_December_NorthEast_denoised_ocl_00100.jpg",
                "key": "00100"
            }
        ]
    },
    {
        "id": "SS_4_December_NorthWest_denoised_ocl",
        "key": "westwinter",
        "items": [
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthWest_denoised_ocl/SS_4_December_NorthWest_denoised_ocl_00001.jpg",
                "key": "00001"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthWest_denoised_ocl/SS_4_December_NorthWest_denoised_ocl_00002.jpg",
                "key": "00002"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthWest_denoised_ocl/SS_4_December_NorthWest_denoised_ocl_00003.jpg",
                "key": "00003"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthWest_denoised_ocl/SS_4_December_NorthWest_denoised_ocl_00004.jpg",
                "key": "00004"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthWest_denoised_ocl/SS_4_December_NorthWest_denoised_ocl_00005.jpg",
                "key": "00005"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthWest_denoised_ocl/SS_4_December_NorthWest_denoised_ocl_00006.jpg",
                "key": "00006"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthWest_denoised_ocl/SS_4_December_NorthWest_denoised_ocl_00007.jpg",
                "key": "00007"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthWest_denoised_ocl/SS_4_December_NorthWest_denoised_ocl_00008.jpg",
                "key": "00008"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthWest_denoised_ocl/SS_4_December_NorthWest_denoised_ocl_00009.jpg",
                "key": "00009"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthWest_denoised_ocl/SS_4_December_NorthWest_denoised_ocl_00010.jpg",
                "key": "00010"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthWest_denoised_ocl/SS_4_December_NorthWest_denoised_ocl_00011.jpg",
                "key": "00011"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthWest_denoised_ocl/SS_4_December_NorthWest_denoised_ocl_00012.jpg",
                "key": "00012"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthWest_denoised_ocl/SS_4_December_NorthWest_denoised_ocl_00013.jpg",
                "key": "00013"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthWest_denoised_ocl/SS_4_December_NorthWest_denoised_ocl_00014.jpg",
                "key": "00014"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthWest_denoised_ocl/SS_4_December_NorthWest_denoised_ocl_00015.jpg",
                "key": "00015"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthWest_denoised_ocl/SS_4_December_NorthWest_denoised_ocl_00016.jpg",
                "key": "00016"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthWest_denoised_ocl/SS_4_December_NorthWest_denoised_ocl_00017.jpg",
                "key": "00017"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthWest_denoised_ocl/SS_4_December_NorthWest_denoised_ocl_00018.jpg",
                "key": "00018"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthWest_denoised_ocl/SS_4_December_NorthWest_denoised_ocl_00019.jpg",
                "key": "00019"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthWest_denoised_ocl/SS_4_December_NorthWest_denoised_ocl_00020.jpg",
                "key": "00020"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthWest_denoised_ocl/SS_4_December_NorthWest_denoised_ocl_00021.jpg",
                "key": "00021"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthWest_denoised_ocl/SS_4_December_NorthWest_denoised_ocl_00022.jpg",
                "key": "00022"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthWest_denoised_ocl/SS_4_December_NorthWest_denoised_ocl_00023.jpg",
                "key": "00023"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthWest_denoised_ocl/SS_4_December_NorthWest_denoised_ocl_00024.jpg",
                "key": "00024"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthWest_denoised_ocl/SS_4_December_NorthWest_denoised_ocl_00025.jpg",
                "key": "00025"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthWest_denoised_ocl/SS_4_December_NorthWest_denoised_ocl_00026.jpg",
                "key": "00026"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthWest_denoised_ocl/SS_4_December_NorthWest_denoised_ocl_00027.jpg",
                "key": "00027"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthWest_denoised_ocl/SS_4_December_NorthWest_denoised_ocl_00028.jpg",
                "key": "00028"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthWest_denoised_ocl/SS_4_December_NorthWest_denoised_ocl_00029.jpg",
                "key": "00029"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthWest_denoised_ocl/SS_4_December_NorthWest_denoised_ocl_00030.jpg",
                "key": "00030"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthWest_denoised_ocl/SS_4_December_NorthWest_denoised_ocl_00031.jpg",
                "key": "00031"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthWest_denoised_ocl/SS_4_December_NorthWest_denoised_ocl_00032.jpg",
                "key": "00032"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthWest_denoised_ocl/SS_4_December_NorthWest_denoised_ocl_00033.jpg",
                "key": "00033"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthWest_denoised_ocl/SS_4_December_NorthWest_denoised_ocl_00034.jpg",
                "key": "00034"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthWest_denoised_ocl/SS_4_December_NorthWest_denoised_ocl_00035.jpg",
                "key": "00035"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthWest_denoised_ocl/SS_4_December_NorthWest_denoised_ocl_00036.jpg",
                "key": "00036"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthWest_denoised_ocl/SS_4_December_NorthWest_denoised_ocl_00037.jpg",
                "key": "00037"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthWest_denoised_ocl/SS_4_December_NorthWest_denoised_ocl_00038.jpg",
                "key": "00038"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthWest_denoised_ocl/SS_4_December_NorthWest_denoised_ocl_00039.jpg",
                "key": "00039"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthWest_denoised_ocl/SS_4_December_NorthWest_denoised_ocl_00040.jpg",
                "key": "00040"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthWest_denoised_ocl/SS_4_December_NorthWest_denoised_ocl_00041.jpg",
                "key": "00041"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthWest_denoised_ocl/SS_4_December_NorthWest_denoised_ocl_00042.jpg",
                "key": "00042"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthWest_denoised_ocl/SS_4_December_NorthWest_denoised_ocl_00043.jpg",
                "key": "00043"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthWest_denoised_ocl/SS_4_December_NorthWest_denoised_ocl_00044.jpg",
                "key": "00044"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthWest_denoised_ocl/SS_4_December_NorthWest_denoised_ocl_00045.jpg",
                "key": "00045"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthWest_denoised_ocl/SS_4_December_NorthWest_denoised_ocl_00046.jpg",
                "key": "00046"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthWest_denoised_ocl/SS_4_December_NorthWest_denoised_ocl_00047.jpg",
                "key": "00047"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthWest_denoised_ocl/SS_4_December_NorthWest_denoised_ocl_00048.jpg",
                "key": "00048"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthWest_denoised_ocl/SS_4_December_NorthWest_denoised_ocl_00049.jpg",
                "key": "00049"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthWest_denoised_ocl/SS_4_December_NorthWest_denoised_ocl_00050.jpg",
                "key": "00050"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthWest_denoised_ocl/SS_4_December_NorthWest_denoised_ocl_00051.jpg",
                "key": "00051"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthWest_denoised_ocl/SS_4_December_NorthWest_denoised_ocl_00052.jpg",
                "key": "00052"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthWest_denoised_ocl/SS_4_December_NorthWest_denoised_ocl_00053.jpg",
                "key": "00053"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthWest_denoised_ocl/SS_4_December_NorthWest_denoised_ocl_00054.jpg",
                "key": "00054"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthWest_denoised_ocl/SS_4_December_NorthWest_denoised_ocl_00055.jpg",
                "key": "00055"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthWest_denoised_ocl/SS_4_December_NorthWest_denoised_ocl_00056.jpg",
                "key": "00056"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthWest_denoised_ocl/SS_4_December_NorthWest_denoised_ocl_00057.jpg",
                "key": "00057"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthWest_denoised_ocl/SS_4_December_NorthWest_denoised_ocl_00058.jpg",
                "key": "00058"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthWest_denoised_ocl/SS_4_December_NorthWest_denoised_ocl_00059.jpg",
                "key": "00059"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthWest_denoised_ocl/SS_4_December_NorthWest_denoised_ocl_00060.jpg",
                "key": "00060"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthWest_denoised_ocl/SS_4_December_NorthWest_denoised_ocl_00061.jpg",
                "key": "00061"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthWest_denoised_ocl/SS_4_December_NorthWest_denoised_ocl_00062.jpg",
                "key": "00062"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthWest_denoised_ocl/SS_4_December_NorthWest_denoised_ocl_00063.jpg",
                "key": "00063"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthWest_denoised_ocl/SS_4_December_NorthWest_denoised_ocl_00064.jpg",
                "key": "00064"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthWest_denoised_ocl/SS_4_December_NorthWest_denoised_ocl_00065.jpg",
                "key": "00065"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthWest_denoised_ocl/SS_4_December_NorthWest_denoised_ocl_00066.jpg",
                "key": "00066"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthWest_denoised_ocl/SS_4_December_NorthWest_denoised_ocl_00067.jpg",
                "key": "00067"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthWest_denoised_ocl/SS_4_December_NorthWest_denoised_ocl_00068.jpg",
                "key": "00068"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthWest_denoised_ocl/SS_4_December_NorthWest_denoised_ocl_00069.jpg",
                "key": "00069"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthWest_denoised_ocl/SS_4_December_NorthWest_denoised_ocl_00070.jpg",
                "key": "00070"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthWest_denoised_ocl/SS_4_December_NorthWest_denoised_ocl_00071.jpg",
                "key": "00071"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthWest_denoised_ocl/SS_4_December_NorthWest_denoised_ocl_00072.jpg",
                "key": "00072"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthWest_denoised_ocl/SS_4_December_NorthWest_denoised_ocl_00073.jpg",
                "key": "00073"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthWest_denoised_ocl/SS_4_December_NorthWest_denoised_ocl_00074.jpg",
                "key": "00074"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthWest_denoised_ocl/SS_4_December_NorthWest_denoised_ocl_00075.jpg",
                "key": "00075"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthWest_denoised_ocl/SS_4_December_NorthWest_denoised_ocl_00076.jpg",
                "key": "00076"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthWest_denoised_ocl/SS_4_December_NorthWest_denoised_ocl_00077.jpg",
                "key": "00077"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthWest_denoised_ocl/SS_4_December_NorthWest_denoised_ocl_00078.jpg",
                "key": "00078"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthWest_denoised_ocl/SS_4_December_NorthWest_denoised_ocl_00079.jpg",
                "key": "00079"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthWest_denoised_ocl/SS_4_December_NorthWest_denoised_ocl_00080.jpg",
                "key": "00080"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthWest_denoised_ocl/SS_4_December_NorthWest_denoised_ocl_00081.jpg",
                "key": "00081"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthWest_denoised_ocl/SS_4_December_NorthWest_denoised_ocl_00082.jpg",
                "key": "00082"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthWest_denoised_ocl/SS_4_December_NorthWest_denoised_ocl_00083.jpg",
                "key": "00083"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthWest_denoised_ocl/SS_4_December_NorthWest_denoised_ocl_00084.jpg",
                "key": "00084"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthWest_denoised_ocl/SS_4_December_NorthWest_denoised_ocl_00085.jpg",
                "key": "00085"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthWest_denoised_ocl/SS_4_December_NorthWest_denoised_ocl_00086.jpg",
                "key": "00086"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthWest_denoised_ocl/SS_4_December_NorthWest_denoised_ocl_00087.jpg",
                "key": "00087"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthWest_denoised_ocl/SS_4_December_NorthWest_denoised_ocl_00088.jpg",
                "key": "00088"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthWest_denoised_ocl/SS_4_December_NorthWest_denoised_ocl_00089.jpg",
                "key": "00089"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthWest_denoised_ocl/SS_4_December_NorthWest_denoised_ocl_00090.jpg",
                "key": "00090"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthWest_denoised_ocl/SS_4_December_NorthWest_denoised_ocl_00091.jpg",
                "key": "00091"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthWest_denoised_ocl/SS_4_December_NorthWest_denoised_ocl_00092.jpg",
                "key": "00092"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthWest_denoised_ocl/SS_4_December_NorthWest_denoised_ocl_00093.jpg",
                "key": "00093"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthWest_denoised_ocl/SS_4_December_NorthWest_denoised_ocl_00094.jpg",
                "key": "00094"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthWest_denoised_ocl/SS_4_December_NorthWest_denoised_ocl_00095.jpg",
                "key": "00095"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthWest_denoised_ocl/SS_4_December_NorthWest_denoised_ocl_00096.jpg",
                "key": "00096"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthWest_denoised_ocl/SS_4_December_NorthWest_denoised_ocl_00097.jpg",
                "key": "00097"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthWest_denoised_ocl/SS_4_December_NorthWest_denoised_ocl_00098.jpg",
                "key": "00098"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthWest_denoised_ocl/SS_4_December_NorthWest_denoised_ocl_00099.jpg",
                "key": "00099"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_NorthWest_denoised_ocl/SS_4_December_NorthWest_denoised_ocl_00100.jpg",
                "key": "00100"
            }
        ]
    },
    {
        "id": "SS_4_December_SouthEast_denoised_ocl",
        "key": "eastwinter",
        "items": [
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthEast_denoised_ocl/SS_4_December_SouthEast_denoised_ocl_00001.jpg",
                "key": "00001"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthEast_denoised_ocl/SS_4_December_SouthEast_denoised_ocl_00002.jpg",
                "key": "00002"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthEast_denoised_ocl/SS_4_December_SouthEast_denoised_ocl_00003.jpg",
                "key": "00003"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthEast_denoised_ocl/SS_4_December_SouthEast_denoised_ocl_00004.jpg",
                "key": "00004"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthEast_denoised_ocl/SS_4_December_SouthEast_denoised_ocl_00005.jpg",
                "key": "00005"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthEast_denoised_ocl/SS_4_December_SouthEast_denoised_ocl_00006.jpg",
                "key": "00006"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthEast_denoised_ocl/SS_4_December_SouthEast_denoised_ocl_00007.jpg",
                "key": "00007"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthEast_denoised_ocl/SS_4_December_SouthEast_denoised_ocl_00008.jpg",
                "key": "00008"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthEast_denoised_ocl/SS_4_December_SouthEast_denoised_ocl_00009.jpg",
                "key": "00009"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthEast_denoised_ocl/SS_4_December_SouthEast_denoised_ocl_00010.jpg",
                "key": "00010"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthEast_denoised_ocl/SS_4_December_SouthEast_denoised_ocl_00011.jpg",
                "key": "00011"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthEast_denoised_ocl/SS_4_December_SouthEast_denoised_ocl_00012.jpg",
                "key": "00012"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthEast_denoised_ocl/SS_4_December_SouthEast_denoised_ocl_00013.jpg",
                "key": "00013"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthEast_denoised_ocl/SS_4_December_SouthEast_denoised_ocl_00014.jpg",
                "key": "00014"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthEast_denoised_ocl/SS_4_December_SouthEast_denoised_ocl_00015.jpg",
                "key": "00015"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthEast_denoised_ocl/SS_4_December_SouthEast_denoised_ocl_00016.jpg",
                "key": "00016"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthEast_denoised_ocl/SS_4_December_SouthEast_denoised_ocl_00017.jpg",
                "key": "00017"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthEast_denoised_ocl/SS_4_December_SouthEast_denoised_ocl_00018.jpg",
                "key": "00018"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthEast_denoised_ocl/SS_4_December_SouthEast_denoised_ocl_00019.jpg",
                "key": "00019"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthEast_denoised_ocl/SS_4_December_SouthEast_denoised_ocl_00020.jpg",
                "key": "00020"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthEast_denoised_ocl/SS_4_December_SouthEast_denoised_ocl_00021.jpg",
                "key": "00021"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthEast_denoised_ocl/SS_4_December_SouthEast_denoised_ocl_00022.jpg",
                "key": "00022"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthEast_denoised_ocl/SS_4_December_SouthEast_denoised_ocl_00023.jpg",
                "key": "00023"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthEast_denoised_ocl/SS_4_December_SouthEast_denoised_ocl_00024.jpg",
                "key": "00024"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthEast_denoised_ocl/SS_4_December_SouthEast_denoised_ocl_00025.jpg",
                "key": "00025"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthEast_denoised_ocl/SS_4_December_SouthEast_denoised_ocl_00026.jpg",
                "key": "00026"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthEast_denoised_ocl/SS_4_December_SouthEast_denoised_ocl_00027.jpg",
                "key": "00027"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthEast_denoised_ocl/SS_4_December_SouthEast_denoised_ocl_00028.jpg",
                "key": "00028"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthEast_denoised_ocl/SS_4_December_SouthEast_denoised_ocl_00029.jpg",
                "key": "00029"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthEast_denoised_ocl/SS_4_December_SouthEast_denoised_ocl_00030.jpg",
                "key": "00030"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthEast_denoised_ocl/SS_4_December_SouthEast_denoised_ocl_00031.jpg",
                "key": "00031"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthEast_denoised_ocl/SS_4_December_SouthEast_denoised_ocl_00032.jpg",
                "key": "00032"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthEast_denoised_ocl/SS_4_December_SouthEast_denoised_ocl_00033.jpg",
                "key": "00033"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthEast_denoised_ocl/SS_4_December_SouthEast_denoised_ocl_00034.jpg",
                "key": "00034"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthEast_denoised_ocl/SS_4_December_SouthEast_denoised_ocl_00035.jpg",
                "key": "00035"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthEast_denoised_ocl/SS_4_December_SouthEast_denoised_ocl_00036.jpg",
                "key": "00036"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthEast_denoised_ocl/SS_4_December_SouthEast_denoised_ocl_00037.jpg",
                "key": "00037"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthEast_denoised_ocl/SS_4_December_SouthEast_denoised_ocl_00038.jpg",
                "key": "00038"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthEast_denoised_ocl/SS_4_December_SouthEast_denoised_ocl_00039.jpg",
                "key": "00039"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthEast_denoised_ocl/SS_4_December_SouthEast_denoised_ocl_00040.jpg",
                "key": "00040"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthEast_denoised_ocl/SS_4_December_SouthEast_denoised_ocl_00041.jpg",
                "key": "00041"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthEast_denoised_ocl/SS_4_December_SouthEast_denoised_ocl_00042.jpg",
                "key": "00042"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthEast_denoised_ocl/SS_4_December_SouthEast_denoised_ocl_00043.jpg",
                "key": "00043"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthEast_denoised_ocl/SS_4_December_SouthEast_denoised_ocl_00044.jpg",
                "key": "00044"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthEast_denoised_ocl/SS_4_December_SouthEast_denoised_ocl_00045.jpg",
                "key": "00045"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthEast_denoised_ocl/SS_4_December_SouthEast_denoised_ocl_00046.jpg",
                "key": "00046"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthEast_denoised_ocl/SS_4_December_SouthEast_denoised_ocl_00047.jpg",
                "key": "00047"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthEast_denoised_ocl/SS_4_December_SouthEast_denoised_ocl_00048.jpg",
                "key": "00048"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthEast_denoised_ocl/SS_4_December_SouthEast_denoised_ocl_00049.jpg",
                "key": "00049"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthEast_denoised_ocl/SS_4_December_SouthEast_denoised_ocl_00050.jpg",
                "key": "00050"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthEast_denoised_ocl/SS_4_December_SouthEast_denoised_ocl_00051.jpg",
                "key": "00051"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthEast_denoised_ocl/SS_4_December_SouthEast_denoised_ocl_00052.jpg",
                "key": "00052"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthEast_denoised_ocl/SS_4_December_SouthEast_denoised_ocl_00053.jpg",
                "key": "00053"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthEast_denoised_ocl/SS_4_December_SouthEast_denoised_ocl_00054.jpg",
                "key": "00054"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthEast_denoised_ocl/SS_4_December_SouthEast_denoised_ocl_00055.jpg",
                "key": "00055"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthEast_denoised_ocl/SS_4_December_SouthEast_denoised_ocl_00056.jpg",
                "key": "00056"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthEast_denoised_ocl/SS_4_December_SouthEast_denoised_ocl_00057.jpg",
                "key": "00057"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthEast_denoised_ocl/SS_4_December_SouthEast_denoised_ocl_00058.jpg",
                "key": "00058"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthEast_denoised_ocl/SS_4_December_SouthEast_denoised_ocl_00059.jpg",
                "key": "00059"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthEast_denoised_ocl/SS_4_December_SouthEast_denoised_ocl_00060.jpg",
                "key": "00060"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthEast_denoised_ocl/SS_4_December_SouthEast_denoised_ocl_00061.jpg",
                "key": "00061"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthEast_denoised_ocl/SS_4_December_SouthEast_denoised_ocl_00062.jpg",
                "key": "00062"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthEast_denoised_ocl/SS_4_December_SouthEast_denoised_ocl_00063.jpg",
                "key": "00063"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthEast_denoised_ocl/SS_4_December_SouthEast_denoised_ocl_00064.jpg",
                "key": "00064"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthEast_denoised_ocl/SS_4_December_SouthEast_denoised_ocl_00065.jpg",
                "key": "00065"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthEast_denoised_ocl/SS_4_December_SouthEast_denoised_ocl_00066.jpg",
                "key": "00066"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthEast_denoised_ocl/SS_4_December_SouthEast_denoised_ocl_00067.jpg",
                "key": "00067"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthEast_denoised_ocl/SS_4_December_SouthEast_denoised_ocl_00068.jpg",
                "key": "00068"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthEast_denoised_ocl/SS_4_December_SouthEast_denoised_ocl_00069.jpg",
                "key": "00069"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthEast_denoised_ocl/SS_4_December_SouthEast_denoised_ocl_00070.jpg",
                "key": "00070"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthEast_denoised_ocl/SS_4_December_SouthEast_denoised_ocl_00071.jpg",
                "key": "00071"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthEast_denoised_ocl/SS_4_December_SouthEast_denoised_ocl_00072.jpg",
                "key": "00072"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthEast_denoised_ocl/SS_4_December_SouthEast_denoised_ocl_00073.jpg",
                "key": "00073"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthEast_denoised_ocl/SS_4_December_SouthEast_denoised_ocl_00074.jpg",
                "key": "00074"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthEast_denoised_ocl/SS_4_December_SouthEast_denoised_ocl_00075.jpg",
                "key": "00075"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthEast_denoised_ocl/SS_4_December_SouthEast_denoised_ocl_00076.jpg",
                "key": "00076"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthEast_denoised_ocl/SS_4_December_SouthEast_denoised_ocl_00077.jpg",
                "key": "00077"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthEast_denoised_ocl/SS_4_December_SouthEast_denoised_ocl_00078.jpg",
                "key": "00078"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthEast_denoised_ocl/SS_4_December_SouthEast_denoised_ocl_00079.jpg",
                "key": "00079"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthEast_denoised_ocl/SS_4_December_SouthEast_denoised_ocl_00080.jpg",
                "key": "00080"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthEast_denoised_ocl/SS_4_December_SouthEast_denoised_ocl_00081.jpg",
                "key": "00081"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthEast_denoised_ocl/SS_4_December_SouthEast_denoised_ocl_00082.jpg",
                "key": "00082"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthEast_denoised_ocl/SS_4_December_SouthEast_denoised_ocl_00083.jpg",
                "key": "00083"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthEast_denoised_ocl/SS_4_December_SouthEast_denoised_ocl_00084.jpg",
                "key": "00084"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthEast_denoised_ocl/SS_4_December_SouthEast_denoised_ocl_00085.jpg",
                "key": "00085"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthEast_denoised_ocl/SS_4_December_SouthEast_denoised_ocl_00086.jpg",
                "key": "00086"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthEast_denoised_ocl/SS_4_December_SouthEast_denoised_ocl_00087.jpg",
                "key": "00087"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthEast_denoised_ocl/SS_4_December_SouthEast_denoised_ocl_00088.jpg",
                "key": "00088"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthEast_denoised_ocl/SS_4_December_SouthEast_denoised_ocl_00089.jpg",
                "key": "00089"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthEast_denoised_ocl/SS_4_December_SouthEast_denoised_ocl_00090.jpg",
                "key": "00090"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthEast_denoised_ocl/SS_4_December_SouthEast_denoised_ocl_00091.jpg",
                "key": "00091"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthEast_denoised_ocl/SS_4_December_SouthEast_denoised_ocl_00092.jpg",
                "key": "00092"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthEast_denoised_ocl/SS_4_December_SouthEast_denoised_ocl_00093.jpg",
                "key": "00093"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthEast_denoised_ocl/SS_4_December_SouthEast_denoised_ocl_00094.jpg",
                "key": "00094"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthEast_denoised_ocl/SS_4_December_SouthEast_denoised_ocl_00095.jpg",
                "key": "00095"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthEast_denoised_ocl/SS_4_December_SouthEast_denoised_ocl_00096.jpg",
                "key": "00096"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthEast_denoised_ocl/SS_4_December_SouthEast_denoised_ocl_00097.jpg",
                "key": "00097"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthEast_denoised_ocl/SS_4_December_SouthEast_denoised_ocl_00098.jpg",
                "key": "00098"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthEast_denoised_ocl/SS_4_December_SouthEast_denoised_ocl_00099.jpg",
                "key": "00099"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthEast_denoised_ocl/SS_4_December_SouthEast_denoised_ocl_00100.jpg",
                "key": "00100"
            }
        ]
    },
    {
        "id": "SS_4_December_SouthWest_denoised_ocl",
        "key": "southwinter",
        "items": [
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthWest_denoised_ocl/SS_4_December_SouthWest_denoised_ocl_00001.jpg",
                "key": "00001"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthWest_denoised_ocl/SS_4_December_SouthWest_denoised_ocl_00002.jpg",
                "key": "00002"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthWest_denoised_ocl/SS_4_December_SouthWest_denoised_ocl_00003.jpg",
                "key": "00003"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthWest_denoised_ocl/SS_4_December_SouthWest_denoised_ocl_00004.jpg",
                "key": "00004"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthWest_denoised_ocl/SS_4_December_SouthWest_denoised_ocl_00005.jpg",
                "key": "00005"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthWest_denoised_ocl/SS_4_December_SouthWest_denoised_ocl_00006.jpg",
                "key": "00006"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthWest_denoised_ocl/SS_4_December_SouthWest_denoised_ocl_00007.jpg",
                "key": "00007"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthWest_denoised_ocl/SS_4_December_SouthWest_denoised_ocl_00008.jpg",
                "key": "00008"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthWest_denoised_ocl/SS_4_December_SouthWest_denoised_ocl_00009.jpg",
                "key": "00009"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthWest_denoised_ocl/SS_4_December_SouthWest_denoised_ocl_00010.jpg",
                "key": "00010"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthWest_denoised_ocl/SS_4_December_SouthWest_denoised_ocl_00011.jpg",
                "key": "00011"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthWest_denoised_ocl/SS_4_December_SouthWest_denoised_ocl_00012.jpg",
                "key": "00012"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthWest_denoised_ocl/SS_4_December_SouthWest_denoised_ocl_00013.jpg",
                "key": "00013"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthWest_denoised_ocl/SS_4_December_SouthWest_denoised_ocl_00014.jpg",
                "key": "00014"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthWest_denoised_ocl/SS_4_December_SouthWest_denoised_ocl_00015.jpg",
                "key": "00015"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthWest_denoised_ocl/SS_4_December_SouthWest_denoised_ocl_00016.jpg",
                "key": "00016"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthWest_denoised_ocl/SS_4_December_SouthWest_denoised_ocl_00017.jpg",
                "key": "00017"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthWest_denoised_ocl/SS_4_December_SouthWest_denoised_ocl_00018.jpg",
                "key": "00018"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthWest_denoised_ocl/SS_4_December_SouthWest_denoised_ocl_00019.jpg",
                "key": "00019"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthWest_denoised_ocl/SS_4_December_SouthWest_denoised_ocl_00020.jpg",
                "key": "00020"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthWest_denoised_ocl/SS_4_December_SouthWest_denoised_ocl_00021.jpg",
                "key": "00021"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthWest_denoised_ocl/SS_4_December_SouthWest_denoised_ocl_00022.jpg",
                "key": "00022"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthWest_denoised_ocl/SS_4_December_SouthWest_denoised_ocl_00023.jpg",
                "key": "00023"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthWest_denoised_ocl/SS_4_December_SouthWest_denoised_ocl_00024.jpg",
                "key": "00024"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthWest_denoised_ocl/SS_4_December_SouthWest_denoised_ocl_00025.jpg",
                "key": "00025"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthWest_denoised_ocl/SS_4_December_SouthWest_denoised_ocl_00026.jpg",
                "key": "00026"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthWest_denoised_ocl/SS_4_December_SouthWest_denoised_ocl_00027.jpg",
                "key": "00027"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthWest_denoised_ocl/SS_4_December_SouthWest_denoised_ocl_00028.jpg",
                "key": "00028"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthWest_denoised_ocl/SS_4_December_SouthWest_denoised_ocl_00029.jpg",
                "key": "00029"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthWest_denoised_ocl/SS_4_December_SouthWest_denoised_ocl_00030.jpg",
                "key": "00030"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthWest_denoised_ocl/SS_4_December_SouthWest_denoised_ocl_00031.jpg",
                "key": "00031"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthWest_denoised_ocl/SS_4_December_SouthWest_denoised_ocl_00032.jpg",
                "key": "00032"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthWest_denoised_ocl/SS_4_December_SouthWest_denoised_ocl_00033.jpg",
                "key": "00033"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthWest_denoised_ocl/SS_4_December_SouthWest_denoised_ocl_00034.jpg",
                "key": "00034"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthWest_denoised_ocl/SS_4_December_SouthWest_denoised_ocl_00035.jpg",
                "key": "00035"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthWest_denoised_ocl/SS_4_December_SouthWest_denoised_ocl_00036.jpg",
                "key": "00036"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthWest_denoised_ocl/SS_4_December_SouthWest_denoised_ocl_00037.jpg",
                "key": "00037"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthWest_denoised_ocl/SS_4_December_SouthWest_denoised_ocl_00038.jpg",
                "key": "00038"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthWest_denoised_ocl/SS_4_December_SouthWest_denoised_ocl_00039.jpg",
                "key": "00039"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthWest_denoised_ocl/SS_4_December_SouthWest_denoised_ocl_00040.jpg",
                "key": "00040"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthWest_denoised_ocl/SS_4_December_SouthWest_denoised_ocl_00041.jpg",
                "key": "00041"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthWest_denoised_ocl/SS_4_December_SouthWest_denoised_ocl_00042.jpg",
                "key": "00042"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthWest_denoised_ocl/SS_4_December_SouthWest_denoised_ocl_00043.jpg",
                "key": "00043"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthWest_denoised_ocl/SS_4_December_SouthWest_denoised_ocl_00044.jpg",
                "key": "00044"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthWest_denoised_ocl/SS_4_December_SouthWest_denoised_ocl_00045.jpg",
                "key": "00045"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthWest_denoised_ocl/SS_4_December_SouthWest_denoised_ocl_00046.jpg",
                "key": "00046"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthWest_denoised_ocl/SS_4_December_SouthWest_denoised_ocl_00047.jpg",
                "key": "00047"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthWest_denoised_ocl/SS_4_December_SouthWest_denoised_ocl_00048.jpg",
                "key": "00048"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthWest_denoised_ocl/SS_4_December_SouthWest_denoised_ocl_00049.jpg",
                "key": "00049"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthWest_denoised_ocl/SS_4_December_SouthWest_denoised_ocl_00050.jpg",
                "key": "00050"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthWest_denoised_ocl/SS_4_December_SouthWest_denoised_ocl_00051.jpg",
                "key": "00051"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthWest_denoised_ocl/SS_4_December_SouthWest_denoised_ocl_00052.jpg",
                "key": "00052"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthWest_denoised_ocl/SS_4_December_SouthWest_denoised_ocl_00053.jpg",
                "key": "00053"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthWest_denoised_ocl/SS_4_December_SouthWest_denoised_ocl_00054.jpg",
                "key": "00054"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthWest_denoised_ocl/SS_4_December_SouthWest_denoised_ocl_00055.jpg",
                "key": "00055"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthWest_denoised_ocl/SS_4_December_SouthWest_denoised_ocl_00056.jpg",
                "key": "00056"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthWest_denoised_ocl/SS_4_December_SouthWest_denoised_ocl_00057.jpg",
                "key": "00057"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthWest_denoised_ocl/SS_4_December_SouthWest_denoised_ocl_00058.jpg",
                "key": "00058"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthWest_denoised_ocl/SS_4_December_SouthWest_denoised_ocl_00059.jpg",
                "key": "00059"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthWest_denoised_ocl/SS_4_December_SouthWest_denoised_ocl_00060.jpg",
                "key": "00060"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthWest_denoised_ocl/SS_4_December_SouthWest_denoised_ocl_00061.jpg",
                "key": "00061"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthWest_denoised_ocl/SS_4_December_SouthWest_denoised_ocl_00062.jpg",
                "key": "00062"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthWest_denoised_ocl/SS_4_December_SouthWest_denoised_ocl_00063.jpg",
                "key": "00063"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthWest_denoised_ocl/SS_4_December_SouthWest_denoised_ocl_00064.jpg",
                "key": "00064"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthWest_denoised_ocl/SS_4_December_SouthWest_denoised_ocl_00065.jpg",
                "key": "00065"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthWest_denoised_ocl/SS_4_December_SouthWest_denoised_ocl_00066.jpg",
                "key": "00066"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthWest_denoised_ocl/SS_4_December_SouthWest_denoised_ocl_00067.jpg",
                "key": "00067"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthWest_denoised_ocl/SS_4_December_SouthWest_denoised_ocl_00068.jpg",
                "key": "00068"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthWest_denoised_ocl/SS_4_December_SouthWest_denoised_ocl_00069.jpg",
                "key": "00069"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthWest_denoised_ocl/SS_4_December_SouthWest_denoised_ocl_00070.jpg",
                "key": "00070"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthWest_denoised_ocl/SS_4_December_SouthWest_denoised_ocl_00071.jpg",
                "key": "00071"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthWest_denoised_ocl/SS_4_December_SouthWest_denoised_ocl_00072.jpg",
                "key": "00072"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthWest_denoised_ocl/SS_4_December_SouthWest_denoised_ocl_00073.jpg",
                "key": "00073"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthWest_denoised_ocl/SS_4_December_SouthWest_denoised_ocl_00074.jpg",
                "key": "00074"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthWest_denoised_ocl/SS_4_December_SouthWest_denoised_ocl_00075.jpg",
                "key": "00075"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthWest_denoised_ocl/SS_4_December_SouthWest_denoised_ocl_00076.jpg",
                "key": "00076"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthWest_denoised_ocl/SS_4_December_SouthWest_denoised_ocl_00077.jpg",
                "key": "00077"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthWest_denoised_ocl/SS_4_December_SouthWest_denoised_ocl_00078.jpg",
                "key": "00078"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthWest_denoised_ocl/SS_4_December_SouthWest_denoised_ocl_00079.jpg",
                "key": "00079"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthWest_denoised_ocl/SS_4_December_SouthWest_denoised_ocl_00080.jpg",
                "key": "00080"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthWest_denoised_ocl/SS_4_December_SouthWest_denoised_ocl_00081.jpg",
                "key": "00081"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthWest_denoised_ocl/SS_4_December_SouthWest_denoised_ocl_00082.jpg",
                "key": "00082"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthWest_denoised_ocl/SS_4_December_SouthWest_denoised_ocl_00083.jpg",
                "key": "00083"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthWest_denoised_ocl/SS_4_December_SouthWest_denoised_ocl_00084.jpg",
                "key": "00084"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthWest_denoised_ocl/SS_4_December_SouthWest_denoised_ocl_00085.jpg",
                "key": "00085"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthWest_denoised_ocl/SS_4_December_SouthWest_denoised_ocl_00086.jpg",
                "key": "00086"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthWest_denoised_ocl/SS_4_December_SouthWest_denoised_ocl_00087.jpg",
                "key": "00087"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthWest_denoised_ocl/SS_4_December_SouthWest_denoised_ocl_00088.jpg",
                "key": "00088"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthWest_denoised_ocl/SS_4_December_SouthWest_denoised_ocl_00089.jpg",
                "key": "00089"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthWest_denoised_ocl/SS_4_December_SouthWest_denoised_ocl_00090.jpg",
                "key": "00090"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthWest_denoised_ocl/SS_4_December_SouthWest_denoised_ocl_00091.jpg",
                "key": "00091"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthWest_denoised_ocl/SS_4_December_SouthWest_denoised_ocl_00092.jpg",
                "key": "00092"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthWest_denoised_ocl/SS_4_December_SouthWest_denoised_ocl_00093.jpg",
                "key": "00093"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthWest_denoised_ocl/SS_4_December_SouthWest_denoised_ocl_00094.jpg",
                "key": "00094"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthWest_denoised_ocl/SS_4_December_SouthWest_denoised_ocl_00095.jpg",
                "key": "00095"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthWest_denoised_ocl/SS_4_December_SouthWest_denoised_ocl_00096.jpg",
                "key": "00096"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthWest_denoised_ocl/SS_4_December_SouthWest_denoised_ocl_00097.jpg",
                "key": "00097"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthWest_denoised_ocl/SS_4_December_SouthWest_denoised_ocl_00098.jpg",
                "key": "00098"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthWest_denoised_ocl/SS_4_December_SouthWest_denoised_ocl_00099.jpg",
                "key": "00099"
            },
            {
                "url": "https://app.helloastoria.com/assets/sunStudy/SS_4_December_SouthWest_denoised_ocl/SS_4_December_SouthWest_denoised_ocl_00100.jpg",
                "key": "00100"
            }
        ]
    }
]