import FloorTwoFloorplate from '../assets/floorplates/floorplate-floor-2.png';

const FloorTwo = () => {
    return (
        <div className="suites__floorplate">
            <img src={FloorTwoFloorplate} alt="" />
            <div className="suites__floorplate--units">
            <svg id="Units" xmlns="http://www.w3.org/2000/svg" width="3855" height="2160" viewBox="-15 -8 3855 2160">
  <rect width="3840" height="2160" fill="none"/>
  <g className="suites__unit" id="C20">
    <polygon id="C20_UNIT_FREESIA" data-name="C20 UNIT FREESIA" points="1451.8 1412.9 1462.7 1422.7 1416.7 1473.7 1463.1 1515.6 1493.2 1482.1 1515.4 1502.1 1572.6 1438.6 1493.1 1367 1451.8 1412.9" fill="#dadada"/>
    <g>
      <path d="M1497.77,1419.59l2.24.71a5,5,0,0,1-1.71,2.78,4.87,4.87,0,0,1-3,.92,4.93,4.93,0,0,1-3.75-1.56,6,6,0,0,1-1.47-4.27,6.29,6.29,0,0,1,1.47-4.44,5.09,5.09,0,0,1,3.89-1.58,4.78,4.78,0,0,1,3.41,1.24,4.56,4.56,0,0,1,1.17,2.11l-2.29.55a2.43,2.43,0,0,0-.84-1.41,2.47,2.47,0,0,0-1.57-.51,2.61,2.61,0,0,0-2.07.91,4.48,4.48,0,0,0-.79,3,4.84,4.84,0,0,0,.78,3.1,2.55,2.55,0,0,0,2,.92,2.32,2.32,0,0,0,1.59-.59A3.38,3.38,0,0,0,1497.77,1419.59Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1508.57,1421.76v2h-7.69a5.2,5.2,0,0,1,.75-2.19,14.31,14.31,0,0,1,2.47-2.75,15.8,15.8,0,0,0,1.82-1.87,2.44,2.44,0,0,0,.45-1.35,1.55,1.55,0,0,0-.39-1.13,1.7,1.7,0,0,0-2.19,0,2.21,2.21,0,0,0-.47,1.38l-2.18-.22a3.61,3.61,0,0,1,1.22-2.6,4.11,4.11,0,0,1,2.58-.79,3.7,3.7,0,0,1,2.66.91,3,3,0,0,1,1,2.28,4.08,4.08,0,0,1-.27,1.47,6.09,6.09,0,0,1-.88,1.46,15.15,15.15,0,0,1-1.44,1.47q-1,.94-1.32,1.26a4.52,4.52,0,0,0-.45.61Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1514,1412.3a3.13,3.13,0,0,1,2.6,1.19c.75.93,1.12,2.49,1.12,4.66s-.38,3.73-1.13,4.67A3.13,3.13,0,0,1,1514,1424a3.27,3.27,0,0,1-2.69-1.29c-.69-.86-1-2.38-1-4.58a7.57,7.57,0,0,1,1.13-4.66A3.12,3.12,0,0,1,1514,1412.3Zm0,1.82a1.14,1.14,0,0,0-.71.25,1.69,1.69,0,0,0-.48.91,12.06,12.06,0,0,0-.23,2.87,12.82,12.82,0,0,0,.2,2.77,2,2,0,0,0,.51,1,1.13,1.13,0,0,0,1.42,0,1.72,1.72,0,0,0,.49-.91,12.56,12.56,0,0,0,.22-2.86,12.88,12.88,0,0,0-.2-2.77,2.08,2.08,0,0,0-.51-1A1.14,1.14,0,0,0,1514,1414.12Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1477.25,1440.8v-11.45H1485v1.35h-6.21v3.55h5.37v1.35h-5.37v5.2Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1485.59,1440.8v-11.45h5.08a6.6,6.6,0,0,1,2.32.31,2.46,2.46,0,0,1,1.28,1.09,3.29,3.29,0,0,1,.47,1.72,2.92,2.92,0,0,1-.78,2.06,4,4,0,0,1-2.44,1.06,3.78,3.78,0,0,1,.91.57,8.21,8.21,0,0,1,1.26,1.52l2,3.12h-1.9l-1.52-2.38c-.44-.69-.81-1.22-1.09-1.58a3.67,3.67,0,0,0-.77-.77,2.58,2.58,0,0,0-.69-.3,4.29,4.29,0,0,0-.85-.06h-1.76v5.09Zm1.51-6.4h3.26a5,5,0,0,0,1.63-.21,1.83,1.83,0,0,0,.89-.69,1.9,1.9,0,0,0,.3-1,1.69,1.69,0,0,0-.59-1.33,2.74,2.74,0,0,0-1.86-.53h-3.63Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1495.09,1440.8v-11.45h8.28v1.35h-6.77v3.51h6.34v1.34h-6.34v3.9h7v1.35Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1503.71,1440.8v-11.45H1512v1.35h-6.77v3.51h6.34v1.34h-6.34v3.9h7v1.35Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1512,1437.12l1.43-.12a3.18,3.18,0,0,0,.47,1.41,2.67,2.67,0,0,0,1.15.89,4.49,4.49,0,0,0,1.76.34,4.23,4.23,0,0,0,1.53-.26,2.1,2.1,0,0,0,1-.71,1.63,1.63,0,0,0,.33-1,1.49,1.49,0,0,0-.32-.94,2.37,2.37,0,0,0-1-.68c-.31-.12-1-.3-2-.56a10.55,10.55,0,0,1-2.21-.71,3.07,3.07,0,0,1-1.22-1.07,2.58,2.58,0,0,1-.4-1.42,2.88,2.88,0,0,1,.49-1.62,3,3,0,0,1,1.44-1.15,5.46,5.46,0,0,1,2.1-.39,5.74,5.74,0,0,1,2.24.41,3.24,3.24,0,0,1,1.5,1.21,3.39,3.39,0,0,1,.56,1.8l-1.45.11a2.36,2.36,0,0,0-.79-1.64,3.1,3.1,0,0,0-2-.55,3.23,3.23,0,0,0-2,.5,1.52,1.52,0,0,0-.63,1.22,1.3,1.3,0,0,0,.44,1,6,6,0,0,0,2.29.82,15.1,15.1,0,0,1,2.53.73,3.44,3.44,0,0,1,1.48,1.17,2.82,2.82,0,0,1,.48,1.63,3.13,3.13,0,0,1-.53,1.72,3.46,3.46,0,0,1-1.5,1.26,5.26,5.26,0,0,1-2.21.45,6.58,6.58,0,0,1-2.6-.46,3.62,3.62,0,0,1-1.65-1.36A3.85,3.85,0,0,1,1512,1437.12Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1520.91,1440.8v-11.45h1.52v11.45Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1523.73,1440.8l4.39-11.45h1.64l4.69,11.45h-1.73l-1.34-3.47h-4.79l-1.25,3.47Zm3.3-4.7h3.88l-1.19-3.18q-.56-1.44-.81-2.37a16.33,16.33,0,0,1-.62,2.19Z" fill="#59585b"/>
    </g>
  </g>
  <g className="suites__unit" id="C19a">
    <polygon id="C19_UNIT_IRIS" data-name="C19 UNIT IRIS" points="1495.8 1364.1 1575.2 1435.7 1575.9 1434.9 1569.7 1429.4 1571.1 1427.9 1631.2 1361.1 1557.9 1295.1 1495.8 1364.1" fill="#dadada"/>
    <g>
      <path d="M1558.7,1354.15l2.24.71a5,5,0,0,1-1.71,2.79,5.39,5.39,0,0,1-6.79-.65,6,6,0,0,1-1.47-4.26,6.3,6.3,0,0,1,1.47-4.45,5.09,5.09,0,0,1,3.89-1.58,4.78,4.78,0,0,1,3.41,1.24,4.56,4.56,0,0,1,1.17,2.11l-2.29.55a2.43,2.43,0,0,0-.84-1.41,2.47,2.47,0,0,0-1.57-.51,2.61,2.61,0,0,0-2.07.91,4.48,4.48,0,0,0-.79,3,4.84,4.84,0,0,0,.78,3.1,2.55,2.55,0,0,0,2,.92,2.32,2.32,0,0,0,1.59-.59A3.38,3.38,0,0,0,1558.7,1354.15Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1567.71,1358.36h-2.2v-8.27a7.59,7.59,0,0,1-2.83,1.66v-2a6.26,6.26,0,0,0,1.86-1.07,4,4,0,0,0,1.39-1.83h1.78Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1568.49,1355.71l2.12-.23a1.53,1.53,0,0,0,.41,1,1.2,1.2,0,0,0,.86.31,1.43,1.43,0,0,0,1.16-.62,5.36,5.36,0,0,0,.61-2.6,2.62,2.62,0,0,1-2.07,1,3.07,3.07,0,0,1-2.33-1,3.78,3.78,0,0,1-1-2.71,3.85,3.85,0,0,1,1-2.81,3.74,3.74,0,0,1,5.5.28,7,7,0,0,1,1.12,4.43q0,3.15-1.17,4.53a3.74,3.74,0,0,1-3,1.39,3.19,3.19,0,0,1-2.17-.71A3.44,3.44,0,0,1,1568.49,1355.71Zm5-4.79a2.54,2.54,0,0,0-.49-1.65,1.47,1.47,0,0,0-1.13-.59,1.25,1.25,0,0,0-1,.48,2.45,2.45,0,0,0-.41,1.58,2.49,2.49,0,0,0,.44,1.62,1.37,1.37,0,0,0,1.1.52,1.33,1.33,0,0,0,1.06-.5A2.16,2.16,0,0,0,1573.46,1350.92Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1552.11,1375.36v-11.45h1.52v11.45Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1555.94,1375.36v-11.45H1561a6.6,6.6,0,0,1,2.32.31,2.51,2.51,0,0,1,1.28,1.09,3.29,3.29,0,0,1,.47,1.72,2.89,2.89,0,0,1-.79,2.06,4,4,0,0,1-2.43,1.06,3.78,3.78,0,0,1,.91.57,8.21,8.21,0,0,1,1.26,1.52l2,3.12h-1.91l-1.51-2.38c-.44-.69-.81-1.22-1.09-1.58a3.94,3.94,0,0,0-.77-.77,2.51,2.51,0,0,0-.7-.3,4.11,4.11,0,0,0-.84-.06h-1.76v5.09Zm1.51-6.4h3.26a5,5,0,0,0,1.63-.21,1.77,1.77,0,0,0,.89-.69,1.9,1.9,0,0,0,.3-1,1.69,1.69,0,0,0-.59-1.33,2.74,2.74,0,0,0-1.86-.53h-3.63Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1565.66,1375.36v-11.45h1.52v11.45Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1569.19,1371.68l1.43-.12a3.18,3.18,0,0,0,.47,1.41,2.67,2.67,0,0,0,1.15.89,4.49,4.49,0,0,0,1.76.34,4.23,4.23,0,0,0,1.53-.26,2.15,2.15,0,0,0,1-.71,1.6,1.6,0,0,0,.32-1,1.48,1.48,0,0,0-.31-.94,2.37,2.37,0,0,0-1-.68,20.62,20.62,0,0,0-2-.55,11,11,0,0,1-2.21-.72,3.07,3.07,0,0,1-1.22-1.07,2.58,2.58,0,0,1-.4-1.42,2.88,2.88,0,0,1,.49-1.62,3,3,0,0,1,1.44-1.15,5.46,5.46,0,0,1,2.1-.39,5.77,5.77,0,0,1,2.24.41,3.3,3.3,0,0,1,1.5,1.21,3.39,3.39,0,0,1,.56,1.8l-1.45.11a2.36,2.36,0,0,0-.79-1.64,3.1,3.1,0,0,0-2-.55,3.21,3.21,0,0,0-2,.5,1.52,1.52,0,0,0-.63,1.22,1.3,1.3,0,0,0,.44,1,6,6,0,0,0,2.29.82,15.1,15.1,0,0,1,2.53.73,3.51,3.51,0,0,1,1.48,1.17,2.82,2.82,0,0,1,.48,1.63,3.11,3.11,0,0,1-.53,1.72,3.46,3.46,0,0,1-1.5,1.26,5.26,5.26,0,0,1-2.21.45,6.61,6.61,0,0,1-2.6-.46,3.62,3.62,0,0,1-1.65-1.36A3.85,3.85,0,0,1,1569.19,1371.68Z" fill="#59585b"/>
    </g>
  </g>
  <g className="suites__unit" id="C18a">
    <rect id="C18_UNIT_DAHLIA" data-name="C18 UNIT DAHLIA" x="1584.78" y="1247.61" width="75.5" height="98.7" transform="translate(-427.1 1634.15) rotate(-47.98)" fill="#dadada"/>
    <g>
      <path d="M1615.36,1282.5l2.24.71a5,5,0,0,1-1.71,2.79,5.39,5.39,0,0,1-6.79-.65,6,6,0,0,1-1.47-4.27,6.29,6.29,0,0,1,1.47-4.44,5.09,5.09,0,0,1,3.89-1.58,4.78,4.78,0,0,1,3.41,1.24,4.56,4.56,0,0,1,1.17,2.11l-2.29.55a2.43,2.43,0,0,0-.84-1.41,2.47,2.47,0,0,0-1.57-.51,2.61,2.61,0,0,0-2.07.91,4.48,4.48,0,0,0-.79,3,4.84,4.84,0,0,0,.78,3.1,2.55,2.55,0,0,0,2,.92,2.32,2.32,0,0,0,1.59-.59A3.38,3.38,0,0,0,1615.36,1282.5Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1624.37,1286.71h-2.2v-8.27a7.59,7.59,0,0,1-2.83,1.66v-2a6.26,6.26,0,0,0,1.86-1.07,4,4,0,0,0,1.39-1.83h1.78Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1627.16,1280.53a2.57,2.57,0,0,1-1.24-1,2.65,2.65,0,0,1-.38-1.38,2.77,2.77,0,0,1,.89-2.11,4.26,4.26,0,0,1,5.08,0,2.74,2.74,0,0,1,.9,2.11,2.54,2.54,0,0,1-.41,1.42,2.63,2.63,0,0,1-1.17,1,3.08,3.08,0,0,1,1.45,1.12,3,3,0,0,1,.5,1.69,3.47,3.47,0,0,1-1,2.58,3.69,3.69,0,0,1-2.69,1,4,4,0,0,1-2.6-.82,3.18,3.18,0,0,1-1.23-2.65,3.27,3.27,0,0,1,.46-1.71A3,3,0,0,1,1627.16,1280.53Zm.25,2.7a2,2,0,0,0,.47,1.41,1.5,1.5,0,0,0,1.16.51,1.46,1.46,0,0,0,1.12-.49,2,2,0,0,0,.45-1.41,1.77,1.77,0,0,0-.46-1.29,1.47,1.47,0,0,0-1.14-.49,1.36,1.36,0,0,0-1.2.55A2,2,0,0,0,1627.41,1283.23Zm.21-4.91a1.35,1.35,0,0,0,.37,1,1.3,1.3,0,0,0,1,.37,1.36,1.36,0,0,0,1-.37,1.38,1.38,0,0,0,.37-1,1.34,1.34,0,0,0-.37-1,1.51,1.51,0,0,0-2,0A1.34,1.34,0,0,0,1627.62,1278.32Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1595.57,1303.71v-11.45h4a9.6,9.6,0,0,1,2,.16,4,4,0,0,1,1.68.82,4.66,4.66,0,0,1,1.35,2,7.64,7.64,0,0,1,.45,2.72,8.09,8.09,0,0,1-.3,2.31,5.64,5.64,0,0,1-.78,1.67,4.13,4.13,0,0,1-1,1,4.22,4.22,0,0,1-1.36.57,7.85,7.85,0,0,1-1.84.2Zm1.52-1.35h2.45a5.93,5.93,0,0,0,1.77-.21,2.53,2.53,0,0,0,1-.6,3.59,3.59,0,0,0,.84-1.45,7,7,0,0,0,.3-2.2,5.34,5.34,0,0,0-.59-2.76,3,3,0,0,0-1.43-1.3,6.14,6.14,0,0,0-2-.23h-2.41Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1604.58,1303.71l4.4-11.45h1.63l4.69,11.45h-1.73l-1.34-3.47h-4.79l-1.25,3.47Zm3.3-4.7h3.88l-1.19-3.18q-.55-1.44-.81-2.37a16.33,16.33,0,0,1-.62,2.19Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1615.18,1303.71v-11.45h1.52v4.7h6v-4.7h1.52v11.45h-1.52v-5.4h-6v5.4Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1625.66,1303.71v-11.45h1.52v10.1h5.64v1.35Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1634.31,1303.71v-11.45h1.52v11.45Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1637.14,1303.71l4.4-11.45h1.63l4.69,11.45h-1.73l-1.34-3.47H1640l-1.25,3.47Zm3.3-4.7h3.88l-1.19-3.18q-.56-1.44-.81-2.37a16.33,16.33,0,0,1-.62,2.19Z" fill="#59585b"/>
    </g>
  </g>
  <g className="suites__unit" id="C17a">
    <polygon id="C17_UNIT_POPPY" data-name="C17 UNIT POPPY" points="1695.5 1176.5 1678.6 1161.2 1613.8 1233.1 1687.1 1299.1 1744.3 1235.7 1687.9 1184.9 1695.5 1176.5" fill="#dadada"/>
    <g>
      <path d="M1671.2,1219.52l2.24.71a5,5,0,0,1-1.71,2.79,5.39,5.39,0,0,1-6.79-.65,6,6,0,0,1-1.47-4.26,6.3,6.3,0,0,1,1.47-4.45,5.09,5.09,0,0,1,3.89-1.58,4.78,4.78,0,0,1,3.41,1.24,4.56,4.56,0,0,1,1.17,2.11l-2.29.55a2.43,2.43,0,0,0-.84-1.41,2.47,2.47,0,0,0-1.57-.51,2.61,2.61,0,0,0-2.07.91,4.48,4.48,0,0,0-.79,3,4.84,4.84,0,0,0,.78,3.1,2.55,2.55,0,0,0,2,.92,2.32,2.32,0,0,0,1.59-.59A3.38,3.38,0,0,0,1671.2,1219.52Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1680.21,1223.73H1678v-8.27a7.59,7.59,0,0,1-2.83,1.66v-2a6.26,6.26,0,0,0,1.86-1.07,4,4,0,0,0,1.39-1.83h1.78Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1681.07,1214.47v-2h7.51v1.6a11.77,11.77,0,0,0-1.89,2.62,15.43,15.43,0,0,0-1.47,3.64,13.48,13.48,0,0,0-.49,3.44h-2.12a14.7,14.7,0,0,1,1-4.84,16.26,16.26,0,0,1,2.47-4.42Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1652.85,1240.73v-11.45h4.32a9.72,9.72,0,0,1,1.75.11,3.43,3.43,0,0,1,1.41.53,2.75,2.75,0,0,1,.92,1.11,3.71,3.71,0,0,1-.58,4,4.44,4.44,0,0,1-3.36,1h-2.94v4.66Zm1.52-6h3a3.18,3.18,0,0,0,2.09-.54,2,2,0,0,0,.61-1.54,2.08,2.08,0,0,0-.36-1.23,1.65,1.65,0,0,0-1-.68,6.27,6.27,0,0,0-1.41-.1h-2.93Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1661.93,1235.15a6.21,6.21,0,0,1,1.53-4.46,5.22,5.22,0,0,1,4-1.62,5.56,5.56,0,0,1,2.86.76,5,5,0,0,1,1.94,2.12,6.89,6.89,0,0,1,.67,3.07,6.76,6.76,0,0,1-.71,3.12,4.82,4.82,0,0,1-2,2.08,5.72,5.72,0,0,1-2.78.71,5.42,5.42,0,0,1-2.89-.79,5,5,0,0,1-1.93-2.13A6.42,6.42,0,0,1,1661.93,1235.15Zm1.57,0a4.62,4.62,0,0,0,1.11,3.26,3.89,3.89,0,0,0,5.61,0,4.88,4.88,0,0,0,1.1-3.42,5.81,5.81,0,0,0-.47-2.44,3.65,3.65,0,0,0-1.38-1.62,3.78,3.78,0,0,0-2-.57,3.88,3.88,0,0,0-2.77,1.1A4.93,4.93,0,0,0,1663.5,1235.18Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1673.84,1240.73v-11.45h4.32a9.8,9.8,0,0,1,1.75.11,3.43,3.43,0,0,1,1.41.53,2.75,2.75,0,0,1,.92,1.11,3.71,3.71,0,0,1-.58,4,4.44,4.44,0,0,1-3.36,1h-2.94v4.66Zm1.52-6h3a3.18,3.18,0,0,0,2.09-.54,2,2,0,0,0,.61-1.54,2.08,2.08,0,0,0-.36-1.23,1.65,1.65,0,0,0-1-.68,6.27,6.27,0,0,0-1.41-.1h-2.93Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1682.93,1240.73v-11.45h4.32a9.8,9.8,0,0,1,1.75.11,3.43,3.43,0,0,1,1.41.53,2.75,2.75,0,0,1,.92,1.11,3.71,3.71,0,0,1-.58,4,4.44,4.44,0,0,1-3.36,1h-2.94v4.66Zm1.52-6h3a3.2,3.2,0,0,0,2.09-.54,2,2,0,0,0,.61-1.54,2.08,2.08,0,0,0-.36-1.23,1.65,1.65,0,0,0-1-.68,6.27,6.27,0,0,0-1.41-.1h-2.93Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1695.79,1240.73v-4.85l-4.41-6.6h1.84l2.26,3.45c.42.65.8,1.29,1.16,1.94.35-.6.76-1.28,1.25-2l2.22-3.36h1.77l-4.57,6.6v4.85Z" fill="#59585b"/>
    </g>
  </g>
  <g className="suites__unit" id="C16a">
    <polygon id="C16_UNIT_JASMINE" data-name="C16 UNIT JASMINE" points="1776.6 1153 1768.7 1145.8 1764.8 1150.1 1748 1134.9 1724.5 1161 1719.2 1156.2 1693.6 1184.6 1747 1232.7 1800.2 1173.7 1804.5 1177.5 1807.7 1173.9 1780.2 1149.1 1776.6 1153" fill="#dadada"/>
    <g>
      <path d="M1744.46,1167.8l2.24.71a5,5,0,0,1-1.71,2.78,4.87,4.87,0,0,1-3,.92,4.93,4.93,0,0,1-3.75-1.56,6,6,0,0,1-1.47-4.27,6.29,6.29,0,0,1,1.47-4.44,5.09,5.09,0,0,1,3.89-1.58,4.78,4.78,0,0,1,3.41,1.24,4.56,4.56,0,0,1,1.17,2.11l-2.29.55a2.43,2.43,0,0,0-.84-1.41,2.47,2.47,0,0,0-1.57-.51,2.61,2.61,0,0,0-2.07.91,4.54,4.54,0,0,0-.79,3,4.84,4.84,0,0,0,.78,3.1,2.55,2.55,0,0,0,2,.92,2.32,2.32,0,0,0,1.59-.59A3.38,3.38,0,0,0,1744.46,1167.8Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1753.47,1172h-2.2v-8.27a7.59,7.59,0,0,1-2.83,1.66v-2a6.26,6.26,0,0,0,1.86-1.07,4,4,0,0,0,1.39-1.83h1.78Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1761.71,1163.36l-2.13.24a1.56,1.56,0,0,0-.4-1,1.2,1.2,0,0,0-.86-.32,1.43,1.43,0,0,0-1.17.63,5.33,5.33,0,0,0-.61,2.6,2.6,2.6,0,0,1,2-1,3.12,3.12,0,0,1,2.36,1,3.81,3.81,0,0,1,1,2.7,3.91,3.91,0,0,1-1,2.82,3.74,3.74,0,0,1-5.5-.28,6.9,6.9,0,0,1-1.12-4.42,7.11,7.11,0,0,1,1.16-4.54,3.77,3.77,0,0,1,3-1.39,3.19,3.19,0,0,1,2.16.73A3.44,3.44,0,0,1,1761.71,1163.36Zm-5,4.79a2.46,2.46,0,0,0,.49,1.65,1.45,1.45,0,0,0,1.13.58,1.26,1.26,0,0,0,1-.47,2.41,2.41,0,0,0,.41-1.56,2.53,2.53,0,0,0-.44-1.64,1.4,1.4,0,0,0-2.16,0A2.14,2.14,0,0,0,1756.73,1168.15Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1719,1185.76l1.37-.19a2.89,2.89,0,0,0,.49,1.8,1.56,1.56,0,0,0,1.21.48,1.79,1.79,0,0,0,1-.26,1.38,1.38,0,0,0,.57-.71,4.94,4.94,0,0,0,.15-1.43v-7.89h1.52v7.8a5.74,5.74,0,0,1-.35,2.23,2.51,2.51,0,0,1-1.1,1.2,3.56,3.56,0,0,1-1.77.42,3,3,0,0,1-2.31-.87A3.61,3.61,0,0,1,1719,1185.76Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1726.45,1189l4.39-11.45h1.64l4.69,11.45h-1.73l-1.34-3.47h-4.79l-1.25,3.47Zm3.3-4.7h3.88l-1.19-3.18q-.56-1.44-.81-2.37a16.33,16.33,0,0,1-.62,2.19Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1736.82,1185.33l1.43-.12a3.18,3.18,0,0,0,.47,1.41,2.67,2.67,0,0,0,1.15.89,4.49,4.49,0,0,0,1.76.34,4.23,4.23,0,0,0,1.53-.26,2.15,2.15,0,0,0,1-.71,1.63,1.63,0,0,0,.33-1,1.49,1.49,0,0,0-.32-.94,2.37,2.37,0,0,0-1-.68c-.31-.12-1-.3-2-.56a10.55,10.55,0,0,1-2.21-.71,3.07,3.07,0,0,1-1.22-1.07,2.58,2.58,0,0,1-.4-1.42,2.88,2.88,0,0,1,.49-1.62,3,3,0,0,1,1.44-1.15,5.46,5.46,0,0,1,2.1-.39,5.74,5.74,0,0,1,2.24.41,3.24,3.24,0,0,1,1.5,1.21,3.39,3.39,0,0,1,.56,1.8l-1.45.11a2.36,2.36,0,0,0-.79-1.64,3.1,3.1,0,0,0-2-.55,3.23,3.23,0,0,0-2,.5,1.52,1.52,0,0,0-.63,1.22,1.3,1.3,0,0,0,.44,1,6,6,0,0,0,2.29.82,15.1,15.1,0,0,1,2.53.73,3.44,3.44,0,0,1,1.48,1.17,2.82,2.82,0,0,1,.48,1.63,3.13,3.13,0,0,1-.53,1.72,3.46,3.46,0,0,1-1.5,1.26,5.26,5.26,0,0,1-2.21.45,6.61,6.61,0,0,1-2.6-.46,3.62,3.62,0,0,1-1.65-1.36A3.85,3.85,0,0,1,1736.82,1185.33Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1745.66,1189v-11.45h2.28l2.71,8.11c.25.75.43,1.32.55,1.69.13-.42.33-1,.61-1.83l2.74-8h2V1189h-1.46v-9.59l-3.33,9.59h-1.37l-3.31-9.75V1189Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1758.09,1189v-11.45h1.52V1189Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1761.89,1189v-11.45h1.55l6,9v-9h1.45V1189h-1.55l-6-9v9Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1772.69,1189v-11.45H1781v1.35h-6.77v3.51h6.34v1.34h-6.34v3.9h7V1189Z" fill="#59585b"/>
    </g>
  </g>
  <g className="suites__unit" id="B1">
    <polygon id="B1_UNIT_DREAM" data-name="B1 UNIT DREAM" points="1638.3 443 1638.4 507 1645.2 507 1645.3 541.5 1713.6 541.5 1713.6 535.7 1728.5 535.7 1728.4 437 1638 437.1 1638 443 1638.3 443" fill="#dadada"/>
    <g>
      <path d="M1680.13,471.55h4.58a12.8,12.8,0,0,1,2,.11,3,3,0,0,1,1.19.47,2.91,2.91,0,0,1,.88,1,2.53,2.53,0,0,1,.35,1.34,2.68,2.68,0,0,1-1.61,2.48,3,3,0,0,1,1.61,1,2.75,2.75,0,0,1,.57,1.73,3.44,3.44,0,0,1-.37,1.52,3.09,3.09,0,0,1-1,1.18,3.37,3.37,0,0,1-1.55.54c-.39,0-1.32.07-2.79.08h-3.9Zm2.31,1.9v2.65H1684c.9,0,1.46,0,1.68,0a1.44,1.44,0,0,0,.93-.41,1.18,1.18,0,0,0,.34-.89,1.25,1.25,0,0,0-.29-.87,1.37,1.37,0,0,0-.87-.4c-.23,0-.89,0-2,0Zm0,4.56v3.06h2.14a10,10,0,0,0,1.59-.07,1.41,1.41,0,0,0,.84-.46,1.37,1.37,0,0,0,.32-1,1.44,1.44,0,0,0-.25-.87,1.37,1.37,0,0,0-.72-.53,7.87,7.87,0,0,0-2-.16Z" fill="#59585b"/>
      <path d="M1696.81,483h-2.19v-8.27a7.64,7.64,0,0,1-2.84,1.66v-2a6.3,6.3,0,0,0,1.87-1.07,4,4,0,0,0,1.38-1.83h1.78Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1662.92,496.32l1.43-.12a3.18,3.18,0,0,0,.47,1.41,2.67,2.67,0,0,0,1.15.89,4.49,4.49,0,0,0,1.76.34,4.23,4.23,0,0,0,1.53-.26,2.1,2.1,0,0,0,1-.71,1.61,1.61,0,0,0,.33-1,1.49,1.49,0,0,0-.32-.94,2.37,2.37,0,0,0-1-.68c-.31-.12-1-.3-2-.56A10.55,10.55,0,0,1,1665,494a3.07,3.07,0,0,1-1.22-1.07,2.58,2.58,0,0,1-.4-1.42,2.88,2.88,0,0,1,.49-1.62,3,3,0,0,1,1.44-1.15,5.46,5.46,0,0,1,2.1-.39,5.74,5.74,0,0,1,2.24.41,3.3,3.3,0,0,1,1.5,1.21,3.39,3.39,0,0,1,.56,1.8l-1.45.11a2.36,2.36,0,0,0-.79-1.64,3.1,3.1,0,0,0-2-.55,3.23,3.23,0,0,0-2,.5,1.52,1.52,0,0,0-.63,1.22,1.3,1.3,0,0,0,.44,1,6,6,0,0,0,2.29.82,15.1,15.1,0,0,1,2.53.73,3.51,3.51,0,0,1,1.48,1.17,2.82,2.82,0,0,1,.48,1.63,3.11,3.11,0,0,1-.53,1.72,3.46,3.46,0,0,1-1.5,1.26,5.26,5.26,0,0,1-2.21.45,6.58,6.58,0,0,1-2.6-.46,3.62,3.62,0,0,1-1.65-1.36A3.85,3.85,0,0,1,1662.92,496.32Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1671.57,500V488.55h8.28v1.35h-6.77v3.51h6.34v1.34h-6.34v3.9h7V500Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1680.15,500V488.55h3.95a9.6,9.6,0,0,1,2,.16,4,4,0,0,1,1.68.82,4.66,4.66,0,0,1,1.35,2,7.64,7.64,0,0,1,.45,2.72,8.09,8.09,0,0,1-.3,2.31,5.64,5.64,0,0,1-.78,1.67,4.13,4.13,0,0,1-1,1,4.22,4.22,0,0,1-1.36.57,7.85,7.85,0,0,1-1.84.2Zm1.52-1.35h2.45a5.93,5.93,0,0,0,1.77-.21,2.53,2.53,0,0,0,1-.6,3.59,3.59,0,0,0,.84-1.45,7,7,0,0,0,.3-2.2,5.34,5.34,0,0,0-.59-2.76,3,3,0,0,0-1.43-1.3,6.14,6.14,0,0,0-2-.23h-2.41Z" fill="#59585b"/>
      <path d="M1699.22,488.55h1.52v6.61a7.87,7.87,0,0,1-.39,2.75,3.39,3.39,0,0,1-1.41,1.65,5,5,0,0,1-2.68.64,5.48,5.48,0,0,1-2.63-.56,3.16,3.16,0,0,1-1.46-1.6,7.74,7.74,0,0,1-.44-2.88v-6.61h1.52v6.61a6.36,6.36,0,0,0,.28,2.2,2.1,2.1,0,0,0,1,1.09,3.35,3.35,0,0,0,1.65.38,3.12,3.12,0,0,0,2.38-.76,4.32,4.32,0,0,0,.71-2.91Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1701,500V488.55h2.28l2.71,8.11c.25.75.43,1.32.55,1.69.13-.42.33-1,.61-1.83l2.74-8h2V500h-1.46v-9.59l-3.33,9.59h-1.37l-3.31-9.75V500Z" fill="#59585b"/>
    </g>
  </g>
  <g className="suites__unit" id="B2">
    <polygon id="B2_UNIT_INSPIRED" data-name="B2 UNIT INSPIRED" points="1832 535.6 1832 515.1 1816.8 515.1 1816.7 437 1732.4 437 1732.5 535.7 1832 535.6" fill="#dadada"/>
    <g>
      <path d="M1768.12,471.55h4.58a12.8,12.8,0,0,1,2,.11,3,3,0,0,1,1.19.47,2.91,2.91,0,0,1,.88,1,2.53,2.53,0,0,1,.35,1.34,2.68,2.68,0,0,1-1.61,2.48,3,3,0,0,1,1.61,1,2.75,2.75,0,0,1,.57,1.73,3.44,3.44,0,0,1-.37,1.52,3.09,3.09,0,0,1-1,1.18,3.37,3.37,0,0,1-1.55.54c-.39,0-1.32.07-2.79.08h-3.9Zm2.31,1.9v2.65H1772c.9,0,1.46,0,1.68,0a1.44,1.44,0,0,0,.93-.41,1.18,1.18,0,0,0,.34-.89,1.25,1.25,0,0,0-.29-.87,1.34,1.34,0,0,0-.87-.4c-.23,0-.89,0-2,0Zm0,4.56v3.06h2.15a10,10,0,0,0,1.58-.07,1.41,1.41,0,0,0,.84-.46,1.37,1.37,0,0,0,.33-1,1.51,1.51,0,0,0-.25-.87,1.44,1.44,0,0,0-.73-.53,7.87,7.87,0,0,0-2-.16Z" fill="#59585b"/>
      <path d="M1786.6,481v2h-7.7a5.5,5.5,0,0,1,.75-2.19,14.67,14.67,0,0,1,2.47-2.75,15,15,0,0,0,1.82-1.87,2.46,2.46,0,0,0,.46-1.35,1.56,1.56,0,0,0-.4-1.13,1.69,1.69,0,0,0-2.18,0,2.15,2.15,0,0,0-.47,1.38l-2.19-.22a3.58,3.58,0,0,1,1.23-2.6,4.09,4.09,0,0,1,2.58-.79,3.72,3.72,0,0,1,2.66.91,3,3,0,0,1,1,2.28,3.88,3.88,0,0,1-.28,1.47,5.78,5.78,0,0,1-.88,1.46,13.05,13.05,0,0,1-1.44,1.47c-.69.63-1.13,1.05-1.31,1.26a3.28,3.28,0,0,0-.45.61Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1752.83,496.32l1.43-.12a3.18,3.18,0,0,0,.47,1.41,2.67,2.67,0,0,0,1.15.89,4.49,4.49,0,0,0,1.76.34,4.23,4.23,0,0,0,1.53-.26,2.15,2.15,0,0,0,1-.71,1.6,1.6,0,0,0,.32-1,1.48,1.48,0,0,0-.31-.94,2.37,2.37,0,0,0-1-.68c-.31-.12-1-.3-2-.56a10.55,10.55,0,0,1-2.21-.71,3.07,3.07,0,0,1-1.22-1.07,2.58,2.58,0,0,1-.4-1.42,2.88,2.88,0,0,1,.49-1.62,3,3,0,0,1,1.44-1.15,5.46,5.46,0,0,1,2.1-.39,5.74,5.74,0,0,1,2.24.41A3.3,3.3,0,0,1,1761,490a3.39,3.39,0,0,1,.56,1.8l-1.45.11a2.36,2.36,0,0,0-.79-1.64,3.1,3.1,0,0,0-2-.55,3.21,3.21,0,0,0-2,.5,1.52,1.52,0,0,0-.63,1.22,1.3,1.3,0,0,0,.44,1,6,6,0,0,0,2.29.82,15.1,15.1,0,0,1,2.53.73,3.51,3.51,0,0,1,1.48,1.17,2.82,2.82,0,0,1,.48,1.63,3.11,3.11,0,0,1-.53,1.72,3.46,3.46,0,0,1-1.5,1.26,5.26,5.26,0,0,1-2.21.45,6.58,6.58,0,0,1-2.6-.46,3.62,3.62,0,0,1-1.65-1.36A3.85,3.85,0,0,1,1752.83,496.32Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1761.7,500V488.55h1.52V500Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1765.44,500V488.55H1767v10.1h5.64V500Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1773.88,500V488.55h8.28v1.35h-6.77v3.51h6.34v1.34h-6.34v3.9h7V500Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1782.45,500V488.55H1784l6,9v-9h1.45V500h-1.55l-6-9v9Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1793.25,500V488.55h8.28v1.35h-6.77v3.51h6.34v1.34h-6.34v3.9h7V500Z" fill="#59585b"/>
    </g>
  </g>
  <g className="suites__unit" id="B3a">
    <polygon id="B3_UNIT_SUNSET" data-name="B3 UNIT SUNSET" points="1831.9 511.1 1831.9 511.1 1862.6 511 1862.6 501.4 1895.7 501.4 1895.7 479.3 1906.2 479.3 1906.2 432.5 1903.5 432.5 1903.5 436.9 1820.7 437 1820.8 511.1 1831.9 511.1" fill="#dadada"/>
    <g>
      <path d="M1854.42,453.51H1859a12.8,12.8,0,0,1,2,.11,3,3,0,0,1,1.19.47,2.91,2.91,0,0,1,.88,1,2.53,2.53,0,0,1,.35,1.34,2.68,2.68,0,0,1-1.61,2.48,3,3,0,0,1,1.61,1,2.75,2.75,0,0,1,.57,1.73,3.44,3.44,0,0,1-.37,1.52,3.09,3.09,0,0,1-1,1.18,3.37,3.37,0,0,1-1.55.54c-.39,0-1.32.07-2.79.08h-3.9Zm2.31,1.9v2.65h1.52c.9,0,1.46,0,1.68,0a1.44,1.44,0,0,0,.93-.41,1.18,1.18,0,0,0,.34-.89,1.25,1.25,0,0,0-.29-.87,1.37,1.37,0,0,0-.87-.4c-.23,0-.89,0-2,0Zm0,4.56V463h2.15a10,10,0,0,0,1.58-.07,1.41,1.41,0,0,0,.84-.46,1.37,1.37,0,0,0,.32-1,1.51,1.51,0,0,0-.24-.87,1.44,1.44,0,0,0-.73-.53,7.87,7.87,0,0,0-2.05-.16Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1863.77,461.92l2.13-.26a2,2,0,0,0,.54,1.25,1.53,1.53,0,0,0,1.08.42,1.5,1.5,0,0,0,1.15-.51,2,2,0,0,0,.46-1.39,1.89,1.89,0,0,0-.44-1.31,1.41,1.41,0,0,0-1.09-.49,3.86,3.86,0,0,0-1,.17l.24-1.79a2,2,0,0,0,1.36-.39,1.35,1.35,0,0,0,.47-1.09,1.23,1.23,0,0,0-.34-.92,1.25,1.25,0,0,0-.92-.35,1.31,1.31,0,0,0-1,.4,1.88,1.88,0,0,0-.48,1.14l-2-.35a4.64,4.64,0,0,1,.63-1.66,2.87,2.87,0,0,1,1.19-1,4,4,0,0,1,1.71-.36,3.4,3.4,0,0,1,2.59,1,2.6,2.6,0,0,1-.84,4.31,2.67,2.67,0,0,1,1.57.95,2.76,2.76,0,0,1,.59,1.77,3.45,3.45,0,0,1-1.1,2.57,3.8,3.8,0,0,1-2.74,1.07,3.76,3.76,0,0,1-2.58-.9A3.55,3.55,0,0,1,1863.77,461.92Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1845.24,482V470.51h1.52v4.7h6v-4.7h1.52V482h-1.52v-5.4h-6V482Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1855.82,482V470.51h8.28v1.35h-6.77v3.51h6.34v1.34h-6.34v3.9h7V482Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1864.35,482V470.51h4.3a5.32,5.32,0,0,1,2.1.34,2.78,2.78,0,0,1,1.25,1.07,2.86,2.86,0,0,1,.45,1.52,2.63,2.63,0,0,1-.4,1.38,2.92,2.92,0,0,1-1.21,1.05,2.94,2.94,0,0,1,1.6,1,2.76,2.76,0,0,1,.56,1.73,3.33,3.33,0,0,1-.34,1.5,3,3,0,0,1-.84,1.06,3.52,3.52,0,0,1-1.25.57,7.57,7.57,0,0,1-1.85.19Zm1.52-6.64h2.47a5.48,5.48,0,0,0,1.45-.13,1.67,1.67,0,0,0,.87-.57,1.62,1.62,0,0,0,.29-1,1.88,1.88,0,0,0-.27-1,1.33,1.33,0,0,0-.78-.59,6.39,6.39,0,0,0-1.74-.16h-2.29Zm0,5.29h2.85a6,6,0,0,0,1-.06,2.4,2.4,0,0,0,.88-.31,1.63,1.63,0,0,0,.57-.64,2,2,0,0,0,.23-1,1.87,1.87,0,0,0-.33-1.11,1.72,1.72,0,0,0-.91-.67,5.53,5.53,0,0,0-1.67-.19h-2.65Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1874.1,482V470.51h8.28v1.35h-6.77v3.51H1882v1.34h-6.34v3.9h7V482Z" fill="#59585b"/>
    </g>
  </g>
  <g className="suites__unit" id="B4a">
    <polygon id="B4_UNIT_SUNRISE" data-name="B4 UNIT SUNRISE" points="1899.7 505.4 1866.6 505.4 1866.6 513 1866.6 513 1866.6 533.4 1919.6 533.4 1919.6 549.1 1987.3 549 1987.3 438.5 1957.8 438.5 1957.8 432.5 1910.2 432.5 1910.2 483.3 1899.7 483.3 1899.7 505.4" fill="#dadada"/>
    <g>
      <path d="M1924.72,493.22h4.58a12.8,12.8,0,0,1,2,.11,3,3,0,0,1,1.19.47,2.91,2.91,0,0,1,.88,1,2.53,2.53,0,0,1,.35,1.34,2.68,2.68,0,0,1-1.61,2.48,3,3,0,0,1,1.61,1,2.75,2.75,0,0,1,.57,1.73,3.44,3.44,0,0,1-.37,1.52,3.09,3.09,0,0,1-1,1.18,3.37,3.37,0,0,1-1.55.54c-.39,0-1.32.07-2.79.08h-3.9Zm2.31,1.9v2.65h1.52c.9,0,1.46,0,1.68,0a1.46,1.46,0,0,0,.93-.41,1.18,1.18,0,0,0,.34-.89,1.25,1.25,0,0,0-.29-.87,1.37,1.37,0,0,0-.87-.4c-.23,0-.89,0-2,0Zm0,4.56v3.06h2.14a10,10,0,0,0,1.59-.07,1.41,1.41,0,0,0,.84-.46,1.37,1.37,0,0,0,.32-1,1.44,1.44,0,0,0-.25-.87,1.37,1.37,0,0,0-.72-.53,7.87,7.87,0,0,0-2-.16Z" fill="#59585b"/>
      <path d="M1940.09,504.67v-2.3h-4.69v-1.93l5-7.27h1.84v7.27h1.43v1.93h-1.43v2.3Zm0-4.23v-3.92l-2.63,3.92Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1889.59,521.67V510.22h2.28l2.71,8.11c.25.75.43,1.32.55,1.69.13-.42.33-1,.61-1.83l2.74-8h2v11.45h-1.46v-9.59l-3.33,9.59h-1.37l-3.31-9.75v9.75Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1900.79,521.67l4.4-11.45h1.63l4.69,11.45h-1.73l-1.34-3.47h-4.79l-1.25,3.47Zm3.3-4.7H1908l-1.19-3.18q-.55-1.44-.81-2.37a16.33,16.33,0,0,1-.62,2.19Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1914,521.67v-4.85l-4.41-6.6h1.84l2.26,3.45c.42.65.8,1.29,1.16,1.94.35-.6.76-1.28,1.25-2l2.22-3.36h1.77l-4.57,6.6v4.85Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1918.78,521.67V510.22h7.73v1.35h-6.21v3.55h5.37v1.35h-5.37v5.2Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1927,521.67V510.22h1.52v10.1h5.64v1.35Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1934.64,516.09a6.21,6.21,0,0,1,1.53-4.46,5.22,5.22,0,0,1,4-1.62,5.56,5.56,0,0,1,2.86.76,5,5,0,0,1,1.94,2.11,7,7,0,0,1,.67,3.08,6.76,6.76,0,0,1-.71,3.12,4.82,4.82,0,0,1-2,2.08,5.72,5.72,0,0,1-2.78.71,5.42,5.42,0,0,1-2.89-.79,5,5,0,0,1-1.93-2.13A6.42,6.42,0,0,1,1934.64,516.09Zm1.57,0a4.6,4.6,0,0,0,1.11,3.26,3.89,3.89,0,0,0,5.61,0A4.88,4.88,0,0,0,1944,516a5.81,5.81,0,0,0-.47-2.44,3.6,3.6,0,0,0-1.38-1.62,3.78,3.78,0,0,0-2-.57,3.88,3.88,0,0,0-2.77,1.1A4.93,4.93,0,0,0,1936.21,516.12Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1948.68,521.67l-3-11.45h1.55l1.74,7.5c.19.79.35,1.57.49,2.35.29-1.23.46-1.93.51-2.12l2.18-7.73H1954l1.64,5.79a30.58,30.58,0,0,1,.89,4.06q.23-1.1.57-2.49l1.8-7.36h1.52l-3.14,11.45h-1.46l-2.41-8.73c-.21-.73-.33-1.17-.36-1.34-.12.53-.24,1-.34,1.34l-2.43,8.73Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1960.92,521.67V510.22h8.28v1.35h-6.77v3.51h6.34v1.34h-6.34v3.9h7v1.35Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1969.53,521.67V510.22h5.08a6.6,6.6,0,0,1,2.32.31,2.5,2.5,0,0,1,1.28,1.09,3.2,3.2,0,0,1,.47,1.72,2.89,2.89,0,0,1-.79,2.06,4,4,0,0,1-2.43,1.06,3.78,3.78,0,0,1,.91.57,8.21,8.21,0,0,1,1.26,1.52l2,3.12h-1.91l-1.51-2.38c-.44-.69-.81-1.22-1.09-1.58a3.67,3.67,0,0,0-.77-.77,2.51,2.51,0,0,0-.7-.3,4.11,4.11,0,0,0-.84-.06H1971v5.09Zm1.51-6.4h3.26a5,5,0,0,0,1.63-.21,1.83,1.83,0,0,0,.89-.69,1.9,1.9,0,0,0,.3-1,1.69,1.69,0,0,0-.59-1.33,2.74,2.74,0,0,0-1.86-.53H1971Z" fill="#59585b"/>
    </g>
  </g>
  <g className="suites__unit" id="B5a">
    <polygon id="B5_UNIT_AMUSE" data-name="B5 UNIT AMUSE" points="1919.6 553.1 1919.6 570.2 1865.8 570.2 1865.8 587.5 1865.8 587.5 1865.8 597.4 1902.2 597.4 1902.3 621.4 1906.4 621.4 1906.4 628.8 1911.4 628.8 1911.4 669.4 1957.9 669.4 1957.9 662.2 1987.4 662.2 1987.3 553 1919.6 553.1" fill="#dadada"/>
    <g>
      <path d="M1933.42,565.69H1938a14.14,14.14,0,0,1,2,.11,3,3,0,0,1,1.19.47,2.91,2.91,0,0,1,.88,1,2.53,2.53,0,0,1,.35,1.34,2.68,2.68,0,0,1-1.61,2.48,3,3,0,0,1,1.61,1,2.75,2.75,0,0,1,.57,1.73,3.44,3.44,0,0,1-.37,1.52,3,3,0,0,1-1,1.18,3.37,3.37,0,0,1-1.55.54c-.39,0-1.32.07-2.79.08h-3.9Zm2.31,1.9v2.65h1.52c.9,0,1.46,0,1.68,0a1.44,1.44,0,0,0,.93-.41,1.18,1.18,0,0,0,.34-.89,1.25,1.25,0,0,0-.29-.87,1.37,1.37,0,0,0-.87-.4c-.23,0-.89,0-2,0Zm0,4.56v3.06h2.15a10,10,0,0,0,1.58-.07,1.41,1.41,0,0,0,.84-.46,1.37,1.37,0,0,0,.32-1,1.51,1.51,0,0,0-.24-.87,1.44,1.44,0,0,0-.73-.53,7.87,7.87,0,0,0-2.05-.16Z" fill="#59585b"/>
      <path d="M1944.52,574.19l2.18-.22a1.91,1.91,0,0,0,.56,1.17,1.51,1.51,0,0,0,1.06.44,1.46,1.46,0,0,0,1.16-.56,2.51,2.51,0,0,0,.48-1.68,2.34,2.34,0,0,0-.47-1.59,1.59,1.59,0,0,0-1.23-.52,2.23,2.23,0,0,0-1.7.83l-1.78-.26,1.13-6h5.8v2.06h-4.14l-.34,1.94a3.38,3.38,0,0,1,1.5-.36,3.27,3.27,0,0,1,2.47,1.06,3.83,3.83,0,0,1,1,2.76,4.11,4.11,0,0,1-.82,2.52,3.65,3.65,0,0,1-3.1,1.52,3.85,3.85,0,0,1-2.59-.86A3.47,3.47,0,0,1,1944.52,574.19Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1898.77,594.14l4.4-11.45h1.63l4.69,11.45h-1.73l-1.34-3.47h-4.79l-1.25,3.47Zm3.3-4.7H1906l-1.19-3.17c-.37-1-.64-1.76-.81-2.38a16.33,16.33,0,0,1-.62,2.19Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1909.3,594.14V582.69h1.55l6,9v-9h1.45v11.45h-1.55l-6-9v9Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1923.45,594.14V584h-3.78v-1.35h9.08V584H1925v10.1Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1929,594.14V582.69h1.52v4.7h6v-4.7H1938v11.45h-1.52v-5.4h-6v5.4Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1947.15,582.69h1.52v6.61a7.87,7.87,0,0,1-.39,2.75,3.5,3.5,0,0,1-1.41,1.65,5.06,5.06,0,0,1-2.68.64,5.48,5.48,0,0,1-2.63-.56,3.12,3.12,0,0,1-1.46-1.6,7.51,7.51,0,0,1-.44-2.88v-6.61h1.51v6.61a6.62,6.62,0,0,0,.28,2.2,2.15,2.15,0,0,0,1,1.09,3.39,3.39,0,0,0,1.66.38,3.15,3.15,0,0,0,2.38-.76,4.38,4.38,0,0,0,.71-2.91Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1949.73,594.14V582.69h5.08a6.6,6.6,0,0,1,2.32.31,2.46,2.46,0,0,1,1.28,1.09,3.29,3.29,0,0,1,.47,1.72,2.89,2.89,0,0,1-.79,2.06,4,4,0,0,1-2.43,1.06,3.78,3.78,0,0,1,.91.57,8.21,8.21,0,0,1,1.26,1.52l2,3.12h-1.9l-1.52-2.38c-.44-.69-.81-1.22-1.09-1.58a3.67,3.67,0,0,0-.77-.77,2.51,2.51,0,0,0-.7-.3,4.11,4.11,0,0,0-.84-.06h-1.76v5.09Zm1.51-6.4h3.26a5,5,0,0,0,1.63-.21,1.83,1.83,0,0,0,.89-.69,1.9,1.9,0,0,0,.3-1,1.69,1.69,0,0,0-.59-1.33,2.74,2.74,0,0,0-1.86-.53h-3.63Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1959.44,594.14V582.69H1961v11.45Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1970.9,582.69h1.52v6.61a7.87,7.87,0,0,1-.39,2.75,3.5,3.5,0,0,1-1.41,1.65,5.06,5.06,0,0,1-2.68.64,5.48,5.48,0,0,1-2.63-.56,3.12,3.12,0,0,1-1.46-1.6,7.51,7.51,0,0,1-.44-2.88v-6.61h1.51v6.61a6.62,6.62,0,0,0,.28,2.2,2.15,2.15,0,0,0,1,1.09,3.39,3.39,0,0,0,1.66.38,3.15,3.15,0,0,0,2.38-.76,4.38,4.38,0,0,0,.71-2.91Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1973.67,594.14V582.69H1976l2.71,8.11c.25.75.43,1.32.55,1.69.13-.42.33-1,.61-1.83l2.74-8h2v11.45h-1.46v-9.59l-3.33,9.59h-1.37l-3.31-9.75v9.75Z" fill="#59585b"/>
    </g>
  </g>
  <g className="suites__unit" id="B6a">
    <polygon id="B6_UNIT_BLISS" data-name="B6 UNIT BLISS" points="1902.4 632.9 1902.4 625.4 1898.3 625.4 1898.2 601.4 1861.8 601.4 1861.8 591.5 1822.5 591.5 1822.5 664.2 1903.4 664.2 1903.4 669.4 1907.4 669.4 1907.4 632.8 1902.4 632.9" fill="#dadada"/>
    <g>
      <path d="M1851.87,611.4h4.58a14.14,14.14,0,0,1,2,.11,3,3,0,0,1,1.19.47,2.91,2.91,0,0,1,.88,1,2.53,2.53,0,0,1,.35,1.34,2.68,2.68,0,0,1-1.61,2.48,3,3,0,0,1,1.61,1,2.75,2.75,0,0,1,.57,1.73,3.44,3.44,0,0,1-.37,1.52,3,3,0,0,1-1,1.18,3.37,3.37,0,0,1-1.55.54c-.39,0-1.32.07-2.79.08h-3.9Zm2.31,1.9V616h1.52c.9,0,1.46,0,1.68,0a1.44,1.44,0,0,0,.93-.41,1.18,1.18,0,0,0,.34-.89,1.25,1.25,0,0,0-.29-.87,1.34,1.34,0,0,0-.87-.4c-.23,0-.89,0-2,0Zm0,4.56v3.06h2.15a10,10,0,0,0,1.58-.07,1.41,1.41,0,0,0,.84-.46,1.37,1.37,0,0,0,.33-1,1.51,1.51,0,0,0-.25-.87,1.44,1.44,0,0,0-.73-.53,7.87,7.87,0,0,0-2-.16Z" fill="#59585b"/>
      <path d="M1870.37,614.2l-2.12.24a1.53,1.53,0,0,0-.41-1,1.18,1.18,0,0,0-.85-.32,1.45,1.45,0,0,0-1.18.63,5.24,5.24,0,0,0-.6,2.6,2.57,2.57,0,0,1,2-1,3.1,3.1,0,0,1,2.35,1.05,3.77,3.77,0,0,1,1,2.7,3.87,3.87,0,0,1-1,2.82,3.74,3.74,0,0,1-5.5-.28,7,7,0,0,1-1.12-4.42,7,7,0,0,1,1.17-4.54,3.75,3.75,0,0,1,3-1.39,3.19,3.19,0,0,1,2.16.73A3.39,3.39,0,0,1,1870.37,614.2Zm-5,4.79a2.46,2.46,0,0,0,.49,1.65,1.45,1.45,0,0,0,1.12.59,1.28,1.28,0,0,0,1-.48,2.41,2.41,0,0,0,.4-1.56,2.53,2.53,0,0,0-.43-1.64,1.37,1.37,0,0,0-1.1-.52,1.35,1.35,0,0,0-1.07.5A2.16,2.16,0,0,0,1865.4,619Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1833.34,639.85l-3-11.45h1.55l1.74,7.5c.19.79.35,1.57.49,2.35.29-1.23.46-1.93.51-2.12l2.18-7.73h1.83l1.64,5.79a30.58,30.58,0,0,1,.89,4.06q.22-1.1.57-2.49l1.8-7.36H1845l-3.14,11.45h-1.46l-2.41-8.73c-.21-.73-.33-1.17-.36-1.34-.12.53-.24,1-.34,1.34l-2.43,8.73Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1845.79,639.85V628.4h1.52v11.45Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1849.53,639.85V628.4h1.52v10.1h5.64v1.35Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1857.87,639.85V628.4h1.52v10.1H1865v1.35Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1865.49,634.27a6.21,6.21,0,0,1,1.53-4.46,5.2,5.2,0,0,1,4-1.62,5.53,5.53,0,0,1,2.86.76,5,5,0,0,1,1.94,2.11,7,7,0,0,1,.67,3.08,6.76,6.76,0,0,1-.71,3.12,4.82,4.82,0,0,1-2,2.08,5.72,5.72,0,0,1-2.78.71,5.42,5.42,0,0,1-2.89-.79,5,5,0,0,1-1.93-2.13A6.42,6.42,0,0,1,1865.49,634.27Zm1.57,0a4.6,4.6,0,0,0,1.11,3.26,3.89,3.89,0,0,0,5.61,0,4.88,4.88,0,0,0,1.1-3.42,5.81,5.81,0,0,0-.47-2.44,3.66,3.66,0,0,0-1.38-1.62,3.78,3.78,0,0,0-2-.57,3.88,3.88,0,0,0-2.77,1.1A4.93,4.93,0,0,0,1867.06,634.3Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1879.53,639.85l-3-11.45h1.55l1.74,7.5c.19.79.35,1.57.49,2.35.29-1.23.46-1.93.51-2.12l2.18-7.73h1.83l1.64,5.79a30.58,30.58,0,0,1,.89,4.06q.23-1.1.57-2.49l1.8-7.36h1.52l-3.14,11.45h-1.46l-2.41-8.73c-.21-.73-.33-1.17-.36-1.34-.12.53-.24,1-.34,1.34l-2.43,8.73Z" fill="#59585b"/>
    </g>
  </g>
  <g className="suites__unit" id="B7a">
    <polygon id="B7_UNIT_BRILLIANT" data-name="B7 UNIT BRILLIANT" points="1732.8 566.5 1732.8 572.5 1732.8 572.5 1732.5 664.3 1818.5 664.2 1818.4 566.4 1732.8 566.5" fill="#dadada"/>
    <g>
      <path d="M1766.33,600.35h4.58a12.8,12.8,0,0,1,2,.11,3,3,0,0,1,1.19.47,2.91,2.91,0,0,1,.88,1,2.53,2.53,0,0,1,.35,1.34,2.68,2.68,0,0,1-1.61,2.48,3,3,0,0,1,1.61,1,2.75,2.75,0,0,1,.57,1.73,3.44,3.44,0,0,1-.37,1.52,3.09,3.09,0,0,1-1,1.18,3.37,3.37,0,0,1-1.55.54c-.39,0-1.32.07-2.79.08h-3.9Zm2.31,1.9v2.65h1.52c.9,0,1.46,0,1.68,0a1.44,1.44,0,0,0,.93-.41,1.18,1.18,0,0,0,.34-.89,1.25,1.25,0,0,0-.29-.87,1.37,1.37,0,0,0-.87-.4c-.23,0-.89,0-2,0Zm0,4.56v3.06h2.15a10,10,0,0,0,1.58-.07,1.46,1.46,0,0,0,.84-.46,1.37,1.37,0,0,0,.33-1,1.51,1.51,0,0,0-.25-.87,1.44,1.44,0,0,0-.73-.53,7.87,7.87,0,0,0-2-.16Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1775.62,602.54v-2h7.51v1.6a11.77,11.77,0,0,0-1.89,2.62,15.43,15.43,0,0,0-1.47,3.64,13.48,13.48,0,0,0-.49,3.44h-2.12a14.45,14.45,0,0,1,1-4.84,16.26,16.26,0,0,1,2.47-4.42Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1751.4,628.8l4.39-11.45h1.64l4.69,11.45h-1.73l-1.34-3.47h-4.79L1753,628.8Zm3.3-4.7h3.88l-1.19-3.18q-.56-1.44-.81-2.37a16.33,16.33,0,0,1-.62,2.19Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1761.77,625.12l1.43-.12a3.18,3.18,0,0,0,.47,1.41,2.67,2.67,0,0,0,1.15.89,4.49,4.49,0,0,0,1.76.34,4.23,4.23,0,0,0,1.53-.26,2.15,2.15,0,0,0,1-.71,1.62,1.62,0,0,0,.32-1,1.48,1.48,0,0,0-.31-.94,2.37,2.37,0,0,0-1-.68c-.31-.12-1-.3-2-.56a10.55,10.55,0,0,1-2.21-.71,3.07,3.07,0,0,1-1.22-1.07,2.58,2.58,0,0,1-.4-1.42,2.88,2.88,0,0,1,.49-1.62,3,3,0,0,1,1.44-1.15,5.46,5.46,0,0,1,2.1-.39,5.77,5.77,0,0,1,2.24.41,3.24,3.24,0,0,1,1.5,1.21,3.39,3.39,0,0,1,.56,1.8l-1.45.11a2.36,2.36,0,0,0-.79-1.64,3.1,3.1,0,0,0-2-.55,3.21,3.21,0,0,0-2,.5,1.52,1.52,0,0,0-.63,1.22,1.3,1.3,0,0,0,.44,1,6,6,0,0,0,2.29.82,15.1,15.1,0,0,1,2.53.73,3.44,3.44,0,0,1,1.48,1.17,2.82,2.82,0,0,1,.48,1.63,3.13,3.13,0,0,1-.53,1.72,3.46,3.46,0,0,1-1.5,1.26,5.23,5.23,0,0,1-2.21.45,6.61,6.61,0,0,1-2.6-.46,3.62,3.62,0,0,1-1.65-1.36A3.85,3.85,0,0,1,1761.77,625.12Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1773.52,628.8V618.7h-3.77v-1.35h9.07v1.35H1775v10.1Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1779,628.8V617.35h8.28v1.35h-6.77v3.51h6.34v1.34h-6.34v3.9h7v1.35Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1787.64,628.8V617.35h5.08a6.6,6.6,0,0,1,2.32.31,2.46,2.46,0,0,1,1.28,1.09,3.29,3.29,0,0,1,.47,1.72,2.89,2.89,0,0,1-.79,2.06,4,4,0,0,1-2.43,1.06,3.78,3.78,0,0,1,.91.57,8.21,8.21,0,0,1,1.26,1.52l2,3.12h-1.9l-1.52-2.38c-.44-.69-.81-1.22-1.09-1.58a3.67,3.67,0,0,0-.77-.77,2.51,2.51,0,0,0-.7-.3,4.11,4.11,0,0,0-.84-.06h-1.76v5.09Zm1.51-6.4h3.26a5,5,0,0,0,1.63-.21,1.83,1.83,0,0,0,.89-.69,1.9,1.9,0,0,0,.3-1,1.69,1.69,0,0,0-.59-1.33,2.74,2.74,0,0,0-1.86-.53h-3.63Z" fill="#59585b"/>
    </g>
  </g>
  <g className="suites__unit" id="B8a">
    <polygon id="B8_UNIT_DELIGHT" data-name="B8 UNIT DELIGHT" points="1646.5 664.3 1728.5 664.3 1728.8 572.5 1647 572.6 1646.5 664.3" fill="#dadada"/>
    <g>
      <path d="M1679.3,599.28h4.58a12.8,12.8,0,0,1,2,.11,3,3,0,0,1,1.19.47,2.91,2.91,0,0,1,.88,1,2.53,2.53,0,0,1,.35,1.34,2.68,2.68,0,0,1-1.61,2.48,3,3,0,0,1,1.61,1,2.75,2.75,0,0,1,.57,1.73,3.44,3.44,0,0,1-.37,1.52,3.09,3.09,0,0,1-1,1.18,3.37,3.37,0,0,1-1.55.54c-.39,0-1.32.07-2.79.08h-3.9Zm2.31,1.9v2.65h1.52c.9,0,1.46,0,1.68,0a1.44,1.44,0,0,0,.93-.41,1.18,1.18,0,0,0,.34-.89,1.25,1.25,0,0,0-.29-.87,1.37,1.37,0,0,0-.87-.4c-.23,0-.89,0-2,0Zm0,4.56v3.06h2.14a10,10,0,0,0,1.59-.07,1.41,1.41,0,0,0,.84-.46,1.37,1.37,0,0,0,.32-1,1.44,1.44,0,0,0-.25-.87,1.37,1.37,0,0,0-.72-.53,7.87,7.87,0,0,0-2-.16Z" fill="#59585b"/>
      <path d="M1692.25,604.55a2.47,2.47,0,0,1-1.63-2.37,2.74,2.74,0,0,1,.9-2.11,4.25,4.25,0,0,1,5.07,0,2.72,2.72,0,0,1,.91,2.11,2.47,2.47,0,0,1-.42,1.42,2.55,2.55,0,0,1-1.16.95,3.13,3.13,0,0,1,1.45,1.12,2.94,2.94,0,0,1,.49,1.69,3.43,3.43,0,0,1-1,2.58,3.67,3.67,0,0,1-2.69,1,4,4,0,0,1-2.6-.82,3.18,3.18,0,0,1-1.23-2.65,3.37,3.37,0,0,1,.46-1.71A3,3,0,0,1,1692.25,604.55Zm.25,2.7a2,2,0,0,0,.46,1.41,1.51,1.51,0,0,0,1.16.51,1.48,1.48,0,0,0,1.13-.49,2.07,2.07,0,0,0,.44-1.41,1.81,1.81,0,0,0-.45-1.29,1.49,1.49,0,0,0-1.15-.49,1.36,1.36,0,0,0-1.2.55A2.05,2.05,0,0,0,1692.5,607.25Zm.2-4.91a1.41,1.41,0,0,0,2.36,1,1.38,1.38,0,0,0,.37-1,1.27,1.27,0,0,0-1.35-1.36,1.3,1.3,0,0,0-1.38,1.37Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1660.37,627.73l4.4-11.45h1.63l4.69,11.45h-1.73l-1.34-3.47h-4.79l-1.25,3.47Zm3.3-4.7h3.88l-1.19-3.18q-.55-1.44-.81-2.37a16.33,16.33,0,0,1-.62,2.19Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1670.35,627.73v-1.41l5.87-7.33a15.61,15.61,0,0,1,1.19-1.36H1671v-1.35h8.21v1.35l-6.43,7.94-.7.81h7.32v1.35Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1678.93,627.73l4.4-11.45H1685l4.69,11.45h-1.73l-1.34-3.47h-4.79l-1.25,3.47Zm3.3-4.7h3.88l-1.19-3.18q-.56-1.44-.81-2.37a16.33,16.33,0,0,1-.62,2.19Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1689.42,627.73V616.28h1.52v10.1h5.64v1.35Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1697.86,627.73V616.28h8.28v1.35h-6.77v3.51h6.34v1.34h-6.34v3.9h7v1.35Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1705.86,627.73l4.39-11.45h1.64l4.69,11.45h-1.73l-1.34-3.47h-4.79l-1.25,3.47Zm3.3-4.7H1713l-1.19-3.18q-.55-1.44-.81-2.37a16.33,16.33,0,0,1-.62,2.19Z" fill="#59585b"/>
    </g>
  </g>
  <g className="suites__unit" id="B9">
    <polygon id="B9_UNIT_FAB" data-name="B9 UNIT FAB" points="1621.2 566.8 1588.7 566.8 1588.7 591.9 1565.8 591.9 1565.8 634 1580.5 634 1580.5 642.6 1595.4 642.6 1595.4 693.3 1642.5 693.3 1642.5 667.8 1642.5 667.8 1642.5 667.8 1642.5 664.3 1642.5 664.3 1643 572.6 1621.2 572.6 1621.2 566.8" fill="#dadada"/>
    <g>
      <path d="M1596.22,601.43h4.58a12.8,12.8,0,0,1,2,.11,3,3,0,0,1,1.19.47,2.91,2.91,0,0,1,.88,1,2.53,2.53,0,0,1,.35,1.34,2.68,2.68,0,0,1-1.61,2.48,3,3,0,0,1,1.61,1,2.75,2.75,0,0,1,.57,1.73,3.44,3.44,0,0,1-.37,1.52,3.09,3.09,0,0,1-1,1.18,3.37,3.37,0,0,1-1.55.54c-.39,0-1.32.07-2.79.08h-3.9Zm2.31,1.9V606h1.52c.9,0,1.46,0,1.68,0a1.46,1.46,0,0,0,.93-.41,1.18,1.18,0,0,0,.34-.89,1.25,1.25,0,0,0-.29-.87,1.37,1.37,0,0,0-.87-.4c-.23,0-.89,0-2,0Zm0,4.56V611h2.14a10,10,0,0,0,1.59-.07,1.41,1.41,0,0,0,.84-.46,1.37,1.37,0,0,0,.32-1,1.44,1.44,0,0,0-.25-.87,1.37,1.37,0,0,0-.72-.53,7.87,7.87,0,0,0-2-.16Z" fill="#59585b"/>
      <path d="M1607.33,610.23l2.13-.23a1.52,1.52,0,0,0,.4,1,1.2,1.2,0,0,0,.87.31,1.44,1.44,0,0,0,1.16-.62,5.48,5.48,0,0,0,.61-2.6,2.65,2.65,0,0,1-2.08,1,3.08,3.08,0,0,1-2.33-1,4.28,4.28,0,0,1,.06-5.52,3.73,3.73,0,0,1,5.49.28,6.92,6.92,0,0,1,1.12,4.43,7.08,7.08,0,0,1-1.16,4.53,3.77,3.77,0,0,1-3,1.39,3.27,3.27,0,0,1-2.18-.71A3.49,3.49,0,0,1,1607.33,610.23Zm5-4.8a2.47,2.47,0,0,0-.49-1.64,1.44,1.44,0,0,0-1.13-.59,1.27,1.27,0,0,0-1,.48,3.26,3.26,0,0,0,0,3.2,1.4,1.4,0,0,0,2.16,0A2.17,2.17,0,0,0,1612.3,605.43Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1577.75,625.86l1.51.39a5.12,5.12,0,0,1-1.71,2.84,4.72,4.72,0,0,1-3,1,5.46,5.46,0,0,1-3-.76,4.71,4.71,0,0,1-1.77-2.18,7.89,7.89,0,0,1-.6-3.07,6.68,6.68,0,0,1,.68-3.12,4.67,4.67,0,0,1,2-2,5.66,5.66,0,0,1,2.77-.69,4.64,4.64,0,0,1,2.89.88,4.55,4.55,0,0,1,1.64,2.46l-1.5.35a3.54,3.54,0,0,0-1.15-1.82,3.09,3.09,0,0,0-1.91-.57,3.74,3.74,0,0,0-2.21.63,3.34,3.34,0,0,0-1.24,1.7,6.81,6.81,0,0,0-.36,2.2,7.1,7.1,0,0,0,.42,2.55,3.2,3.2,0,0,0,1.33,1.63,3.71,3.71,0,0,0,1.94.54,3.27,3.27,0,0,0,2.16-.74A3.75,3.75,0,0,0,1577.75,625.86Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1579.49,629.88l4.39-11.45h1.64l4.69,11.45h-1.73l-1.34-3.47h-4.79l-1.25,3.47Zm3.3-4.7h3.88l-1.19-3.18q-.56-1.44-.81-2.37a16.33,16.33,0,0,1-.62,2.19Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1590.06,629.88V618.43h5.08a6.6,6.6,0,0,1,2.32.31,2.46,2.46,0,0,1,1.28,1.09,3.29,3.29,0,0,1,.47,1.72,2.89,2.89,0,0,1-.79,2.06,4,4,0,0,1-2.43,1.06,3.78,3.78,0,0,1,.91.57,8.21,8.21,0,0,1,1.26,1.52l2,3.12h-1.9l-1.52-2.38c-.44-.69-.81-1.22-1.09-1.58a3.67,3.67,0,0,0-.77-.77,2.51,2.51,0,0,0-.7-.3,4.11,4.11,0,0,0-.84-.06h-1.76v5.09Zm1.51-6.4h3.26a5,5,0,0,0,1.63-.21,1.83,1.83,0,0,0,.89-.69,1.9,1.9,0,0,0,.3-1,1.66,1.66,0,0,0-.59-1.33,2.74,2.74,0,0,0-1.86-.53h-3.63Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1599.52,629.88V618.43h4a9.6,9.6,0,0,1,2,.16,4,4,0,0,1,1.68.82,4.66,4.66,0,0,1,1.35,2,7.64,7.64,0,0,1,.45,2.72,8.09,8.09,0,0,1-.3,2.31,5.64,5.64,0,0,1-.78,1.67,4.13,4.13,0,0,1-1.05,1,4.22,4.22,0,0,1-1.36.57,7.85,7.85,0,0,1-1.84.2Zm1.52-1.35h2.45a5.93,5.93,0,0,0,1.77-.21,2.53,2.53,0,0,0,1-.6,3.59,3.59,0,0,0,.84-1.45,7,7,0,0,0,.3-2.2,5.34,5.34,0,0,0-.59-2.76,3,3,0,0,0-1.43-1.3,6.14,6.14,0,0,0-2-.23H1601Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1610.08,629.88V618.43h1.52v11.45Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1613.88,629.88V618.43h1.55l6,9v-9h1.45v11.45h-1.55l-6-9v9Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1623.58,629.88l4.4-11.45h1.63l4.69,11.45h-1.73l-1.34-3.47h-4.79l-1.25,3.47Zm3.3-4.7h3.88l-1.19-3.18q-.55-1.44-.81-2.37a16.33,16.33,0,0,1-.62,2.19Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1634.07,629.88V618.43h1.52v10.1h5.64v1.35Z" fill="#59585b"/>
    </g>
  </g>
  <g className="suites__unit" id="B10a">
    <polygon id="B10_UNIT_FLOURISH" data-name="B10 UNIT FLOURISH" points="1576.5 646.6 1576.5 638 1561.9 638 1561.8 588 1584.7 587.9 1584.7 566.8 1555.9 566.8 1555.9 572.6 1533.1 572.6 1533.1 549.5 1460.7 549.5 1460.7 648.2 1502.1 648.1 1502.1 672.4 1549.9 672.4 1549.9 693.4 1591.4 693.3 1591.4 646.6 1576.5 646.6" fill="#dadada"/>
    <g>
      <path d="M1502.71,584.35h4.58a12.8,12.8,0,0,1,2,.11,3,3,0,0,1,1.19.47,2.91,2.91,0,0,1,.88,1,2.53,2.53,0,0,1,.35,1.34,2.68,2.68,0,0,1-1.61,2.48,3,3,0,0,1,1.61,1,2.75,2.75,0,0,1,.57,1.73,3.44,3.44,0,0,1-.37,1.52,3.09,3.09,0,0,1-1,1.18,3.37,3.37,0,0,1-1.55.54c-.39,0-1.32.07-2.79.08h-3.9Zm2.31,1.9v2.65h1.52c.9,0,1.46,0,1.68,0a1.44,1.44,0,0,0,.93-.41,1.18,1.18,0,0,0,.34-.89,1.25,1.25,0,0,0-.29-.87,1.37,1.37,0,0,0-.87-.4c-.23,0-.89,0-2,0Zm0,4.56v3.06h2.15a10,10,0,0,0,1.58-.07,1.46,1.46,0,0,0,.84-.46,1.37,1.37,0,0,0,.33-1,1.51,1.51,0,0,0-.25-.87,1.44,1.44,0,0,0-.73-.53,7.87,7.87,0,0,0-2-.16Z" fill="#59585b"/>
      <path d="M1519.39,595.8h-2.19v-8.27a7.64,7.64,0,0,1-2.84,1.66v-2a6.3,6.3,0,0,0,1.87-1.07,4,4,0,0,0,1.38-1.83h1.78Z" fill="#59585b"/>
      <path d="M1526.38,584.3a3.16,3.16,0,0,1,2.61,1.19c.74.93,1.11,2.49,1.11,4.66s-.37,3.73-1.12,4.67a3.15,3.15,0,0,1-2.6,1.18,3.27,3.27,0,0,1-2.69-1.29c-.68-.86-1-2.38-1-4.58s.37-3.71,1.12-4.66A3.12,3.12,0,0,1,1526.38,584.3Zm0,1.82a1.16,1.16,0,0,0-.71.25,1.69,1.69,0,0,0-.48.91,12.63,12.63,0,0,0-.23,2.87,12,12,0,0,0,.21,2.77,1.85,1.85,0,0,0,.51,1,1.12,1.12,0,0,0,1.41,0,1.72,1.72,0,0,0,.49-.91,12.41,12.41,0,0,0,.23-2.86,12.08,12.08,0,0,0-.21-2.77,1.93,1.93,0,0,0-.51-1A1.14,1.14,0,0,0,1526.38,586.12Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1480.87,612.8V601.35h4a9.6,9.6,0,0,1,2,.16,4,4,0,0,1,1.68.82,4.66,4.66,0,0,1,1.35,2,7.64,7.64,0,0,1,.45,2.72,8.09,8.09,0,0,1-.3,2.31,5.64,5.64,0,0,1-.78,1.67,4.13,4.13,0,0,1-1,1,4.22,4.22,0,0,1-1.36.57,7.85,7.85,0,0,1-1.84.2Zm1.52-1.35h2.45a5.93,5.93,0,0,0,1.77-.21,2.53,2.53,0,0,0,1-.6,3.59,3.59,0,0,0,.84-1.45,7,7,0,0,0,.3-2.2,5.34,5.34,0,0,0-.59-2.76,3,3,0,0,0-1.43-1.3,6.14,6.14,0,0,0-2-.23h-2.41Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1489.87,612.8l4.4-11.45h1.63l4.69,11.45h-1.73l-1.34-3.47h-4.79l-1.25,3.47Zm3.3-4.7h3.88l-1.19-3.18q-.55-1.44-.81-2.37a16.33,16.33,0,0,1-.62,2.19Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1500.5,612.8V601.35h7.73v1.35H1502v3.55h5.37v1.35H1502v5.2Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1508.88,612.8V601.35h7.73v1.35h-6.21v3.55h5.37v1.35h-5.37v5.2Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1516.9,607.22a6.21,6.21,0,0,1,1.53-4.46,5.22,5.22,0,0,1,4-1.62,5.56,5.56,0,0,1,2.86.76,5,5,0,0,1,1.94,2.11,7,7,0,0,1,.67,3.08,6.76,6.76,0,0,1-.71,3.12,4.82,4.82,0,0,1-2,2.08,5.72,5.72,0,0,1-2.78.71,5.42,5.42,0,0,1-2.89-.79,5,5,0,0,1-1.93-2.13A6.42,6.42,0,0,1,1516.9,607.22Zm1.57,0a4.62,4.62,0,0,0,1.11,3.26,3.89,3.89,0,0,0,5.61,0,4.88,4.88,0,0,0,1.1-3.42,5.78,5.78,0,0,0-.47-2.44,3.66,3.66,0,0,0-1.38-1.62,3.78,3.78,0,0,0-2-.57,3.88,3.88,0,0,0-2.77,1.1A4.93,4.93,0,0,0,1518.47,607.25Z" fill="#59585b"/>
      <path d="M1529.81,612.8V601.35h3.94a9.48,9.48,0,0,1,2,.16,3.89,3.89,0,0,1,1.68.82,4.59,4.59,0,0,1,1.36,2,7.64,7.64,0,0,1,.45,2.72,7.74,7.74,0,0,1-.31,2.31,5.4,5.4,0,0,1-.78,1.67,4,4,0,0,1-1,1,4.28,4.28,0,0,1-1.37.57,7.79,7.79,0,0,1-1.84.2Zm1.52-1.35h2.44a6,6,0,0,0,1.78-.21,2.53,2.53,0,0,0,1-.6,3.83,3.83,0,0,0,.84-1.45,7,7,0,0,0,.3-2.2,5.34,5.34,0,0,0-.59-2.76,3,3,0,0,0-1.44-1.3,6.1,6.1,0,0,0-2-.23h-2.4Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1539.38,612.8V601.35h1.52V612.8Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1543.12,612.8V601.35h1.52v10.1h5.64v1.35Z" fill="#59585b"/>
    </g>
  </g>
  <g className="suites__unit" id="B11a">
    <rect id="B11_UNIT_FLORA-2" data-name="B11 UNIT FLORA-2" x="1327.4" y="549.5" width="129.3" height="98.7" fill="#dadada"/>
    <g>
      <path d="M1380,584.64h4.58a12.8,12.8,0,0,1,2,.11,3,3,0,0,1,1.19.47,2.91,2.91,0,0,1,.88,1,2.53,2.53,0,0,1,.35,1.34,2.68,2.68,0,0,1-1.61,2.48,3,3,0,0,1,1.61,1,2.75,2.75,0,0,1,.57,1.73,3.44,3.44,0,0,1-.37,1.52,3.09,3.09,0,0,1-1,1.18,3.37,3.37,0,0,1-1.55.54c-.39,0-1.32.07-2.79.08H1380Zm2.31,1.9v2.65h1.52c.9,0,1.46,0,1.68,0a1.44,1.44,0,0,0,.93-.41,1.18,1.18,0,0,0,.34-.89,1.25,1.25,0,0,0-.29-.87,1.37,1.37,0,0,0-.87-.4c-.23,0-.89,0-2,0Zm0,4.56v3.06h2.14a10,10,0,0,0,1.59-.07,1.41,1.41,0,0,0,.84-.46,1.37,1.37,0,0,0,.32-1,1.44,1.44,0,0,0-.25-.87,1.37,1.37,0,0,0-.72-.53,7.87,7.87,0,0,0-2-.16Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1395.12,596.09h-2.2v-8.27a7.59,7.59,0,0,1-2.83,1.66v-2a6.26,6.26,0,0,0,1.86-1.07,4,4,0,0,0,1.39-1.83h1.78Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1401.38,596.09h-2.2v-8.27a7.59,7.59,0,0,1-2.83,1.66v-2a6.26,6.26,0,0,0,1.86-1.07,4,4,0,0,0,1.39-1.83h1.78Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1355,613.09V601.64h4a9.6,9.6,0,0,1,2,.16,4,4,0,0,1,1.68.82,4.66,4.66,0,0,1,1.35,2,7.64,7.64,0,0,1,.45,2.72,8.09,8.09,0,0,1-.3,2.31,5.64,5.64,0,0,1-.78,1.67,4.13,4.13,0,0,1-1.05,1,4.22,4.22,0,0,1-1.36.57,7.85,7.85,0,0,1-1.84.2Zm1.52-1.35H1359a5.93,5.93,0,0,0,1.77-.21,2.53,2.53,0,0,0,1-.6,3.59,3.59,0,0,0,.84-1.45,7,7,0,0,0,.3-2.2,5.34,5.34,0,0,0-.59-2.76,3,3,0,0,0-1.43-1.3,6.14,6.14,0,0,0-2-.23h-2.41Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1365.58,613.09V601.64h1.52v11.45Z" fill="#59585b"/>
      <path d="M1368.51,613.09l4.4-11.45h1.63l4.69,11.45h-1.73l-1.33-3.47h-4.79l-1.26,3.47Zm3.31-4.7h3.88l-1.2-3.18q-.54-1.44-.81-2.37a13.83,13.83,0,0,1-.62,2.19Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1378.94,613.09V601.64h1.55l6,9v-9H1388v11.45h-1.55l-6-9v9Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1393.08,613.09V603h-3.78v-1.35h9.08V603h-3.79v10.1Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1398.6,613.09V601.64h1.52v4.7h6v-4.7h1.52v11.45h-1.52v-5.4h-6v5.4Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1416.79,601.64h1.52v6.61a7.87,7.87,0,0,1-.39,2.75,3.47,3.47,0,0,1-1.42,1.65,5,5,0,0,1-2.67.64,5.48,5.48,0,0,1-2.63-.56,3.1,3.1,0,0,1-1.46-1.61,7.45,7.45,0,0,1-.44-2.87v-6.61h1.51v6.61a6.62,6.62,0,0,0,.28,2.2,2.15,2.15,0,0,0,1,1.09,3.37,3.37,0,0,0,1.66.38,3.15,3.15,0,0,0,2.38-.76,4.38,4.38,0,0,0,.71-2.91Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1419.11,609.41l1.43-.12a3.18,3.18,0,0,0,.47,1.41,2.67,2.67,0,0,0,1.15.89,4.49,4.49,0,0,0,1.76.34,4.23,4.23,0,0,0,1.53-.26,2.1,2.1,0,0,0,1-.71,1.63,1.63,0,0,0,.33-1,1.49,1.49,0,0,0-.32-.94,2.37,2.37,0,0,0-1-.68c-.31-.12-1-.3-2-.56a10.55,10.55,0,0,1-2.21-.71A3.07,3.07,0,0,1,1420,606a2.58,2.58,0,0,1-.4-1.42A2.88,2.88,0,0,1,1420,603a3,3,0,0,1,1.44-1.15,5.46,5.46,0,0,1,2.1-.39,5.74,5.74,0,0,1,2.24.41,3.24,3.24,0,0,1,1.5,1.21,3.39,3.39,0,0,1,.56,1.8l-1.45.11a2.36,2.36,0,0,0-.79-1.64,3.1,3.1,0,0,0-2-.55,3.23,3.23,0,0,0-2,.5,1.52,1.52,0,0,0-.63,1.22,1.3,1.3,0,0,0,.44,1,6,6,0,0,0,2.29.82,15.1,15.1,0,0,1,2.53.73,3.44,3.44,0,0,1,1.48,1.17,2.82,2.82,0,0,1,.48,1.63,3.13,3.13,0,0,1-.53,1.72,3.46,3.46,0,0,1-1.5,1.26,5.26,5.26,0,0,1-2.21.45,6.61,6.61,0,0,1-2.6-.46,3.62,3.62,0,0,1-1.65-1.36A3.85,3.85,0,0,1,1419.11,609.41Z" fill="#59585b"/>
    </g>
  </g>
  <g className="suites__unit" id="B12a">
    <polygon id="B12_UNIT_GLOW-2" data-name="B12 UNIT GLOW-2" points="1225.2 549.7 1225.3 570.8 1239.3 570.8 1239.4 648.3 1323.4 648.3 1323.4 549.6 1225.2 549.7" fill="#dadada"/>
    <g>
      <path d="M1268.1,584.64h4.58a12.8,12.8,0,0,1,2,.11,3,3,0,0,1,1.19.47,2.91,2.91,0,0,1,.88,1,2.53,2.53,0,0,1,.35,1.34,2.68,2.68,0,0,1-1.61,2.48,3,3,0,0,1,1.61,1,2.75,2.75,0,0,1,.57,1.73,3.44,3.44,0,0,1-.37,1.52,3.09,3.09,0,0,1-1,1.18,3.37,3.37,0,0,1-1.55.54c-.39,0-1.32.07-2.79.08h-3.9Zm2.31,1.9v2.65h1.52c.9,0,1.46,0,1.68,0a1.44,1.44,0,0,0,.93-.41,1.18,1.18,0,0,0,.34-.89,1.25,1.25,0,0,0-.29-.87,1.34,1.34,0,0,0-.87-.4c-.23,0-.89,0-2,0Zm0,4.56v3.06h2.15a10,10,0,0,0,1.58-.07,1.41,1.41,0,0,0,.84-.46,1.37,1.37,0,0,0,.33-1,1.51,1.51,0,0,0-.25-.87,1.44,1.44,0,0,0-.73-.53,7.87,7.87,0,0,0-2-.16Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1283.21,596.09H1281v-8.27a7.59,7.59,0,0,1-2.83,1.66v-2a6.26,6.26,0,0,0,1.86-1.07,4,4,0,0,0,1.39-1.83h1.78Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1291.42,594.05v2h-7.69a5.2,5.2,0,0,1,.75-2.19,14.67,14.67,0,0,1,2.47-2.75,15.8,15.8,0,0,0,1.82-1.87,2.44,2.44,0,0,0,.45-1.35,1.55,1.55,0,0,0-.39-1.13,1.7,1.7,0,0,0-2.19,0,2.21,2.21,0,0,0-.47,1.38L1284,588a3.61,3.61,0,0,1,1.22-2.6,4.11,4.11,0,0,1,2.58-.79,3.74,3.74,0,0,1,2.67.91,3,3,0,0,1,1,2.28,4.08,4.08,0,0,1-.27,1.47,6.09,6.09,0,0,1-.88,1.46,15.15,15.15,0,0,1-1.44,1.47q-1,.95-1.32,1.26a4.52,4.52,0,0,0-.45.61Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1254.27,608.6v-1.35h4.86v4.25a8.82,8.82,0,0,1-2.31,1.34,6.81,6.81,0,0,1-2.44.45,6.52,6.52,0,0,1-3.06-.73,4.8,4.8,0,0,1-2.09-2.09,6.68,6.68,0,0,1-.7-3.05,7,7,0,0,1,.7-3.12,4.7,4.7,0,0,1,2-2.16,6.45,6.45,0,0,1,3-.7,6.16,6.16,0,0,1,2.24.4,3.83,3.83,0,0,1,1.58,1.12,4.89,4.89,0,0,1,.86,1.88l-1.36.37a4.49,4.49,0,0,0-.64-1.37,2.67,2.67,0,0,0-1.1-.8,4,4,0,0,0-1.58-.3,4.62,4.62,0,0,0-1.79.31,3.39,3.39,0,0,0-1.23.84,3.87,3.87,0,0,0-.72,1.13,6,6,0,0,0-.44,2.3,5.65,5.65,0,0,0,.53,2.57,3.4,3.4,0,0,0,1.54,1.53,4.77,4.77,0,0,0,2.14.5,5.17,5.17,0,0,0,1.92-.38,4.83,4.83,0,0,0,1.42-.81V608.6Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1259.41,613.09l4.4-11.45h1.63l4.69,11.45h-1.73l-1.34-3.47h-4.79l-1.25,3.47Zm3.3-4.7h3.88l-1.19-3.18q-.56-1.44-.81-2.37A16.33,16.33,0,0,1,1264,605Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1269.38,613.09v-1.41l5.87-7.33a15.61,15.61,0,0,1,1.19-1.36H1270v-1.35h8.21V603l-6.43,7.94-.7.81h7.32v1.35Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1278,613.09l4.39-11.45H1284l4.69,11.45H1287l-1.34-3.47h-4.79l-1.25,3.47Zm3.3-4.7h3.88l-1.19-3.18q-.56-1.44-.81-2.37a16.33,16.33,0,0,1-.62,2.19Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1288.5,613.09V601.64h1.55l6,9v-9h1.45v11.45H1296l-6-9v9Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1299.52,613.09V601.64H1301v11.45Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1302.35,613.09l4.4-11.45h1.63l4.69,11.45h-1.73l-1.34-3.47h-4.79l-1.25,3.47Zm3.3-4.7h3.88l-1.19-3.18q-.55-1.44-.81-2.37a16.33,16.33,0,0,1-.62,2.19Z" fill="#59585b"/>
    </g>
  </g>
  <g className="suites__unit" id="B17">
    <polygon id="B17_UNIT_JOY-2" data-name="B17 UNIT JOY-2" points="1226.9 521.9 1323.6 521.9 1323.6 423.2 1238.2 423.2 1238.2 499.2 1226.9 499.2 1226.9 521.9" fill="#dadada"/>
    <g>
      <path d="M1270.72,457.47h4.58a12.8,12.8,0,0,1,2,.11,3,3,0,0,1,1.19.47,2.91,2.91,0,0,1,.88,1,2.53,2.53,0,0,1,.35,1.34,2.68,2.68,0,0,1-1.61,2.48,3,3,0,0,1,1.61,1,2.75,2.75,0,0,1,.57,1.73,3.44,3.44,0,0,1-.37,1.52,3.09,3.09,0,0,1-1,1.18,3.37,3.37,0,0,1-1.55.54c-.39,0-1.32.07-2.79.08h-3.9Zm2.31,1.9V462h1.52c.9,0,1.46,0,1.68,0a1.46,1.46,0,0,0,.93-.41,1.18,1.18,0,0,0,.34-.89,1.25,1.25,0,0,0-.29-.87,1.37,1.37,0,0,0-.87-.4c-.23,0-.89,0-2,0Zm0,4.56V467h2.14a10,10,0,0,0,1.59-.07,1.41,1.41,0,0,0,.84-.46,1.37,1.37,0,0,0,.32-1,1.44,1.44,0,0,0-.25-.87,1.37,1.37,0,0,0-.72-.53,7.87,7.87,0,0,0-2-.16Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1285.83,468.92h-2.2v-8.27a7.59,7.59,0,0,1-2.83,1.66v-2a6.26,6.26,0,0,0,1.86-1.07,4,4,0,0,0,1.39-1.83h1.78Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1286.69,459.66v-2h7.51v1.6a11.77,11.77,0,0,0-1.89,2.62,15.43,15.43,0,0,0-1.47,3.64,13.48,13.48,0,0,0-.49,3.44h-2.12a14.45,14.45,0,0,1,1-4.84,16.26,16.26,0,0,1,2.47-4.42Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1255,485.92V474.47h1.52v10.1h5.64v1.35Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1263.62,485.92V474.47h1.52v11.45Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1267.46,485.92V474.47h5.08a6.6,6.6,0,0,1,2.32.31,2.46,2.46,0,0,1,1.28,1.09,3.2,3.2,0,0,1,.47,1.72,2.92,2.92,0,0,1-.78,2.06,4,4,0,0,1-2.44,1.06,3.78,3.78,0,0,1,.91.57,8.21,8.21,0,0,1,1.26,1.52l2,3.12h-1.9l-1.52-2.38c-.44-.69-.81-1.22-1.09-1.58a3.67,3.67,0,0,0-.77-.77,2.58,2.58,0,0,0-.69-.3,4.29,4.29,0,0,0-.85-.06H1269v5.09Zm1.51-6.4h3.26a5,5,0,0,0,1.63-.21,1.83,1.83,0,0,0,.89-.69,1.9,1.9,0,0,0,.3-1,1.69,1.69,0,0,0-.59-1.33,2.74,2.74,0,0,0-1.86-.53H1269Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1277.18,485.92V474.47h1.52v11.45Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1280.89,480.34a6.21,6.21,0,0,1,1.53-4.46,5.22,5.22,0,0,1,4-1.62,5.53,5.53,0,0,1,2.86.76,5,5,0,0,1,1.94,2.11,7,7,0,0,1,.67,3.08,6.76,6.76,0,0,1-.71,3.12,4.82,4.82,0,0,1-2,2.08,5.72,5.72,0,0,1-2.78.71,5.42,5.42,0,0,1-2.89-.79,5,5,0,0,1-1.93-2.13A6.42,6.42,0,0,1,1280.89,480.34Zm1.57,0a4.6,4.6,0,0,0,1.11,3.26,3.89,3.89,0,0,0,5.61,0,4.88,4.88,0,0,0,1.1-3.42,5.81,5.81,0,0,0-.47-2.44,3.6,3.6,0,0,0-1.38-1.62,3.78,3.78,0,0,0-2-.57,3.88,3.88,0,0,0-2.77,1.1A4.93,4.93,0,0,0,1282.46,480.37Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1292.8,485.92V474.47h4.32a9.8,9.8,0,0,1,1.75.11,3.43,3.43,0,0,1,1.41.53,2.75,2.75,0,0,1,.92,1.11,3.71,3.71,0,0,1-.58,4,4.44,4.44,0,0,1-3.36,1h-2.94v4.66Zm1.52-6h3a3.18,3.18,0,0,0,2.09-.54,2,2,0,0,0,.61-1.54,2.08,2.08,0,0,0-.36-1.23,1.65,1.65,0,0,0-1-.68,6.27,6.27,0,0,0-1.41-.1h-2.93Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1301.93,485.92V474.47h8.28v1.35h-6.77v3.51h6.34v1.34h-6.34v3.9h7v1.35Z" fill="#59585b"/>
    </g>
  </g>
  <g className="suites__unit" id="B19">
    <polygon id="B19_UNIT_LOVE-2" data-name="B19 UNIT LOVE-2" points="1403.1 423.1 1327.6 423.2 1327.6 521.9 1403 521.8 1403.1 423.1" fill="#dadada"/>
    <g>
      <path d="M1352.25,457.47h4.58a12.8,12.8,0,0,1,2,.11,3,3,0,0,1,1.19.47,2.91,2.91,0,0,1,.88,1,2.53,2.53,0,0,1,.35,1.34,2.68,2.68,0,0,1-1.61,2.48,3,3,0,0,1,1.61,1,2.75,2.75,0,0,1,.57,1.73,3.44,3.44,0,0,1-.37,1.52,3.09,3.09,0,0,1-1,1.18,3.37,3.37,0,0,1-1.55.54c-.39,0-1.32.07-2.79.08h-3.9Zm2.31,1.9V462h1.52c.9,0,1.46,0,1.68,0a1.44,1.44,0,0,0,.93-.41,1.18,1.18,0,0,0,.34-.89,1.25,1.25,0,0,0-.29-.87,1.37,1.37,0,0,0-.87-.4c-.23,0-.89,0-2,0Zm0,4.56V467h2.15a10,10,0,0,0,1.58-.07,1.41,1.41,0,0,0,.84-.46,1.37,1.37,0,0,0,.33-1,1.51,1.51,0,0,0-.25-.87,1.44,1.44,0,0,0-.73-.53,7.87,7.87,0,0,0-2-.16Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1367.36,468.92h-2.2v-8.27a7.59,7.59,0,0,1-2.83,1.66v-2a6.26,6.26,0,0,0,1.86-1.07,4,4,0,0,0,1.39-1.83h1.78Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1370.15,462.74a2.57,2.57,0,0,1-1.24-1,2.65,2.65,0,0,1-.38-1.38,2.77,2.77,0,0,1,.89-2.11,4.26,4.26,0,0,1,5.08,0,2.74,2.74,0,0,1,.9,2.11,2.54,2.54,0,0,1-.41,1.42,2.63,2.63,0,0,1-1.17.95,3.08,3.08,0,0,1,1.45,1.12,3,3,0,0,1,.5,1.69,3.47,3.47,0,0,1-1,2.58,3.69,3.69,0,0,1-2.69,1,4.07,4.07,0,0,1-2.61-.82,3.2,3.2,0,0,1-1.22-2.65,3.27,3.27,0,0,1,.46-1.71A3,3,0,0,1,1370.15,462.74Zm.25,2.7a2,2,0,0,0,.47,1.41,1.5,1.5,0,0,0,1.16.51,1.46,1.46,0,0,0,1.12-.49,2,2,0,0,0,.45-1.41,1.77,1.77,0,0,0-.46-1.29,1.47,1.47,0,0,0-1.14-.49,1.36,1.36,0,0,0-1.2.55A2,2,0,0,0,1370.4,465.44Zm.21-4.91a1.35,1.35,0,0,0,.37,1,1.3,1.3,0,0,0,1,.37,1.34,1.34,0,0,0,1-.37,1.39,1.39,0,0,0,.38-1,1.34,1.34,0,0,0-.37-1,1.51,1.51,0,0,0-2,0A1.34,1.34,0,0,0,1370.61,460.53Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1335.31,485.92V474.47h1.52v10.1h5.64v1.35Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1342.93,480.34a6.21,6.21,0,0,1,1.53-4.46,5.22,5.22,0,0,1,4-1.62,5.56,5.56,0,0,1,2.86.76,5,5,0,0,1,1.94,2.11,7,7,0,0,1,.67,3.08,6.76,6.76,0,0,1-.71,3.12,4.82,4.82,0,0,1-2,2.08,5.72,5.72,0,0,1-2.78.71,5.42,5.42,0,0,1-2.89-.79,5,5,0,0,1-1.93-2.13A6.42,6.42,0,0,1,1342.93,480.34Zm1.57,0a4.6,4.6,0,0,0,1.11,3.26,3.89,3.89,0,0,0,5.61,0,4.88,4.88,0,0,0,1.1-3.42,5.81,5.81,0,0,0-.47-2.44,3.6,3.6,0,0,0-1.38-1.62,3.78,3.78,0,0,0-2-.57,3.88,3.88,0,0,0-2.77,1.1A4.93,4.93,0,0,0,1344.5,480.37Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1354.79,485.92V474.47h4.3a5.32,5.32,0,0,1,2.1.34,2.78,2.78,0,0,1,1.25,1.07,2.86,2.86,0,0,1,.45,1.52,2.63,2.63,0,0,1-.4,1.38,2.92,2.92,0,0,1-1.21,1.05,2.94,2.94,0,0,1,1.6,1,2.76,2.76,0,0,1,.56,1.73,3.33,3.33,0,0,1-.34,1.5,3,3,0,0,1-.84,1.06,3.52,3.52,0,0,1-1.25.57,7.57,7.57,0,0,1-1.85.19Zm1.52-6.64h2.47a5.48,5.48,0,0,0,1.45-.13,1.67,1.67,0,0,0,.87-.57,1.62,1.62,0,0,0,.29-1,1.88,1.88,0,0,0-.27-1,1.33,1.33,0,0,0-.78-.59,6.39,6.39,0,0,0-1.74-.16h-2.29Zm0,5.29h2.85a6,6,0,0,0,1-.06,2.4,2.4,0,0,0,.88-.31,1.63,1.63,0,0,0,.57-.64,2,2,0,0,0,.23-1,1.87,1.87,0,0,0-.33-1.11,1.72,1.72,0,0,0-.91-.67,5.53,5.53,0,0,0-1.67-.19h-2.65Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1364.53,485.92V474.47h8.28v1.35H1366v3.51h6.34v1.34H1366v3.9h7v1.35Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1373.06,485.92V474.47h1.52v10.1h5.64v1.35Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1381.71,485.92V474.47h1.52v11.45Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1384.54,485.92l4.4-11.45h1.63l4.69,11.45h-1.73l-1.34-3.47h-4.79l-1.25,3.47Zm3.3-4.7h3.88l-1.19-3.18q-.55-1.44-.81-2.37a16.33,16.33,0,0,1-.62,2.19Z" fill="#59585b"/>
    </g>
  </g>
  <g className="suites__unit" id="B18">
    <polygon id="B18_UNIT_KEEN-2" data-name="B18 UNIT KEEN-2" points="1499.9 414.8 1498.9 414.8 1498.9 423.1 1407.1 423.1 1407 521.8 1499.9 521.8 1499.9 414.8" fill="#dadada"/>
    <g>
      <path d="M1444.48,457.47h4.58a12.8,12.8,0,0,1,2,.11,3,3,0,0,1,1.19.47,2.91,2.91,0,0,1,.88,1,2.53,2.53,0,0,1,.35,1.34,2.68,2.68,0,0,1-1.61,2.48,3,3,0,0,1,1.61,1,2.75,2.75,0,0,1,.57,1.73,3.44,3.44,0,0,1-.37,1.52,3.09,3.09,0,0,1-1,1.18,3.37,3.37,0,0,1-1.55.54c-.39,0-1.32.07-2.79.08h-3.9Zm2.31,1.9V462h1.52c.9,0,1.46,0,1.68,0a1.44,1.44,0,0,0,.93-.41,1.18,1.18,0,0,0,.34-.89,1.25,1.25,0,0,0-.29-.87,1.37,1.37,0,0,0-.87-.4c-.23,0-.89,0-2,0Zm0,4.56V467h2.15a10,10,0,0,0,1.58-.07,1.41,1.41,0,0,0,.84-.46,1.37,1.37,0,0,0,.33-1,1.51,1.51,0,0,0-.25-.87,1.44,1.44,0,0,0-.73-.53,7.87,7.87,0,0,0-2-.16Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1459.59,468.92h-2.2v-8.27a7.59,7.59,0,0,1-2.83,1.66v-2a6.26,6.26,0,0,0,1.86-1.07,4,4,0,0,0,1.39-1.83h1.78Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1460.37,466.27l2.12-.23a1.53,1.53,0,0,0,.41,1,1.2,1.2,0,0,0,.87.31,1.42,1.42,0,0,0,1.15-.62,5.36,5.36,0,0,0,.61-2.6,2.62,2.62,0,0,1-2.07,1,3.07,3.07,0,0,1-2.33-1,3.79,3.79,0,0,1-1-2.72,3.84,3.84,0,0,1,1-2.8,3.74,3.74,0,0,1,5.5.28,9.34,9.34,0,0,1,0,9,3.74,3.74,0,0,1-3,1.39,3.24,3.24,0,0,1-2.17-.71A3.44,3.44,0,0,1,1460.37,466.27Zm5-4.8a2.53,2.53,0,0,0-.49-1.64,1.45,1.45,0,0,0-1.13-.59,1.25,1.25,0,0,0-1,.48,2.44,2.44,0,0,0-.41,1.57,2.5,2.5,0,0,0,.44,1.63,1.37,1.37,0,0,0,1.1.52,1.33,1.33,0,0,0,1.06-.5A2.17,2.17,0,0,0,1465.34,461.47Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1435,485.92V474.47h4.32a9.72,9.72,0,0,1,1.75.11,3.43,3.43,0,0,1,1.41.53,2.75,2.75,0,0,1,.92,1.11,3.71,3.71,0,0,1-.58,4,4.44,4.44,0,0,1-3.36,1h-2.94v4.66Zm1.52-6h3a3.18,3.18,0,0,0,2.09-.54,2,2,0,0,0,.61-1.54,2.08,2.08,0,0,0-.36-1.23,1.65,1.65,0,0,0-1-.68,6.27,6.27,0,0,0-1.41-.1h-2.93Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1442.07,485.92l4.39-11.45h1.64l4.69,11.45h-1.73l-1.34-3.47h-4.79l-1.25,3.47Zm3.3-4.7h3.88l-1.19-3.18q-.55-1.44-.81-2.37a16.33,16.33,0,0,1-.62,2.19Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1452.61,485.92V474.47h1.55l6,9v-9h1.45v11.45h-1.55l-6-9v9Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1463.25,482.24l1.43-.12a3.18,3.18,0,0,0,.47,1.41,2.67,2.67,0,0,0,1.15.89,4.49,4.49,0,0,0,1.76.34,4.23,4.23,0,0,0,1.53-.26,2.1,2.1,0,0,0,1-.71,1.61,1.61,0,0,0,.33-1,1.49,1.49,0,0,0-.32-.94,2.37,2.37,0,0,0-1-.68c-.31-.12-1-.3-2-.56a10.55,10.55,0,0,1-2.21-.71,3.07,3.07,0,0,1-1.22-1.07,2.58,2.58,0,0,1-.4-1.42,2.88,2.88,0,0,1,.49-1.62,3,3,0,0,1,1.44-1.15,5.46,5.46,0,0,1,2.1-.39,5.74,5.74,0,0,1,2.24.41,3.3,3.3,0,0,1,1.5,1.21,3.39,3.39,0,0,1,.56,1.8l-1.45.11a2.36,2.36,0,0,0-.79-1.64,3.1,3.1,0,0,0-2-.55,3.23,3.23,0,0,0-2,.5,1.52,1.52,0,0,0-.63,1.22,1.3,1.3,0,0,0,.44,1,6,6,0,0,0,2.29.82,15.1,15.1,0,0,1,2.53.73,3.44,3.44,0,0,1,1.48,1.17,2.82,2.82,0,0,1,.48,1.63,3.13,3.13,0,0,1-.53,1.72,3.46,3.46,0,0,1-1.5,1.26,5.26,5.26,0,0,1-2.21.45,6.58,6.58,0,0,1-2.6-.46,3.62,3.62,0,0,1-1.65-1.36A3.85,3.85,0,0,1,1463.25,482.24Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1475.07,485.92v-4.85l-4.41-6.6h1.84l2.26,3.45c.41.65.8,1.29,1.16,1.94.35-.6.76-1.28,1.25-2l2.22-3.36h1.77l-4.57,6.6v4.85Z" fill="#59585b"/>
    </g>
  </g>
  <g className="suites__unit" id="B13a">
    <polygon id="B13_UNIT_GRAND-2" data-name="B13 UNIT GRAND-2" points="1225.3 574.8 1225.3 577.2 1193.3 577.3 1193.3 584.5 1160.4 584.5 1160.4 607.7 1150.2 607.7 1150.2 654.2 1153.4 654.2 1153.4 648.4 1235.4 648.3 1235.3 574.8 1225.3 574.8" fill="#dadada"/>
    <g>
      <path d="M1183.63,599.82h4.58a12.8,12.8,0,0,1,2,.11,3,3,0,0,1,1.19.47,2.91,2.91,0,0,1,.88,1,2.53,2.53,0,0,1,.35,1.34,2.68,2.68,0,0,1-1.61,2.48,3,3,0,0,1,1.61,1,2.75,2.75,0,0,1,.57,1.73,3.44,3.44,0,0,1-.37,1.52,3.09,3.09,0,0,1-1,1.18,3.37,3.37,0,0,1-1.55.54c-.39,0-1.32.07-2.79.08h-3.9Zm2.31,1.9v2.65h1.52c.9,0,1.46,0,1.68,0a1.44,1.44,0,0,0,.93-.41,1.18,1.18,0,0,0,.34-.89,1.25,1.25,0,0,0-.29-.87,1.37,1.37,0,0,0-.87-.4c-.23,0-.89,0-2,0Zm0,4.56v3.06h2.14a10,10,0,0,0,1.59-.07,1.41,1.41,0,0,0,.84-.46,1.37,1.37,0,0,0,.32-1,1.44,1.44,0,0,0-.25-.87,1.37,1.37,0,0,0-.72-.53,7.87,7.87,0,0,0-2-.16Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1198.74,611.27h-2.2V603a7.59,7.59,0,0,1-2.83,1.66v-2a6.26,6.26,0,0,0,1.86-1.07,4,4,0,0,0,1.39-1.83h1.78Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1199.46,608.23l2.13-.26a2,2,0,0,0,.54,1.25,1.54,1.54,0,0,0,1.08.43,1.51,1.51,0,0,0,1.15-.52,2,2,0,0,0,.46-1.39,1.89,1.89,0,0,0-.44-1.31,1.41,1.41,0,0,0-1.09-.49,3.86,3.86,0,0,0-1,.17l.24-1.79a2,2,0,0,0,1.36-.39,1.35,1.35,0,0,0,.47-1.09,1.23,1.23,0,0,0-.34-.92,1.25,1.25,0,0,0-.92-.35,1.31,1.31,0,0,0-1,.4,1.88,1.88,0,0,0-.48,1.14l-2-.35a4.64,4.64,0,0,1,.63-1.66,2.87,2.87,0,0,1,1.19-1,4,4,0,0,1,1.71-.36,3.4,3.4,0,0,1,2.59,1,2.83,2.83,0,0,0,.73,5.26,2.76,2.76,0,0,1,.59,1.77,3.45,3.45,0,0,1-1.1,2.57,3.8,3.8,0,0,1-2.74,1.07,3.76,3.76,0,0,1-2.58-.9A3.55,3.55,0,0,1,1199.46,608.23Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1168.18,623.78v-1.35H1173v4.25a8.82,8.82,0,0,1-2.31,1.34,6.81,6.81,0,0,1-2.44.45,6.52,6.52,0,0,1-3.06-.73,4.8,4.8,0,0,1-2.09-2.09,6.68,6.68,0,0,1-.7-3,7,7,0,0,1,.7-3.12,4.7,4.7,0,0,1,2-2.16,6.45,6.45,0,0,1,3-.7,6.16,6.16,0,0,1,2.24.4,3.76,3.76,0,0,1,1.58,1.13,4.77,4.77,0,0,1,.86,1.87l-1.36.38a4.45,4.45,0,0,0-.64-1.38,2.67,2.67,0,0,0-1.1-.8,4,4,0,0,0-1.58-.3,4.62,4.62,0,0,0-1.79.31,3.39,3.39,0,0,0-1.23.84,3.87,3.87,0,0,0-.72,1.13,6,6,0,0,0-.44,2.3,5.65,5.65,0,0,0,.53,2.57,3.36,3.36,0,0,0,1.54,1.53,4.77,4.77,0,0,0,2.14.5,5.17,5.17,0,0,0,1.92-.38,4.83,4.83,0,0,0,1.42-.81v-2.13Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1174.11,628.27V616.82h8.28v1.35h-6.77v3.51H1182V623h-6.34v3.9h7v1.35Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1182.72,628.27V616.82h5.08a6.6,6.6,0,0,1,2.32.31,2.46,2.46,0,0,1,1.28,1.09,3.29,3.29,0,0,1,.47,1.72,2.89,2.89,0,0,1-.79,2.06,4,4,0,0,1-2.43,1.06,3.78,3.78,0,0,1,.91.57,8.21,8.21,0,0,1,1.26,1.52l2,3.12h-1.9l-1.52-2.38c-.44-.69-.81-1.22-1.09-1.58a3.67,3.67,0,0,0-.77-.77,2.51,2.51,0,0,0-.7-.3,4.11,4.11,0,0,0-.84-.06h-1.76v5.09Zm1.51-6.4h3.26a5,5,0,0,0,1.63-.21,1.83,1.83,0,0,0,.89-.69,1.9,1.9,0,0,0,.3-1,1.69,1.69,0,0,0-.59-1.33,2.74,2.74,0,0,0-1.86-.53h-3.63Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1192.12,628.27V616.82h4.3a5.32,5.32,0,0,1,2.1.34,2.78,2.78,0,0,1,1.25,1.07,2.86,2.86,0,0,1,.45,1.52,2.63,2.63,0,0,1-.4,1.38,1.11,1.11,0,1,0,.39,2.09,2.76,2.76,0,0,1,.56,1.73,3.33,3.33,0,0,1-.34,1.5,3,3,0,0,1-.84,1.06,3.52,3.52,0,0,1-1.25.57,7.57,7.57,0,0,1-1.85.19Zm1.52-6.64h2.47a5.48,5.48,0,0,0,1.45-.13,1.67,1.67,0,0,0,.87-.57,1.62,1.62,0,0,0,.29-1,1.88,1.88,0,0,0-.27-1,1.37,1.37,0,0,0-.78-.59,6.39,6.39,0,0,0-1.74-.16h-2.29Zm0,5.29h2.85a6,6,0,0,0,1-.06,2.4,2.4,0,0,0,.88-.31,1.63,1.63,0,0,0,.57-.64,2,2,0,0,0,.23-1,1.87,1.87,0,0,0-.33-1.11,1.72,1.72,0,0,0-.91-.67,5.58,5.58,0,0,0-1.67-.19h-2.65Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1201.87,628.27V616.82h8.28v1.35h-6.77v3.51h6.34V623h-6.34v3.9h7v1.35Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1210.48,628.27V616.82h5.08a6.6,6.6,0,0,1,2.32.31,2.46,2.46,0,0,1,1.28,1.09,3.29,3.29,0,0,1,.47,1.72,2.89,2.89,0,0,1-.79,2.06,4,4,0,0,1-2.43,1.06,3.78,3.78,0,0,1,.91.57,8.21,8.21,0,0,1,1.26,1.52l2,3.12h-1.9l-1.52-2.38c-.44-.69-.81-1.22-1.09-1.58a3.67,3.67,0,0,0-.77-.77,2.51,2.51,0,0,0-.7-.3,4.11,4.11,0,0,0-.84-.06H1212v5.09Zm1.51-6.4h3.26a5,5,0,0,0,1.63-.21,1.83,1.83,0,0,0,.89-.69,1.9,1.9,0,0,0,.3-1,1.66,1.66,0,0,0-.59-1.33,2.74,2.74,0,0,0-1.86-.53H1212Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1219.66,628.27l4.39-11.45h1.64l4.69,11.45h-1.73l-1.34-3.47h-4.79l-1.25,3.47Zm3.3-4.7h3.88l-1.19-3.17c-.37-1-.64-1.76-.81-2.38a16.33,16.33,0,0,1-.62,2.19Z" fill="#59585b"/>
    </g>
  </g>
  <g className="suites__unit" id="B14a">
    <polygon id="B14_UNIT_GRACE-2" data-name="B14 UNIT GRACE-2" points="1156.4 580.5 1189.3 580.5 1189.2 555.2 1135.7 555.2 1135.7 537.3 1069 537.4 1069.1 648.1 1098.1 648.1 1098.1 654.2 1146.2 654.2 1146.2 603.7 1156.4 603.7 1156.4 580.5" fill="#dadada"/>
    <g>
      <path d="M1097.51,572.13h4.58a14.14,14.14,0,0,1,2,.11,3,3,0,0,1,1.19.47,2.91,2.91,0,0,1,.88,1,2.53,2.53,0,0,1,.35,1.34,2.68,2.68,0,0,1-1.61,2.48,3,3,0,0,1,1.61,1,2.75,2.75,0,0,1,.57,1.73,3.44,3.44,0,0,1-.37,1.52,3,3,0,0,1-1,1.18,3.37,3.37,0,0,1-1.55.54c-.39,0-1.32.07-2.79.08h-3.9Zm2.31,1.9v2.65h1.52c.9,0,1.46,0,1.68,0a1.44,1.44,0,0,0,.93-.41,1.18,1.18,0,0,0,.34-.89,1.25,1.25,0,0,0-.29-.87,1.37,1.37,0,0,0-.87-.4c-.23,0-.89,0-2,0Zm0,4.56v3.06H1102a10,10,0,0,0,1.59-.07,1.41,1.41,0,0,0,.84-.46,1.37,1.37,0,0,0,.32-1,1.44,1.44,0,0,0-.25-.87,1.37,1.37,0,0,0-.72-.53,7.87,7.87,0,0,0-2-.16Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1112.62,583.58h-2.2v-8.27a7.59,7.59,0,0,1-2.83,1.66v-2a6.26,6.26,0,0,0,1.86-1.07,4,4,0,0,0,1.39-1.83h1.78Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1117.58,583.58v-2.3h-4.68v-1.93l5-7.27h1.84v7.27h1.42v1.93h-1.42v2.3Zm0-4.23v-3.92l-2.63,3.92Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1091.57,597.33l1.37-.19a2.89,2.89,0,0,0,.49,1.8,1.56,1.56,0,0,0,1.21.48,1.79,1.79,0,0,0,1-.26,1.38,1.38,0,0,0,.57-.71,4.94,4.94,0,0,0,.15-1.43v-7.89h1.52v7.8a5.74,5.74,0,0,1-.35,2.23,2.51,2.51,0,0,1-1.1,1.2,3.56,3.56,0,0,1-1.77.42,3,3,0,0,1-2.31-.87A3.61,3.61,0,0,1,1091.57,597.33Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1099,600.58l4.39-11.45H1105l4.69,11.45H1108l-1.34-3.47h-4.79l-1.25,3.47Zm3.3-4.7h3.88l-1.19-3.17c-.37-1-.64-1.76-.81-2.38a16.33,16.33,0,0,1-.62,2.19Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1109.54,600.58V589.13h4a9.6,9.6,0,0,1,2,.16,4,4,0,0,1,1.68.82,4.66,4.66,0,0,1,1.35,2,7.64,7.64,0,0,1,.45,2.72,8.09,8.09,0,0,1-.3,2.31,5.64,5.64,0,0,1-.78,1.67,4.13,4.13,0,0,1-1,1,4.22,4.22,0,0,1-1.36.57,7.85,7.85,0,0,1-1.84.2Zm1.52-1.35h2.45a5.93,5.93,0,0,0,1.77-.21,2.53,2.53,0,0,0,1-.6,3.59,3.59,0,0,0,.84-1.45,7,7,0,0,0,.3-2.2,5.34,5.34,0,0,0-.59-2.76,3,3,0,0,0-1.43-1.3,6.14,6.14,0,0,0-2-.23h-2.41Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1119.88,600.58V589.13h8.28v1.35h-6.77V594h6.34v1.34h-6.34v3.9h7v1.35Z" fill="#59585b"/>
    </g>
  </g>
  <g className="suites__unit" id="B15a">
    <polygon id="B15_UNIT_HOPE-2" data-name="B15 UNIT HOPE-2" points="1135.7 516.7 1192 516.7 1192 492 1156.9 492 1156.9 469.4 1151.1 469.4 1151.1 458.6 1145.8 458.6 1145.8 417.5 1099.3 417.5 1099.3 422.5 1069 422.5 1069 533.4 1135.7 533.3 1135.7 516.7" fill="#dadada"/>
    <g>
      <path d="M1097.68,469.29h4.58a14.14,14.14,0,0,1,2,.11,3,3,0,0,1,1.19.47,2.91,2.91,0,0,1,.88,1,2.53,2.53,0,0,1,.35,1.34,2.68,2.68,0,0,1-1.61,2.48,3,3,0,0,1,1.61,1,2.75,2.75,0,0,1,.57,1.73,3.44,3.44,0,0,1-.37,1.52,3,3,0,0,1-1,1.18,3.37,3.37,0,0,1-1.55.54c-.39,0-1.32.07-2.79.08h-3.9Zm2.31,1.9v2.65h1.52c.9,0,1.46,0,1.68,0a1.46,1.46,0,0,0,.93-.41,1.18,1.18,0,0,0,.34-.89,1.25,1.25,0,0,0-.29-.87,1.37,1.37,0,0,0-.87-.4c-.23,0-.89,0-2,0Zm0,4.56v3.06h2.14a10,10,0,0,0,1.59-.07,1.41,1.41,0,0,0,.84-.46,1.37,1.37,0,0,0,.32-1,1.44,1.44,0,0,0-.25-.87,1.37,1.37,0,0,0-.72-.53,7.87,7.87,0,0,0-2.05-.16Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1112.79,480.74h-2.2v-8.27a7.59,7.59,0,0,1-2.83,1.66v-2a6.26,6.26,0,0,0,1.86-1.07,4,4,0,0,0,1.39-1.83h1.78Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1113.64,477.79l2.19-.22a1.87,1.87,0,0,0,.55,1.17,1.51,1.51,0,0,0,1.07.44,1.49,1.49,0,0,0,1.16-.56,2.51,2.51,0,0,0,.48-1.68,2.31,2.31,0,0,0-.48-1.59,1.56,1.56,0,0,0-1.23-.52,2.21,2.21,0,0,0-1.69.83l-1.78-.26,1.12-6h5.81v2.06h-4.14l-.35,1.94a3.38,3.38,0,0,1,1.5-.36,3.29,3.29,0,0,1,2.48,1.06,3.82,3.82,0,0,1,1,2.76,4.11,4.11,0,0,1-.82,2.52,3.62,3.62,0,0,1-3.1,1.52,3.81,3.81,0,0,1-2.58-.86A3.48,3.48,0,0,1,1113.64,477.79Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1081.78,497.74V486.29h1.52v10.1h5.64v1.35Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1089.58,497.74l4.4-11.45h1.63l4.69,11.45h-1.73l-1.34-3.47h-4.79l-1.25,3.47Zm3.3-4.7h3.88l-1.19-3.17c-.37-1-.64-1.76-.81-2.38a16.33,16.33,0,0,1-.62,2.19Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1100.15,497.74V486.29h2.28l2.71,8.11c.25.75.43,1.32.55,1.69.13-.42.33-1,.61-1.83l2.74-8h2v11.45h-1.46v-9.59l-3.33,9.59h-1.37l-3.31-9.75v9.75Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1112.58,497.74V486.29h1.52v11.45Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1124,486.29h1.52v6.61a7.87,7.87,0,0,1-.39,2.75,3.5,3.5,0,0,1-1.41,1.65,5.06,5.06,0,0,1-2.68.64,5.48,5.48,0,0,1-2.63-.56,3.12,3.12,0,0,1-1.46-1.6,7.51,7.51,0,0,1-.44-2.88v-6.61h1.51v6.61a6.62,6.62,0,0,0,.28,2.2,2.15,2.15,0,0,0,1,1.09,3.39,3.39,0,0,0,1.66.38,3.15,3.15,0,0,0,2.38-.76,4.38,4.38,0,0,0,.71-2.91Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1126.8,497.74V486.29h2.28l2.71,8.11c.25.75.43,1.32.55,1.69.13-.42.33-1,.61-1.83l2.74-8h2v11.45h-1.46v-9.59l-3.33,9.59h-1.37l-3.31-9.75v9.75Z" fill="#59585b"/>
    </g>
  </g>
  <g className="suites__unit" id="B16">
    <polygon id="B16_UNIT_IMAGINE-2" data-name="B16 UNIT IMAGINE-2" points="1155.1 465.4 1160.9 465.4 1160.9 488 1196 488 1196 495.2 1234.2 495.2 1234.2 423.2 1154.7 423.3 1154.7 417.5 1149.8 417.5 1149.8 454.6 1155.1 454.6 1155.1 465.4" fill="#dadada"/>
    <g>
      <path d="M1182,437.52h4.58a12.8,12.8,0,0,1,2,.11,3,3,0,0,1,1.19.47,2.91,2.91,0,0,1,.88,1,2.53,2.53,0,0,1,.35,1.34,2.68,2.68,0,0,1-1.61,2.48,3,3,0,0,1,1.61,1,2.75,2.75,0,0,1,.57,1.73,3.44,3.44,0,0,1-.37,1.52,3.09,3.09,0,0,1-1,1.18,3.37,3.37,0,0,1-1.55.54c-.39,0-1.32.07-2.79.08H1182Zm2.31,1.9v2.65h1.52c.9,0,1.46,0,1.68,0a1.44,1.44,0,0,0,.93-.41,1.18,1.18,0,0,0,.34-.89,1.25,1.25,0,0,0-.29-.87,1.37,1.37,0,0,0-.87-.4c-.23,0-.89,0-2,0Zm0,4.56V447h2.14a10,10,0,0,0,1.59-.07,1.46,1.46,0,0,0,.84-.46,1.37,1.37,0,0,0,.32-1,1.44,1.44,0,0,0-.25-.87,1.37,1.37,0,0,0-.72-.53,7.87,7.87,0,0,0-2-.16Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1197.14,449h-2.2V440.7a7.59,7.59,0,0,1-2.83,1.66v-2a6.26,6.26,0,0,0,1.86-1.07,4,4,0,0,0,1.39-1.83h1.78Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1205.38,440.32l-2.13.24a1.56,1.56,0,0,0-.4-1,1.2,1.2,0,0,0-.86-.32,1.43,1.43,0,0,0-1.17.63,5.33,5.33,0,0,0-.61,2.6,2.6,2.6,0,0,1,2-1,3.12,3.12,0,0,1,2.36,1.05,3.81,3.81,0,0,1,1,2.7,3.91,3.91,0,0,1-1,2.82,3.74,3.74,0,0,1-5.5-.28,6.9,6.9,0,0,1-1.12-4.42,7.11,7.11,0,0,1,1.16-4.54,3.77,3.77,0,0,1,3-1.39,3.19,3.19,0,0,1,2.16.73A3.44,3.44,0,0,1,1205.38,440.32Zm-5,4.79a2.46,2.46,0,0,0,.49,1.65,1.45,1.45,0,0,0,1.13.58,1.26,1.26,0,0,0,1-.47,2.41,2.41,0,0,0,.41-1.56,2.53,2.53,0,0,0-.44-1.64,1.4,1.4,0,0,0-2.16,0A2.14,2.14,0,0,0,1200.4,445.11Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1161.72,466V454.52h1.52v10.1h5.64V466Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1169.52,466l4.4-11.45h1.63l4.69,11.45h-1.73l-1.34-3.47h-4.79l-1.25,3.47Zm3.3-4.7h3.88l-1.19-3.18q-.55-1.44-.81-2.37a16.33,16.33,0,0,1-.62,2.19Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1180.06,466V454.52h1.55l6,9v-9h1.45V466h-1.55l-6-9v9Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1194.2,466v-10.1h-3.78v-1.35h9.08v1.35h-3.79V466Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1197.33,466l4.4-11.45h1.63l4.69,11.45h-1.73L1205,462.5h-4.79l-1.25,3.47Zm3.3-4.7h3.88l-1.19-3.18q-.55-1.44-.81-2.37a16.33,16.33,0,0,1-.62,2.19Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1207.86,466V454.52h1.55l6,9v-9h1.45V466h-1.55l-6-9v9Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1217.57,466l4.39-11.45h1.64l4.69,11.45h-1.73l-1.34-3.47h-4.79l-1.25,3.47Zm3.3-4.7h3.88l-1.19-3.18q-.55-1.44-.81-2.37a16.33,16.33,0,0,1-.62,2.19Z" fill="#59585b"/>
    </g>
  </g>
  <g className="suites__unit" id="B20">
    <polygon id="B20_UNIT_LUCKY-2" data-name="B20 UNIT LUCKY-2" points="1565.6 507.1 1634.4 507 1634.3 444.5 1589.3 444.6 1589.3 414.8 1503.9 414.8 1503.9 521.8 1565.7 521.8 1565.6 507.1" fill="#dadada"/>
    <g>
      <path d="M1545.91,451.79h4.58a14.14,14.14,0,0,1,2,.11,3,3,0,0,1,1.19.47,2.91,2.91,0,0,1,.88,1,2.53,2.53,0,0,1,.35,1.34,2.68,2.68,0,0,1-1.61,2.48,3,3,0,0,1,1.61,1,2.75,2.75,0,0,1,.57,1.73,3.44,3.44,0,0,1-.37,1.52,3,3,0,0,1-1,1.18,3.37,3.37,0,0,1-1.55.54c-.39,0-1.32.07-2.79.08h-3.9Zm2.31,1.9v2.65h1.52c.9,0,1.46,0,1.68,0a1.44,1.44,0,0,0,.93-.41,1.18,1.18,0,0,0,.34-.89,1.25,1.25,0,0,0-.29-.87,1.34,1.34,0,0,0-.87-.4c-.23,0-.89,0-2,0Zm0,4.56v3.06h2.15a10,10,0,0,0,1.58-.07,1.41,1.41,0,0,0,.84-.46,1.37,1.37,0,0,0,.33-1,1.51,1.51,0,0,0-.25-.87,1.44,1.44,0,0,0-.73-.53,7.87,7.87,0,0,0-2.05-.16Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1562.81,461.2v2h-7.69a5.2,5.2,0,0,1,.75-2.19,14.67,14.67,0,0,1,2.47-2.75,15.8,15.8,0,0,0,1.82-1.87,2.44,2.44,0,0,0,.45-1.35,1.51,1.51,0,0,0-.39-1.12,1.46,1.46,0,0,0-1.09-.4,1.49,1.49,0,0,0-1.1.41,2.21,2.21,0,0,0-.47,1.38l-2.18-.22a3.61,3.61,0,0,1,1.22-2.6,4.11,4.11,0,0,1,2.58-.79,3.7,3.7,0,0,1,2.66.91,3,3,0,0,1,1,2.28,4.08,4.08,0,0,1-.27,1.47,6.33,6.33,0,0,1-.88,1.47,16.64,16.64,0,0,1-1.44,1.46q-1,.94-1.32,1.26a4.52,4.52,0,0,0-.45.61Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1568.28,451.74a3.13,3.13,0,0,1,2.6,1.19c.75.94,1.12,2.49,1.12,4.66s-.38,3.73-1.13,4.67a3.13,3.13,0,0,1-2.59,1.18,3.27,3.27,0,0,1-2.69-1.29c-.69-.86-1-2.38-1-4.58a7.57,7.57,0,0,1,1.13-4.66A3.12,3.12,0,0,1,1568.28,451.74Zm0,1.82a1.16,1.16,0,0,0-.71.25,1.69,1.69,0,0,0-.48.91,12.06,12.06,0,0,0-.23,2.87,12.82,12.82,0,0,0,.2,2.77,2.08,2.08,0,0,0,.51,1,1.16,1.16,0,0,0,.71.25,1.09,1.09,0,0,0,.71-.26,1.72,1.72,0,0,0,.49-.91,12.56,12.56,0,0,0,.22-2.86,13,13,0,0,0-.2-2.77,2.08,2.08,0,0,0-.51-1A1.14,1.14,0,0,0,1568.28,453.56Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1535.63,480.24v-1.41l5.87-7.33a15.61,15.61,0,0,1,1.19-1.36h-6.4v-1.35h8.21v1.35l-6.43,7.94-.7.81h7.32v1.35Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1545.68,480.24V468.79h1.52v11.45Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1549.47,480.24V468.79H1551l6,9v-9h1.45v11.45h-1.55l-6-9v9Z" fill="#59585b"/>
      <path d="M1561,480.24V468.79h1.56l6,9v-9h1.46v11.45h-1.56l-6-9v9Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1571.25,480.24V468.79h1.52v11.45Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1574.07,480.24l4.39-11.45h1.64l4.69,11.45h-1.73l-1.34-3.47h-4.79l-1.25,3.47Zm3.3-4.7h3.88l-1.19-3.17c-.37-1-.64-1.76-.81-2.38a16.33,16.33,0,0,1-.62,2.19Z" fill="#59585b"/>
    </g>
  </g>
  <g className="suites__unit" id="C1">
    <polygon id="C1_UNIT_MERRY-2" data-name="C1 UNIT MERRY-2" points="1409.4 1481.8 1383.8 1458.7 1338 1509.4 1342.3 1513.3 1332.3 1524.4 1405.7 1590.4 1466 1523.5 1414 1476.7 1409.4 1481.8" fill="#dadada"/>
    <g>
      <path d="M1399.39,1513l2.24.71a5,5,0,0,1-1.71,2.79,5.39,5.39,0,0,1-6.79-.65,6,6,0,0,1-1.47-4.27,6.29,6.29,0,0,1,1.47-4.44,5.09,5.09,0,0,1,3.89-1.58,4.78,4.78,0,0,1,3.41,1.24,4.56,4.56,0,0,1,1.17,2.11l-2.29.55a2.43,2.43,0,0,0-.84-1.41,2.47,2.47,0,0,0-1.57-.51,2.61,2.61,0,0,0-2.07.91,4.48,4.48,0,0,0-.79,3,4.84,4.84,0,0,0,.78,3.1,2.55,2.55,0,0,0,2,.92,2.32,2.32,0,0,0,1.59-.59A3.38,3.38,0,0,0,1399.39,1513Z" fill="#59585b"/>
      <path d="M1408.75,1517.17h-2.19v-8.27a7.64,7.64,0,0,1-2.84,1.66v-2a6.3,6.3,0,0,0,1.87-1.07,4,4,0,0,0,1.38-1.83h1.78Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1376.91,1534.17v-11.45h2.28l2.71,8.11c.25.75.43,1.32.55,1.69.13-.42.33-1,.61-1.83l2.74-8h2v11.45h-1.46v-9.59l-3.33,9.59h-1.37l-3.31-9.75v9.75Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1389.11,1534.17v-11.45h8.28v1.35h-6.77v3.51H1397v1.34h-6.34v3.9h7v1.35Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1397.73,1534.17v-11.45h5.08a6.6,6.6,0,0,1,2.32.31,2.46,2.46,0,0,1,1.28,1.09,3.29,3.29,0,0,1,.47,1.72,2.89,2.89,0,0,1-.79,2.06,4,4,0,0,1-2.43,1.06,3.78,3.78,0,0,1,.91.57,8.21,8.21,0,0,1,1.26,1.52l2,3.12h-1.9l-1.52-2.38c-.44-.69-.81-1.22-1.09-1.58a3.67,3.67,0,0,0-.77-.77,2.51,2.51,0,0,0-.7-.3,4.11,4.11,0,0,0-.84-.06h-1.76v5.09Zm1.51-6.4h3.26a5,5,0,0,0,1.63-.21,1.83,1.83,0,0,0,.89-.69,1.9,1.9,0,0,0,.3-1,1.69,1.69,0,0,0-.59-1.33,2.74,2.74,0,0,0-1.86-.53h-3.63Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1407.22,1534.17v-11.45h5.08a6.6,6.6,0,0,1,2.32.31,2.46,2.46,0,0,1,1.28,1.09,3.29,3.29,0,0,1,.47,1.72,2.89,2.89,0,0,1-.79,2.06,4,4,0,0,1-2.43,1.06,3.78,3.78,0,0,1,.91.57,8.21,8.21,0,0,1,1.26,1.52l2,3.12h-1.9l-1.52-2.38c-.44-.69-.81-1.22-1.09-1.58a3.67,3.67,0,0,0-.77-.77,2.51,2.51,0,0,0-.7-.3,4.11,4.11,0,0,0-.84-.06h-1.76v5.09Zm1.51-6.4H1412a5,5,0,0,0,1.63-.21,1.83,1.83,0,0,0,.89-.69,1.9,1.9,0,0,0,.3-1,1.69,1.69,0,0,0-.59-1.33,2.74,2.74,0,0,0-1.86-.53h-3.63Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1420.27,1534.17v-4.85l-4.41-6.6h1.84l2.26,3.45c.42.65.8,1.29,1.16,1.94.35-.6.76-1.28,1.25-2l2.22-3.36h1.77l-4.57,6.6v4.85Z" fill="#59585b"/>
    </g>
  </g>
  <g className="suites__unit" id="C2">
    <polygon id="C2_UNIT_STELLAR-2" data-name="C2 UNIT STELLAR-2" points="1263.1 1601.3 1278.3 1615 1288.5 1603.7 1346.5 1656.1 1403 1593.4 1329.7 1527.4 1263.1 1601.3" fill="#dadada"/>
    <g>
      <path d="M1337.12,1581.56l2.24.71a5,5,0,0,1-1.71,2.78,4.86,4.86,0,0,1-3,.91,4.92,4.92,0,0,1-3.75-1.55,6,6,0,0,1-1.47-4.27,6.29,6.29,0,0,1,1.47-4.44,5.09,5.09,0,0,1,3.89-1.58,4.78,4.78,0,0,1,3.41,1.24,4.56,4.56,0,0,1,1.17,2.11l-2.29.55a2.43,2.43,0,0,0-.84-1.41,2.47,2.47,0,0,0-1.57-.51,2.61,2.61,0,0,0-2.07.91,4.48,4.48,0,0,0-.79,3,4.84,4.84,0,0,0,.78,3.1,2.55,2.55,0,0,0,2,.92,2.32,2.32,0,0,0,1.59-.59A3.38,3.38,0,0,0,1337.12,1581.56Z" fill="#59585b"/>
      <path d="M1348.28,1583.73v2h-7.7a5.5,5.5,0,0,1,.75-2.19,14.67,14.67,0,0,1,2.47-2.75,15,15,0,0,0,1.82-1.87,2.46,2.46,0,0,0,.46-1.35,1.56,1.56,0,0,0-.4-1.13,1.69,1.69,0,0,0-2.18,0,2.15,2.15,0,0,0-.47,1.38l-2.19-.22a3.58,3.58,0,0,1,1.23-2.6,4.07,4.07,0,0,1,2.58-.79,3.72,3.72,0,0,1,2.66.91,3,3,0,0,1,1,2.28,3.88,3.88,0,0,1-.28,1.47,5.78,5.78,0,0,1-.88,1.46,13.05,13.05,0,0,1-1.44,1.47c-.69.63-1.13,1.05-1.31,1.26a3.28,3.28,0,0,0-.45.61Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1308.48,1599.09l1.43-.13a3.18,3.18,0,0,0,.47,1.42,2.67,2.67,0,0,0,1.15.89,4.49,4.49,0,0,0,1.76.34,4.23,4.23,0,0,0,1.53-.26,2.15,2.15,0,0,0,1-.71,1.6,1.6,0,0,0,.32-1,1.48,1.48,0,0,0-.31-.94,2.37,2.37,0,0,0-1-.68c-.31-.12-1-.3-2-.56a10.55,10.55,0,0,1-2.21-.71,3.07,3.07,0,0,1-1.22-1.07,2.58,2.58,0,0,1-.4-1.42,2.88,2.88,0,0,1,.49-1.62,3,3,0,0,1,1.44-1.15,5.46,5.46,0,0,1,2.1-.39,5.77,5.77,0,0,1,2.24.41,3.24,3.24,0,0,1,1.5,1.21,3.39,3.39,0,0,1,.56,1.8l-1.45.11a2.36,2.36,0,0,0-.79-1.64,3.1,3.1,0,0,0-2-.55,3.14,3.14,0,0,0-2,.5,1.52,1.52,0,0,0-.63,1.22,1.3,1.3,0,0,0,.44,1,6,6,0,0,0,2.29.82,15.1,15.1,0,0,1,2.53.73,3.51,3.51,0,0,1,1.48,1.17,2.82,2.82,0,0,1,.48,1.63,3.13,3.13,0,0,1-.53,1.72,3.46,3.46,0,0,1-1.5,1.26,5.22,5.22,0,0,1-2.21.44,6.62,6.62,0,0,1-2.6-.45,3.62,3.62,0,0,1-1.65-1.36A3.85,3.85,0,0,1,1308.48,1599.09Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1320.23,1602.77v-10.1h-3.77v-1.35h9.07v1.35h-3.79v10.1Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1325.73,1602.77v-11.45H1334v1.35h-6.77v3.51h6.34v1.34h-6.34v3.9h7v1.35Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1334.26,1602.77v-11.45h1.52v10.1h5.64v1.35Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1342.59,1602.77v-11.45h1.52v10.1h5.64v1.35Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1350.39,1602.77l4.4-11.45h1.63l4.69,11.45h-1.73l-1.34-3.47h-4.79l-1.25,3.47Zm3.3-4.7h3.88l-1.19-3.18q-.56-1.44-.81-2.37a16.33,16.33,0,0,1-.62,2.19Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1361,1602.77v-11.45h5.08a6.88,6.88,0,0,1,2.32.3,2.61,2.61,0,0,1,1.28,1.09,3.35,3.35,0,0,1,.47,1.73,2.89,2.89,0,0,1-.79,2.06,4,4,0,0,1-2.43,1.06,3.78,3.78,0,0,1,.91.57,8.21,8.21,0,0,1,1.26,1.52l2,3.12h-1.9l-1.52-2.38c-.44-.69-.81-1.22-1.09-1.58a3.94,3.94,0,0,0-.77-.77,2.51,2.51,0,0,0-.7-.3,4.11,4.11,0,0,0-.84-.06h-1.76v5.09Zm1.51-6.4h3.26a5,5,0,0,0,1.63-.21,1.77,1.77,0,0,0,.89-.69,1.9,1.9,0,0,0,.3-1,1.71,1.71,0,0,0-.59-1.33,2.74,2.74,0,0,0-1.86-.53h-3.63Z" fill="#59585b"/>
    </g>
  </g>
  <g className="suites__unit" id="C3">
    <polygon id="C3_UNIT_INSPIRE-2" data-name="C3 UNIT INSPIRE-2" points="1281.3 1617.7 1281.3 1617.7 1260.8 1640.5 1268 1646.9 1245.8 1671.6 1262.2 1686.3 1255.2 1694.1 1289.9 1725.4 1291.7 1723.5 1288.4 1720.5 1343.8 1659 1288.8 1609.4 1281.3 1617.7" fill="#dadada"/>
    <g>
      <path d="M1290.11,1653.64l2.24.71a5,5,0,0,1-1.71,2.78,4.86,4.86,0,0,1-3,.91,4.92,4.92,0,0,1-3.75-1.55,6,6,0,0,1-1.47-4.27,6.29,6.29,0,0,1,1.47-4.44,5.09,5.09,0,0,1,3.89-1.58,4.78,4.78,0,0,1,3.41,1.24,4.56,4.56,0,0,1,1.17,2.11l-2.29.55a2.43,2.43,0,0,0-.84-1.41,2.47,2.47,0,0,0-1.57-.51,2.61,2.61,0,0,0-2.07.91,4.48,4.48,0,0,0-.79,3,4.84,4.84,0,0,0,.78,3.1,2.55,2.55,0,0,0,2,.92,2.32,2.32,0,0,0,1.59-.59A3.38,3.38,0,0,0,1290.11,1653.64Z" fill="#59585b"/>
      <path d="M1293.78,1654.81l2.12-.26a2,2,0,0,0,.55,1.24,1.55,1.55,0,0,0,2.22-.08,2,2,0,0,0,.47-1.39,1.84,1.84,0,0,0-.45-1.31,1.41,1.41,0,0,0-1.09-.49,3.79,3.79,0,0,0-1,.17l.24-1.79a2,2,0,0,0,1.36-.39,1.39,1.39,0,0,0,.47-1.09,1.2,1.2,0,0,0-1.26-1.27,1.3,1.3,0,0,0-1,.39,1.88,1.88,0,0,0-.49,1.15l-2-.35a4.46,4.46,0,0,1,.64-1.66,2.84,2.84,0,0,1,1.18-1,4,4,0,0,1,1.71-.36,3.42,3.42,0,0,1,2.6,1,2.82,2.82,0,0,0,.73,5.26,2.76,2.76,0,0,1,.59,1.77,3.42,3.42,0,0,1-1.11,2.57,4.12,4.12,0,0,1-5.32.17A3.54,3.54,0,0,1,1293.78,1654.81Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1265.93,1674.85V1663.4h1.52v11.45Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1269.72,1674.85V1663.4h1.55l6,9v-9h1.45v11.45h-1.55l-6-9v9Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1280.36,1671.17l1.43-.13a3.18,3.18,0,0,0,.47,1.42,2.67,2.67,0,0,0,1.15.89,4.49,4.49,0,0,0,1.76.34,4.23,4.23,0,0,0,1.53-.26,2.1,2.1,0,0,0,1-.71,1.63,1.63,0,0,0,.33-1,1.49,1.49,0,0,0-.32-.94,2.37,2.37,0,0,0-1-.68c-.31-.12-1-.3-2-.56a10.55,10.55,0,0,1-2.21-.71,3.07,3.07,0,0,1-1.22-1.07,2.58,2.58,0,0,1-.4-1.42,2.88,2.88,0,0,1,.49-1.62,3,3,0,0,1,1.44-1.15,5.46,5.46,0,0,1,2.1-.39,5.74,5.74,0,0,1,2.24.41,3.24,3.24,0,0,1,1.5,1.21,3.39,3.39,0,0,1,.56,1.8l-1.45.11a2.36,2.36,0,0,0-.79-1.64,3.1,3.1,0,0,0-2-.55,3.23,3.23,0,0,0-2,.5,1.52,1.52,0,0,0-.63,1.22,1.3,1.3,0,0,0,.44,1,6,6,0,0,0,2.29.82,15.1,15.1,0,0,1,2.53.73A3.44,3.44,0,0,1,1289,1670a2.82,2.82,0,0,1,.48,1.63,3.13,3.13,0,0,1-.53,1.72,3.46,3.46,0,0,1-1.5,1.26,5.26,5.26,0,0,1-2.21.44,6.62,6.62,0,0,1-2.6-.45,3.69,3.69,0,0,1-1.65-1.36A3.85,3.85,0,0,1,1280.36,1671.17Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1289,1674.85V1663.4h4.32a9.8,9.8,0,0,1,1.75.11,3.43,3.43,0,0,1,1.41.53,2.75,2.75,0,0,1,.92,1.11,3.71,3.71,0,0,1-.58,4,4.44,4.44,0,0,1-3.36,1h-2.94v4.66Zm1.52-6h3a3.12,3.12,0,0,0,2.09-.55,1.92,1.92,0,0,0,.61-1.53,2.08,2.08,0,0,0-.36-1.23,1.65,1.65,0,0,0-1-.68,6.27,6.27,0,0,0-1.41-.1h-2.93Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1298.31,1674.85V1663.4h1.52v11.45Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1302.14,1674.85V1663.4h5.08a6.6,6.6,0,0,1,2.32.31,2.49,2.49,0,0,1,1.28,1.08,3.35,3.35,0,0,1,.47,1.73,2.89,2.89,0,0,1-.79,2.06,4,4,0,0,1-2.43,1.06,3.78,3.78,0,0,1,.91.57,8.21,8.21,0,0,1,1.26,1.52l2,3.12h-1.9l-1.52-2.38c-.44-.69-.81-1.22-1.09-1.58a3.67,3.67,0,0,0-.77-.77,2.51,2.51,0,0,0-.7-.3,4.11,4.11,0,0,0-.84-.06h-1.76v5.09Zm1.51-6.4h3.26a5,5,0,0,0,1.63-.21,1.83,1.83,0,0,0,.89-.69,1.9,1.9,0,0,0,.3-1,1.69,1.69,0,0,0-.59-1.33,2.74,2.74,0,0,0-1.86-.53h-3.63Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1311.64,1674.85V1663.4h8.28v1.35h-6.77v3.51h6.34v1.34h-6.34v3.9h7v1.35Z" fill="#59585b"/>
    </g>
  </g>
  <g className="suites__unit" id="C4">
    <g>
      <polygon id="C4_UNIT_PRIMROSE-2" data-name="C4 UNIT PRIMROSE-2" points="1256.6 1686.6 1240.2 1671.8 1262.3 1647.2 1256.7 1642.1 1241.5 1628.5 1206.1 1667.8 1194.4 1657.3 1149 1707.7 1231.1 1781.6 1250.9 1759.7 1255.4 1763.8 1287.3 1728.4 1249.6 1694.4 1256.6 1686.6" fill="#dadada"/>
      <g>
        <path d="M1216.6,1708.24l2.24.71a5,5,0,0,1-1.71,2.79,5.39,5.39,0,0,1-6.79-.65,6,6,0,0,1-1.47-4.26,6.3,6.3,0,0,1,1.47-4.45,5.09,5.09,0,0,1,3.89-1.58,4.78,4.78,0,0,1,3.41,1.24,4.56,4.56,0,0,1,1.17,2.11l-2.29.55a2.43,2.43,0,0,0-.84-1.41,2.47,2.47,0,0,0-1.57-.51,2.61,2.61,0,0,0-2.07.91,4.48,4.48,0,0,0-.79,3,4.84,4.84,0,0,0,.78,3.1,2.55,2.55,0,0,0,2,.92,2.32,2.32,0,0,0,1.59-.59A3.38,3.38,0,0,0,1216.6,1708.24Z" fill="#59585b"/>
        <path d="M1224.65,1712.45v-2.3H1220v-1.93l5-7.27h1.84v7.27h1.43v1.93h-1.43v2.3Zm0-4.23v-3.92l-2.63,3.92Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1183.36,1729.45V1718h4.32a9.8,9.8,0,0,1,1.75.11,3.43,3.43,0,0,1,1.41.53,2.75,2.75,0,0,1,.92,1.11,3.71,3.71,0,0,1-.58,4,4.44,4.44,0,0,1-3.36,1h-2.94v4.66Zm1.52-6h3a3.18,3.18,0,0,0,2.09-.54,2,2,0,0,0,.61-1.54,2.08,2.08,0,0,0-.36-1.23,1.7,1.7,0,0,0-1-.68,6.27,6.27,0,0,0-1.41-.1h-2.93Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1192.48,1729.45V1718h5.08a6.6,6.6,0,0,1,2.32.31,2.51,2.51,0,0,1,1.28,1.09,3.29,3.29,0,0,1,.47,1.72,2.89,2.89,0,0,1-.79,2.06,4,4,0,0,1-2.43,1.06,3.78,3.78,0,0,1,.91.57,8.21,8.21,0,0,1,1.26,1.52l2,3.12h-1.9l-1.52-2.38c-.44-.69-.81-1.22-1.09-1.58a3.67,3.67,0,0,0-.77-.77,2.51,2.51,0,0,0-.7-.3,4.11,4.11,0,0,0-.84-.06H1194v5.09Zm1.51-6.4h3.26a5,5,0,0,0,1.63-.21,1.83,1.83,0,0,0,.89-.69,1.9,1.9,0,0,0,.3-1,1.66,1.66,0,0,0-.59-1.33,2.74,2.74,0,0,0-1.86-.53H1194Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1202.2,1729.45V1718h1.52v11.45Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1205.9,1729.45V1718h2.28l2.71,8.11c.25.75.43,1.32.55,1.69.13-.42.33-1,.61-1.83l2.74-8h2v11.45h-1.46v-9.59l-3.33,9.59h-1.37l-3.31-9.75v9.75Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1218.1,1729.45V1718h5.08a6.6,6.6,0,0,1,2.32.31,2.51,2.51,0,0,1,1.28,1.09,3.29,3.29,0,0,1,.47,1.72,2.89,2.89,0,0,1-.79,2.06,4,4,0,0,1-2.43,1.06,3.78,3.78,0,0,1,.91.57,8.21,8.21,0,0,1,1.26,1.52l2,3.12h-1.9l-1.52-2.38c-.44-.69-.81-1.22-1.09-1.58a3.67,3.67,0,0,0-.77-.77,2.51,2.51,0,0,0-.7-.3,4.11,4.11,0,0,0-.84-.06h-1.76v5.09Zm1.51-6.4h3.26a5,5,0,0,0,1.63-.21,1.83,1.83,0,0,0,.89-.69,1.9,1.9,0,0,0,.3-1,1.69,1.69,0,0,0-.59-1.33,2.74,2.74,0,0,0-1.86-.53h-3.63Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1227.06,1723.87a6.21,6.21,0,0,1,1.53-4.46,5.22,5.22,0,0,1,4-1.62,5.56,5.56,0,0,1,2.86.76,5,5,0,0,1,1.94,2.12,6.89,6.89,0,0,1,.67,3.07,6.76,6.76,0,0,1-.71,3.12,4.82,4.82,0,0,1-2,2.08,5.72,5.72,0,0,1-2.78.71,5.42,5.42,0,0,1-2.89-.79,5,5,0,0,1-1.93-2.13A6.42,6.42,0,0,1,1227.06,1723.87Zm1.57,0a4.6,4.6,0,0,0,1.11,3.26,3.89,3.89,0,0,0,5.61,0,4.87,4.87,0,0,0,1.1-3.42,5.81,5.81,0,0,0-.47-2.44,3.6,3.6,0,0,0-1.38-1.62,3.78,3.78,0,0,0-2-.57,3.88,3.88,0,0,0-2.77,1.1A4.93,4.93,0,0,0,1228.63,1723.9Z" fill="#59585b"/>
      </g>
      <g>
        <path d="M1238.69,1725.77l1.43-.12a3.18,3.18,0,0,0,.47,1.41,2.67,2.67,0,0,0,1.15.89,4.49,4.49,0,0,0,1.76.34,4.23,4.23,0,0,0,1.53-.26,2.15,2.15,0,0,0,1-.71,1.6,1.6,0,0,0,.32-1,1.48,1.48,0,0,0-.31-.94,2.37,2.37,0,0,0-1-.68,20.62,20.62,0,0,0-2-.55,11,11,0,0,1-2.21-.72,3.07,3.07,0,0,1-1.22-1.07,2.58,2.58,0,0,1-.4-1.42,2.88,2.88,0,0,1,.49-1.62,3,3,0,0,1,1.44-1.15,5.46,5.46,0,0,1,2.1-.39,5.77,5.77,0,0,1,2.24.41,3.3,3.3,0,0,1,1.5,1.21,3.39,3.39,0,0,1,.56,1.8l-1.45.11a2.36,2.36,0,0,0-.79-1.64,3.1,3.1,0,0,0-2-.55,3.21,3.21,0,0,0-2,.5,1.52,1.52,0,0,0-.63,1.22,1.3,1.3,0,0,0,.44,1,6,6,0,0,0,2.29.82,15.1,15.1,0,0,1,2.53.73,3.51,3.51,0,0,1,1.48,1.17,2.82,2.82,0,0,1,.48,1.63,3.11,3.11,0,0,1-.53,1.72,3.46,3.46,0,0,1-1.5,1.26,5.26,5.26,0,0,1-2.21.45,6.61,6.61,0,0,1-2.6-.46,3.62,3.62,0,0,1-1.65-1.36A3.85,3.85,0,0,1,1238.69,1725.77Z" fill="#59585b"/>
      </g>
    </g>
    <g>
      <path d="M1247.34,1729.45V1718h8.28v1.35h-6.77v3.51h6.34v1.34h-6.34v3.9h7v1.35Z" fill="#59585b"/>
    </g>
  </g>
  <g className="suites__unit" id="C5-6" data-name="C5/6">
    <polygon points="1173.9 1508.1 1119.8 1568.2 1115.9 1564.7 1079.4 1605.2 1084.7 1610 1065 1631.9 1146.1 1705 1191.4 1654.6 1178.7 1643.2 1214.7 1603.2 1198.9 1589 1203.6 1583.8 1224.2 1561 1224.6 1561.4 1228.3 1557.2 1173.9 1508.1" fill="#dadada"/>
    <g>
      <path d="M1140.24,1591.65l2.24.71a5,5,0,0,1-1.71,2.79,5.39,5.39,0,0,1-6.79-.65,6,6,0,0,1-1.47-4.26,6.3,6.3,0,0,1,1.47-4.45,5.09,5.09,0,0,1,3.89-1.58,4.78,4.78,0,0,1,3.41,1.24,4.56,4.56,0,0,1,1.17,2.11l-2.29.55a2.43,2.43,0,0,0-.84-1.41,2.47,2.47,0,0,0-1.57-.51,2.61,2.61,0,0,0-2.07.91,4.48,4.48,0,0,0-.79,3,4.84,4.84,0,0,0,.78,3.1,2.55,2.55,0,0,0,2,.92,2.32,2.32,0,0,0,1.59-.59A3.38,3.38,0,0,0,1140.24,1591.65Z" fill="#59585b"/>
      <path d="M1144,1592.92l2.18-.23a1.88,1.88,0,0,0,.56,1.17,1.51,1.51,0,0,0,1.06.44,1.46,1.46,0,0,0,1.16-.56,2.51,2.51,0,0,0,.48-1.68,2.36,2.36,0,0,0-.47-1.59,1.59,1.59,0,0,0-1.23-.52,2.23,2.23,0,0,0-1.7.83l-1.78-.26,1.13-6h5.8v2.06h-4.14l-.34,1.94a3.38,3.38,0,0,1,1.5-.36,3.27,3.27,0,0,1,2.47,1.06,3.83,3.83,0,0,1,1,2.76,4.11,4.11,0,0,1-.82,2.52,3.65,3.65,0,0,1-3.1,1.52,3.85,3.85,0,0,1-2.59-.86A3.47,3.47,0,0,1,1144,1592.92Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1152.26,1596.06l2.83-11.85h1.65l-2.87,11.85Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1167.93,1587.21l-2.13.24a1.58,1.58,0,0,0-.4-1,1.2,1.2,0,0,0-.86-.31,1.42,1.42,0,0,0-1.17.62,5.33,5.33,0,0,0-.61,2.6,2.6,2.6,0,0,1,2-1,3.12,3.12,0,0,1,2.36,1,3.81,3.81,0,0,1,1,2.7,3.9,3.9,0,0,1-1,2.82,3.74,3.74,0,0,1-5.5-.28,6.9,6.9,0,0,1-1.12-4.42,7.11,7.11,0,0,1,1.16-4.54,3.77,3.77,0,0,1,3-1.39,3.19,3.19,0,0,1,2.16.73A3.47,3.47,0,0,1,1167.93,1587.21Zm-5,4.79a2.46,2.46,0,0,0,.49,1.65,1.47,1.47,0,0,0,1.13.59,1.27,1.27,0,0,0,1-.48,2.41,2.41,0,0,0,.41-1.56,2.53,2.53,0,0,0-.44-1.64,1.35,1.35,0,0,0-1.09-.52,1.38,1.38,0,0,0-1.07.5A2.16,2.16,0,0,0,1163,1592Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1133.31,1612.86l-4.44-11.45h1.64l3,8.32c.24.66.44,1.29.6,1.87.18-.62.38-1.25.62-1.87l3.09-8.32h1.55l-4.49,11.45Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1139.39,1612.86v-11.45h1.52v11.45Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1143.1,1607.28a6.21,6.21,0,0,1,1.53-4.46,5.22,5.22,0,0,1,4-1.62,5.53,5.53,0,0,1,2.86.76,5,5,0,0,1,1.94,2.12,6.89,6.89,0,0,1,.67,3.07,6.76,6.76,0,0,1-.71,3.12,4.82,4.82,0,0,1-2,2.08,5.72,5.72,0,0,1-2.78.71,5.42,5.42,0,0,1-2.89-.79,5,5,0,0,1-1.93-2.13A6.42,6.42,0,0,1,1143.1,1607.28Zm1.57,0a4.6,4.6,0,0,0,1.11,3.26,3.89,3.89,0,0,0,5.61,0,4.87,4.87,0,0,0,1.1-3.42,5.81,5.81,0,0,0-.47-2.44,3.6,3.6,0,0,0-1.38-1.62,3.78,3.78,0,0,0-2-.57,3.88,3.88,0,0,0-2.77,1.1A4.93,4.93,0,0,0,1144.67,1607.31Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1155,1612.86v-11.45h1.52v10.1h5.64v1.35Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1162.76,1612.86l4.4-11.45h1.63l4.69,11.45h-1.73l-1.34-3.47h-4.79l-1.25,3.47Zm3.3-4.7h3.88l-1.19-3.17c-.37-1-.64-1.76-.81-2.38a16.33,16.33,0,0,1-.62,2.19Z" fill="#59585b"/>
    </g>
  </g>
  <g className="suites__unit" id="C7">
    <polygon id="C7_UNIT_ROSE" data-name="C7 UNIT ROSE" points="1249.3 1570.6 1306.6 1507 1302.1 1502.9 1302.3 1502.7 1234.1 1441.3 1176.6 1505.1 1232.5 1555.5 1249.3 1570.6" fill="#dadada"/>
    <g>
      <path d="M1241.69,1495.11l2.24.71a5,5,0,0,1-1.71,2.79,5.39,5.39,0,0,1-6.79-.65,6,6,0,0,1-1.47-4.26,6.3,6.3,0,0,1,1.47-4.45,5.09,5.09,0,0,1,3.89-1.58,4.78,4.78,0,0,1,3.41,1.24,4.56,4.56,0,0,1,1.17,2.11l-2.29.55a2.43,2.43,0,0,0-.84-1.41,2.47,2.47,0,0,0-1.57-.51,2.61,2.61,0,0,0-2.07.91,4.48,4.48,0,0,0-.79,3,4.84,4.84,0,0,0,.78,3.1,2.55,2.55,0,0,0,2,.92,2.32,2.32,0,0,0,1.59-.59A3.38,3.38,0,0,0,1241.69,1495.11Z" fill="#59585b"/>
      <path d="M1245.43,1490.06v-2h7.51v1.6a11.77,11.77,0,0,0-1.89,2.62,15,15,0,0,0-1.46,3.64,13.53,13.53,0,0,0-.5,3.44H1247a15,15,0,0,1,1-4.84,16.29,16.29,0,0,1,2.48-4.42Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1224.89,1516.32v-11.45H1230a6.6,6.6,0,0,1,2.32.31,2.51,2.51,0,0,1,1.28,1.09,3.29,3.29,0,0,1,.47,1.72,2.89,2.89,0,0,1-.79,2.06,4,4,0,0,1-2.43,1.06,3.78,3.78,0,0,1,.91.57,8.21,8.21,0,0,1,1.26,1.52l2,3.12h-1.9l-1.52-2.38c-.44-.69-.81-1.22-1.09-1.58a3.94,3.94,0,0,0-.77-.77,2.51,2.51,0,0,0-.7-.3,4.11,4.11,0,0,0-.84-.06h-1.76v5.09Zm1.51-6.4h3.26a5,5,0,0,0,1.63-.21,1.83,1.83,0,0,0,.89-.69,1.9,1.9,0,0,0,.3-1,1.69,1.69,0,0,0-.59-1.33,2.74,2.74,0,0,0-1.86-.53h-3.63Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1233.86,1510.74a6.21,6.21,0,0,1,1.53-4.46,5.22,5.22,0,0,1,4-1.62,5.56,5.56,0,0,1,2.86.76,5,5,0,0,1,1.94,2.12,6.89,6.89,0,0,1,.67,3.07,6.76,6.76,0,0,1-.71,3.12,4.82,4.82,0,0,1-2,2.08,5.72,5.72,0,0,1-2.78.71,5.42,5.42,0,0,1-2.89-.79,5,5,0,0,1-1.93-2.13A6.42,6.42,0,0,1,1233.86,1510.74Zm1.57,0a4.6,4.6,0,0,0,1.11,3.26,3.89,3.89,0,0,0,5.61,0,4.87,4.87,0,0,0,1.1-3.42,5.81,5.81,0,0,0-.47-2.44,3.66,3.66,0,0,0-1.38-1.62,3.78,3.78,0,0,0-2-.57,3.88,3.88,0,0,0-2.77,1.1A4.93,4.93,0,0,0,1235.43,1510.77Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1245.49,1512.64l1.43-.12a3.18,3.18,0,0,0,.47,1.41,2.67,2.67,0,0,0,1.15.89,4.49,4.49,0,0,0,1.76.34,4.23,4.23,0,0,0,1.53-.26,2.15,2.15,0,0,0,1-.71,1.61,1.61,0,0,0,.33-1,1.49,1.49,0,0,0-.32-.94,2.37,2.37,0,0,0-1-.68,20.62,20.62,0,0,0-2-.55,11,11,0,0,1-2.21-.72,3.07,3.07,0,0,1-1.22-1.07,2.58,2.58,0,0,1-.4-1.42,2.88,2.88,0,0,1,.49-1.62,3,3,0,0,1,1.44-1.15,5.46,5.46,0,0,1,2.1-.39,5.74,5.74,0,0,1,2.24.41,3.3,3.3,0,0,1,1.5,1.21,3.39,3.39,0,0,1,.56,1.8l-1.45.11a2.36,2.36,0,0,0-.79-1.64,3.1,3.1,0,0,0-2-.55,3.23,3.23,0,0,0-2,.5,1.52,1.52,0,0,0-.63,1.22,1.3,1.3,0,0,0,.44,1,6,6,0,0,0,2.29.82,15.1,15.1,0,0,1,2.53.73,3.51,3.51,0,0,1,1.48,1.17,2.82,2.82,0,0,1,.48,1.63,3.11,3.11,0,0,1-.53,1.72,3.46,3.46,0,0,1-1.5,1.26,5.26,5.26,0,0,1-2.21.45,6.58,6.58,0,0,1-2.6-.46,3.62,3.62,0,0,1-1.65-1.36A3.85,3.85,0,0,1,1245.49,1512.64Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1254.13,1516.32v-11.45h8.28v1.35h-6.77v3.51H1262v1.34h-6.34v3.9h7v1.35Z" fill="#59585b"/>
    </g>
  </g>
  <g className="suites__unit" id="C8">
    <rect id="C8_UNIT_ASTER" data-name="C8 UNIT ASTER" x="1257.39" y="1392.73" width="81.6" height="91.8" transform="translate(-639.58 1440.04) rotate(-47.98)" fill="#dadada"/>
    <g>
      <path d="M1297.26,1423.34l2.24.71a5,5,0,0,1-1.71,2.78,4.87,4.87,0,0,1-3,.92,4.93,4.93,0,0,1-3.75-1.56,6,6,0,0,1-1.47-4.27,6.29,6.29,0,0,1,1.47-4.44,5.09,5.09,0,0,1,3.89-1.58,4.78,4.78,0,0,1,3.41,1.24,4.56,4.56,0,0,1,1.17,2.11l-2.29.55a2.43,2.43,0,0,0-.84-1.41,2.47,2.47,0,0,0-1.57-.51,2.61,2.61,0,0,0-2.07.91,4.48,4.48,0,0,0-.79,3,4.84,4.84,0,0,0,.78,3.1,2.55,2.55,0,0,0,2,.92,2.32,2.32,0,0,0,1.59-.59A3.38,3.38,0,0,0,1297.26,1423.34Z" fill="#59585b"/>
      <path d="M1302.89,1421.37a2.47,2.47,0,0,1-1.63-2.37,2.74,2.74,0,0,1,.9-2.11,4.25,4.25,0,0,1,5.07,0,2.72,2.72,0,0,1,.91,2.11,2.47,2.47,0,0,1-.42,1.42,2.61,2.61,0,0,1-1.16.95,3.06,3.06,0,0,1,1.45,1.12,2.94,2.94,0,0,1,.49,1.69,3.43,3.43,0,0,1-1,2.58,3.67,3.67,0,0,1-2.69,1,4,4,0,0,1-2.6-.82,3.18,3.18,0,0,1-1.23-2.65,3.37,3.37,0,0,1,.46-1.71A3,3,0,0,1,1302.89,1421.37Zm.25,2.7a2,2,0,0,0,.46,1.41,1.51,1.51,0,0,0,1.16.51,1.48,1.48,0,0,0,1.13-.49,2.07,2.07,0,0,0,.44-1.41,1.81,1.81,0,0,0-.45-1.29,1.49,1.49,0,0,0-1.15-.49,1.36,1.36,0,0,0-1.2.55A2.05,2.05,0,0,0,1303.14,1424.07Zm.2-4.91a1.41,1.41,0,0,0,2.36,1,1.38,1.38,0,0,0,.37-1,1.27,1.27,0,0,0-1.35-1.36,1.3,1.3,0,0,0-1.38,1.37Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1276.49,1444.55l4.39-11.45h1.64l4.69,11.45h-1.73l-1.34-3.47h-4.79l-1.25,3.47Zm3.3-4.7h3.88l-1.19-3.18q-.56-1.44-.81-2.37a16.33,16.33,0,0,1-.62,2.19Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1286.87,1440.87l1.43-.12a3.18,3.18,0,0,0,.47,1.41,2.67,2.67,0,0,0,1.15.89,4.49,4.49,0,0,0,1.76.34,4.23,4.23,0,0,0,1.53-.26,2.15,2.15,0,0,0,1-.71,1.62,1.62,0,0,0,.32-1,1.48,1.48,0,0,0-.31-.94,2.37,2.37,0,0,0-1-.68c-.31-.12-1-.3-2-.56a10.55,10.55,0,0,1-2.21-.71,3.07,3.07,0,0,1-1.22-1.07,2.58,2.58,0,0,1-.4-1.42,2.88,2.88,0,0,1,.49-1.62,3,3,0,0,1,1.44-1.15,5.46,5.46,0,0,1,2.1-.39,5.74,5.74,0,0,1,2.24.41,3.24,3.24,0,0,1,1.5,1.21,3.39,3.39,0,0,1,.56,1.8l-1.45.11a2.36,2.36,0,0,0-.79-1.64,3.1,3.1,0,0,0-2-.55,3.21,3.21,0,0,0-2,.5,1.52,1.52,0,0,0-.63,1.22,1.3,1.3,0,0,0,.44,1,6,6,0,0,0,2.29.82,15.1,15.1,0,0,1,2.53.73,3.44,3.44,0,0,1,1.48,1.17,2.82,2.82,0,0,1,.48,1.63,3.13,3.13,0,0,1-.53,1.72,3.46,3.46,0,0,1-1.5,1.26,5.26,5.26,0,0,1-2.21.45,6.58,6.58,0,0,1-2.6-.46,3.62,3.62,0,0,1-1.65-1.36A3.85,3.85,0,0,1,1286.87,1440.87Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1298.62,1444.55v-10.1h-3.78v-1.35h9.08v1.35h-3.79v10.1Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1304.12,1444.55V1433.1h8.28v1.35h-6.77V1438H1312v1.34h-6.34v3.9h7v1.35Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1312.73,1444.55V1433.1h5.08a6.6,6.6,0,0,1,2.32.31,2.46,2.46,0,0,1,1.28,1.09,3.29,3.29,0,0,1,.47,1.72,2.89,2.89,0,0,1-.79,2.06,4,4,0,0,1-2.43,1.06,3.78,3.78,0,0,1,.91.57,8.21,8.21,0,0,1,1.26,1.52l2,3.12h-1.9l-1.52-2.38c-.44-.69-.81-1.22-1.09-1.58a3.67,3.67,0,0,0-.77-.77,2.51,2.51,0,0,0-.7-.3,4.11,4.11,0,0,0-.84-.06h-1.76v5.09Zm1.51-6.4h3.26a5,5,0,0,0,1.63-.21,1.83,1.83,0,0,0,.89-.69,1.9,1.9,0,0,0,.3-1,1.69,1.69,0,0,0-.59-1.33,2.74,2.74,0,0,0-1.86-.53h-3.63Z" fill="#59585b"/>
    </g>
  </g>
  <g className="suites__unit" id="C9">
    <polygon id="C9_UNIT_DAYLILY" data-name="C9 UNIT DAYLILY" points="1294.1 1374.8 1362.3 1436.2 1376.8 1420 1381.2 1423.9 1402.9 1399.8 1384.2 1383 1399.6 1365.9 1368.3 1337.8 1358.5 1348.6 1352.1 1342.9 1342.2 1354 1304.4 1320 1272.9 1354.9 1294.5 1374.3 1294.1 1374.8" fill="#dadada"/>
    <g>
      <path d="M1350.44,1372.66l2.24.71a5,5,0,0,1-1.71,2.79,5.39,5.39,0,0,1-6.79-.65,6,6,0,0,1-1.47-4.26,6.3,6.3,0,0,1,1.47-4.45,5.09,5.09,0,0,1,3.89-1.58,4.78,4.78,0,0,1,3.41,1.24,4.56,4.56,0,0,1,1.17,2.11l-2.29.55a2.43,2.43,0,0,0-.84-1.41,2.47,2.47,0,0,0-1.57-.51,2.61,2.61,0,0,0-2.07.91,4.48,4.48,0,0,0-.79,3,4.84,4.84,0,0,0,.78,3.1,2.55,2.55,0,0,0,2,.92,2.32,2.32,0,0,0,1.59-.59A3.38,3.38,0,0,0,1350.44,1372.66Z" fill="#59585b"/>
      <path d="M1354.23,1374.22l2.13-.23a1.52,1.52,0,0,0,.4,1,1.2,1.2,0,0,0,.87.31,1.44,1.44,0,0,0,1.16-.62,5.48,5.48,0,0,0,.61-2.6,2.64,2.64,0,0,1-2.07,1,3.09,3.09,0,0,1-2.34-1,4.28,4.28,0,0,1,.06-5.52,3.73,3.73,0,0,1,5.49.28,6.92,6.92,0,0,1,1.12,4.43,7.06,7.06,0,0,1-1.16,4.53,3.77,3.77,0,0,1-3,1.39,3.27,3.27,0,0,1-2.18-.71A3.49,3.49,0,0,1,1354.23,1374.22Zm5-4.8a2.47,2.47,0,0,0-.49-1.64,1.44,1.44,0,0,0-1.13-.59,1.27,1.27,0,0,0-1,.48,2.46,2.46,0,0,0-.4,1.58,2.43,2.43,0,0,0,.44,1.62,1.4,1.4,0,0,0,2.16,0A2.17,2.17,0,0,0,1359.2,1369.42Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1326.29,1393.87v-11.45h4a9.6,9.6,0,0,1,2,.16,4,4,0,0,1,1.68.82,4.66,4.66,0,0,1,1.35,2,7.64,7.64,0,0,1,.45,2.72,8.09,8.09,0,0,1-.3,2.31,5.64,5.64,0,0,1-.78,1.67,4.13,4.13,0,0,1-1,1,4.22,4.22,0,0,1-1.36.57,7.85,7.85,0,0,1-1.84.2Zm1.52-1.35h2.45a5.93,5.93,0,0,0,1.77-.21,2.53,2.53,0,0,0,1-.6,3.59,3.59,0,0,0,.84-1.45,7,7,0,0,0,.3-2.2,5.34,5.34,0,0,0-.59-2.76,3,3,0,0,0-1.43-1.3,6.14,6.14,0,0,0-2-.23h-2.41Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1335.3,1393.87l4.39-11.45h1.64l4.69,11.45h-1.73l-1.34-3.47h-4.79l-1.25,3.47Zm3.3-4.7h3.88l-1.19-3.17c-.37-1-.64-1.76-.81-2.38a16.33,16.33,0,0,1-.62,2.19Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1348.55,1393.87V1389l-4.41-6.6H1346l2.26,3.45c.41.65.8,1.29,1.16,1.94.35-.6.76-1.28,1.25-2l2.22-3.36h1.77l-4.57,6.6v4.85Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1353.14,1393.87v-11.45h1.52v10.1h5.64v1.35Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1361.8,1393.87v-11.45h1.52v11.45Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1365.54,1393.87v-11.45h1.52v10.1h5.64v1.35Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1375.89,1393.87V1389l-4.41-6.6h1.84l2.26,3.45c.42.65.8,1.29,1.16,1.94.35-.6.76-1.28,1.25-2l2.22-3.36H1382l-4.57,6.6v4.85Z" fill="#59585b"/>
    </g>
  </g>
  <g className="suites__unit" id="C10">
    <polygon id="C10_UNIT_CALICO" data-name="C10 UNIT CALICO" points="1351.8 1337.3 1358.2 1343 1368 1332.1 1405.2 1365.7 1389.9 1382.7 1405.6 1396.8 1424.8 1375.4 1420.6 1371.5 1435.8 1354.6 1453 1370.1 1501.5 1316.3 1428.2 1250.2 1400.5 1281 1382.5 1264.7 1350.5 1300.2 1334.9 1286.2 1307.1 1317 1341.9 1348.3 1351.8 1337.3" fill="#dadada"/>
    <g>
      <path d="M1413.39,1306.32l2.24.71a5,5,0,0,1-1.71,2.79,5.39,5.39,0,0,1-6.79-.65,6,6,0,0,1-1.47-4.26,6.3,6.3,0,0,1,1.47-4.45,5.09,5.09,0,0,1,3.89-1.58,4.78,4.78,0,0,1,3.41,1.24,4.56,4.56,0,0,1,1.17,2.11l-2.29.55a2.43,2.43,0,0,0-.84-1.41,2.47,2.47,0,0,0-1.57-.51,2.61,2.61,0,0,0-2.07.91,4.48,4.48,0,0,0-.79,3,4.84,4.84,0,0,0,.78,3.1,2.55,2.55,0,0,0,2,.92,2.32,2.32,0,0,0,1.59-.59A3.38,3.38,0,0,0,1413.39,1306.32Z" fill="#59585b"/>
      <path d="M1422.75,1310.53h-2.19v-8.27a7.64,7.64,0,0,1-2.84,1.66v-2a6.3,6.3,0,0,0,1.87-1.07A4,4,0,0,0,1421,1299h1.78Z" fill="#59585b"/>
      <path d="M1429.74,1299a3.16,3.16,0,0,1,2.61,1.19c.74.94,1.11,2.49,1.11,4.66s-.37,3.73-1.12,4.67a3.15,3.15,0,0,1-2.6,1.18,3.27,3.27,0,0,1-2.69-1.29c-.68-.86-1-2.38-1-4.58a7.57,7.57,0,0,1,1.13-4.66A3.12,3.12,0,0,1,1429.74,1299Zm0,1.82a1.16,1.16,0,0,0-.71.25,1.69,1.69,0,0,0-.48.91,12.63,12.63,0,0,0-.23,2.87,12.82,12.82,0,0,0,.2,2.77,2,2,0,0,0,.52,1,1.11,1.11,0,0,0,.7.25,1.07,1.07,0,0,0,.71-.26,1.72,1.72,0,0,0,.49-.91,12.41,12.41,0,0,0,.23-2.86,12.14,12.14,0,0,0-.21-2.77,1.93,1.93,0,0,0-.51-1A1.14,1.14,0,0,0,1429.74,1300.85Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1400.22,1323.51l1.51.39a5.19,5.19,0,0,1-1.71,2.85,4.77,4.77,0,0,1-3,1,5.46,5.46,0,0,1-3-.76,4.71,4.71,0,0,1-1.77-2.18,7.89,7.89,0,0,1-.6-3.07,6.68,6.68,0,0,1,.68-3.12,4.67,4.67,0,0,1,2-2,5.66,5.66,0,0,1,2.77-.69,4.64,4.64,0,0,1,2.89.88,4.5,4.5,0,0,1,1.64,2.46l-1.5.35a3.54,3.54,0,0,0-1.15-1.82,3.09,3.09,0,0,0-1.91-.57,3.74,3.74,0,0,0-2.21.63,3.34,3.34,0,0,0-1.24,1.7,7.35,7.35,0,0,0,.07,4.75,3.13,3.13,0,0,0,1.32,1.63,3.71,3.71,0,0,0,1.94.54,3.27,3.27,0,0,0,2.16-.74A3.71,3.71,0,0,0,1400.22,1323.51Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1402,1327.53l4.39-11.45H1408l4.69,11.45H1411l-1.34-3.47h-4.79l-1.25,3.47Zm3.3-4.7h3.88l-1.19-3.17c-.37-1-.64-1.76-.81-2.38a16.33,16.33,0,0,1-.62,2.19Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1412.45,1327.53v-11.45H1414v10.1h5.64v1.35Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1421.1,1327.53v-11.45h1.52v11.45Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1433.45,1323.51l1.51.39a5.19,5.19,0,0,1-1.71,2.85,4.77,4.77,0,0,1-3,1,5.46,5.46,0,0,1-3-.76,4.71,4.71,0,0,1-1.77-2.18,7.89,7.89,0,0,1-.6-3.07,6.68,6.68,0,0,1,.68-3.12,4.67,4.67,0,0,1,2-2,5.66,5.66,0,0,1,2.77-.69,4.64,4.64,0,0,1,2.89.88,4.5,4.5,0,0,1,1.64,2.46l-1.5.35a3.54,3.54,0,0,0-1.15-1.82,3.09,3.09,0,0,0-1.91-.57,3.74,3.74,0,0,0-2.21.63,3.34,3.34,0,0,0-1.24,1.7,7.35,7.35,0,0,0,.07,4.75,3.13,3.13,0,0,0,1.32,1.63,3.71,3.71,0,0,0,1.94.54,3.27,3.27,0,0,0,2.16-.74A3.71,3.71,0,0,0,1433.45,1323.51Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1436,1322a6.21,6.21,0,0,1,1.53-4.46,5.22,5.22,0,0,1,4-1.62,5.56,5.56,0,0,1,2.86.76,5,5,0,0,1,1.94,2.12,6.89,6.89,0,0,1,.67,3.07,6.76,6.76,0,0,1-.71,3.12,4.82,4.82,0,0,1-2,2.08,5.72,5.72,0,0,1-2.78.71,5.42,5.42,0,0,1-2.89-.79,5,5,0,0,1-1.93-2.13A6.42,6.42,0,0,1,1436,1322Zm1.57,0a4.6,4.6,0,0,0,1.11,3.26,3.89,3.89,0,0,0,5.61,0,4.87,4.87,0,0,0,1.1-3.42,5.81,5.81,0,0,0-.47-2.44,3.6,3.6,0,0,0-1.38-1.62,3.78,3.78,0,0,0-2-.57,3.88,3.88,0,0,0-2.77,1.1A4.93,4.93,0,0,0,1437.58,1322Z" fill="#59585b"/>
    </g>
  </g>
  <g className="suites__unit" id="C11a">
    <rect id="C11_UNIT_GLORY" data-name="C11 UNIT GLORY" x="1446.12" y="1182.86" width="129.3" height="98.7" transform="translate(-415.95 1529.72) rotate(-47.98)" fill="#dadada"/>
    <g>
      <path d="M1509.15,1220.49l2.24.71a5,5,0,0,1-1.71,2.79,5.39,5.39,0,0,1-6.79-.65,6,6,0,0,1-1.47-4.26,6.3,6.3,0,0,1,1.47-4.45,5.09,5.09,0,0,1,3.89-1.58,4.78,4.78,0,0,1,3.41,1.24,4.56,4.56,0,0,1,1.17,2.11l-2.29.55a2.43,2.43,0,0,0-.84-1.41,2.47,2.47,0,0,0-1.57-.51,2.61,2.61,0,0,0-2.07.91,4.48,4.48,0,0,0-.79,3,4.84,4.84,0,0,0,.78,3.1,2.55,2.55,0,0,0,2,.92,2.32,2.32,0,0,0,1.59-.59A3.38,3.38,0,0,0,1509.15,1220.49Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1518.16,1224.7H1516v-8.27a7.59,7.59,0,0,1-2.83,1.66v-2A6.26,6.26,0,0,0,1515,1215a4,4,0,0,0,1.39-1.83h1.78Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1524.42,1224.7h-2.2v-8.27a7.59,7.59,0,0,1-2.83,1.66v-2a6.26,6.26,0,0,0,1.86-1.07,4,4,0,0,0,1.39-1.83h1.78Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1495,1237.21v-1.35h4.86v4.25a8.82,8.82,0,0,1-2.31,1.34,6.81,6.81,0,0,1-2.44.45,6.52,6.52,0,0,1-3.06-.73,4.8,4.8,0,0,1-2.09-2.09,6.68,6.68,0,0,1-.7-3,7,7,0,0,1,.7-3.12,4.7,4.7,0,0,1,2-2.16,6.45,6.45,0,0,1,3-.7,6.16,6.16,0,0,1,2.24.4,3.76,3.76,0,0,1,1.58,1.13,4.77,4.77,0,0,1,.86,1.87l-1.36.38a4.45,4.45,0,0,0-.64-1.38,2.67,2.67,0,0,0-1.1-.8,4,4,0,0,0-1.58-.3,4.44,4.44,0,0,0-1.79.32,3.26,3.26,0,0,0-1.23.83,3.87,3.87,0,0,0-.72,1.13,6,6,0,0,0-.44,2.3,5.63,5.63,0,0,0,.53,2.57,3.36,3.36,0,0,0,1.54,1.53,4.77,4.77,0,0,0,2.14.5,5.17,5.17,0,0,0,1.92-.38,4.83,4.83,0,0,0,1.42-.81v-2.13Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1500.78,1241.7v-11.45h1.52v10.1h5.64v1.35Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1508.4,1236.12a6.21,6.21,0,0,1,1.53-4.46,5.22,5.22,0,0,1,4-1.62,5.56,5.56,0,0,1,2.86.76,5,5,0,0,1,1.94,2.12,6.89,6.89,0,0,1,.67,3.07,6.76,6.76,0,0,1-.71,3.12,4.82,4.82,0,0,1-2,2.08,5.72,5.72,0,0,1-2.78.71,5.42,5.42,0,0,1-2.89-.79,5,5,0,0,1-1.93-2.13A6.42,6.42,0,0,1,1508.4,1236.12Zm1.57,0a4.6,4.6,0,0,0,1.11,3.26,3.89,3.89,0,0,0,5.61,0,4.87,4.87,0,0,0,1.1-3.42,5.81,5.81,0,0,0-.47-2.44,3.66,3.66,0,0,0-1.38-1.62,3.78,3.78,0,0,0-2-.57,3.88,3.88,0,0,0-2.77,1.1A4.93,4.93,0,0,0,1510,1236.15Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1520.34,1241.7v-11.45h5.08a6.6,6.6,0,0,1,2.32.31,2.46,2.46,0,0,1,1.28,1.09,3.29,3.29,0,0,1,.47,1.72,2.92,2.92,0,0,1-.78,2.06,4,4,0,0,1-2.44,1.06,3.78,3.78,0,0,1,.91.57,8.21,8.21,0,0,1,1.26,1.52l2,3.12h-1.9l-1.52-2.38c-.44-.69-.81-1.22-1.09-1.58a3.94,3.94,0,0,0-.77-.77,2.58,2.58,0,0,0-.69-.3,4.29,4.29,0,0,0-.85-.06h-1.76v5.09Zm1.51-6.4h3.26a5,5,0,0,0,1.63-.21,1.83,1.83,0,0,0,.89-.69,1.9,1.9,0,0,0,.3-1,1.69,1.69,0,0,0-.59-1.33,2.74,2.74,0,0,0-1.86-.53h-3.63Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1533.4,1241.7v-4.85l-4.41-6.6h1.84l2.26,3.45c.41.65.8,1.29,1.16,1.94.35-.6.76-1.28,1.25-2l2.22-3.36h1.77l-4.57,6.6v4.85Z" fill="#59585b"/>
    </g>
  </g>
  <g className="suites__unit" id="C12a">
    <polygon id="C12_UNIT_PEONY" data-name="C12 UNIT PEONY" points="1659.1 1141.4 1643.4 1127.3 1634 1137.7 1576.4 1085.8 1520.1 1148.2 1593.4 1214.3 1659.1 1141.4" fill="#dadada"/>
    <g>
      <path d="M1583.68,1139.23l2.24.71a5,5,0,0,1-1.71,2.78,4.86,4.86,0,0,1-3,.91,4.92,4.92,0,0,1-3.75-1.55,6,6,0,0,1-1.47-4.27,6.29,6.29,0,0,1,1.47-4.44,5.09,5.09,0,0,1,3.89-1.58,4.78,4.78,0,0,1,3.41,1.24,4.56,4.56,0,0,1,1.17,2.11l-2.29.55a2.43,2.43,0,0,0-.84-1.41,2.47,2.47,0,0,0-1.57-.51,2.61,2.61,0,0,0-2.07.91,4.48,4.48,0,0,0-.79,3,4.84,4.84,0,0,0,.78,3.1,2.55,2.55,0,0,0,2,.92,2.32,2.32,0,0,0,1.59-.59A3.38,3.38,0,0,0,1583.68,1139.23Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1592.69,1143.44h-2.2v-8.27a7.59,7.59,0,0,1-2.83,1.66v-2a6.49,6.49,0,0,0,1.86-1.07,4,4,0,0,0,1.39-1.83h1.78Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1600.9,1141.4v2h-7.69a5.2,5.2,0,0,1,.75-2.19,14.67,14.67,0,0,1,2.47-2.75,15.8,15.8,0,0,0,1.82-1.87,2.44,2.44,0,0,0,.45-1.35,1.55,1.55,0,0,0-.39-1.13,1.7,1.7,0,0,0-2.19,0,2.21,2.21,0,0,0-.47,1.38l-2.18-.22a3.61,3.61,0,0,1,1.22-2.6,4.11,4.11,0,0,1,2.58-.79,3.74,3.74,0,0,1,2.67.91,3,3,0,0,1,1,2.28,4.08,4.08,0,0,1-.27,1.47,6.09,6.09,0,0,1-.88,1.46,15.15,15.15,0,0,1-1.44,1.47q-1,.95-1.32,1.26a4.52,4.52,0,0,0-.45.61Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1565.63,1160.44V1149H1570a9.8,9.8,0,0,1,1.75.11,3.43,3.43,0,0,1,1.41.53,2.75,2.75,0,0,1,.92,1.11,3.71,3.71,0,0,1-.58,4,4.44,4.44,0,0,1-3.36,1h-2.94v4.66Zm1.52-6h3a3.12,3.12,0,0,0,2.09-.55,1.92,1.92,0,0,0,.61-1.53,2.08,2.08,0,0,0-.36-1.23,1.7,1.7,0,0,0-1-.68,6.27,6.27,0,0,0-1.41-.1h-2.93Z" fill="#59585b"/>
      <path d="M1576.34,1160.44V1149h8.28v1.35h-6.77v3.51h6.34v1.34h-6.34v3.9h7v1.35Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1583.06,1154.86a6.21,6.21,0,0,1,1.53-4.46,5.22,5.22,0,0,1,4-1.62,5.56,5.56,0,0,1,2.86.76,5,5,0,0,1,1.94,2.11,7,7,0,0,1,.67,3.08,6.76,6.76,0,0,1-.71,3.12,4.82,4.82,0,0,1-2,2.08,5.71,5.71,0,0,1-2.78.7,5.33,5.33,0,0,1-2.89-.78,5,5,0,0,1-1.93-2.13A6.42,6.42,0,0,1,1583.06,1154.86Zm1.57,0a4.61,4.61,0,0,0,1.11,3.27,3.88,3.88,0,0,0,5.61,0,4.82,4.82,0,0,0,1.1-3.41,5.78,5.78,0,0,0-.47-2.44,3.54,3.54,0,0,0-1.38-1.62,3.78,3.78,0,0,0-2-.57,3.88,3.88,0,0,0-2.77,1.1A4.92,4.92,0,0,0,1584.63,1154.88Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1595,1160.44V1149h1.55l6,9v-9H1604v11.45h-1.55l-6-9v9Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1609.23,1160.44v-4.85l-4.41-6.6h1.84l2.26,3.45c.41.65.8,1.29,1.16,1.94.35-.6.76-1.28,1.25-2l2.22-3.36h1.77l-4.57,6.6v4.85Z" fill="#59585b"/>
    </g>
  </g>
  <g className="suites__unit" id="C13a">
    <polygon id="C13_UNIT_TULIP" data-name="C13 UNIT TULIP" points="1639.9 1124.4 1638.1 1122.7 1659.7 1099.1 1654.4 1094.2 1676.5 1070 1659.4 1054.3 1666.3 1046.8 1631.9 1015.4 1629.8 1017.8 1634.1 1021.7 1578.8 1082.2 1633.1 1131.8 1639.9 1124.4" fill="#dadada"/>
    <g>
      <path d="M1627.41,1064.79l2.24.71a5,5,0,0,1-1.71,2.79,5.39,5.39,0,0,1-6.79-.65,6,6,0,0,1-1.47-4.26,6.3,6.3,0,0,1,1.47-4.45,5.09,5.09,0,0,1,3.89-1.58,4.78,4.78,0,0,1,3.41,1.24,4.56,4.56,0,0,1,1.17,2.11l-2.29.55a2.43,2.43,0,0,0-.84-1.41,2.47,2.47,0,0,0-1.57-.51,2.61,2.61,0,0,0-2.07.91,4.48,4.48,0,0,0-.79,3,4.84,4.84,0,0,0,.78,3.1,2.55,2.55,0,0,0,2,.92,2.32,2.32,0,0,0,1.59-.59A3.38,3.38,0,0,0,1627.41,1064.79Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1636.42,1069h-2.2v-8.27a7.59,7.59,0,0,1-2.83,1.66v-2a6.26,6.26,0,0,0,1.86-1.07,4,4,0,0,0,1.39-1.83h1.78Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1637.14,1066l2.13-.26a2,2,0,0,0,.54,1.25,1.54,1.54,0,0,0,1.08.43,1.51,1.51,0,0,0,1.15-.52,2,2,0,0,0,.46-1.39,1.89,1.89,0,0,0-.44-1.31,1.41,1.41,0,0,0-1.09-.49,3.86,3.86,0,0,0-1,.17l.24-1.79a2,2,0,0,0,1.36-.39,1.35,1.35,0,0,0,.47-1.09,1.23,1.23,0,0,0-.34-.92,1.25,1.25,0,0,0-.92-.35,1.31,1.31,0,0,0-1,.4,1.88,1.88,0,0,0-.48,1.14l-2-.35a4.64,4.64,0,0,1,.63-1.66,2.87,2.87,0,0,1,1.19-1,4,4,0,0,1,1.71-.36,3.38,3.38,0,0,1,2.59,1,2.83,2.83,0,0,0,.73,5.26,2.76,2.76,0,0,1,.59,1.77,3.45,3.45,0,0,1-1.1,2.57,4.09,4.09,0,0,1-5.32.17A3.55,3.55,0,0,1,1637.14,1066Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1616.29,1086v-10.1h-3.77v-1.35h9.07v1.35h-3.79V1086Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1629.68,1074.55h1.52v6.61a7.87,7.87,0,0,1-.39,2.75,3.5,3.5,0,0,1-1.41,1.65,5.06,5.06,0,0,1-2.68.64,5.48,5.48,0,0,1-2.63-.56,3.12,3.12,0,0,1-1.46-1.6,7.54,7.54,0,0,1-.44-2.88v-6.61h1.51v6.61a6.62,6.62,0,0,0,.28,2.2,2.15,2.15,0,0,0,1,1.09,3.39,3.39,0,0,0,1.66.38,3.13,3.13,0,0,0,2.38-.76,4.38,4.38,0,0,0,.71-2.91Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1632.16,1086v-11.45h1.52v10.1h5.64V1086Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1640.82,1086v-11.45h1.52V1086Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1644.62,1086v-11.45h4.32a9.72,9.72,0,0,1,1.75.11,3.43,3.43,0,0,1,1.41.53,2.75,2.75,0,0,1,.92,1.11,3.71,3.71,0,0,1-.58,4,4.44,4.44,0,0,1-3.36,1h-2.94V1086Zm1.52-6h3a3.18,3.18,0,0,0,2.09-.54,2,2,0,0,0,.61-1.54,2.08,2.08,0,0,0-.36-1.23,1.65,1.65,0,0,0-1-.68,6.27,6.27,0,0,0-1.41-.1h-2.93Z" fill="#59585b"/>
    </g>
  </g>
  <g className="suites__unit" id="C14a">
    <polygon id="C14_UNIT_DAISY" data-name="C14 UNIT DAISY" points="1665.1 1054.1 1682.2 1069.7 1660 1094 1678.7 1111 1714.8 1071.4 1728 1083.5 1773 1034.3 1691.1 959.6 1671.6 981 1667.1 976.9 1634.6 1012.5 1671.9 1046.5 1665.1 1054.1" fill="#dadada"/>
    <g>
      <path d="M1700.17,1026.14l2.24.73a5,5,0,0,1-1.74,2.77,5.4,5.4,0,0,1-6.79-.69,6,6,0,0,1-1.44-4.27,6.29,6.29,0,0,1,1.51-4.43,5.06,5.06,0,0,1,3.89-1.56,4.79,4.79,0,0,1,3.41,1.26,4.68,4.68,0,0,1,1.16,2.12l-2.3.53a2.36,2.36,0,0,0-2.4-1.93,2.57,2.57,0,0,0-2.07.9,4.48,4.48,0,0,0-.81,2.95,4.79,4.79,0,0,0,.76,3.1,2.53,2.53,0,0,0,2,.94,2.31,2.31,0,0,0,1.59-.58A3.39,3.39,0,0,0,1700.17,1026.14Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1709.15,1030.41H1707l0-8.28a7.41,7.41,0,0,1-2.84,1.65v-2a6.47,6.47,0,0,0,1.87-1.06,4,4,0,0,0,1.4-1.82h1.78Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1714.1,1030.44l0-2.3-4.69,0v-1.92l5-7.25,1.84,0,0,7.26h1.43l0,1.93h-1.42v2.31Zm0-4.23,0-3.92-2.66,3.9Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1686.42,1047.2v-11.45h3.95a9.6,9.6,0,0,1,2,.16,4,4,0,0,1,1.68.82,4.66,4.66,0,0,1,1.35,2,7.64,7.64,0,0,1,.45,2.72,8.09,8.09,0,0,1-.3,2.31,5.64,5.64,0,0,1-.78,1.67,4.13,4.13,0,0,1-1,1,4,4,0,0,1-1.36.57,7.85,7.85,0,0,1-1.84.2Zm1.52-1.35h2.45a5.93,5.93,0,0,0,1.77-.21,2.53,2.53,0,0,0,1-.6,3.59,3.59,0,0,0,.84-1.45,7,7,0,0,0,.3-2.2,5.34,5.34,0,0,0-.59-2.76,3,3,0,0,0-1.43-1.3,6.14,6.14,0,0,0-2-.23h-2.41Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1695.43,1047.2l4.4-11.45h1.63l4.69,11.45h-1.73l-1.34-3.47h-4.79l-1.25,3.47Zm3.3-4.7h3.88l-1.19-3.17c-.37-1-.64-1.76-.81-2.38a16.33,16.33,0,0,1-.62,2.19Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1706.23,1047.2v-11.45h1.52v11.45Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1709.77,1043.52l1.43-.12a3.18,3.18,0,0,0,.47,1.41,2.67,2.67,0,0,0,1.15.89,4.49,4.49,0,0,0,1.76.34,4.23,4.23,0,0,0,1.53-.26,2.15,2.15,0,0,0,1-.71,1.6,1.6,0,0,0,.32-1,1.48,1.48,0,0,0-.31-.94,2.37,2.37,0,0,0-1-.68,20.62,20.62,0,0,0-2-.55,11,11,0,0,1-2.21-.72,3.07,3.07,0,0,1-1.22-1.07,2.58,2.58,0,0,1-.4-1.42,2.88,2.88,0,0,1,.49-1.62,3,3,0,0,1,1.44-1.15,5.46,5.46,0,0,1,2.1-.39,5.77,5.77,0,0,1,2.24.41,3.3,3.3,0,0,1,1.5,1.21,3.39,3.39,0,0,1,.56,1.8l-1.45.11a2.36,2.36,0,0,0-.79-1.64,3.1,3.1,0,0,0-2-.55,3.21,3.21,0,0,0-2,.5,1.52,1.52,0,0,0-.63,1.22,1.3,1.3,0,0,0,.44,1,6,6,0,0,0,2.29.82,15.1,15.1,0,0,1,2.53.73,3.51,3.51,0,0,1,1.48,1.17,2.82,2.82,0,0,1,.48,1.63,3.11,3.11,0,0,1-.53,1.72,3.46,3.46,0,0,1-1.5,1.26,5.23,5.23,0,0,1-2.21.45,6.61,6.61,0,0,1-2.6-.46,3.62,3.62,0,0,1-1.65-1.36A3.85,3.85,0,0,1,1709.77,1043.52Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1721.59,1047.2v-4.85l-4.41-6.6H1719l2.26,3.45c.41.65.8,1.29,1.16,1.94.35-.6.76-1.28,1.25-2l2.22-3.36h1.77l-4.57,6.6v4.85Z" fill="#59585b"/>
    </g>
  </g>
  <g className="suites__unit" id="C15a">
    <polygon id="C15_UNIT_MARYGOLD" data-name="C15 UNIT MARYGOLD" points="1858.2 1110.6 1775.9 1036.4 1731.2 1085.9 1743.5 1097 1705.8 1138.8 1724.2 1155.3 1747.7 1129.3 1764.5 1144.4 1768.4 1140.1 1776.3 1147.3 1779.9 1143.4 1810.4 1171 1841.5 1136.4 1837.8 1133.1 1858.2 1110.6" fill="#dadada"/>
    <g>
      <path d="M1783.55,1092.91l2.24.71a5,5,0,0,1-1.71,2.79,5.39,5.39,0,0,1-6.79-.65,6,6,0,0,1-1.47-4.26,6.3,6.3,0,0,1,1.47-4.45,5.09,5.09,0,0,1,3.89-1.58,4.78,4.78,0,0,1,3.41,1.24,4.56,4.56,0,0,1,1.17,2.11l-2.29.55a2.43,2.43,0,0,0-.84-1.41,2.47,2.47,0,0,0-1.57-.51,2.61,2.61,0,0,0-2.07.91,4.48,4.48,0,0,0-.79,3,4.84,4.84,0,0,0,.78,3.1,2.55,2.55,0,0,0,2,.92,2.32,2.32,0,0,0,1.59-.59A3.38,3.38,0,0,0,1783.55,1092.91Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1792.56,1097.12h-2.2v-8.27a7.59,7.59,0,0,1-2.83,1.66v-2a6.26,6.26,0,0,0,1.86-1.07,4,4,0,0,0,1.39-1.83h1.78Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1793.4,1094.17l2.19-.22a1.9,1.9,0,0,0,.55,1.17,1.51,1.51,0,0,0,1.07.44,1.49,1.49,0,0,0,1.16-.56,2.51,2.51,0,0,0,.48-1.68,2.29,2.29,0,0,0-.48-1.59,1.56,1.56,0,0,0-1.23-.52,2.21,2.21,0,0,0-1.69.83l-1.78-.26,1.12-6h5.81v2.06h-4.14l-.35,1.94a3.38,3.38,0,0,1,1.5-.36,3.29,3.29,0,0,1,2.48,1.06,3.82,3.82,0,0,1,1,2.76,4.11,4.11,0,0,1-.82,2.52,3.64,3.64,0,0,1-3.1,1.52,3.81,3.81,0,0,1-2.58-.86A3.48,3.48,0,0,1,1793.4,1094.17Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1749.16,1114.12v-11.45h2.28l2.71,8.11c.25.75.43,1.32.55,1.69.13-.42.33-1,.61-1.83l2.74-8h2v11.45h-1.46v-9.59l-3.33,9.59h-1.37l-3.31-9.75v9.75Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1760.37,1114.12l4.4-11.45h1.63l4.69,11.45h-1.73l-1.34-3.47h-4.79l-1.25,3.47Zm3.3-4.7h3.88l-1.19-3.17c-.37-1-.64-1.76-.81-2.38a16.33,16.33,0,0,1-.62,2.19Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1770.94,1114.12v-11.45H1776a6.6,6.6,0,0,1,2.32.31,2.46,2.46,0,0,1,1.28,1.09,3.2,3.2,0,0,1,.47,1.72,2.92,2.92,0,0,1-.78,2.06,4,4,0,0,1-2.44,1.06,3.78,3.78,0,0,1,.91.57A8.21,8.21,0,0,1,1779,1111l2,3.12h-1.9l-1.52-2.38c-.44-.69-.81-1.22-1.09-1.58a3.67,3.67,0,0,0-.77-.77,2.58,2.58,0,0,0-.69-.3,4.29,4.29,0,0,0-.85-.06h-1.76v5.09Zm1.51-6.4h3.26a5,5,0,0,0,1.63-.21,1.83,1.83,0,0,0,.89-.69,1.9,1.9,0,0,0,.3-1,1.69,1.69,0,0,0-.59-1.33,2.74,2.74,0,0,0-1.86-.53h-3.63Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1784,1114.12v-4.85l-4.41-6.6h1.84l2.26,3.45c.41.65.8,1.29,1.16,1.94.35-.6.76-1.28,1.25-2l2.22-3.36h1.77l-4.57,6.6v4.85Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1793.86,1109.63v-1.35h4.86v4.25a8.82,8.82,0,0,1-2.31,1.34,6.81,6.81,0,0,1-2.44.45,6.52,6.52,0,0,1-3.06-.73,4.8,4.8,0,0,1-2.09-2.09,6.68,6.68,0,0,1-.7-3,7,7,0,0,1,.7-3.12,4.7,4.7,0,0,1,2-2.16,6.45,6.45,0,0,1,3-.7,6.16,6.16,0,0,1,2.24.4,3.76,3.76,0,0,1,1.58,1.13,4.77,4.77,0,0,1,.86,1.87l-1.36.38a4.45,4.45,0,0,0-.64-1.38,2.67,2.67,0,0,0-1.1-.8,4,4,0,0,0-1.58-.3,4.62,4.62,0,0,0-1.79.31,3.39,3.39,0,0,0-1.23.84,3.87,3.87,0,0,0-.72,1.13,6,6,0,0,0-.44,2.3,5.63,5.63,0,0,0,.53,2.57,3.36,3.36,0,0,0,1.54,1.53,4.77,4.77,0,0,0,2.14.5,5.17,5.17,0,0,0,1.92-.38,4.83,4.83,0,0,0,1.42-.81v-2.13Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1799.43,1108.54a6.21,6.21,0,0,1,1.53-4.46,5.22,5.22,0,0,1,4-1.62,5.53,5.53,0,0,1,2.86.76,5,5,0,0,1,1.94,2.11,7,7,0,0,1,.67,3.08,6.76,6.76,0,0,1-.71,3.12,4.82,4.82,0,0,1-2,2.08,5.72,5.72,0,0,1-2.78.71,5.42,5.42,0,0,1-2.89-.79,5,5,0,0,1-1.93-2.13A6.42,6.42,0,0,1,1799.43,1108.54Zm1.57,0a4.6,4.6,0,0,0,1.11,3.26,3.89,3.89,0,0,0,5.61,0,4.87,4.87,0,0,0,1.1-3.42,5.81,5.81,0,0,0-.47-2.44,3.6,3.6,0,0,0-1.38-1.62,3.78,3.78,0,0,0-2-.57,3.88,3.88,0,0,0-2.77,1.1A4.93,4.93,0,0,0,1801,1108.57Z" fill="#59585b"/>
    </g>
    <g>
      <path d="M1811.29,1114.12v-11.45h1.52v10.1h5.64v1.35Z" fill="#59585b"/>
      <path d="M1820.25,1114.12v-11.45h4a9.6,9.6,0,0,1,2,.16,4,4,0,0,1,1.68.82,4.66,4.66,0,0,1,1.35,2,7.64,7.64,0,0,1,.45,2.72,8.09,8.09,0,0,1-.3,2.31,5.9,5.9,0,0,1-.78,1.67,4.13,4.13,0,0,1-1.05,1,4.32,4.32,0,0,1-1.36.57,7.85,7.85,0,0,1-1.84.2Zm1.52-1.35h2.44a6,6,0,0,0,1.78-.21,2.53,2.53,0,0,0,1-.6,3.59,3.59,0,0,0,.84-1.45,7,7,0,0,0,.3-2.2,5.34,5.34,0,0,0-.59-2.76,3,3,0,0,0-1.43-1.3,6.17,6.17,0,0,0-2-.23h-2.4Z" fill="#59585b"/>
    </g>
  </g>
</svg>

            </div>
        </div>
    )
}

export default FloorTwo;