import { useContext, useState, useEffect } from 'react';
import { Context } from 'react';

const PropertyTourSeasonalSunlightImage = ({ sunStudy, isActive, currentImage }) => {
    const [loadedImages, setLoadedImages] = useState(0);
    const wrapperClasses = ['propertyTour__seasonalSunlight--imageWrapper'];
    // isActive && wrapperClasses.push('active');

    // const imageLoaded = () => {
    //     const inc = loadedImages + 1;
    //     setLoadedImages(inc);
    //     if (loadedImages > 90) console.log('all loaded');
    // }

    // useEffect(() => {
    //     console.log(loadedImages)
    // }, [loadedImages])

    return (
        <div className={wrapperClasses.join(' ')}>
            {sunStudy && sunStudy.items.map((item, i) => {
                const classes = ['propertyTour__seasonalSunlight--image'];
                item.key === currentImage && classes.push('active');
                return <img key={i} className={classes.join(' ')} src={item.url} alt="" />
            })}
        </div>
    )
}

export default PropertyTourSeasonalSunlightImage;