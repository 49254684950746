const mapItems = [
    {
        "name": "Restaurants & Cafes",
        "items": [
            {
                "id": 1,
                "title": "Dao Café",
                "coords": {
                    "lat": 45.35301,
                    "lng": -75.73525
                },
                "walkTime": 27,
                "bikeTime": 8,
                "driveTime": 7,
                "transitTime": 15,
                "image": "location-restaurants-01.jpg"
            },
            {
                "id": 2,
                "title": "Swiss Chalet",
                "coords": {
                    "lat": 45.34959,
                    "lng": -75.73344
                },
                "walkTime": 34,
                "bikeTime": 10,
                "driveTime": 8,
                "transitTime": 18,
                "image": "location-restaurants-02.jpg"
            },
            {
                "id": 3,
                "title": "Tim Hortons",
                "coords": {
                    "lat": 45.37045,
                    "lng": -75.73256
                },
                "walkTime": 7,
                "bikeTime": 2,
                "driveTime": 3,
                "transitTime": 4,
                "image": "location-restaurants-03.jpg"
            },
            {
                "id": 4,
                "title": "Starbucks",
                "coords": {
                    "lat": 45.36251,
                    "lng": -75.73539
                },
                "walkTime": 12,
                "bikeTime": 4,
                "driveTime": 4,
                "transitTime": 10,
                "image": "location-restaurants-04.jpg"
            },
            {
                "id": 5,
                "title": "Lone Star Texas Grill",
                "coords": {
                    "lat": 45.37083,
                    "lng": -75.71692
                },
                "walkTime": 26,
                "bikeTime": 8,
                "driveTime": 5,
                "transitTime": 19,
                "image": "location-restaurants-05.jpg"
            },
            {
                "id": 6,
                "title": "Nando's PERi-PERi",
                "coords": {
                    "lat": 45.36152,
                    "lng": -75.73366
                },
                "walkTime": 15,
                "bikeTime": 6,
                "driveTime": 4,
                "transitTime": 9,
                "image": "location-restaurants-06.jpg"
            },
            {
                "id": 7,
                "title": "Bridgehead",
                "coords": {
                    "lat": 45.3708,
                    "lng": -75.76855
                },
                "walkTime": 54,
                "bikeTime": 19,
                "driveTime": 11,
                "transitTime": 26,
                "image": "location-restaurants-07.jpg"
            },
            {
                "id": 8,
                "title": "Second Cup",
                "coords": {
                    "lat": 45.34488,
                    "lng": -75.72961
                },
                "walkTime": 42,
                "bikeTime": 13,
                "driveTime": 10,
                "transitTime": 20,
                "image": "location-restaurants-08.jpg"
            },
            {
                "id": 9,
                "title": "Chances R Restaurant",
                "coords": {
                    "lat": 45.35035,
                    "lng": -75.75868
                },
                "walkTime": 41,
                "bikeTime": 16,
                "driveTime": 9,
                "transitTime": 22,
                "image": "location-restaurants-09.jpg"
            },
            {
                "id": 10,
                "title": "La Casa Del Gusto",
                "coords": {
                    "lat": 45.36204,
                    "lng": -75.73457
                },
                "walkTime": 12,
                "bikeTime": 4,
                "driveTime": 4,
                "transitTime": 9,
                "image": "location-restaurants-10.jpg"
            },
            {
                "id": 11,
                "title": "The Keg Steakhouse Manor",
                "coords": {
                    "lat": 45.38825,
                    "lng": -75.76198
                },
                "walkTime": 51,
                "bikeTime": 15,
                "driveTime": 12,
                "transitTime": 31,
                "image": "location-restaurants-11.jpg"
            },
            {
                "id": 12,
                "title": "Montana's",
                "coords": {
                    "lat": 45.34579,
                    "lng": -75.73004
                },
                "walkTime": 40,
                "bikeTime": 14,
                "driveTime": 10,
                "transitTime": 17,
                "image": "location-restaurants-12.jpg"
            },
            {
                "id": 13,
                "title": "Tutti Frutti Breakfast & Lunch",
                "coords": {
                    "lat": 45.36058,
                    "lng": -75.73301
                },
                "walkTime": 16,
                "bikeTime": 6,
                "driveTime": 4,
                "transitTime": 12,
                "image": "location-restaurants-13.jpg"
            },
            {
                "id": 14,
                "title": "Barley Mow",
                "coords": {
                    "lat": 45.35576,
                    "lng": -75.73496
                },
                "walkTime": 24,
                "bikeTime": 8,
                "driveTime": 6,
                "transitTime": 15,
                "image": "location-restaurants-14.jpg"
            },
            {
                "id": 15,
                "title": "Boston Pizza",
                "coords": {
                    "lat": 45.37952,
                    "lng": -75.74837
                },
                "walkTime": 34,
                "bikeTime": 11,
                "driveTime": 7,
                "transitTime": 20,
                "image": "location-restaurants-15.jpg"
            }
        ]
    },
    {
        "name": "Shopping",
        "items": [
            {
                "id": 48,
                "title": "Walmart Supercentre",
                "coords": {
                    "lat": 45.36376,
                    "lng": -75.7384
                },
                "walkTime": 9,
                "bikeTime": 2,
                "driveTime": 4,
                "transitTime": 1,
                "image": "location-shopping-01.jpg"
            },
            {
                "id": 49,
                "title": "Loblaws",
                "coords": {
                    "lat": 45.36192,
                    "lng": -75.73535
                },
                "walkTime": 12,
                "bikeTime": 4,
                "driveTime": 4,
                "transitTime": 9,
                "image": "location-shopping-02.jpg"
            },
            {
                "id": 50,
                "title": "Shoppers Drug Mart",
                "coords": {
                    "lat": 45.36048,
                    "lng": -75.73436
                },
                "walkTime": 33,
                "bikeTime": 11,
                "driveTime": 6,
                "transitTime": 20,
                "image": "location-shopping-03.jpg"
            },
            {
                "id": 51,
                "title": "Food Basics",
                "coords": {
                    "lat": 45.3605,
                    "lng": -75.73298
                },
                "walkTime": 16,
                "bikeTime": 6,
                "driveTime": 4,
                "transitTime": 10,
                "image": "location-shopping-04.jpg"
            },
            {
                "id": 52,
                "title": "Thyme & Again",
                "coords": {
                    "lat": 45.37725,
                    "lng": -75.75633
                },
                "walkTime": 34,
                "bikeTime": 11,
                "driveTime": 9,
                "transitTime": 25,
                "image": "location-shopping-05.jpg"
            },
            {
                "id": 53,
                "title": "Produce Depot",
                "coords": {
                    "lat": 45.37666,
                    "lng": -75.75694
                },
                "walkTime": 34,
                "bikeTime": 11,
                "driveTime": 9,
                "transitTime": 26,
                "image": "location-shopping-06.jpg"
            },
            {
                "id": 54,
                "title": "Merivale Mall",
                "coords": {
                    "lat": 45.34526,
                    "lng": -75.73143
                },
                "walkTime": 40,
                "bikeTime": 14,
                "driveTime": 7,
                "transitTime": 16,
                "image": "location-shopping-07.jpg"
            },
            {
                "id": 55,
                "title": "Farm Boy",
                "coords": {
                    "lat": 45.34645,
                    "lng": -75.73192
                },
                "walkTime": 38,
                "bikeTime": 14,
                "driveTime": 7,
                "transitTime": 17,
                "image": "location-shopping-08.jpg"
            },
            {
                "id": 56,
                "title": "College Square Mall",
                "coords": {
                    "lat": 45.35065,
                    "lng": -75.7602
                },
                "walkTime": 41,
                "bikeTime": 16,
                "driveTime": 8,
                "transitTime": 24,
                "image": "location-shopping-09.jpg"
            },
            {
                "id": 57,
                "title": "Carlingwood Shopping Centre",
                "coords": {
                    "lat": 45.37227,
                    "lng": -75.76943
                },
                "walkTime": 46,
                "bikeTime": 12,
                "driveTime": 10,
                "transitTime": 25,
                "image": "location-shopping-10.jpg"
            },
            {
                "id": 58,
                "title": "Canadian Tire",
                "coords": {
                    "lat": 45.37891,
                    "lng": -75.7491
                },
                "walkTime": 31,
                "bikeTime": 10,
                "driveTime": 7,
                "transitTime": 17,
                "image": "location-shopping-11.jpg"
            },
            {
                "id": 59,
                "title": "The Shoe Company",
                "coords": {
                    "lat": 45.35073,
                    "lng": -75.73189
                },
                "walkTime": 33,
                "bikeTime": 11,
                "driveTime": 6,
                "transitTime": 17,
                "image": "location-shopping-12.jpg"
            },
            {
                "id": 60,
                "title": "Laura",
                "coords": {
                    "lat": 45.35012,
                    "lng": -75.73123
                },
                "walkTime": 35,
                "bikeTime": 11,
                "driveTime": 7,
                "transitTime": 18,
                "image": "location-shopping-13.jpg"
            },
            {
                "id": 61,
                "title": "Nicastro Fine Foods",
                "coords": {
                    "lat": 45.35286,
                    "lng": -75.73571
                },
                "walkTime": 27,
                "bikeTime": 8,
                "driveTime": 6,
                "transitTime": 14,
                "image": "location-shopping-14.jpg"
            },
            {
                "id": 62,
                "title": "Freshco",
                "coords": {
                    "lat": 45.35281,
                    "lng": -75.73327
                },
                "walkTime": 29,
                "bikeTime": 8,
                "driveTime": 6,
                "transitTime": 17,
                "image": "location-shopping-15.jpg"
            }
        ]
    },
    {
        "name": "Lifestyle",
        "items": [
            {
                "id": 18,
                "title": "Ottawa Sport Medicine Centre",
                "coords": {
                    "lat": 45.3603,
                    "lng": -75.73962
                },
                "walkTime": 17,
                "bikeTime": 5,
                "driveTime": 5,
                "transitTime": 16,
                "image": "location-lifestyle-01.jpg"
            },
            {
                "id": 19,
                "title": "Movati Athletic",
                "coords": {
                    "lat": 45.35371,
                    "lng": -75.73171
                },
                "walkTime": 31,
                "bikeTime": 10,
                "driveTime": 8,
                "transitTime": 19,
                "image": "location-lifestyle-02.jpg"
            },
            {
                "id": 20,
                "title": "The Ottawa Hospital Civic Campus",
                "coords": {
                    "lat": 45.3929,
                    "lng": -75.72173
                },
                "walkTime": 44,
                "bikeTime": 16,
                "driveTime": 8,
                "transitTime": 24,
                "image": "location-lifestyle-03.jpg"
            },
            {
                "id": 21,
                "title": "West Side Spa",
                "coords": {
                    "lat": 45.37037,
                    "lng": -75.73328
                },
                "walkTime": 7,
                "bikeTime": 2,
                "driveTime": 2,
                "transitTime": 3,
                "image": "location-lifestyle-04.jpg"
            },
            {
                "id": 22,
                "title": "Planet Fitness",
                "coords": {
                    "lat": 45.34512,
                    "lng": -75.7312
                },
                "walkTime": 40,
                "bikeTime": 14,
                "driveTime": 9,
                "transitTime": 18,
                "image": "location-lifestyle-05.jpg"
            },
            {
                "id": 23,
                "title": "Ottawa Public Library - Carlingwood",
                "coords": {
                    "lat": 45.37319,
                    "lng": -75.77203
                },
                "walkTime": 51,
                "bikeTime": 15,
                "driveTime": 11,
                "transitTime": 39,
                "image": "location-lifestyle-06.jpg"
            },
            {
                "id": 24,
                "title": "Ottawa Public Library - Nepean Centrepointe",
                "coords": {
                    "lat": 45.34437,
                    "lng": -75.76251
                },
                "walkTime": 54,
                "bikeTime": 20,
                "driveTime": 11,
                "transitTime": 31,
                "image": "location-lifestyle-07.jpg"
            },
            {
                "id": 25,
                "title": "Dovercourt Recreation Centre",
                "coords": {
                    "lat": 45.38338,
                    "lng": -75.75288
                },
                "walkTime": 40,
                "bikeTime": 13,
                "driveTime": 10,
                "transitTime": 28,
                "image": "location-lifestyle-08.jpg"
            },
            {
                "id": 26,
                "title": "Algonquin College Ottawa Campus",
                "coords": {
                    "lat": 45.34992,
                    "lng": -75.75493
                },
                "walkTime": 36,
                "bikeTime": 15,
                "driveTime": 7,
                "transitTime": 20,
                "image": "location-lifestyle-09.jpg"
            },
            {
                "id": 27,
                "title": "Tailgators",
                "coords": {
                    "lat": 45.34427,
                    "lng": -75.73148
                },
                "walkTime": 42,
                "bikeTime": 12,
                "driveTime": 7,
                "transitTime": 20,
                "image": "location-lifestyle-10.jpg"
            },
            {
                "id": 28,
                "title": "Crestview Outdoor Pool",
                "coords": {
                    "lat": 45.3449,
                    "lng": -75.73815
                },
                "walkTime": 43,
                "bikeTime": 13,
                "driveTime": 8,
                "transitTime": 27,
                "image": "location-lifestyle-11.jpg"
            },
            {
                "id": 29,
                "title": "DARA Tennis Club",
                "coords": {
                    "lat": 45.39137,
                    "lng": -75.7115
                },
                "walkTime": 51,
                "bikeTime": 17,
                "driveTime": 8,
                "transitTime": 33,
                "image": "location-lifestyle-12.jpg"
            },
            {
                "id": 30,
                "title": "Elmdale Lawn Bowls Club",
                "coords": {
                    "lat": 45.39753,
                    "lng": -75.72484
                },
                "walkTime": 53,
                "bikeTime": 20,
                "driveTime": 10,
                "transitTime": 23,
                "image": "location-lifestyle-13.jpg"
            },
            {
                "id": 31,
                "title": "Anytime Fitness Merivale",
                "coords": {
                    "lat": 45.35014,
                    "lng": -75.73112
                },
                "walkTime": 35,
                "bikeTime": 10,
                "driveTime": 6,
                "transitTime": 18,
                "image": "location-lifestyle-14.jpg"
            },
            {
                "id": 32,
                "title": "Renu Massage Therapy and Spa",
                "coords": {
                    "lat": 45.39767,
                    "lng": -75.74077
                },
                "walkTime": 52,
                "bikeTime": 18,
                "driveTime": 9,
                "transitTime": 26,
                "image": "location-lifestyle-15.jpg"
            },
            {
                "id": 33,
                "title": "Royal Canadian Legion Branch 480",
                "coords": {
                    "lat": 45.39118,
                    "lng": -75.75617
                },
                "walkTime": 52,
                "bikeTime": 17,
                "driveTime": 10,
                "transitTime": 27,
                "image": "location-lifestyle-16.jpg"
            }
        ]
    },
    {
        "name": "Transit",
        "items": [
            {
                "id": 65,
                "title": "Central Park/Staten Bus stop",
                "coords": {
                    "lat": 45.36741,
                    "lng": -75.73768
                },
                "walkTime": 2,
                "bikeTime": 1,
                "driveTime": 1,
                "transitTime": 1,
                "image": "location-transit-01.jpg"
            },
            {
                "id": 66,
                "title": "Tunney's Pasture Station",
                "coords": {
                    "lat": 45.40372,
                    "lng": -75.73519
                },
                "walkTime": 66,
                "bikeTime": 26,
                "driveTime": 12,
                "transitTime": 31,
                "image": "location-transit-02.jpg"
            },
            {
                "id": 67,
                "title": "Westboro Station",
                "coords": {
                    "lat": 45.39644,
                    "lng": -75.75207
                },
                "walkTime": 52,
                "bikeTime": 16,
                "driveTime": 11,
                "transitTime": 26,
                "image": "location-transit-03.jpg"
            },
            {
                "id": 68,
                "title": "Baseline Station",
                "coords": {
                    "lat": 45.34778,
                    "lng": -75.76179
                },
                "walkTime": 45,
                "bikeTime": 14,
                "driveTime": 9,
                "transitTime": 24,
                "image": "location-transit-04.jpg"
            }
        ]
    },
    {
        "name": "Parks & Trails",
        "items": [
            {
                "id": 36,
                "title": "General Burns Park",
                "coords": {
                    "lat": 45.3521,
                    "lng": -75.72053
                },
                "walkTime": 40,
                "bikeTime": 13,
                "driveTime": 8,
                "transitTime": 30,
                "image": "location-parks-01.jpg"
            },
            {
                "id": 37,
                "title": "Carlington Heights Reservoir",
                "coords": {
                    "lat": 45.37221,
                    "lng": -75.74146
                },
                "walkTime": 30,
                "bikeTime": 12,
                "driveTime": 8,
                "transitTime": 19,
                "image": "location-parks-02.jpg"
            },
            {
                "id": 38,
                "title": "Central Experimental Farm",
                "coords": {
                    "lat": 45.3875,
                    "lng": -75.70916
                },
                "walkTime": 45,
                "bikeTime": 14,
                "driveTime": 9,
                "transitTime": 39,
                "image": "location-parks-03.jpg"
            },
            {
                "id": 39,
                "title": "Dominion Arboretum",
                "coords": {
                    "lat": 45.39113,
                    "lng": -75.70616
                },
                "walkTime": 52,
                "bikeTime": 17,
                "driveTime": 12,
                "transitTime": 38,
                "image": "location-parks-04.jpg"
            },
            {
                "id": 40,
                "title": "Celebration Park",
                "coords": {
                    "lat": 45.3668,
                    "lng": -75.7355
                },
                "walkTime": 3,
                "bikeTime": 1,
                "driveTime": 2,
                "transitTime": 1,
                "image": "location-parks-05.jpg"
            },
            {
                "id": 41,
                "title": "Dow's Lake",
                "coords": {
                    "lat": 45.39483,
                    "lng": -75.70114
                },
                "walkTime": 67,
                "bikeTime": 20,
                "driveTime": 11,
                "transitTime": 36,
                "image": "location-parks-06.jpg"
            },
            {
                "id": 42,
                "title": "Commissioners Park",
                "coords": {
                    "lat": 45.39749,
                    "lng": -75.70651
                },
                "walkTime": 60,
                "bikeTime": 18,
                "driveTime": 10,
                "transitTime": 32,
                "image": "location-parks-07.jpg"
            },
            {
                "id": 43,
                "title": "Hogs Back",
                "coords": {
                    "lat": 45.37306,
                    "lng": -75.69101
                },
                "walkTime": 56,
                "bikeTime": 16,
                "driveTime": 10,
                "transitTime": 33,
                "image": "location-parks-08.jpg"
            },
            {
                "id": 44,
                "title": "Mooney's Bay",
                "coords": {
                    "lat": 45.36746,
                    "lng": -75.69162
                },
                "walkTime": 59,
                "bikeTime": 18,
                "driveTime": 11,
                "transitTime": 38,
                "image": "location-parks-09.jpg"
            },
            {
                "id": 45,
                "title": "Vincent Massey Park",
                "coords": {
                    "lat": 45.37853,
                    "lng": -75.69887
                },
                "walkTime": 54,
                "bikeTime": 15,
                "driveTime": 10,
                "transitTime": 28,
                "image": "location-parks-10.jpg"
            }
        ]
    }
];

export default mapItems;