import LowerLevelFloorplate from '../assets/floorplates/floorplate-lower-level.png';

const LowerLevel = () => {
    return (
        <div className="suites__floorplate">
            <img src={LowerLevelFloorplate} alt="" />
            <div className="suites__floorplate--units">
                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="3884.51" height="2189.63" viewBox="-10 -10 3884.51 2189.63">
                    <defs>
                        <clipPath id="clip-path" transform="translate(13 8.01)">
                        <rect width="3840" height="2160" fill="none"/>
                        </clipPath>
                        <clipPath id="clip-path-4" transform="translate(13 8.01)">
                        <rect x="1051.66" y="721.73" width="603.15" height="540.55" fill="none"/>
                        </clipPath>
                        <clipPath id="clip-path-5" transform="translate(13 8.01)">
                        <path d="M1387.78,944.45h-38.66c-2-7.7-9-30-30.71-46.31-13.19-9.95-25.17-13.66-33-15.19v-8.2h-33.74v8.39a69.62,69.62,0,0,0-54.19,56.06h-4.69v35.25h4.88c0,5.06.66,30.4,20.81,49.88a68.86,68.86,0,0,0,35.62,18v8.06h36.19V1042c6.93-1.36,20.83-5.87,36.75-21.75a80.21,80.21,0,0,0,22.12-42.38h38.62V944.44Z" fill="none"/>
                        </clipPath>
                        <clipPath id="clip-path-9" transform="translate(13 8.01)">
                        <rect x="2799.82" y="1474.57" width="322.5" height="331.91" fill="none"/>
                        </clipPath>
                        <clipPath id="clip-path-13" transform="translate(13 8.01)">
                        <rect x="46.54" y="-8" width="924.49" height="2189.59" fill="none"/>
                        </clipPath>
                        <clipPath id="clip-path-24" transform="translate(13 8.01)">
                        <rect x="1811.68" y="1008.25" width="966.17" height="999.24" fill="none"/>
                        </clipPath>
                        <clipPath id="clip-path-36" transform="translate(13 8.01)">
                        <rect x="2066.01" y="770.97" width="50.65" height="50.66" fill="none"/>
                        </clipPath>
                        <clipPath id="clip-path-37" transform="translate(13 8.01)">
                        <rect x="2066.01" y="869.44" width="50.65" height="50.66" fill="none"/>
                        </clipPath>
                        <clipPath id="clip-path-38" transform="translate(13 8.01)">
                        <rect x="2008.45" y="978.25" width="50.66" height="50.66" fill="none"/>
                        </clipPath>
                        <clipPath id="clip-path-41" transform="translate(13 8.01)">
                        <polygon points="1880.44 898.65 1880.44 944.46 1917.57 944.46 1917.57 964.72 1943.74 964.72 1943.74 944.46 2001.78 944.46 2010.72 935.64 2010.72 898.65 1880.44 898.65" fill="none"/>
                        </clipPath>
                    </defs>

                    <g id="Units">
                        <g className="suites__unit" id="LC1">
                        <polygon id="C1_UNIT_MERRY-2" data-name="C1 UNIT MERRY-2" points="1422.36 1489.41 1400.54 1469.71 1399.91 1470.41 1395.33 1466.25 1350.31 1516.48 1354.72 1520.34 1344.15 1532.12 1417.56 1598.42 1478.16 1531.07 1426.67 1484.65 1422.36 1489.41" fill="#dadada"/>
                        <g>
                            <g>
                            <path d="M1397.74,1511.88a2.72,2.72,0,0,0-2.77-2c-1.73,0-3.19,1.21-3.19,3.81s1.46,3.81,3.19,3.81a2.73,2.73,0,0,0,2.77-2l1.87.48a4.39,4.39,0,0,1-4.64,3.4c-2.8,0-5.3-2-5.3-5.73s2.5-5.73,5.3-5.73a4.39,4.39,0,0,1,4.64,3.39Z" transform="translate(13 8.01)" fill="#59585b"/>
                            <path d="M1405.35,1508.14v11.2h-2V1511l-2.14,1.28v-2.17l3.28-1.92Z" transform="translate(13 8.01)" fill="#59585b"/>
                            </g>
                            <g>
                            <path d="M1358,1530.69h3.77l-1.1,5.65h-1.22l.15-1.54a3.88,3.88,0,0,1-3.41,1.67c-2,0-4-1.44-4-4.56,0-3.59,2.26-6.9,6.2-6.9a3.47,3.47,0,0,1,3.68,3.37l-1.41.37a2.38,2.38,0,0,0-2.53-2.4c-2.72,0-4.46,2.56-4.46,5.35,0,2.22,1,3.42,2.75,3.42A3.76,3.76,0,0,0,1360,1532h-2.31Z" transform="translate(13 8.01)" fill="#59585b"/>
                            </g>
                            <g>
                            <path d="M1373.75,1529.54a7.09,7.09,0,0,1-2.42,5.57,5.38,5.38,0,0,1-3.76,1.36c-2.31,0-4-1.65-4-4.53a7.07,7.07,0,0,1,2.42-5.57,5.34,5.34,0,0,1,3.76-1.36C1372.1,1525,1373.75,1526.66,1373.75,1529.54Zm-7.09-1.88a6,6,0,0,0-1.57,4.15c0,2.15,1,3.31,2.72,3.31a3.94,3.94,0,0,0,2.9-1.31,6,6,0,0,0,1.56-4.14c0-2.15-1-3.32-2.72-3.32A3.94,3.94,0,0,0,1366.66,1527.66Z" transform="translate(13 8.01)" fill="#59585b"/>
                            </g>
                            <g>
                            <path d="M1378.62,1525.14l-1.92,9.86h4.93l-.28,1.34H1375l2.19-11.2Z" transform="translate(13 8.01)" fill="#59585b"/>
                            </g>
                            <g>
                            <path d="M1388.41,1525.14c2.6,0,4.42,1.47,4.42,4.46,0,3.75-2.56,6.74-6.38,6.74h-3.11l2.19-11.2Zm-1.72,1.34L1385,1535h1.42c3,0,4.85-2.33,4.85-5.25,0-2.2-1.15-3.27-3.2-3.27Z" transform="translate(13 8.01)" fill="#59585b"/>
                            </g>
                            <g>
                            <path d="M1402.17,1525.14l-.27,1.34H1397l-.63,3.22h4l-.25,1.34h-4l-.77,4h5.07l-.27,1.34h-6.5l2.19-11.2Z" transform="translate(13 8.01)" fill="#59585b"/>
                            </g>
                            <g>
                            <path d="M1412.45,1525.14l-2.19,11.2h-1.59l-3.28-8.72-1.7,8.72h-1.42l2.19-11.2h1.59l3.26,8.75,1.72-8.75Z" transform="translate(13 8.01)" fill="#59585b"/>
                            </g>
                            <g>
                            <path d="M1417.13,1531.06h-1.64l-1,5.28H1413l2.19-11.2h3.56c1.77,0,2.85,1,2.85,2.51a3.15,3.15,0,0,1-3,3.31l2.29,5.38h-1.58Zm-.76-4.58-.63,3.23H1418c1.34,0,2.17-.59,2.17-1.81,0-1-.56-1.42-1.6-1.42Z" transform="translate(13 8.01)" fill="#59585b"/>
                            </g>
                            <g>
                            <path d="M1432.67,1529.54a7.09,7.09,0,0,1-2.42,5.57,5.36,5.36,0,0,1-3.76,1.36c-2.31,0-4-1.65-4-4.53a7.07,7.07,0,0,1,2.42-5.57,5.34,5.34,0,0,1,3.76-1.36C1431,1525,1432.67,1526.66,1432.67,1529.54Zm-7.09-1.88a6,6,0,0,0-1.57,4.15c0,2.15,1,3.31,2.72,3.31a3.94,3.94,0,0,0,2.9-1.31,6,6,0,0,0,1.56-4.14c0-2.15-1-3.32-2.72-3.32A3.94,3.94,0,0,0,1425.58,1527.66Z" transform="translate(13 8.01)" fill="#59585b"/>
                            </g>
                            <g>
                            <path d="M1439,1525.14c2.6,0,4.42,1.47,4.42,4.46,0,3.75-2.56,6.74-6.38,6.74h-3.11l2.19-11.2Zm-1.72,1.34-1.65,8.52h1.42c3,0,4.85-2.33,4.85-5.25,0-2.2-1.15-3.27-3.2-3.27Z" transform="translate(13 8.01)" fill="#59585b"/>
                            </g>
                        </g>
                        </g>
                        <g className="suites__unit" id="LC2">
                        <polygon id="C2_UNIT_STELLAR-2" data-name="C2 UNIT STELLAR-2" points="1284.91 1598.15 1358.26 1664.33 1414.89 1601.39 1341.48 1535.09 1284.91 1598.15" fill="#dadada"/>
                        <g>
                            <g>
                            <path d="M1337.47,1576.31a2.72,2.72,0,0,0-2.77-2c-1.73,0-3.19,1.21-3.19,3.81s1.46,3.81,3.19,3.81a2.73,2.73,0,0,0,2.77-2l1.87.47a4.39,4.39,0,0,1-4.64,3.4c-2.8,0-5.3-2-5.3-5.73s2.5-5.73,5.3-5.73a4.39,4.39,0,0,1,4.64,3.39Z" transform="translate(13 8.01)" fill="#59585b"/>
                            <path d="M1340.85,1583l4-5.17a3.06,3.06,0,0,0,.86-2,1.29,1.29,0,0,0-1.38-1.48c-.8,0-1.32.53-1.45,1.48l-1.86-.36a3.21,3.21,0,0,1,3.36-3,3.09,3.09,0,0,1,3.35,3.25,4.29,4.29,0,0,1-1,2.65l-2.74,3.57H1348v1.86h-7.13Z" transform="translate(13 8.01)" fill="#59585b"/>
                            </g>
                            <g>
                            <path d="M1307.27,1589.57H1315l-.27,1.34h-3.16l-1.92,9.86h-1.42l1.92-9.86H1307Z" transform="translate(13 8.01)" fill="#59585b"/>
                            </g>
                            <g>
                            <path d="M1319.08,1595.49h-1.64l-1,5.28H1315l2.19-11.2h3.56c1.77,0,2.85,1,2.85,2.51a3.15,3.15,0,0,1-3,3.31l2.29,5.38h-1.58Zm-.76-4.58-.63,3.23h2.23c1.34,0,2.17-.59,2.17-1.81,0-1-.56-1.42-1.6-1.42Z" transform="translate(13 8.01)" fill="#59585b"/>
                            </g>
                            <g>
                            <path d="M1328.07,1589.57l-2.19,11.2h-1.43l2.19-11.2Z" transform="translate(13 8.01)" fill="#59585b"/>
                            </g>
                            <g>
                            <path d="M1332.14,1589.57l-1.92,9.86h4.93l-.27,1.34h-6.36l2.19-11.2Z" transform="translate(13 8.01)" fill="#59585b"/>
                            <path d="M1340.33,1589.57l-1.92,9.86h4.93l-.27,1.34h-6.36l2.19-11.2Z" transform="translate(13 8.01)" fill="#59585b"/>
                            </g>
                            <g>
                            <path d="M1348.81,1589.57l-2.2,11.2h-1.42l2.19-11.2Z" transform="translate(13 8.01)" fill="#59585b"/>
                            </g>
                            <g>
                            <path d="M1351.46,1589.57h1.43l-1.46,7.49a4.64,4.64,0,0,0-.07.64c0,1.18.6,1.85,2,1.85a2.83,2.83,0,0,0,3-2.49l1.46-7.49h1.43l-1.43,7.39c-.54,2.85-2.26,3.94-4.61,3.94a2.88,2.88,0,0,1-3.2-2.87,5.84,5.84,0,0,1,.1-1.07Z" transform="translate(13 8.01)" fill="#59585b"/>
                            </g>
                            <g>
                            <path d="M1363.58,1589.57l1.47,7.89,4.47-7.89h1.73l-2.2,11.2h-1.42l1.6-8.16-4.07,7.14h-1.05l-1.36-7.14-1.6,8.16h-1.43l2.19-11.2Z" transform="translate(13 8.01)" fill="#59585b"/>
                            </g>
                        </g>
                        </g>
                        <g className="suites__unit" id="LC3">
                        <polygon id="C3_UNIT_INSPIRE-2" data-name="C3 UNIT INSPIRE-2" points="1273.51 1646.76 1280.51 1653.04 1257.06 1678.98 1274.15 1694.41 1267.53 1701.86 1298.98 1730.21 1355.59 1667.3 1300.17 1617.3 1273.51 1646.76" fill="#dadada"/>
                        <g>
                            <g>
                            <path d="M1290.46,1652.39a2.72,2.72,0,0,0-2.77-2c-1.73,0-3.19,1.21-3.19,3.81s1.46,3.81,3.19,3.81a2.73,2.73,0,0,0,2.77-2l1.87.48a4.39,4.39,0,0,1-4.64,3.4c-2.8,0-5.3-2-5.3-5.73s2.5-5.73,5.3-5.73a4.39,4.39,0,0,1,4.64,3.39Z" transform="translate(13 8.01)" fill="#59585b"/>
                            <path d="M1295.65,1656.49a1.81,1.81,0,0,0,1.81,1.63,1.93,1.93,0,0,0,0-3.84,2,2,0,0,0-1,.24l-.8-1.12,2.11-2.9h-3.56v-1.85h6.41v.7l-2.64,3.57a3,3,0,0,1,.45,0c1.73,0,2.79,1.36,2.79,3.33a3.79,3.79,0,0,1-7.54.52Z" transform="translate(13 8.01)" fill="#59585b"/>
                            </g>
                            <g>
                            <path d="M1269.19,1665.65l-2.19,11.2h-1.42l1-5.28h-5l-1.05,5.28h-1.42l2.19-11.2h1.43l-.9,4.57h5l.9-4.57Z" transform="translate(13 8.01)" fill="#59585b"/>
                            <path d="M1272.7,1665.65l-2.2,11.2h-1.42l2.19-11.2Z" transform="translate(13 8.01)" fill="#59585b"/>
                            </g>
                            <g>
                            <path d="M1279.59,1665.65c1.79,0,2.77.91,2.77,2.49a2.76,2.76,0,0,1-1.78,2.61A2.37,2.37,0,0,1,1282,1673c0,2.53-1.8,3.83-4.21,3.83h-4l2.19-11.2Zm-4,9.85h2.32c1.53,0,2.67-.7,2.67-2.2,0-1.14-.69-1.75-1.9-1.75h-2.32Zm1.65-8.51-.63,3.22h2.24c1.08,0,2.13-.55,2.13-1.81,0-1-.58-1.41-1.54-1.41Z" transform="translate(13 8.01)" fill="#59585b"/>
                            </g>
                            <g>
                            <path d="M1287.11,1665.65l-2.19,11.2h-1.43l2.19-11.2Z" transform="translate(13 8.01)" fill="#59585b"/>
                            </g>
                            <g>
                            <path d="M1293.82,1668.17c-.35-1-.87-1.31-1.54-1.31a1.42,1.42,0,0,0-1.55,1.55c0,1,.7,1.44,1.78,2,1.28.67,2.12,1.24,2.12,2.86a3.28,3.28,0,0,1-1.05,2.66,3.89,3.89,0,0,1-3,1,3.11,3.11,0,0,1-3-3.08l1.28-.68c.1,1.32.72,2.41,2.05,2.41a2,2,0,0,0,2.24-2.09c0-1.11-.64-1.52-1.71-2a3.25,3.25,0,0,1-2.19-2.87,3,3,0,0,1,3.28-3.1,2.57,2.57,0,0,1,2.5,2Z" transform="translate(13 8.01)" fill="#59585b"/>
                            </g>
                            <g>
                            <path d="M1305,1673.58c-1,2.21-2.57,3.4-4.83,3.4s-4.07-1.56-4.07-4.48c0-3.56,2.29-7,6.21-7a3.55,3.55,0,0,1,3.64,3.39l-1.36.37c-.27-1.68-1.11-2.42-2.53-2.42-2.95,0-4.48,2.8-4.48,5.48,0,2.11.94,3.29,2.77,3.29,1.47,0,2.48-.7,3.41-2.45Z" transform="translate(13 8.01)" fill="#59585b"/>
                            </g>
                            <g>
                            <path d="M1309.16,1665.65h1.43l-1.46,7.49a4.64,4.64,0,0,0-.07.64c0,1.18.6,1.85,2,1.85a2.84,2.84,0,0,0,3-2.49l1.45-7.49h1.43l-1.43,7.39c-.54,2.85-2.26,3.94-4.61,3.94a2.88,2.88,0,0,1-3.2-2.87,5.84,5.84,0,0,1,.1-1.07Z" transform="translate(13 8.01)" fill="#59585b"/>
                            </g>
                            <g>
                            <path d="M1323.45,1668.17c-.35-1-.87-1.31-1.54-1.31a1.42,1.42,0,0,0-1.55,1.55c0,1,.7,1.44,1.78,2,1.28.67,2.13,1.24,2.13,2.86a3.28,3.28,0,0,1-1.06,2.66,3.89,3.89,0,0,1-3,1,3.11,3.11,0,0,1-3-3.08l1.28-.68c.1,1.32.72,2.41,2,2.41a2,2,0,0,0,2.24-2.09c0-1.11-.64-1.52-1.71-2a3.25,3.25,0,0,1-2.19-2.87,3,3,0,0,1,3.28-3.1,2.57,2.57,0,0,1,2.5,2Z" transform="translate(13 8.01)" fill="#59585b"/>
                            </g>
                        </g>
                        </g>
                        <g className="suites__unit" id="LC16">
                        <polygon id="C16_UNIT_JASMINE" data-name="C16 UNIT JASMINE" points="1709.53 1153.69 1697.94 1143.49 1690.22 1152.04 1691.22 1152.95 1682.19 1162.98 1692.46 1172.4 1696.27 1168.19 1717.71 1187.71 1709.71 1196.54 1758.39 1240.55 1812.65 1180.35 1817.33 1184.49 1853.1 1144.72 1778.2 1077.57 1709.53 1153.69" fill="#dadada"/>
                        <g>
                            <g>
                            <path d="M1755,1133.44a2.72,2.72,0,0,0-2.77-2c-1.73,0-3.19,1.22-3.19,3.81s1.46,3.81,3.19,3.81a2.73,2.73,0,0,0,2.77-1.95l1.87.47a4.39,4.39,0,0,1-4.64,3.4c-2.8,0-5.3-2-5.3-5.73s2.5-5.73,5.3-5.73a4.39,4.39,0,0,1,4.64,3.39Z" transform="translate(13 8.01)" fill="#59585b"/>
                            </g>
                            <g>
                            <path d="M1762.64,1129.7v11.2h-2v-8.39l-2.14,1.28v-2.17l3.28-1.92Z" transform="translate(13 8.01)" fill="#59585b"/>
                            </g>
                            <g>
                            <path d="M1768.8,1133.73a3.51,3.51,0,0,1,3.62,3.65,3.75,3.75,0,0,1-7.49,0,4.18,4.18,0,0,1,.54-2l3.27-5.84H1771l-2.43,4.16Zm-1.85,3.65a1.73,1.73,0,1,0,3.45,0,1.73,1.73,0,1,0-3.45,0Z" transform="translate(13 8.01)" fill="#59585b"/>
                            </g>
                            <g>
                            <path d="M1736.39,1146.7l-2.19,11.2h-1.42l1-5.28h-5l-1,5.28h-1.42l2.19-11.2H1730l-.9,4.57h5l.9-4.57Z" transform="translate(13 8.01)" fill="#59585b"/>
                            </g>
                            <g>
                            <path d="M1747.55,1151.1a7.09,7.09,0,0,1-2.42,5.57,5.38,5.38,0,0,1-3.76,1.36c-2.31,0-4-1.65-4-4.53a7.07,7.07,0,0,1,2.42-5.57,5.34,5.34,0,0,1,3.76-1.36C1745.9,1146.57,1747.55,1148.22,1747.55,1151.1Zm-7.09-1.88a6,6,0,0,0-1.57,4.15c0,2.15,1,3.31,2.72,3.31a3.94,3.94,0,0,0,2.9-1.31,6,6,0,0,0,1.56-4.14c0-2.15-1-3.32-2.72-3.32A3.94,3.94,0,0,0,1740.46,1149.22Z" transform="translate(13 8.01)" fill="#59585b"/>
                            </g>
                            <g>
                            <path d="M1759,1146.7l-2.19,11.2h-1.59l-3.28-8.72-1.7,8.72h-1.42l2.19-11.2h1.59l3.26,8.75,1.72-8.75Z" transform="translate(13 8.01)" fill="#59585b"/>
                            </g>
                            <g>
                            <path d="M1768.09,1146.7l-.27,1.34h-4.91l-.63,3.22h4l-.25,1.34h-4l-.77,4h5.07l-.27,1.34h-6.5l2.19-11.2Z" transform="translate(13 8.01)" fill="#59585b"/>
                            </g>
                            <g>
                            <path d="M1774.46,1149.22c-.35-1-.87-1.31-1.54-1.31a1.42,1.42,0,0,0-1.55,1.55c0,1,.7,1.45,1.78,2,1.28.67,2.12,1.24,2.12,2.86a3.28,3.28,0,0,1-1,2.66,3.89,3.89,0,0,1-3,1,3.11,3.11,0,0,1-3-3.07l1.28-.69c.1,1.33.72,2.41,2,2.41a2,2,0,0,0,2.24-2.09c0-1.11-.64-1.52-1.71-2a3.25,3.25,0,0,1-2.19-2.87,3,3,0,0,1,3.28-3.1,2.55,2.55,0,0,1,2.49,2Z" transform="translate(13 8.01)" fill="#59585b"/>
                            </g>
                            <g>
                            <path d="M1777.45,1146.7h7.73l-.27,1.34h-3.16l-1.92,9.86h-1.42l1.92-9.86h-3.15Z" transform="translate(13 8.01)" fill="#59585b"/>
                            </g>
                            <g>
                            <path d="M1786.46,1146.7h1.5l1.46,5,3.36-5h1.58l-4.46,6.37-1,4.83h-1.42l.94-4.83Z" transform="translate(13 8.01)" fill="#59585b"/>
                            </g>
                        </g>
                        </g>
                        <g className="suites__unit" id="LC17">
                        <polygon id="C17_UNIT_POPPY" data-name="C17 UNIT POPPY" points="1712.06 1187.98 1696.54 1173.85 1657.1 1217.43 1651.59 1212.46 1625.86 1241.03 1698.88 1306.59 1755.71 1243.53 1704.06 1196.82 1712.06 1187.98" fill="#dadada"/>
                        <g>
                            <g>
                            <path d="M1672.85,1221.41a2.72,2.72,0,0,0-2.77-2c-1.73,0-3.19,1.21-3.19,3.81s1.46,3.81,3.19,3.81a2.73,2.73,0,0,0,2.77-2l1.87.48a4.39,4.39,0,0,1-4.64,3.4c-2.8,0-5.3-2-5.3-5.73s2.5-5.73,5.3-5.73a4.39,4.39,0,0,1,4.64,3.39Z" transform="translate(13 8.01)" fill="#59585b"/>
                            </g>
                            <g>
                            <path d="M1680.46,1217.67v11.2h-2v-8.39l-2.14,1.28v-2.17l3.28-1.92Z" transform="translate(13 8.01)" fill="#59585b"/>
                            </g>
                            <g>
                            <path d="M1689.26,1218.5l-4.22,10.37h-2l3.83-9.35h-4.41v-1.85h6.79Z" transform="translate(13 8.01)" fill="#59585b"/>
                            </g>
                            <g>
                            <path d="M1658.76,1234.67l-2.2,11.2h-1.42l2.19-11.2Z" transform="translate(13 8.01)" fill="#59585b"/>
                            </g>
                            <g>
                            <path d="M1665,1234.67c1.79,0,2.77.91,2.77,2.49a2.76,2.76,0,0,1-1.78,2.61,2.37,2.37,0,0,1,1.42,2.27c0,2.53-1.8,3.83-4.21,3.83h-4l2.19-11.2Zm-4,9.86h2.32c1.53,0,2.67-.71,2.67-2.21,0-1.14-.69-1.75-1.9-1.75h-2.32Zm1.65-8.52-.63,3.22h2.24a1.89,1.89,0,0,0,2.13-1.81c0-1-.58-1.41-1.54-1.41Z" transform="translate(13 8.01)" fill="#59585b"/>
                            </g>
                            <g>
                            <path d="M1677.38,1234.67l-.27,1.34h-4.91l-.63,3.22h4l-.25,1.34h-4l-.77,4h5.07l-.27,1.34h-6.5l2.19-11.2Z" transform="translate(13 8.01)" fill="#59585b"/>
                            </g>
                            <g>
                            <path d="M1681.58,1240.59h-1.64l-1,5.28h-1.43l2.19-11.2h3.56c1.77,0,2.85,1,2.85,2.51a3.15,3.15,0,0,1-3,3.31l2.29,5.38h-1.58Zm-.76-4.58-.63,3.23h2.23c1.34,0,2.17-.59,2.17-1.81,0-1-.56-1.42-1.6-1.42Z" transform="translate(13 8.01)" fill="#59585b"/>
                            </g>
                            <g>
                            <path d="M1690.58,1234.67l-2.2,11.2H1687l2.19-11.2Z" transform="translate(13 8.01)" fill="#59585b"/>
                            </g>
                            <g>
                            <path d="M1697.28,1237.19c-.35-1-.87-1.31-1.54-1.31a1.42,1.42,0,0,0-1.55,1.55c0,1,.7,1.45,1.78,2,1.28.67,2.12,1.24,2.12,2.86a3.28,3.28,0,0,1-1,2.66,3.89,3.89,0,0,1-3,1,3.11,3.11,0,0,1-3-3.08l1.28-.68c.1,1.33.72,2.41,2.05,2.41a2,2,0,0,0,2.24-2.09c0-1.11-.64-1.52-1.71-2a3.25,3.25,0,0,1-2.19-2.87,3,3,0,0,1,3.28-3.1,2.57,2.57,0,0,1,2.5,2Z" transform="translate(13 8.01)" fill="#59585b"/>
                            </g>
                        </g>
                        </g>
                        <g className="suites__unit" id="LC18">
                        <polygon id="C18_UNIT_DAHLIA" data-name="C18 UNIT DAHLIA" points="1572.31 1300.5 1645.2 1366.15 1696.21 1309.56 1623.19 1244 1572.31 1300.5" fill="#dadada"/>
                        <g>
                            <g>
                            <path d="M1615.7,1285.18a2.72,2.72,0,0,0-2.77-2c-1.73,0-3.19,1.21-3.19,3.81s1.46,3.81,3.19,3.81a2.73,2.73,0,0,0,2.77-2l1.87.48a4.39,4.39,0,0,1-4.64,3.4c-2.81,0-5.3-2-5.3-5.73s2.49-5.73,5.3-5.73a4.39,4.39,0,0,1,4.64,3.39Z" transform="translate(13 8.01)" fill="#59585b"/>
                            </g>
                            <g>
                            <path d="M1623.31,1281.44v11.2h-2v-8.39l-2.14,1.28v-2.17l3.28-1.92Z" transform="translate(13 8.01)" fill="#59585b"/>
                            </g>
                            <g>
                            <path d="M1632.56,1284.36a2.78,2.78,0,0,1-1.06,2.25,2.9,2.9,0,0,1,1.49,2.68,3.65,3.65,0,0,1-7.3,0,2.89,2.89,0,0,1,1.48-2.68,2.77,2.77,0,0,1-1-2.25,3.22,3.22,0,0,1,6.43,0Zm-4.85,4.93a1.64,1.64,0,1,0,1.63-1.61A1.53,1.53,0,0,0,1627.71,1289.29Zm.29-4.89a1.35,1.35,0,1,0,1.34-1.4A1.3,1.3,0,0,0,1628,1284.4Z" transform="translate(13 8.01)" fill="#59585b"/>
                            </g>
                            <g>
                            <path d="M1592.84,1298.44l1.47,7.89,4.47-7.89h1.72l-2.19,11.2h-1.42l1.6-8.16-4.07,7.14h-1.05l-1.36-7.14-1.61,8.16H1589l2.19-11.2Z" transform="translate(13 8.01)" fill="#59585b"/>
                            </g>
                            <g>
                            <path d="M1607.24,1298.44l1.7,11.2h-1.43l-.4-3h-4.17l-1.59,3h-1.42l6-11.2Zm-3.6,6.86h3.28l-.72-5Z" transform="translate(13 8.01)" fill="#59585b"/>
                            </g>
                            <g>
                            <path d="M1614.31,1298.44l-1.92,9.85h4.93l-.28,1.35h-6.35l2.19-11.2Z" transform="translate(13 8.01)" fill="#59585b"/>
                            </g>
                            <g>
                            <path d="M1622.65,1298.44l-1.92,9.85h4.93l-.28,1.35H1619l2.19-11.2Z" transform="translate(13 8.01)" fill="#59585b"/>
                            </g>
                            <g>
                            <path d="M1637.24,1302.84a7.09,7.09,0,0,1-2.42,5.57,5.38,5.38,0,0,1-3.76,1.36c-2.31,0-4-1.65-4-4.53a7.07,7.07,0,0,1,2.42-5.57,5.34,5.34,0,0,1,3.76-1.36C1635.59,1298.31,1637.24,1300,1637.24,1302.84Zm-7.09-1.88a6,6,0,0,0-1.57,4.15c0,2.14,1,3.31,2.72,3.31a3.94,3.94,0,0,0,2.9-1.31,6.05,6.05,0,0,0,1.56-4.15c0-2.14-1-3.31-2.72-3.31A3.94,3.94,0,0,0,1630.15,1301Z" transform="translate(13 8.01)" fill="#59585b"/>
                            </g>
                            <g>
                            <path d="M1652.8,1298.44l-5.11,11.2h-1.42l-.89-7.68-3.61,7.68h-1.43l-.83-11.2H1641l.45,9.12,3.73-7.94h1.33l.8,7.94,4-9.12Z" transform="translate(13 8.01)" fill="#59585b"/>
                            </g>
                        </g>
                        </g>
                        <g className="suites__unit" id="LC19">
                        <polygon id="C19_UNIT_IRIS" data-name="C19 UNIT IRIS" points="1508.62 1371.22 1581.44 1436.91 1642.53 1369.12 1569.64 1303.47 1508.62 1371.22" fill="#dadada"/>
                        <g>
                            <g>
                            <path d="M1554.6,1351.34a2.72,2.72,0,0,0-2.77-1.95c-1.73,0-3.19,1.21-3.19,3.81s1.46,3.81,3.19,3.81a2.73,2.73,0,0,0,2.77-2l1.87.48a4.39,4.39,0,0,1-4.64,3.4c-2.8,0-5.3-2-5.3-5.73s2.5-5.73,5.3-5.73a4.39,4.39,0,0,1,4.64,3.39Z" transform="translate(13 8.01)" fill="#59585b"/>
                            </g>
                            <g>
                            <path d="M1562.21,1347.6v11.2h-2v-8.39l-2.14,1.28v-2.17l3.28-1.92Z" transform="translate(13 8.01)" fill="#59585b"/>
                            </g>
                            <g>
                            <path d="M1568.06,1354.77a3.51,3.51,0,0,1-3.61-3.65,3.75,3.75,0,0,1,7.49,0,4.19,4.19,0,0,1-.55,2l-3.26,5.84h-2.24l2.43-4.16Zm1.86-3.65a1.73,1.73,0,1,0-3.46,0,1.73,1.73,0,1,0,3.46,0Z" transform="translate(13 8.01)" fill="#59585b"/>
                            </g>
                            <g>
                            <path d="M1543.66,1364.6l-6,11.2h-1.42l-1.55-11.2h1.48l1.16,9.2,4.8-9.2Z" transform="translate(13 8.01)" fill="#59585b"/>
                            </g>
                            <g>
                            <path d="M1547,1364.6l-2.2,11.2h-1.42l2.19-11.2Z" transform="translate(13 8.01)" fill="#59585b"/>
                            </g>
                            <g>
                            <path d="M1558,1369a7.09,7.09,0,0,1-2.42,5.57,5.36,5.36,0,0,1-3.76,1.36c-2.31,0-4-1.65-4-4.53a7.07,7.07,0,0,1,2.42-5.57,5.34,5.34,0,0,1,3.76-1.36C1556.37,1364.47,1558,1366.12,1558,1369Zm-7.09-1.88a6,6,0,0,0-1.57,4.15c0,2.15,1,3.31,2.72,3.31a3.94,3.94,0,0,0,2.9-1.31,6.05,6.05,0,0,0,1.56-4.15c0-2.14-1-3.31-2.72-3.31A3.94,3.94,0,0,0,1550.93,1367.12Z" transform="translate(13 8.01)" fill="#59585b"/>
                            </g>
                            <g>
                            <path d="M1562.89,1364.6l-1.92,9.86h4.93l-.28,1.34h-6.35l2.19-11.2Z" transform="translate(13 8.01)" fill="#59585b"/>
                            </g>
                            <g>
                            <path d="M1576.14,1364.6l-.27,1.34H1571l-.63,3.22h4l-.25,1.34h-4l-.77,4h5.07l-.27,1.34h-6.5l2.19-11.2Z" transform="translate(13 8.01)" fill="#59585b"/>
                            </g>
                            <g>
                            <path d="M1577.74,1364.6h7.73l-.27,1.34H1582l-1.92,9.86h-1.42l1.92-9.86h-3.15Z" transform="translate(13 8.01)" fill="#59585b"/>
                            </g>
                        </g>
                        </g>
                        <g className="suites__unit" id="LC20">
                        <polygon id="C20_UNIT_FREESIA" data-name="C20 UNIT FREESIA" points="1464.22 1420.53 1475.52 1430.72 1429.35 1481.68 1474.74 1522.61 1504.55 1489.26 1525.79 1508.58 1583.69 1444.33 1505.95 1374.19 1464.22 1420.53" fill="#dadada"/>
                        <g>
                            <g>
                            <path d="M1488.37,1426.9a2.72,2.72,0,0,0-2.77-2c-1.73,0-3.19,1.22-3.19,3.81s1.46,3.81,3.19,3.81a2.73,2.73,0,0,0,2.77-2l1.87.47a4.39,4.39,0,0,1-4.64,3.4c-2.81,0-5.3-2-5.3-5.73s2.49-5.73,5.3-5.73a4.39,4.39,0,0,1,4.64,3.39Z" transform="translate(13 8.01)" fill="#59585b"/>
                            </g>
                            <g>
                            <path d="M1491.75,1433.56l4-5.17a3.06,3.06,0,0,0,.86-2,1.29,1.29,0,0,0-1.38-1.48c-.8,0-1.32.53-1.45,1.48l-1.86-.36a3.21,3.21,0,0,1,3.36-3,3.09,3.09,0,0,1,3.35,3.25,4.29,4.29,0,0,1-1,2.65l-2.74,3.57h3.94v1.86h-7.13Z" transform="translate(13 8.01)" fill="#59585b"/>
                            </g>
                            <g>
                            <path d="M1509.15,1428.76c0,3.58-1.46,5.73-4.13,5.73s-4.13-2.15-4.13-5.73,1.46-5.73,4.13-5.73S1509.15,1425.17,1509.15,1428.76Zm-6.27,0c0,2.49.67,3.87,2.14,3.87s2.15-1.38,2.15-3.87-.68-3.88-2.15-3.88S1502.88,1426.26,1502.88,1428.76Z" transform="translate(13 8.01)" fill="#59585b"/>
                            </g>
                            <g>
                            <path d="M1474.47,1451.36l-3.3-5.65-1.11,5.65h-1.42l2.19-11.2h1.43l-.88,4.49,4.77-4.49h1.92l-5.46,5.09,3.59,6.11Z" transform="translate(13 8.01)" fill="#59585b"/>
                            </g>
                            <g>
                            <path d="M1484.42,1440.16l1.7,11.2h-1.43l-.4-3h-4.17l-1.59,3h-1.42l6-11.2Zm-3.6,6.86h3.28l-.72-5Z" transform="translate(13 8.01)" fill="#59585b"/>
                            </g>
                            <g>
                            <path d="M1491.49,1440.16l-1.92,9.86h4.93l-.27,1.34h-6.36l2.19-11.2Z" transform="translate(13 8.01)" fill="#59585b"/>
                            </g>
                            <g>
                            <path d="M1500.07,1440.16l1.47,7.89,4.47-7.89h1.72l-2.19,11.2h-1.42l1.6-8.16-4.07,7.14h-1.05l-1.36-7.14-1.61,8.16h-1.42l2.19-11.2Z" transform="translate(13 8.01)" fill="#59585b"/>
                            </g>
                            <g>
                            <path d="M1512,1440.16l-2.2,11.2h-1.42l2.19-11.2Z" transform="translate(13 8.01)" fill="#59585b"/>
                            </g>
                            <g>
                            <path d="M1518.51,1440.16l1.7,11.2h-1.43l-.4-3h-4.17l-1.59,3h-1.42l6-11.2Zm-3.6,6.86h3.28l-.72-5Z" transform="translate(13 8.01)" fill="#59585b"/>
                            </g>
                        </g>
                        </g>
                    </g>
                    <g id="VR">
                        <g className="suites__vr" data-vr="https://astoria-tours.web.app/Amenities_Lower_Floor/#golf_area" id="vrLobby">
                        <g>
                            <g>
                            <path d="M1602.21,723.09H1606l-1.1,5.65h-1.22l.15-1.54a3.88,3.88,0,0,1-3.41,1.67c-2,0-4-1.44-4-4.56,0-3.59,2.26-6.9,6.2-6.9a3.47,3.47,0,0,1,3.68,3.38l-1.41.36a2.38,2.38,0,0,0-2.53-2.4c-2.72,0-4.46,2.56-4.46,5.35,0,2.22,1,3.42,2.75,3.42a3.76,3.76,0,0,0,3.59-3.09H1602Z" transform="translate(13 8.01)" fill="#59585b"/>
                            </g>
                            <g>
                            <path d="M1618,721.94a7.09,7.09,0,0,1-2.42,5.57,5.38,5.38,0,0,1-3.76,1.36c-2.31,0-4-1.65-4-4.53a7.07,7.07,0,0,1,2.42-5.57,5.34,5.34,0,0,1,3.76-1.36C1616.32,717.41,1618,719.06,1618,721.94Zm-7.09-1.88a6,6,0,0,0-1.57,4.15c0,2.15,1,3.31,2.72,3.31a3.94,3.94,0,0,0,2.9-1.31,6,6,0,0,0,1.56-4.14c0-2.15-1-3.32-2.72-3.32A3.94,3.94,0,0,0,1610.88,720.06Z" transform="translate(13 8.01)" fill="#59585b"/>
                            </g>
                            <g>
                            <path d="M1622.85,717.54l-1.92,9.86h4.93l-.28,1.34h-6.35l2.19-11.2Z" transform="translate(13 8.01)" fill="#59585b"/>
                            <path d="M1636,717.54l-.27,1.34h-5l-.64,3.35h4l-.27,1.34h-4l-1,5.17h-1.43l2.19-11.2Z" transform="translate(13 8.01)" fill="#59585b"/>
                            </g>
                            <g>
                            <path d="M1581.51,737.06c-.35-1-.87-1.31-1.54-1.31a1.42,1.42,0,0,0-1.55,1.55c0,1,.7,1.45,1.78,2,1.28.67,2.13,1.24,2.13,2.86a3.28,3.28,0,0,1-1.06,2.66,3.89,3.89,0,0,1-3,1,3.11,3.11,0,0,1-3-3.08l1.28-.68c.1,1.33.72,2.41,2,2.41a2,2,0,0,0,2.24-2.09c0-1.11-.64-1.52-1.71-2a3.25,3.25,0,0,1-2.19-2.87,3,3,0,0,1,3.28-3.1,2.55,2.55,0,0,1,2.49,2Z" transform="translate(13 8.01)" fill="#59585b"/>
                            </g>
                            <g>
                            <path d="M1587.21,734.54l-2.2,11.2h-1.42l2.19-11.2Z" transform="translate(13 8.01)" fill="#59585b"/>
                            </g>
                            <g>
                            <path d="M1591.45,734.54l1.47,7.89,4.47-7.89h1.72l-2.19,11.2h-1.42l1.6-8.16-4.07,7.14h-1l-1.36-7.14-1.61,8.16h-1.42l2.19-11.2Z" transform="translate(13 8.01)" fill="#59585b"/>
                            </g>
                            <g>
                            <path d="M1601.83,734.54h1.43L1601.8,742a4.64,4.64,0,0,0-.07.64c0,1.18.6,1.85,2,1.85a2.84,2.84,0,0,0,3-2.49l1.45-7.49h1.43l-1.43,7.39c-.54,2.85-2.26,3.94-4.61,3.94a2.88,2.88,0,0,1-3.2-2.87,5.84,5.84,0,0,1,.1-1.07Z" transform="translate(13 8.01)" fill="#59585b"/>
                            </g>
                            <g>
                            <path d="M1613.43,734.54l-1.92,9.86h4.93l-.27,1.34h-6.36l2.19-11.2Z" transform="translate(13 8.01)" fill="#59585b"/>
                            </g>
                            <g>
                            <path d="M1624.64,734.54l1.7,11.2h-1.42l-.41-3h-4.17l-1.59,3h-1.42l6-11.2Zm-3.6,6.86h3.28l-.72-5Z" transform="translate(13 8.01)" fill="#59585b"/>
                            </g>
                            <g>
                            <path d="M1627.91,734.54h7.73l-.27,1.34h-3.16l-1.92,9.86h-1.42l1.92-9.86h-3.15Z" transform="translate(13 8.01)" fill="#59585b"/>
                            </g>
                            <g>
                            <path d="M1645.55,738.94a7.09,7.09,0,0,1-2.42,5.57,5.38,5.38,0,0,1-3.76,1.36c-2.31,0-4-1.65-4-4.53a7.07,7.07,0,0,1,2.42-5.57,5.34,5.34,0,0,1,3.76-1.36C1643.9,734.41,1645.55,736.06,1645.55,738.94Zm-7.09-1.88a6,6,0,0,0-1.57,4.15c0,2.15,1,3.31,2.72,3.31a3.94,3.94,0,0,0,2.9-1.31,6,6,0,0,0,1.56-4.14c0-2.15-1-3.32-2.72-3.32A3.94,3.94,0,0,0,1638.46,737.06Z" transform="translate(13 8.01)" fill="#59585b"/>
                            </g>
                            <g>
                            <path d="M1650.91,740.46h-1.64l-1,5.28h-1.42l2.19-11.2h3.56c1.77,0,2.85,1,2.85,2.51a3.15,3.15,0,0,1-3,3.31l2.29,5.38h-1.58Zm-.76-4.58-.63,3.23h2.23c1.34,0,2.17-.59,2.17-1.81,0-1-.56-1.42-1.6-1.42Z" transform="translate(13 8.01)" fill="#59585b"/>
                            </g>
                        </g>
                        <g>
                            <circle cx="1719.68" cy="747.99" r="26.03" fill="#baa6cc"/>
                            <g id="Group_125-6" data-name="Group 125-6">
                            <g id="Light-6">
                                <g>
                                <g>
                                    <path d="M1693.45,740.16l1.35-.18a3,3,0,0,0,.79,1.66,2,2,0,0,0,1.37.51,2.27,2.27,0,0,0,2.27-2.31,2,2,0,0,0-.61-1.53,2.09,2.09,0,0,0-1.55-.61,3.89,3.89,0,0,0-1,.15l.15-1.19.21,0a2.79,2.79,0,0,0,1.56-.45,1.55,1.55,0,0,0,.69-1.39,1.64,1.64,0,0,0-.5-1.24,1.82,1.82,0,0,0-1.3-.49,1.85,1.85,0,0,0-1.32.5,2.5,2.5,0,0,0-.67,1.49l-1.36-.24a3.48,3.48,0,0,1,1.13-2.11,3.27,3.27,0,0,1,2.19-.75,3.59,3.59,0,0,1,1.66.39,2.82,2.82,0,0,1,1.16,1.05,2.71,2.71,0,0,1,.4,1.43,2.3,2.3,0,0,1-.38,1.3,2.58,2.58,0,0,1-1.13.93,2.51,2.51,0,0,1,1.51.94,2.77,2.77,0,0,1,.55,1.77,3.26,3.26,0,0,1-1.06,2.45,3.7,3.7,0,0,1-2.66,1,3.45,3.45,0,0,1-2.41-.87A3.33,3.33,0,0,1,1693.45,740.16Z" transform="translate(13 8.01)" fill="#fff"/>
                                    <path d="M1709,734.75l-1.35.1a2.56,2.56,0,0,0-.51-1.16,1.9,1.9,0,0,0-2.49-.21,3.06,3.06,0,0,0-1,1.36,7.2,7.2,0,0,0-.38,2.55,3.09,3.09,0,0,1,1.2-1.11,3.2,3.2,0,0,1,1.48-.36,3,3,0,0,1,2.3,1,3.54,3.54,0,0,1,1,2.57,4.22,4.22,0,0,1-.45,1.93,3.17,3.17,0,0,1-1.22,1.36,3.37,3.37,0,0,1-1.78.48A3.47,3.47,0,0,1,1703,742a6.28,6.28,0,0,1-1.07-4.1,7.41,7.41,0,0,1,1.18-4.65,3.42,3.42,0,0,1,2.78-1.26,3.08,3.08,0,0,1,2.13.73A3.13,3.13,0,0,1,1709,734.75Zm-5.53,4.75a3.15,3.15,0,0,0,.3,1.34,2.2,2.2,0,0,0,.83,1,2.1,2.1,0,0,0,1.12.34,1.92,1.92,0,0,0,1.47-.7,2.71,2.71,0,0,0,.62-1.88,2.53,2.53,0,0,0-.61-1.8,2,2,0,0,0-1.53-.66,2.09,2.09,0,0,0-1.56.66A2.37,2.37,0,0,0,1703.5,739.5Z" transform="translate(13 8.01)" fill="#fff"/>
                                    <path d="M1710.57,737.63a10,10,0,0,1,.4-3.14,3.9,3.9,0,0,1,1.2-1.84,3.05,3.05,0,0,1,2-.65,3.27,3.27,0,0,1,1.56.36,3,3,0,0,1,1.11,1,5.62,5.62,0,0,1,.68,1.64,11.11,11.11,0,0,1,.25,2.6,10.34,10.34,0,0,1-.4,3.14,4,4,0,0,1-1.19,1.84,3.09,3.09,0,0,1-2,.65,3,3,0,0,1-2.5-1.15A7.22,7.22,0,0,1,1710.57,737.63Zm1.39,0a6.87,6.87,0,0,0,.64,3.62,1.81,1.81,0,0,0,3.13,0,10.5,10.5,0,0,0,0-7.22,1.88,1.88,0,0,0-1.58-.9,1.73,1.73,0,0,0-1.49.79A6.94,6.94,0,0,0,1712,737.63Z" transform="translate(13 8.01)" fill="#fff"/>
                                </g>
                                </g>
                            </g>
                            <path d="M1722.55,727a2.83,2.83,0,1,0,2.45,2.8A2.64,2.64,0,0,0,1722.55,727Zm0,4.9a2.12,2.12,0,1,1,1.84-2.1A2,2,0,0,1,1722.55,731.87Z" transform="translate(13 8.01)" fill="#fff"/>
                            <path id="Path_76-6" data-name="Path 76-6" d="M1691.4,738s-8.6,3.6-2.6,9,15.6,4.9,15.6,4.9" transform="translate(13 8.01)" fill="none" stroke="#fdfeff" stroke-linecap="round" stroke-width="2"/>
                            <path id="Path_77-6" data-name="Path 77-6" d="M1701,748.68l.34.34.81.81,1,1,.85.85c.14.14.27.28.41.41h0v-.53l-.34.34-.81.81-1,1-.84.84-.41.41h0a.39.39,0,0,0,0,.55.39.39,0,0,0,.54,0l.34-.34.81-.8,1-1,.85-.85.41-.41h0a.38.38,0,0,0,0-.54l-.34-.34-.81-.81-1-1-.84-.84c-.14-.14-.27-.28-.41-.41h0a.38.38,0,0,0-.54,0,.39.39,0,0,0,0,.54h0Z" transform="translate(13 8.01)" fill="#fff" stroke="#fdfeff"/>
                            <path id="Path_78-6" data-name="Path 78-6" d="M1710.39,751.88s12-1.42,14.52-6.27-3.19-7.26-3.19-7.26" transform="translate(13 8.01)" fill="none" stroke="#fdfeff" stroke-linecap="round" stroke-width="2"/>
                            </g>
                        </g>
                        <g>
                            <g>
                            <path d="M1700.49,661.88l-1.92,9.86h4.93l-.27,1.34h-6.36l2.19-11.2Z" transform="translate(13 8.01)" fill="#59585b"/>
                            </g>
                            <g>
                            <path d="M1715.09,666.28a7.09,7.09,0,0,1-2.42,5.57,5.38,5.38,0,0,1-3.76,1.36c-2.31,0-4-1.65-4-4.53a7.07,7.07,0,0,1,2.42-5.57,5.34,5.34,0,0,1,3.76-1.36C1713.44,661.75,1715.09,663.4,1715.09,666.28ZM1708,664.4a6,6,0,0,0-1.57,4.15c0,2.15,1,3.31,2.72,3.31a3.94,3.94,0,0,0,2.9-1.31,6,6,0,0,0,1.56-4.14c0-2.15-1-3.32-2.72-3.32A3.94,3.94,0,0,0,1708,664.4Z" transform="translate(13 8.01)" fill="#59585b"/>
                            </g>
                            <g>
                            <path d="M1718.65,661.88h1.43l-1.46,7.49a4.64,4.64,0,0,0-.07.64c0,1.18.6,1.85,2,1.85a2.84,2.84,0,0,0,3-2.49l1.45-7.49h1.43l-1.43,7.39c-.54,2.85-2.26,3.94-4.61,3.94a2.88,2.88,0,0,1-3.2-2.87,5.84,5.84,0,0,1,.1-1.07Z" transform="translate(13 8.01)" fill="#59585b"/>
                            </g>
                            <g>
                            <path d="M1736.81,661.88l-2.19,11.2H1733l-3.28-8.72-1.7,8.72h-1.42l2.19-11.2h1.59l3.26,8.75,1.72-8.75Z" transform="translate(13 8.01)" fill="#59585b"/>
                            </g>
                            <g>
                            <path d="M1743.48,667.43h3.77l-1.1,5.65h-1.22l.15-1.54a3.88,3.88,0,0,1-3.41,1.67c-2,0-4-1.44-4-4.56,0-3.59,2.26-6.9,6.2-6.9a3.47,3.47,0,0,1,3.68,3.37l-1.41.37a2.38,2.38,0,0,0-2.53-2.4c-2.72,0-4.46,2.56-4.46,5.35,0,2.22,1,3.42,2.75,3.42a3.76,3.76,0,0,0,3.59-3.09h-2.31Z" transform="translate(13 8.01)" fill="#59585b"/>
                            </g>
                            <g>
                            <path d="M1757.43,661.88l-.27,1.34h-4.91l-.63,3.22h4l-.25,1.34h-4l-.77,4h5.07l-.27,1.34h-6.5l2.19-11.2Z" transform="translate(13 8.01)" fill="#59585b"/>
                            </g>
                        </g>
                        </g>
                        <g className="suites__vr" data-vr="https://astoria-tours.web.app/Amenities_Lower_Floor/#gym_yoga" id="vrFitness">
                        <g>
                            <g>
                            <path d="M1760,965.69l-.27,1.34h-5l-.64,3.35h4l-.28,1.34h-4l-1,5.17h-1.43l2.19-11.2Z" transform="translate(13 8.01)" fill="#59585b"/>
                            </g>
                            <g>
                            <path d="M1763.45,965.69l-2.2,11.2h-1.42l2.19-11.2Z" transform="translate(13 8.01)" fill="#59585b"/>
                            </g>
                            <g>
                            <path d="M1765.12,965.69h7.73l-.27,1.34h-3.16l-1.92,9.86h-1.42L1768,967h-3.15Z" transform="translate(13 8.01)" fill="#59585b"/>
                            </g>
                            <g>
                            <path d="M1783,965.69l-2.19,11.2h-1.59l-3.28-8.72-1.7,8.72h-1.42l2.19-11.2h1.59l3.26,8.75,1.72-8.75Z" transform="translate(13 8.01)" fill="#59585b"/>
                            </g>
                            <g>
                            <path d="M1792.09,965.69l-.27,1.34h-4.91l-.63,3.22h4l-.25,1.34h-4l-.77,4h5.07l-.27,1.34h-6.5l2.19-11.2Z" transform="translate(13 8.01)" fill="#59585b"/>
                            </g>
                            <g>
                            <path d="M1798.46,968.21c-.35-1-.87-1.31-1.54-1.31a1.42,1.42,0,0,0-1.55,1.55c0,1,.7,1.45,1.78,2,1.28.67,2.12,1.24,2.12,2.86a3.28,3.28,0,0,1-1,2.66,3.89,3.89,0,0,1-3,1,3.11,3.11,0,0,1-3-3.08l1.28-.68c.1,1.33.72,2.41,2,2.41a2,2,0,0,0,2.24-2.09c0-1.11-.64-1.52-1.71-2.05a3.25,3.25,0,0,1-2.19-2.87,3,3,0,0,1,3.28-3.1,2.55,2.55,0,0,1,2.49,2Z" transform="translate(13 8.01)" fill="#59585b"/>
                            <path d="M1806.78,968.21c-.35-1-.87-1.31-1.54-1.31a1.42,1.42,0,0,0-1.55,1.55c0,1,.7,1.45,1.78,2,1.28.67,2.12,1.24,2.12,2.86a3.28,3.28,0,0,1-1,2.66,3.89,3.89,0,0,1-3,1,3.11,3.11,0,0,1-3-3.08l1.28-.68c.1,1.33.72,2.41,2.05,2.41a2,2,0,0,0,2.24-2.09c0-1.11-.64-1.52-1.71-2.05a3.25,3.25,0,0,1-2.19-2.87,3,3,0,0,1,3.28-3.1,2.57,2.57,0,0,1,2.5,2Z" transform="translate(13 8.01)" fill="#59585b"/>
                            </g>
                        </g>
                        <g>
                            <circle cx="1792.75" cy="935.88" r="26.03" fill="#baa6cc"/>
                            <g id="Group_125-7" data-name="Group 125-7">
                            <g id="Light-7">
                                <g>
                                <g>
                                    <path d="M1766.52,928.06l1.35-.18a3,3,0,0,0,.79,1.66,2,2,0,0,0,1.37.5,2.18,2.18,0,0,0,1.61-.66,2.22,2.22,0,0,0,.66-1.64,2,2,0,0,0-.61-1.53,2.09,2.09,0,0,0-1.55-.61,3.89,3.89,0,0,0-1,.15l.15-1.19.21,0a2.79,2.79,0,0,0,1.56-.45,1.55,1.55,0,0,0,.69-1.39,1.64,1.64,0,0,0-.5-1.24,1.82,1.82,0,0,0-1.3-.49,1.85,1.85,0,0,0-1.32.5A2.5,2.5,0,0,0,1768,923l-1.36-.24a3.48,3.48,0,0,1,1.13-2.11,3.27,3.27,0,0,1,2.19-.75,3.59,3.59,0,0,1,1.66.39,2.82,2.82,0,0,1,1.16,1.05,2.71,2.71,0,0,1,.4,1.43,2.3,2.3,0,0,1-.38,1.3,2.58,2.58,0,0,1-1.13.93,2.51,2.51,0,0,1,1.51.94,2.77,2.77,0,0,1,.55,1.77,3.26,3.26,0,0,1-1.06,2.45,3.7,3.7,0,0,1-2.66,1,3.45,3.45,0,0,1-2.41-.87A3.33,3.33,0,0,1,1766.52,928.06Z" transform="translate(13 8.01)" fill="#fff"/>
                                    <path d="M1782.1,922.65l-1.35.1a2.56,2.56,0,0,0-.51-1.16,1.9,1.9,0,0,0-2.49-.21,3.14,3.14,0,0,0-1,1.36,7.2,7.2,0,0,0-.38,2.55,3.09,3.09,0,0,1,1.2-1.11,3.2,3.2,0,0,1,1.48-.36,3,3,0,0,1,2.3,1,3.54,3.54,0,0,1,1,2.57,4.22,4.22,0,0,1-.45,1.93,3.17,3.17,0,0,1-1.22,1.36,3.37,3.37,0,0,1-1.78.48,3.47,3.47,0,0,1-2.76-1.25,6.28,6.28,0,0,1-1.07-4.1,7.41,7.41,0,0,1,1.18-4.65,3.42,3.42,0,0,1,2.78-1.26,3,3,0,0,1,3.13,2.75Zm-5.53,4.75a3.15,3.15,0,0,0,.3,1.34,2.2,2.2,0,0,0,.83,1,2.09,2.09,0,0,0,1.12.33,1.9,1.9,0,0,0,1.47-.69,2.71,2.71,0,0,0,.62-1.88,2.53,2.53,0,0,0-.61-1.8,2,2,0,0,0-1.53-.66,2.09,2.09,0,0,0-1.56.66A2.37,2.37,0,0,0,1776.57,927.4Z" transform="translate(13 8.01)" fill="#fff"/>
                                    <path d="M1783.64,925.53a10,10,0,0,1,.4-3.14,3.9,3.9,0,0,1,1.2-1.84,3.05,3.05,0,0,1,2-.65,3.24,3.24,0,0,1,1.56.36,3,3,0,0,1,1.11,1,5.62,5.62,0,0,1,.68,1.64,10.59,10.59,0,0,1,.25,2.6,10,10,0,0,1-.4,3.14,4,4,0,0,1-1.19,1.84,3.09,3.09,0,0,1-2,.65,3,3,0,0,1-2.5-1.15A7.22,7.22,0,0,1,1783.64,925.53Zm1.39,0a6.87,6.87,0,0,0,.64,3.62,1.82,1.82,0,0,0,3.13,0,10.5,10.5,0,0,0,0-7.22,1.88,1.88,0,0,0-1.58-.9,1.73,1.73,0,0,0-1.49.79A6.94,6.94,0,0,0,1785,925.53Z" transform="translate(13 8.01)" fill="#fff"/>
                                </g>
                                </g>
                            </g>
                            <path d="M1795.62,914.87a2.83,2.83,0,1,0,2.45,2.8A2.64,2.64,0,0,0,1795.62,914.87Zm0,4.9a2.12,2.12,0,1,1,1.84-2.1A2,2,0,0,1,1795.62,919.77Z" transform="translate(13 8.01)" fill="#fff"/>
                            <path id="Path_76-7" data-name="Path 76-7" d="M1764.47,925.87s-8.6,3.6-2.6,9,15.6,4.9,15.6,4.9" transform="translate(13 8.01)" fill="none" stroke="#fdfeff" stroke-linecap="round" stroke-width="2"/>
                            <path id="Path_77-7" data-name="Path 77-7" d="M1774.09,936.58l.34.34.81.81,1,1,.85.85c.14.14.27.28.41.41h0v-.53l-.34.34-.81.81-1,1-.84.84-.41.41h0a.39.39,0,0,0,0,.55.39.39,0,0,0,.54,0l.34-.34.81-.8,1-1,.85-.85c.14-.14.28-.27.41-.41h0a.38.38,0,0,0,0-.54l-.34-.34-.81-.81-1-1-.84-.84-.41-.41h0a.38.38,0,0,0-.54,0,.39.39,0,0,0,0,.54h0Z" transform="translate(13 8.01)" fill="#fff" stroke="#fdfeff"/>
                            <path id="Path_78-7" data-name="Path 78-7" d="M1783.46,939.78s12-1.42,14.52-6.27-3.19-7.26-3.19-7.26" transform="translate(13 8.01)" fill="none" stroke="#fdfeff" stroke-linecap="round" stroke-width="2"/>
                            </g>
                        </g>
                        </g>
                        <g className="suites__vr" data-vr="https://astoria-tours.web.app/Amenities_Lower_Floor/#theatre" id="vrTheatre">
                        <g>
                            <g>
                            <path d="M1702.76,510.42h7.73l-.27,1.34h-3.16l-1.92,9.86h-1.42l1.92-9.86h-3.15Z" transform="translate(13 8.01)" fill="#59585b"/>
                            </g>
                            <g>
                            <path d="M1720.45,510.42l-2.19,11.2h-1.42l1-5.28h-5l-1,5.28h-1.43l2.19-11.2h1.43l-.9,4.57h5l.9-4.57Z" transform="translate(13 8.01)" fill="#59585b"/>
                            </g>
                            <g>
                            <path d="M1729.58,510.42l-.27,1.34h-4.91l-.63,3.22h4l-.25,1.34h-4l-.77,4h5.07l-.27,1.34h-6.5l2.19-11.2Z" transform="translate(13 8.01)" fill="#59585b"/>
                            </g>
                            <g>
                            <path d="M1736.18,510.42l1.7,11.2h-1.42l-.41-3h-4.17l-1.59,3h-1.42l6-11.2Zm-3.6,6.86h3.28l-.72-5Z" transform="translate(13 8.01)" fill="#59585b"/>
                            </g>
                            <g>
                            <path d="M1739.45,510.42h7.73l-.27,1.34h-3.16l-1.92,9.86h-1.42l1.92-9.86h-3.15Z" transform="translate(13 8.01)" fill="#59585b"/>
                            </g>
                            <g>
                            <path d="M1751.25,516.34h-1.64l-1,5.28h-1.42l2.19-11.2h3.56c1.77,0,2.85,1,2.85,2.51a3.15,3.15,0,0,1-3,3.31l2.29,5.38h-1.58Zm-.76-4.58-.63,3.23h2.23c1.34,0,2.17-.59,2.17-1.81,0-1-.56-1.42-1.6-1.42Z" transform="translate(13 8.01)" fill="#59585b"/>
                            </g>
                            <g>
                            <path d="M1765.16,510.42l-.27,1.34H1760l-.63,3.22h4l-.25,1.34h-4l-.77,4h5.07l-.27,1.34h-6.5l2.19-11.2Z" transform="translate(13 8.01)" fill="#59585b"/>
                            </g>
                        </g>
                        <g>
                            <circle cx="1746.32" cy="482.67" r="26.03" fill="#baa6cc"/>
                            <g id="Group_125-8" data-name="Group 125-8">
                            <g id="Light-8">
                                <g>
                                <g>
                                    <path d="M1720.09,474.85l1.35-.18a3,3,0,0,0,.79,1.66,2,2,0,0,0,1.37.5,2.25,2.25,0,0,0,2.27-2.3,2,2,0,0,0-.61-1.53,2.09,2.09,0,0,0-1.55-.61,3.89,3.89,0,0,0-1,.15l.15-1.19.21,0a2.79,2.79,0,0,0,1.56-.45,1.55,1.55,0,0,0,.69-1.39,1.64,1.64,0,0,0-.5-1.24,1.82,1.82,0,0,0-1.3-.49,1.85,1.85,0,0,0-1.32.5,2.5,2.5,0,0,0-.67,1.49l-1.36-.24a3.48,3.48,0,0,1,1.13-2.11,3.27,3.27,0,0,1,2.19-.75,3.59,3.59,0,0,1,1.66.39,2.82,2.82,0,0,1,1.16,1.05,2.71,2.71,0,0,1,.4,1.43,2.3,2.3,0,0,1-.38,1.3,2.63,2.63,0,0,1-1.13.93,2.51,2.51,0,0,1,1.51.94,2.83,2.83,0,0,1,.55,1.77,3.26,3.26,0,0,1-1.06,2.45,3.7,3.7,0,0,1-2.66,1,3.45,3.45,0,0,1-2.41-.87A3.33,3.33,0,0,1,1720.09,474.85Z" transform="translate(13 8.01)" fill="#fff"/>
                                    <path d="M1735.67,469.44l-1.35.1a2.56,2.56,0,0,0-.51-1.16,1.9,1.9,0,0,0-2.49-.21,3.14,3.14,0,0,0-1,1.36,7.2,7.2,0,0,0-.38,2.55,3.09,3.09,0,0,1,1.2-1.11,3.2,3.2,0,0,1,1.48-.36,3,3,0,0,1,2.3,1,3.54,3.54,0,0,1,.95,2.57,4.22,4.22,0,0,1-.45,1.93,3.17,3.17,0,0,1-1.22,1.36,3.37,3.37,0,0,1-1.78.48,3.47,3.47,0,0,1-2.76-1.25,6.28,6.28,0,0,1-1.07-4.1,7.41,7.41,0,0,1,1.18-4.65,3.42,3.42,0,0,1,2.78-1.26,3,3,0,0,1,3.13,2.75Zm-5.53,4.75a3.15,3.15,0,0,0,.3,1.34,2.2,2.2,0,0,0,.83,1,2.09,2.09,0,0,0,1.12.33,1.9,1.9,0,0,0,1.47-.69,2.71,2.71,0,0,0,.62-1.88,2.53,2.53,0,0,0-.61-1.8,2,2,0,0,0-1.53-.66,2.09,2.09,0,0,0-1.56.66A2.37,2.37,0,0,0,1730.14,474.19Z" transform="translate(13 8.01)" fill="#fff"/>
                                    <path d="M1737.21,472.32a10,10,0,0,1,.4-3.14,3.9,3.9,0,0,1,1.2-1.84,3.05,3.05,0,0,1,2-.65,3.24,3.24,0,0,1,1.56.36,3,3,0,0,1,1.11,1,5.62,5.62,0,0,1,.68,1.64,11.11,11.11,0,0,1,.25,2.6,10,10,0,0,1-.4,3.14,4,4,0,0,1-1.19,1.84,3.09,3.09,0,0,1-2,.65,3,3,0,0,1-2.5-1.15A7.22,7.22,0,0,1,1737.21,472.32Zm1.39,0a6.87,6.87,0,0,0,.64,3.62,1.82,1.82,0,0,0,3.13,0,10.5,10.5,0,0,0,0-7.22,1.88,1.88,0,0,0-1.58-.9,1.73,1.73,0,0,0-1.49.79A6.94,6.94,0,0,0,1738.6,472.32Z" transform="translate(13 8.01)" fill="#fff"/>
                                </g>
                                </g>
                            </g>
                            <path d="M1749.18,461.66a2.82,2.82,0,1,0,2.45,2.8A2.64,2.64,0,0,0,1749.18,461.66Zm0,4.9a2.12,2.12,0,1,1,1.84-2.1A2,2,0,0,1,1749.18,466.56Z" transform="translate(13 8.01)" fill="#fff"/>
                            <path id="Path_76-8" data-name="Path 76-8" d="M1718,472.66s-8.6,3.6-2.6,9,15.6,4.9,15.6,4.9" transform="translate(13 8.01)" fill="none" stroke="#fdfeff" stroke-linecap="round" stroke-width="2"/>
                            <path id="Path_77-8" data-name="Path 77-8" d="M1727.66,483.37l.34.34.81.81,1,1,.85.85.41.41h0v-.53l-.34.34-.81.81-1,1-.84.84c-.14.14-.28.27-.41.41h0a.39.39,0,0,0,0,.55.39.39,0,0,0,.54,0l.34-.34.81-.8,1-1,.85-.85c.14-.14.28-.27.41-.41h0a.38.38,0,0,0,0-.54l-.34-.34-.81-.81-1-1-.84-.84-.41-.41h0a.38.38,0,0,0-.54,0,.39.39,0,0,0,0,.54h0Z" transform="translate(13 8.01)" fill="#fff" stroke="#fdfeff"/>
                            <path id="Path_78-8" data-name="Path 78-8" d="M1737,486.57s12-1.42,14.52-6.27-3.19-7.26-3.19-7.26" transform="translate(13 8.01)" fill="none" stroke="#fdfeff" stroke-linecap="round" stroke-width="2"/>
                            </g>
                        </g>
                        </g>
                        <g className="suites__vr" data-vr="https://astoria-tours.web.app/Amenities_Lower_Floor/#pool" id="vrPool">
                        <g>
                            <g>
                            <path d="M1865.76,963l-1,4.8h-1.42l2.19-11.2h3.59c1.81,0,3,1,3,2.65,0,2.5-1.66,3.75-4.13,3.75Zm4.91-3.49a1.42,1.42,0,0,0-1.6-1.57h-2.33l-.72,3.71h2.44A2,2,0,0,0,1870.67,959.5Z" transform="translate(13 8.01)" fill="#59585b"/>
                            </g>
                            <g>
                            <path d="M1883.13,961a7.09,7.09,0,0,1-2.42,5.57,5.38,5.38,0,0,1-3.76,1.36c-2.31,0-4-1.65-4-4.53a7.07,7.07,0,0,1,2.42-5.57,5.34,5.34,0,0,1,3.76-1.36C1881.48,956.46,1883.13,958.11,1883.13,961Zm-7.09-1.88a6,6,0,0,0-1.57,4.15c0,2.15,1,3.31,2.72,3.31a3.94,3.94,0,0,0,2.9-1.31,6,6,0,0,0,1.56-4.14c0-2.15-1-3.32-2.72-3.32A3.94,3.94,0,0,0,1876,959.11Z" transform="translate(13 8.01)" fill="#59585b"/>
                            </g>
                            <g>
                            <path d="M1894.84,961a7.09,7.09,0,0,1-2.42,5.57,5.38,5.38,0,0,1-3.76,1.36c-2.31,0-4-1.65-4-4.53a7.07,7.07,0,0,1,2.42-5.57,5.34,5.34,0,0,1,3.76-1.36C1893.19,956.46,1894.84,958.11,1894.84,961Zm-7.09-1.88a6,6,0,0,0-1.57,4.15c0,2.15,1,3.31,2.72,3.31a3.94,3.94,0,0,0,2.9-1.31,6,6,0,0,0,1.56-4.14c0-2.15-1-3.32-2.72-3.32A3.94,3.94,0,0,0,1887.75,959.11Z" transform="translate(13 8.01)" fill="#59585b"/>
                            </g>
                            <g>
                            <path d="M1899.71,956.59l-1.92,9.86h4.93l-.28,1.34h-6.35l2.19-11.2Z" transform="translate(13 8.01)" fill="#59585b"/>
                            </g>
                        </g>
                        <g>
                            <circle cx="1896.35" cy="928.85" r="26.03" fill="#baa6cc"/>
                            <g id="Group_125-9" data-name="Group 125-9">
                            <g id="Light-9">
                                <g>
                                <g>
                                    <path d="M1870.12,921l1.35-.18a3,3,0,0,0,.79,1.66,2,2,0,0,0,1.37.51,2.27,2.27,0,0,0,2.27-2.31,2,2,0,0,0-.61-1.53,2.09,2.09,0,0,0-1.55-.61,3.89,3.89,0,0,0-1,.15l.15-1.19.21,0a2.79,2.79,0,0,0,1.56-.45,1.55,1.55,0,0,0,.69-1.39,1.64,1.64,0,0,0-.5-1.24,1.82,1.82,0,0,0-1.3-.49,1.85,1.85,0,0,0-1.32.5,2.5,2.5,0,0,0-.67,1.49l-1.36-.24a3.48,3.48,0,0,1,1.13-2.11,3.27,3.27,0,0,1,2.19-.75,3.59,3.59,0,0,1,1.66.39,2.78,2.78,0,0,1,1.16,1.06,2.68,2.68,0,0,1,.4,1.42,2.3,2.3,0,0,1-.38,1.3,2.58,2.58,0,0,1-1.13.93,2.51,2.51,0,0,1,1.51.94,2.77,2.77,0,0,1,.54,1.77,3.25,3.25,0,0,1-1,2.45,3.7,3.7,0,0,1-2.66,1,3.45,3.45,0,0,1-2.41-.87A3.33,3.33,0,0,1,1870.12,921Z" transform="translate(13 8.01)" fill="#fff"/>
                                    <path d="M1885.7,915.61l-1.35.1a2.56,2.56,0,0,0-.51-1.16,1.9,1.9,0,0,0-2.49-.21,3.06,3.06,0,0,0-1,1.36,7.2,7.2,0,0,0-.38,2.55,3.09,3.09,0,0,1,1.2-1.11,3.2,3.2,0,0,1,1.48-.36,3,3,0,0,1,2.3,1,3.54,3.54,0,0,1,1,2.57,4.22,4.22,0,0,1-.45,1.93,3.17,3.17,0,0,1-1.22,1.36,3.37,3.37,0,0,1-1.78.48,3.47,3.47,0,0,1-2.76-1.25,6.28,6.28,0,0,1-1.07-4.1,7.41,7.41,0,0,1,1.18-4.65,3.42,3.42,0,0,1,2.78-1.26,3.08,3.08,0,0,1,2.13.73A3.13,3.13,0,0,1,1885.7,915.61Zm-5.53,4.75a3.15,3.15,0,0,0,.3,1.34,2.2,2.2,0,0,0,.83,1,2.1,2.1,0,0,0,1.12.34,1.92,1.92,0,0,0,1.47-.7,2.71,2.71,0,0,0,.62-1.88,2.53,2.53,0,0,0-.61-1.8,2,2,0,0,0-1.53-.65,2.08,2.08,0,0,0-1.56.65A2.37,2.37,0,0,0,1880.17,920.36Z" transform="translate(13 8.01)" fill="#fff"/>
                                    <path d="M1887.24,918.49a10,10,0,0,1,.4-3.14,3.9,3.9,0,0,1,1.2-1.84,3.05,3.05,0,0,1,2-.65,3.27,3.27,0,0,1,1.56.36,3,3,0,0,1,1.11,1,5.62,5.62,0,0,1,.68,1.64,11.11,11.11,0,0,1,.25,2.6,10.34,10.34,0,0,1-.4,3.14,4,4,0,0,1-1.19,1.84,3.09,3.09,0,0,1-2,.65,3,3,0,0,1-2.5-1.14A7.28,7.28,0,0,1,1887.24,918.49Zm1.39,0a6.87,6.87,0,0,0,.64,3.62,1.81,1.81,0,0,0,3.13,0,10.5,10.5,0,0,0,0-7.22,1.88,1.88,0,0,0-1.58-.9,1.73,1.73,0,0,0-1.49.79A6.94,6.94,0,0,0,1888.63,918.49Z" transform="translate(13 8.01)" fill="#fff"/>
                                </g>
                                </g>
                            </g>
                            <path d="M1899.21,907.83a2.82,2.82,0,1,0,2.45,2.8A2.64,2.64,0,0,0,1899.21,907.83Zm0,4.9a2.12,2.12,0,1,1,1.84-2.1A2,2,0,0,1,1899.21,912.73Z" transform="translate(13 8.01)" fill="#fff"/>
                            <path id="Path_76-9" data-name="Path 76-9" d="M1868.07,918.83s-8.6,3.6-2.6,9,15.6,4.9,15.6,4.9" transform="translate(13 8.01)" fill="none" stroke="#fdfeff" stroke-linecap="round" stroke-width="2"/>
                            <path id="Path_77-9" data-name="Path 77-9" d="M1877.69,929.55l.34.34.81.81,1,1,.85.85.41.41h0v-.53l-.34.34-.81.81-1,1-.84.84c-.14.14-.28.27-.41.41h0a.39.39,0,0,0,0,.55.39.39,0,0,0,.54,0l.34-.34.81-.8,1-1,.85-.85.41-.41h0a.38.38,0,0,0,0-.54l-.34-.34-.81-.81-1-1-.84-.84c-.14-.14-.27-.28-.41-.41h0a.38.38,0,0,0-.54,0,.39.39,0,0,0,0,.54h0Z" transform="translate(13 8.01)" fill="#fff" stroke="#fdfeff"/>
                            <path id="Path_78-9" data-name="Path 78-9" d="M1887.05,932.74s12-1.42,14.52-6.27-3.19-7.26-3.19-7.26" transform="translate(13 8.01)" fill="none" stroke="#fdfeff" stroke-linecap="round" stroke-width="2"/>
                            </g>
                        </g>
                        </g>
                    </g>
                </svg>
            </div>
        </div>
    );
}

export default LowerLevel;