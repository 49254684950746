import { useEffect, useState } from 'react';

// Context
import AppContext from './context/context';

// Components
import Nav from './components/Nav.component';

// Pages
import HomePage from './pages/Home.page';
import SuitesPage from './pages/Suites.page';
import PropertyTourPage from './pages/PropertyTour.page';
import NeighbourhoodTourPage from './pages/NeighbourhoodTour.page';
import GalleryPage from './pages/Gallery.page';


import './sass/main.scss';
import Loading from './pages/Loading.page';

function App() {
  const [currentSection, setCurrentSection] = useState('home');
  const [loading, setLoading] = useState(true);

  const LoadedContent = () => {
    const classes = ['content__wrapper'];
    !loading && classes.push('active');

    return (
      <div className={classes.join(' ')} data-screen="loaded">
        <div className="content" onWaiting={() => console.log('ITS DONE')}>
          {currentSection === 'home' && <HomePage setCurrentSection={setCurrentSection} />}
          {currentSection === 'suites' && <SuitesPage />}
          {<PropertyTourPage isActive={currentSection === 'propertyTour'} />}
          {currentSection === 'neighbourhoodTour' && <NeighbourhoodTourPage />}
          {currentSection === 'gallery' && <GalleryPage />}
        </div>

        <Nav currentSection={currentSection} setCurrentSection={setCurrentSection} />
      </div>
    )
  }


  return (
    <AppContext>
      <div className="App">
        <div className="container">
            {loading && <Loading
              loading={loading}
              setLoading={setLoading}
            />}
            <LoadedContent isActive={loading === false} />
        </div>
      </div>
    </AppContext>
  );
}

export default App;
